import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ZodTypeAny } from 'zod';

import { isFieldRequired } from '@/helpers/formUtils';

type CheckboxProps = React.ComponentProps<typeof Checkbox>;

interface Props extends CheckboxProps {
  name: string;
  label: string;
  schema?: ZodTypeAny;
  handleChange?: (name: string, checked: boolean) => void;
}

const HookFormCheckbox = ({ name, label, schema, handleChange, ...restProps }: Props) => {
  const { control } = useFormContext();

  const required = schema !== undefined && isFieldRequired(schema, name);

  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(event) => {
                  onChange(event);
                  if (!handleChange) return;
                  handleChange(name, event.target.checked);
                }}
                {...restProps}
              />
            }
            label={
              required && !restProps.disabled ? (
                <span>
                  {label} <span style={{ color: 'red' }}>*</span>
                </span>
              ) : (
                label
              )
            }
          />
        )}
      />
    </FormControl>
  );
};

export default HookFormCheckbox;