import { Link, useLocation } from '@tanstack/react-router';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';

import NavigationMenuListItem from '@/components/navigation/NavigationMenuListItem';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu';
import { FeatureFlag } from '@/contexts/Settings.types';
import { useFetchData } from '@/helpers/hooks';
import { cn } from '@/lib/utils';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';

interface Page {
  name: string;
  elementId: string;
  path?: string;
  items?: { title: string; link: string }[];
}

const getPages = (features: FeatureFlag[] | undefined): Page[] => {
  return [
    { name: 'What is weavr', elementId: 'intro' },
    { name: 'Meet our mob', elementId: 'meet-the-team' },
    { name: 'Pricing', elementId: 'pricing' },
    ...(features?.includes('CaseStudies')
      ? [{ name: 'Case Studies', elementId: 'casestudies', path: 'casestudies' }]
      : []),
    ...(features?.includes('Blogs')
      ? [{ name: 'Blogs', elementId: 'blogs', path: 'blogs' }]
      : []),

    {
      name: 'Resources',
      elementId: 'resources',
      items: [
        { title: 'Terms and conditions', link: 'terms' },
        { title: 'Privacy policy', link: 'privacy_policy' },
        { title: 'Security', link: 'security' },
      ],
    },
  ];
};
export default function NavigationMenuShadUnauth() {
  const location = useLocation();
  const normalizePath = (path: string) => {
    return path.split('/')[1].toLowerCase();
  };
  const isActivePage = (pagePath: string) => {
    const normalizedCurrentPath = normalizePath(location.pathname);
    const normalizedPagePath = normalizePath(pagePath);
    return normalizedCurrentPath === normalizedPagePath;
  };

  const { data: enabledFeatures } = useFetchData<FeatureFlag[]>(
    ['enabledFeatures'],
    `public/FeaturesEnabled`,
    {
      refetchOnWindowFocus: false,
    },
  );

  const { globalSettings, setGlobalSettings, features } = useGlobalSettingsStore(
    useShallow((state) => ({
      setGlobalSettings: state.setGlobalSettings,
      globalSettings: state.globalSettings,
      features: state.globalSettings.enabledFeatures,
    })),
  );

  useEffect(() => {
    if (!features.length && enabledFeatures?.length) {
      setGlobalSettings({
        ...globalSettings,
        enabledFeatures: enabledFeatures,
      });
    }
  }, [features, enabledFeatures, globalSettings, setGlobalSettings]);

  const pages = useMemo(() => getPages(enabledFeatures), [enabledFeatures]);

  const pagesWithoutItem = useMemo(() => pages.filter((page) => !page.items), [pages]);

  const pagesWithItem = pages.filter((page) => page.items);

  return (
    <>
      <NavigationMenu>
        <NavigationMenuList>
          {pagesWithoutItem.map((page, index) => {
            if (!page.items) {
              return (
                <NavigationMenuItem key={page.elementId}>
                  <Link
                    to={page.path ? `/${page.path}` : `/#${page.elementId}`}
                    className={navigationMenuTriggerStyle()}
                  >
                    {page.name}
                  </Link>
                </NavigationMenuItem>
              );
            } else {
              return (
                <NavigationMenuItem key={`${page.name}-${index}`}>
                  <NavigationMenuTrigger
                    className={cn({
                      'border-b-2 border-b-brown-dark bg-accent text-accent-foreground':
                        isActivePage(`/${page.name.replace(' ', '_').toLowerCase()}`),
                    })}
                  >
                    {page.name}
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <div className="ri flex w-[400px] overflow-auto">
                      <ul className="flex-grow p-4">
                        {page.items.map((subPage) => {
                          const title = subPage.title;
                          return (
                            <NavigationMenuListItem
                              key={`${page.name}-${title}`}
                              title={title}
                              to={`/${subPage.link}`}
                            >
                              {title}
                            </NavigationMenuListItem>
                          );
                        })}
                      </ul>
                    </div>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              );
            }
          })}
        </NavigationMenuList>
      </NavigationMenu>
      <NavigationMenu>
        <NavigationMenuList>
          {pagesWithItem.map((page, index) => {
            if (!page.items) {
              return (
                <NavigationMenuItem key={page.elementId}>
                  <Link
                    to={page.path ? `/${page.path}` : `/#${page.elementId}`}
                    className={navigationMenuTriggerStyle()}
                  >
                    {page.name}
                  </Link>
                </NavigationMenuItem>
              );
            } else {
              return (
                <NavigationMenuItem key={`${page.name}-${index}`}>
                  <NavigationMenuTrigger
                    className={cn({
                      'border-b-2 border-b-brown-dark bg-accent text-accent-foreground':
                        isActivePage(`/${page.name.replace(' ', '_').toLowerCase()}`),
                    })}
                  >
                    {page.name}
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <div className="ri flex w-[400px] overflow-auto">
                      <ul className="flex-grow p-4">
                        {page.items.map((subPage) => {
                          const title = subPage.title;
                          return (
                            <NavigationMenuListItem
                              key={`${page.name}-${title}`}
                              title={title}
                              to={`/${subPage.link}`}
                            >
                              {title}
                            </NavigationMenuListItem>
                          );
                        })}
                      </ul>
                    </div>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              );
            }
          })}
        </NavigationMenuList>
      </NavigationMenu>
    </>
  );
}
