import { styled } from '@mui/material/styles';

interface StyledCardProps {
  isActive: boolean;
  groupedById?: string;
  className?: string;
}

const StyledCard = styled('div')<StyledCardProps>(({ theme, isActive, className }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginLeft: '',
  paddingBlock: '0.3rem',
  marginBlock: '0.3rem',
  paddingInline: '1rem',
  ...(isActive &&
    !className?.includes('gradient-border') && {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[3],
    }),
  variants: [
    {
      props: ({ groupedById }) => groupedById,
      style: {
        marginLeft: '25px',
      },
    },
    {
      props: ({ isActive }) => isActive,
      style: {
        paddingBlock: '10px',
        marginBlock: '10px',
      },
    },
  ],
}));

export default StyledCard;
