import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';

import { DropdownOption } from '@/components/dropdownOption.type';

type MenuProps = {
  buttonLabel: React.ReactNode;
  menuItems: string[] | DropdownOption[];
  menuAction: (item: string) => void;
  closeOnClick?: boolean;
  showSelected?: boolean;
  initialSelectedItems?: string[];
  multiple?: boolean;
  disabled?: boolean;
};

const BasicMenu = React.memo(function BasicMenu({
  buttonLabel,
  menuItems,
  menuAction,
  closeOnClick = false,
  showSelected = false,
  initialSelectedItems,
  multiple = false,
  disabled = false,
}: MenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(
    new Set(initialSelectedItems ?? []),
  );
  const open = Boolean(anchorEl);

  useEffect(() => {
    setSelectedItems(new Set(initialSelectedItems));
  }, [initialSelectedItems]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: string) => {
    if (disabled) return;

    menuAction(item);
    if (multiple) {
      setSelectedItems((prevSelectedItems) => {
        const updatedSelectedItems = new Set(prevSelectedItems);
        if (updatedSelectedItems.has(item)) {
          updatedSelectedItems.delete(item);
        } else {
          updatedSelectedItems.add(item);
        }
        return updatedSelectedItems;
      });
    } else setSelectedItems(new Set([item]));

    if (closeOnClick) {
      handleClose();
    }
  };

  const isDropdownOption = (item: string | DropdownOption): item is DropdownOption => {
    return (item as DropdownOption).id !== undefined;
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={disabled}
      >
        {buttonLabel}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems.map((item) => {
          const menuItem = isDropdownOption(item)
            ? item
            : { title: item, id: item, disabled: false };

          const isSelected = selectedItems.has(menuItem.id);

          return (
            <MenuItem
              key={menuItem.id}
              disabled={menuItem.disabled}
              onClick={() => handleMenuItemClick(menuItem.id)}
              style={{
                backgroundColor: showSelected && isSelected ? '#faf4f0' : 'inherit',
              }}
            >
              <ListItemText
                inset={menuItem.inset}
                sx={{
                  '& .MuiListItemText-inset': {
                    paddingLeft: '20px !important',
                  },
                }}
              >
                {menuItem.title}
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
});

export default BasicMenu;
