import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PhotoCameraIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.5 6.00002H16.5L15.78 4.55002C15.6961 4.38359 15.5674 4.2439 15.4084 4.14672C15.2494 4.04954 15.0664 3.99873 14.88 4.00002H10.12C9.93364 3.99873 9.75062 4.04954 9.59159 4.14672C9.43257 4.2439 9.30386 4.38359 9.22 4.55002L8.5 6.00002H4.5C4.23478 6.00002 3.98043 6.10538 3.79289 6.29292C3.60536 6.48045 3.5 6.73481 3.5 7.00002V19C3.5 19.2652 3.60536 19.5196 3.79289 19.7071C3.98043 19.8947 4.23478 20 4.5 20H20.5C20.7652 20 21.0196 19.8947 21.2071 19.7071C21.3946 19.5196 21.5 19.2652 21.5 19V7.00002C21.5 6.73481 21.3946 6.48045 21.2071 6.29292C21.0196 6.10538 20.7652 6.00002 20.5 6.00002ZM12.5 16C11.9067 16 11.3266 15.8241 10.8333 15.4944C10.3399 15.1648 9.95542 14.6963 9.72836 14.1481C9.5013 13.5999 9.44189 12.9967 9.55764 12.4148C9.6734 11.8328 9.95912 11.2983 10.3787 10.8787C10.7982 10.4591 11.3328 10.1734 11.9147 10.0577C12.4967 9.94191 13.0999 10.0013 13.6481 10.2284C14.1962 10.4554 14.6648 10.84 14.9944 11.3333C15.3241 11.8267 15.5 12.4067 15.5 13C15.5 13.7957 15.1839 14.5587 14.6213 15.1213C14.0587 15.684 13.2956 16 12.5 16Z"
          fill="#F6921E"
          stroke="black"
        />
        <path
          d="M15.5 13C15.5 13.5934 15.3241 14.1734 14.9944 14.6667C14.6648 15.1601 14.1962 15.5446 13.6481 15.7717C13.0999 15.9987 12.4967 16.0581 11.9147 15.9424C11.3328 15.8266 10.7982 15.5409 10.3787 15.1213C9.95912 14.7018 9.6734 14.1672 9.55764 13.5853C9.44189 13.0034 9.5013 12.4002 9.72836 11.852C9.95542 11.3038 10.3399 10.8353 10.8333 10.5056C11.3266 10.176 11.9067 10 12.5 10C13.2956 10 14.0587 10.3161 14.6213 10.8787C15.1839 11.4413 15.5 12.2044 15.5 13ZM20.5 6.00002H16.5L15.78 4.55002C15.6961 4.38359 15.5674 4.2439 15.4084 4.14672C15.2494 4.04954 15.0664 3.99873 14.88 4.00002H10.12C9.93364 3.99873 9.75062 4.04954 9.59159 4.14672C9.43257 4.2439 9.30386 4.38359 9.22 4.55002L8.5 6.00002H4.5C4.23478 6.00002 3.98043 6.10538 3.79289 6.29292C3.60536 6.48045 3.5 6.73481 3.5 7.00002V19C3.5 19.2652 3.60536 19.5196 3.79289 19.7071C3.98043 19.8947 4.23478 20 4.5 20H20.5C20.7652 20 21.0196 19.8947 21.2071 19.7071C21.3946 19.5196 21.5 19.2652 21.5 19V7.00002C21.5 6.73481 21.3946 6.48045 21.2071 6.29292C21.0196 6.10538 20.7652 6.00002 20.5 6.00002Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
