import React, { useEffect, useState } from 'react';
import Confetti from 'react-dom-confetti';

interface ConfettiComponentProps {
  show: boolean;
}

const ConfettiComponent: React.FC<ConfettiComponentProps> = ({ show }) => {
  const [active, setActive] = useState(show);

  const confettiConfig = {
    angle: 180,
    spread: 360,
    startVelocity: 25, // Reduced velocity
    elementCount: 200, // Reduced element count
    dragFriction: 0.11, // Slightly reduced drag
    duration: 3000, // Reduced duration
    stagger: 2, // Reduced stagger
    width: '12px', // Slightly larger confetti pieces
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    setActive(show);

    if (show) {
      timeoutId = setTimeout(() => {
        setActive(false);
      }, confettiConfig.duration);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [show]);

  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
      }}
    >
      <Confetti active={active} config={confettiConfig} />
    </div>
  );
};

export default ConfettiComponent;
