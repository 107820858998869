import { Cancel } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Card,
  CardContent,
  Collapse,
  debounce,
  IconButton,
  InputBase,
  Paper,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { getRouteApi } from '@tanstack/react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { DropdownOption } from '@/components/dropdownOption.type';
import ButtonMenu from '@/components/mui/ButtonMenu';
import MultiCheckBox from '@/components/mui/MultiCheckbox';
import useUpdateFilters from '@/hooks/useUpdateFilters';
import BulkUpdatePanel from '@/pages/strategies/BulkUpdatePanel';
import {
  filterDeliverablesByDueDate,
  getDeliverableDueItems,
  updateDeliverableDueFilter,
} from '@/pages/strategies/helper/deliverableUtils';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import { Action } from '@/pages/strategies/types/Action.types';
import { Contribution } from '@/pages/strategies/types/Contribution.types';
import { Deliverable } from '@/pages/strategies/types/deliverable.types';
import { StatusDropdownOption } from '@/pages/strategies/types/Strategy.types';
import { strategyItemFilters } from '@/types/itemFilter';

type Props = {
  handleSearch: (term: string) => void;
  deliverablesStatusList: StatusDropdownOption[];
  allActionsInStrategy: Action[];
  allDeliverables: Deliverable[];
  responseTypeItems: DropdownOption[];
  selectedContributions: Contribution[];
};

export const StrategySearchPanel = ({
  handleSearch,
  deliverablesStatusList,
  responseTypeItems,
  allDeliverables,
  allActionsInStrategy,
  selectedContributions,
}: Props) => {
  const {
    dropdownOptions,
    selectedActionIds,
    selectedDeliverableIds,
    selectedContributionIds,
  } = useStrategyStore(
    useShallow((state) => ({
      dropdownOptions: state.dropdownOptions,
      selectedActionIds: state.selectedActionIds,
      selectedDeliverableIds: state.selectedDeliverableIds,
      selectedContributionIds: state.selectedContributionIds,
    })),
  );
  const routeApi = getRouteApi('/strategies');
  const updateFilters = useUpdateFilters<strategyItemFilters>(routeApi);

  const {
    status: filterStatuses,
    businessUnit: filteredBusinessUnits,
    actionArea: filteredActionAreas,
    frequency: filteredFrequency,
    responseType: filteredResponseTypes,
    deliverableDue: filteredDeliverableDue,
  } = routeApi.useSearch();
  const [recentFilters, setRecentFilters] = useState<string[]>(() => {
    try {
      const items = localStorage.getItem('strategyRecentFilters');
      return items ? JSON.parse(items) : [];
    } catch {
      return [];
    }
  });
  const [searchTerm, setSearchTerm] = useState('');

  const updateRecentFilters = useCallback((a: string) => {
    setRecentFilters((prev) => {
      const index = prev.indexOf(a);
      if (index !== -1) {
        prev.splice(index, 1);
      } else {
        prev.push(a);
      }
      // Keep only the last 5 items
      localStorage.setItem('strategyRecentFilters', JSON.stringify(prev || []));
      return [...prev];
    });
  }, []);
  const debouncedHandleSearch = debounce((value) => handleSearch(value), 600);

  const deliverableDueItems: DropdownOption[] = useMemo(
    () =>
      getDeliverableDueItems(
        allDeliverables
          .map((deliverable) => deliverable.targetEndDate)
          .filter((x) => x !== undefined),
      ),
    [allDeliverables],
  );

  useEffect(() => {
    const deliverableDueNumber = Number(filteredDeliverableDue);
    if (!isNaN(deliverableDueNumber) && deliverableDueNumber > 0) {
      const filteredDeliverables = filterDeliverablesByDueDate(
        deliverableDueItems,
        deliverableDueNumber,
      );
      updateFilters('deliverableDue', filteredDeliverables);
    }
  }, [filteredDeliverableDue, deliverableDueItems, updateFilters]);

  const handleDeliverableDueFilter = useCallback(
    (items: string[]) => {
      updateDeliverableDueFilter(items, updateFilters, filteredDeliverableDue);
    },
    [filteredDeliverableDue, updateFilters],
  );
  return (
    <Card elevation={4}>
      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',

            minWidth: '330px',
          }}
        >
          <IconButton type="button" aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            className="w-full"
            placeholder="Search Actions, Deliverables, Contributions..."
            inputProps={{
              'aria-label': 'Search...',
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              },
            }}
            value={searchTerm}
            onChange={(e) => {
              const value = e.target.value;
              setSearchTerm(value);
              debouncedHandleSearch(value);
            }}
          />

          {searchTerm && (
            <IconButton
              aria-label="clear"
              onClick={() => {
                setSearchTerm('');
                handleSearch('');
              }}
            >
              <Cancel />
            </IconButton>
          )}
        </Paper>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {(!!filterStatuses ||
            !!filteredBusinessUnits ||
            !!filteredResponseTypes ||
            !!filteredDeliverableDue ||
            !!filteredActionAreas ||
            !!filteredFrequency) && (
            <Chip
              key="resetButton"
              onClick={() => {
                updateFilters('status', undefined);
                updateFilters('businessUnit', undefined);
                updateFilters('actionArea', undefined);
                updateFilters('responseType', undefined);
                updateFilters('deliverableDue', undefined);
              }}
              color="error"
              label="Reset filter"
              variant="outlined"
              icon={<Cancel />}
            ></Chip>
          )}
          {recentFilters.some((x) => x === 'deliverableStatus') &&
            !!dropdownOptions?.statuses.length && (
              <MultiCheckBox
                key="deliverableStatus"
                items={deliverablesStatusList}
                onItemsSelected={(items) => updateFilters('status', items)}
                width={200}
                selectedItems={filterStatuses || []}
                label="Deliverable Status"
              />
            )}
          {recentFilters.some((x) => x === 'businessUnit') &&
            !!dropdownOptions?.businessUnits?.length && (
              <MultiCheckBox
                key="businessUnits"
                items={dropdownOptions?.businessUnits as DropdownOption[]}
                onItemsSelected={(items) => updateFilters('businessUnit', items)}
                selectedItems={filteredBusinessUnits || []}
                label="Business Units"
                width={200}
              />
            )}
          {recentFilters.some((x) => x === 'actionArea') &&
            !!dropdownOptions &&
            dropdownOptions?.actionAreas?.length > 0 && (
              <MultiCheckBox
                key="actionArea"
                items={dropdownOptions?.actionAreas.filter((a) =>
                  allActionsInStrategy.some((fa) => fa.areaId === a.id),
                )}
                onItemsSelected={(items) => updateFilters('actionArea', items)}
                width={200}
                selectedItems={filteredActionAreas || []}
                label="Action Area"
              />
            )}
          {recentFilters.some((x) => x === 'frequency') && (
            <MultiCheckBox
              key="frequency"
              items={[
                { id: '0', title: 'Once' },
                { id: '2', title: 'Monthly' },
                { id: '4', title: 'Quarterly' },
                { id: '8', title: 'Twice a year' },
                { id: '16', title: 'Annual' },
              ]}
              onItemsSelected={(items) => updateFilters('frequency', items)}
              width={200}
              selectedItems={filteredFrequency || []}
              label="Frequency"
            />
          )}
          {recentFilters.some((x) => x === 'questionType') &&
            responseTypeItems.length > 0 && (
              <MultiCheckBox
                key="questionTypes"
                items={responseTypeItems}
                onItemsSelected={(items) => updateFilters('responseType', items)}
                selectedItems={filteredResponseTypes || []}
                label="Question Type"
                width={200}
              />
            )}
          {recentFilters.some((x) => x === 'deliverableDue') &&
            deliverableDueItems.length > 0 && (
              <MultiCheckBox
                key="deliverableDue"
                items={deliverableDueItems}
                onItemsSelected={handleDeliverableDueFilter}
                hideSelectAll={true}
                selectedItems={filteredDeliverableDue || []}
                label="Deliverable Due"
                width={200}
              />
            )}
          <Collapse
            in={
              selectedActionIds.length > 0 ||
              selectedDeliverableIds.length > 0 ||
              selectedContributionIds.length > 0
            }
            orientation={'horizontal'}
            mountOnEnter={true}
            unmountOnExit={true}
            timeout={'auto'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <BulkUpdatePanel
              selectedActions={allActionsInStrategy.filter((a) =>
                selectedActionIds.includes(a.id),
              )}
              selectedDeliverables={allDeliverables.filter((d) =>
                selectedDeliverableIds.includes(d.id),
              )}
              selectedContributions={selectedContributions}
            ></BulkUpdatePanel>
          </Collapse>
        </div>
        <ButtonMenu
          buttonLabel="Add Filter +"
          multiple={true}
          menuItems={[
            { title: 'Deliverable Status', id: 'deliverableStatus' },
            { title: 'Business Units', id: 'businessUnit' },
            { title: 'Action Area', id: 'actionArea' },
            { title: 'Frequency', id: 'frequency' },
            { title: 'Question Type', id: 'questionType' },
            { title: 'Deliverable Due', id: 'deliverableDue' },
          ]}
          menuAction={updateRecentFilters}
          initialSelectedItems={recentFilters}
          showSelected={true}
        />
      </CardContent>
    </Card>
  );
};
