import AddIcon from '@mui/icons-material/Add';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Chip,
  createFilterOptions,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { memo, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';

import { DropdownOption } from '@/components/dropdownOption.type';
import HookFormAutoComplete from '@/components/HookFormAutoComplete';
import HookFormDatePicker from '@/components/HookFormDatePicker';
import HookFormSelect from '@/components/HookFormSelect';
import HookFormSwitch from '@/components/HookFormSwitch';
import DeleteIconRed from '@/components/icons/DeleteIconRed';
import { getAvailableToDate } from '@/helpers/dateUtils';
import { useDeleteData } from '@/helpers/hooks';
import { emptyGuid, getFrequency } from '@/helpers/utils';
import PeriodUpdateDialog from '@/pages/strategies/PeriodUpdateDialog';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import {
  ContributionCardProps,
  ContributionPeriod,
  Periodicity,
  responseTypesNoOption,
} from '@/pages/strategies/types/Contribution.types';
import CardCountChip from '@/pages/strategies/wrapperCard/CardCountChip';
import { useConfirm } from '@/store/useConfirmDialogStore';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';

import ContributionInfo from './contributionInfo/ContributionInfo';
import NewReportTypeDialog from './NewReportTypeDialog';

export type AutoCompleteOption = {
  inputValue: string;
  title: string;
  id: string;
};

type Props = {
  handleLastActionClick?: (e: React.MouseEvent<HTMLElement> | null) => void;
  onBlurParent?: () => void;
  showSaveIcon?: boolean;
  businessUnits?: string[];
} & Pick<
  ContributionCardProps,
  'item' | 'dropdownOptions' | 'setActiveContributions' | 'disabled'
>;

const ContributionCardContent = memo(
  function ContributionCardContent({
    item,
    dropdownOptions,
    setActiveContributions,
    handleLastActionClick,
    disabled = false,
    onBlurParent,
    showSaveIcon,
    businessUnits,
  }: Props) {
    const {
      subIndicators,
      activeContributions,
      strategyEnd,
      allowHoldByContribution,
      strategyStatusId,
      allowAutoUpdate,
    } = useStrategyStore(
      useShallow((state) => ({
        subIndicators: state.subIndicators,
        activeContributions: state.activeContributions,
        strategyStatusId: state.primaryStrategy?.statusId,
        strategyEnd: state.strategyEnd,
        allowHoldByContribution: state.allowHoldByContribution,
        allowAutoUpdate: state.allowAutoUpdate,
      })),
    );

    const strategyNotStarted =
      strategyStatusId === 'daeb927e-0644-4857-a135-ec4f9b6ccef5';

    const globalSettings = useGlobalSettingsStore(
      useShallow((state) => state.globalSettings),
    );
    const confirm = useConfirm();
    const filter = createFilterOptions<AutoCompleteOption>();

    const [newReportTypeDialogOpen, setNewReportTypeDialogOpen] = useState(false);
    const [periodUpdateDialogOpen, setPeriodUpdateDialogOpen] = useState(false);
    const [activePeriod, setActivePeriod] = useState<ContributionPeriod | undefined>(
      undefined,
    );
    const contributionList = useMemo(
      () =>
        activeContributions?.filter(
          (x) =>
            x.deliverableId === item.deliverableId &&
            x.responseType === 'Status' &&
            x.id !== item.id,
        ),
      [activeContributions, item.deliverableId, item.id],
    );

    const confirmDeleteContributionMutation = useDeleteData<{ message: string }, unknown>(
      `/Contribution/ConfirmDeleteContribution`,
    );
    const deleteContributionMutation = useDeleteData<{ message: string }, unknown>(
      `/Contribution/DeleteContribution`,
    );

    const confirmDeleteContributionPeriodMutation = useDeleteData<
      { message: string },
      unknown
    >(`/Period/ConfirmDeletePeriod`);
    const deleteContributionPeriodMutation = useDeleteData<
      { message: string; confirmDelete: boolean },
      unknown
    >(`/Period/DeletePeriod`);
    const deleteContributionPeriodAndSplitMutation = useDeleteData<
      { message: string; confirmDelete: boolean },
      unknown
    >(`/Period/DeleteAndSplitPeriod`);

    const handleContributionDelete = async () => {
      const res = await confirmDeleteContributionMutation.mutateAsync(item.id);
      if (res.message) {
        const multiConfirm = await confirm({
          title: 'Delete Contribution',
          message: res.message,
        });

        if (multiConfirm) {
          await deleteContributionMutation.mutateAsync(item.id);
        }
        setActiveContributions?.([]);
      }
    };
    const { control, watch, setValue } = useFormContext();
    const itemResponseType = watch('responseType');
    const textFieldRef = useRef<HTMLElement>();

    const newPeriodFrom = watch('newPeriodFrom');
    const newPeriodFrequency = watch('newPeriodFrequency');

    const availablePeriodDates = useMemo(() => {
      if (newPeriodFrequency !== undefined && newPeriodFrom && strategyEnd) {
        return getAvailableToDate(
          newPeriodFrom,
          strategyEnd,
          newPeriodFrequency as Periodicity,
        );
      }
      return [];
    }, [newPeriodFrom, strategyEnd, newPeriodFrequency]);

    return (
      <>
        <hr />
        <div
          className="w-full @container"
          style={{
            rowGap: 1.5,
          }}
        >
          <div className="pb-1">
            {!item.isNew && (
              <>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: 16,
                    fontWeight: 500,
                    p: 1,
                    display: 'inline',
                  }}
                >
                  Question Periods
                </Typography>

                <Tooltip title="Add a new period">
                  <span>
                    <IconButton
                      sx={{ padding: 0, marginTop: -0.5 }}
                      disabled={disabled}
                      onClick={() => {
                        const newPeriod: ContributionPeriod = {
                          id: emptyGuid,
                          contributionId: item.id,
                          periodicity: Periodicity.Monthly,
                          isNew: true,
                        };
                        setActivePeriod(newPeriod);
                        setPeriodUpdateDialogOpen(true);
                        handleLastActionClick?.(null);
                      }}
                    >
                      <AddIcon sx={{ width: 18, height: 18 }}></AddIcon>
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )}
          </div>
          {item.isNew ? (
            <div className="mb-3 w-full @container">
              <div className="grid grid-cols-1 gap-4 @md:grid-cols-2 @2xl:grid-cols-3">
                <div>
                  <HookFormDatePicker
                    name="newPeriodFrom"
                    label="From"
                    isMonthPicker={true}
                    openTo="month"
                  />
                </div>

                {newPeriodFrequency !== Periodicity.Once && (
                  <div>
                    <HookFormDatePicker
                      name="newPeriodTo"
                      label="To"
                      isMonthPicker={true}
                      shouldDisableMonth={(month) => {
                        return !availablePeriodDates.some((d) =>
                          dayjs(d).isSame(month as Date, 'month'),
                        );
                      }}
                      shouldDisableYear={(year) => {
                        return !availablePeriodDates.some((d) =>
                          dayjs(d).isSame(year as Date, 'year'),
                        );
                      }}
                    />
                  </div>
                )}

                <div>
                  <HookFormSelect name="newPeriodFrequency" label="Frequency">
                    <MenuItem value={Periodicity.Monthly}>Monthly</MenuItem>
                    <MenuItem value={Periodicity.Quarterly}>Quarterly</MenuItem>
                    <MenuItem value={Periodicity.TwiceAYear}>Twice a year</MenuItem>
                    <MenuItem value={Periodicity.Annual}>Annual</MenuItem>
                    <MenuItem value={Periodicity.Once}>Once</MenuItem>
                  </HookFormSelect>
                </div>
              </div>
            </div>
          ) : (
            <div className="mb-3 w-full">
              <div className="flex w-full flex-wrap items-center">
                {item.contributionPeriods.map((period) => (
                  <div key={period.id} className="flex w-full">
                    <div className="flex flex-wrap items-center gap-x-4 gap-y-0">
                      {/* from */}
                      <div className="flex items-center gap-4">
                        <div className="flex items-center gap-1">
                          <DateRangeIcon />
                          <Typography
                            variant="h6"
                            sx={{
                              display: 'inline',
                              fontSize: 14,
                            }}
                          >
                            {period.periodicity === Periodicity.Once ? 'Date' : 'From'}
                          </Typography>
                        </div>
                        <span className="whitespace-nowrap">
                          {dayjs(period.fromDate).format('MMM / YYYY')}
                        </span>
                      </div>

                      {/* to */}
                      {period.periodicity !== Periodicity.Once && (
                        <div className="flex items-center gap-4">
                          <Typography variant="h6">To</Typography>
                          <Typography whiteSpace={'nowrap'}>
                            {dayjs(period.toDate).format('MMM / YYYY')}
                          </Typography>
                        </div>
                      )}

                      {/* frequency */}
                      <Typography
                        fontWeight={'bold'}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {getFrequency(period.periodicity)}
                      </Typography>
                    </div>
                    <div className="ml-auto flex items-center gap-2">
                      <div className="flex items-center">
                        <Tooltip title="Edit">
                          <span>
                            <IconButton
                              disabled={disabled}
                              sx={{ padding: 0 }}
                              onClick={() => {
                                setActivePeriod(
                                  item.contributionPeriods.find(
                                    (p) => p.id === period.id,
                                  ),
                                );
                                setPeriodUpdateDialogOpen(true);
                                handleLastActionClick?.(null);
                              }}
                            >
                              <EditIcon sx={{ width: 16, height: 16 }} />
                            </IconButton>
                          </span>
                        </Tooltip>

                        {item.contributionPeriods.length > 1 && (
                          <Tooltip title="Delete">
                            <span>
                              <IconButton
                                disabled={disabled}
                                color="error"
                                sx={{ padding: 0 }}
                                onClick={async () => {
                                  const res =
                                    await confirmDeleteContributionPeriodMutation.mutateAsync(
                                      period.id as string,
                                    );
                                  if (res.message) {
                                    const multiConfirm = await confirm({
                                      title: 'Existing Approved Progress Updates',
                                      message: res.message ?? '',
                                      confirmButtonText:
                                        'Only to Not Approved (recommended)',
                                      altButtonText: 'Apply to All Progress Updates',
                                      altButtonColor: 'info',
                                      hasAltOption: true,
                                      size: 'lg',
                                    });
                                    if (
                                      (multiConfirm.altButtonText as string) &&
                                      multiConfirm.altButtonText === multiConfirm.comment
                                    ) {
                                      await deleteContributionPeriodMutation.mutateAsync(
                                        period.id as string,
                                      );
                                    } else if (multiConfirm.confirmed) {
                                      await deleteContributionPeriodAndSplitMutation.mutateAsync(
                                        period.id as string,
                                      );
                                    }
                                  } else {
                                    const confirmed = await confirm({
                                      title: 'Confirm',
                                      message:
                                        'Deleting this period will result in the removal of all associated progress updates.\n' +
                                        ' Are you sure you want to proceed?',
                                    });
                                    if (confirmed) {
                                      deleteContributionPeriodMutation.mutate(
                                        period.id as string,
                                      );
                                    }
                                  }
                                }}
                              >
                                <DeleteIcon sx={{ width: 16, height: 16 }} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </div>

                      {period.overrideBUIds && period.overrideBUIds?.length > 0 && (
                        <Chip
                          label="Override"
                          size="small"
                          variant="outlined"
                          color="warning"
                        />
                      )}
                      {period.progressResponseCount !== undefined && (
                        <CardCountChip
                          count={period.progressResponseCount}
                          title="Progress updates"
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>

              {!!item.holdById && (
                <div className="col-span-full">
                  <Typography
                    variant="body2"
                    color={item.achievedDate ? 'inherit' : 'error'}
                    sx={{
                      mt: 0,
                    }}
                  >
                    {item.achievedDate
                      ? 'Active after: ' + dayjs(item.achievedDate).format('MMM / YYYY')
                      : 'Not activated'}
                  </Typography>
                </div>
              )}
            </div>
          )}
          <Divider />
          {/* response type */}
          <div className="mt-3 w-full @container">
            <div className="grid grid-cols-1 gap-4 @md:grid-cols-2 @2xl:grid-cols-3">
              <div>
                <HookFormSelect
                  variant="outlined"
                  label={'Response Type'}
                  name={'responseType'}
                  autoWidth={true}
                  sx={{
                    '@container (max-width: 42rem)': {
                      width: '100%',
                    },
                  }}
                  onMouseDown={handleLastActionClick}
                  onChangeInterceptAsync={async (event) => {
                    const newValue = event.target.value;
                    if (
                      item.responseType === 'Percentage' ||
                      item.responseType === 'Text' ||
                      (item.responseType === 'MultipleTextResponses' &&
                        globalSettings.role !== 'Global Admin')
                    ) {
                      const confirmed = await confirm({
                        title: 'Change Response Type',
                        message: `"${item.responseType}" is no longer available. If you change the response type, you will not be able to change it back.`,
                      });
                      if (confirmed) return event;
                    } else {
                      if (
                        !responseTypesNoOption.includes(item.responseType) &&
                        responseTypesNoOption.includes(newValue as string)
                      ) {
                        const confirmed = await confirm({
                          title: 'Change Response Type',
                          message: `Please confirm all response options will be removed if you change the response type.`,
                        });
                        if (confirmed) return event;
                      } else {
                        return event;
                      }
                    }
                  }}
                  disabled={disabled || !showSaveIcon}
                >
                  <MenuItem value="Number">Number</MenuItem>
                  <MenuItem value="Currency">Currency</MenuItem>
                  <MenuItem value="SingleChoice">Single Choice</MenuItem>
                  <MenuItem value="MultipleChoice">Checkboxes</MenuItem>
                  <MenuItem value="MultipleNumberResponses">
                    Multiple Number Responses
                  </MenuItem>
                  <MenuItem value="Status">Status</MenuItem>
                  {item.responseType === 'Percentage' && (
                    <MenuItem value="Percentage">Percentage</MenuItem>
                  )}
                  {item.responseType === 'Text' && <MenuItem value="Text">Text</MenuItem>}
                  {(item.responseType === 'MultipleTextResponses' ||
                    globalSettings.role === 'Global Admin') && (
                    <MenuItem value="MultipleTextResponses">
                      Multiple Text{' '}
                      {globalSettings.role === 'Global Admin' ? '(admin)' : ''}
                    </MenuItem>
                  )}
                </HookFormSelect>
              </div>

              {(itemResponseType === 'SingleChoice' ||
                itemResponseType === 'MultipleChoice' ||
                itemResponseType === 'MultipleNumberResponses' ||
                itemResponseType === 'MultipleTextResponses') && (
                <div>
                  <Controller
                    control={control}
                    name={'responseOptions'}
                    render={({
                      field: { onChange, value, ref },
                      formState: { isSubmitting },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        disableCloseOnSelect
                        value={item.responseOptions
                          .filter((x) => value.some((v: DropdownOption) => v.id === x.id))
                          .concat(
                            value.filter((v: DropdownOption) => v.id === emptyGuid),
                          )}
                        disabled={disabled || isSubmitting}
                        size={'small'}
                        onChange={async (event, newValue) => {
                          const isDeleting = item.responseOptions.some(
                            (item) => !newValue.includes(item),
                          );
                          if (isDeleting) {
                            const confirmed = await confirm({
                              title: 'Delete Response Option',
                              message:
                                'Updating or deleting the Response Options will trigger updates to all Progress Responses associated with this contribution. Are you sure you wish to proceed?',
                            });
                            if (!confirmed) return;
                            if (textFieldRef.current) {
                              textFieldRef.current.focus();
                            }
                          }
                          const values = newValue as AutoCompleteOption[];
                          const input = values.map((x) => {
                            return x.id
                              ? x
                              : {
                                  id: emptyGuid,
                                  title: x.inputValue ?? values[values.length - 1],
                                };
                          });
                          onChange(input);
                          //wait 1 sec
                          setTimeout(() => {
                            if (onBlurParent) onBlurParent();
                          }, 0);
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(
                            options as AutoCompleteOption[],
                            params,
                          );
                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some(
                            (option) => inputValue === option.title,
                          );
                          if (inputValue !== '' && !isExisting) {
                            filtered.push({
                              id: '',
                              title: `Add "${inputValue}"`,
                              inputValue: inputValue,
                            });
                          }
                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          return option.title;
                        }}
                        clearOnBlur
                        multiple
                        options={item.responseOptions}
                        renderOption={(props, option) => (
                          <li {...props} key={props.key}>
                            {option.title}
                          </li>
                        )}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={'Response Options'}
                            onMouseDown={handleLastActionClick}
                            ref={ref}
                            helperText={error?.message}
                            inputRef={textFieldRef}
                            error={!!error}
                          />
                        )}
                      />
                    )}
                  ></Controller>
                </div>
              )}
              {itemResponseType === 'Status' && allowAutoUpdate && (
                <HookFormSwitch
                  sx={{ marginLeft: 1 }}
                  label={'Auto update Deliverable status'}
                  name={'autoStatusUpdate'}
                  disabled={disabled}
                />
              )}

              <div>
                {dropdownOptions?.reportTypes && (
                  <HookFormAutoComplete
                    multiple
                    disabled={disabled}
                    name={'reportTypeIds'}
                    label={'Report Type'}
                    options={dropdownOptions?.reportTypes || []}
                    filterOptions={(options, params) => {
                      const filtered = filter(options as AutoCompleteOption[], params);
                      // Suggest the creation of a new value
                      filtered.push({
                        id: '',
                        title: `Add a new Report Type ...`,
                        inputValue: 'AddNew',
                      });
                      return filtered;
                    }}
                    onChangeInterceptor={(event, newValue) => {
                      const values = newValue as AutoCompleteOption[];
                      if (values.some((x) => x.inputValue === 'AddNew')) {
                        setNewReportTypeDialogOpen(true);
                        return;
                      } else {
                        return newValue;
                      }
                    }}
                  />
                )}
              </div>

              {allowHoldByContribution && (
                <div>
                  <HookFormAutoComplete
                    label={'Hold by Question'}
                    name={'holdById'}
                    options={
                      contributionList?.map((x) => ({ id: x.id, title: x.name })) || []
                    }
                    disabled={disabled}
                  ></HookFormAutoComplete>
                </div>
              )}

              {subIndicators && (
                <div>
                  <HookFormAutoComplete
                    label={'SubIndicator'}
                    name={'subIndicator'}
                    disabled={globalSettings.role !== 'Global Admin' || disabled}
                    options={subIndicators}
                  ></HookFormAutoComplete>
                </div>
              )}
            </div>
          </div>
          {!disabled && (
            <>
              {item.isNew && showSaveIcon && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
                  <Tooltip title="Save">
                    <IconButton
                      form="contribution-form"
                      type="submit"
                      sx={{
                        backgroundColor: '#2e7d32',
                        '&:hover': {
                          backgroundColor: '#1b5e20',
                        },
                      }}
                    >
                      <SaveIcon style={{ color: 'white' }}></SaveIcon>
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              {!item.isNew && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: 1,
                  }}
                >
                  <ContributionInfo
                    contributionId={item.id}
                    disabled={strategyNotStarted}
                  />
                  <DeleteIconRed handleActionDelete={handleContributionDelete} />
                </Box>
              )}
            </>
          )}
        </div>
        <NewReportTypeDialog
          isOpen={newReportTypeDialogOpen}
          setOpen={setNewReportTypeDialogOpen}
          contributionId={item.id}
          setReportTypeId={(id) => {
            setValue('reportTypeIds', [id, ...item.reportTypeIds]);
          }}
        ></NewReportTypeDialog>
        {activePeriod && (
          <PeriodUpdateDialog
            isOpen={periodUpdateDialogOpen}
            setOpen={setPeriodUpdateDialogOpen}
            contributionPeriod={activePeriod}
            businessUnits={businessUnits}
            allPeriods={item.contributionPeriods}
          />
        )}
      </>
    );
  },
  (prev, next) => prev.item === next.item && prev.disabled == next.disabled,
);

export default ContributionCardContent;
