import React from 'react';

import ErrorComponent from '@/components/ErrorComponent';
import ReactLoading from 'react-loading';

export const GenericErrorComponent: React.FC<{ error: Error }> = ({ error }) => {
  const shouldReloadOnError = (message: string) =>
    /Loading chunk \d+ failed/.test(message) ||
    /Failed to fetch dynamically imported module/.test(message) ||
    /Failed to load module script/.test(message) || // Add this line
    message.includes('MIME type of "text/html"');

  if (shouldReloadOnError(error.message)) {
    // Force reload after a short delay to ensure state is updated
    setTimeout(() => window.location.reload(), 1000);

    return (
      <div className="fixed inset-0 z-50 grid h-screen place-items-center bg-white dark:bg-gray-900">
        <div className="text-center">
          <h2 className="mb-4 text-3xl font-bold text-gray-900 dark:text-white">
            Weavr is being updated!
          </h2>
          <div className="flex justify-center">
            <ReactLoading type="bubbles" height={150} width={150} color="#9C5738" />
          </div>
        </div>
      </div>
    );
  }

  return <ErrorComponent error={error} />;
};
