import BarChartIcon from '@mui/icons-material/BarChart';
import CSIRORAPIcon from '@mui/icons-material/Book';
import BetterServicesFirstNationsIcon from '@mui/icons-material/EmojiPeople';
import ActivitiesParticipationIcon from '@mui/icons-material/Group';
import iDiCIcon from '@mui/icons-material/Info';
import AnnualProgressReportIcon from '@mui/icons-material/InsertChartOutlined';
import RelationshipsStakeholderEngagementIcon from '@mui/icons-material/PeopleOutline';
import CulturalEducationAlignmentIcon from '@mui/icons-material/School';
import ProcurementEnvironmentIcon from '@mui/icons-material/ShoppingCart';
import EmploymentPathwaysIcon from '@mui/icons-material/Work';
import { SvgIconProps } from '@mui/material';
import React, { memo } from 'react';

import BaseIcon from '@/pages/strategies/Icons/BaseIcon';

const REPORT_TYPES = [
  'Impact Measurement',
  'RA Impact Questionnaire',
  'CSIRO RAP',
  'Impact Questionnaire',
  'Annual Progress Report',
  'iDiC',
  'Impact Measurement Questionnaire',
  'Activities & Participation',
  'Cultural Education & Alignment',
  'Procurement & Environment',
  'Better Services to First Nations',
  'Employment Pathways',
  'Relationships/Stakeholder Engagement',
] as const;

interface Props extends SvgIconProps {
  reportType: string | undefined;
}

const ReportTypeIcon: React.FC<Props> = memo(({ reportType }) => {
  if (reportType === undefined) {
    return null;
  }

  let IconComponent: React.ElementType | undefined = undefined;

  switch (reportType) {
    case 'Impact Measurement':
      IconComponent = BarChartIcon;
      break;
    case 'RA Impact Questionnaire':
      IconComponent = BarChartIcon;
      break;
    case 'CSIRO RAP':
      IconComponent = CSIRORAPIcon;
      break;
    case 'Impact Questionnaire':
      IconComponent = BarChartIcon;
      break;
    case 'Annual Progress Report':
      IconComponent = AnnualProgressReportIcon;
      break;
    case 'iDiC':
      IconComponent = iDiCIcon;
      break;
    case 'Impact Measurement Questionnaire':
      IconComponent = BarChartIcon;
      break;
    case 'Activities & Participation':
      IconComponent = ActivitiesParticipationIcon;
      break;
    case 'Cultural Education & Alignment':
      IconComponent = CulturalEducationAlignmentIcon;
      break;
    case 'Procurement & Environment':
      IconComponent = ProcurementEnvironmentIcon;
      break;
    case 'Better Services to First Nations':
      IconComponent = BetterServicesFirstNationsIcon;
      break;
    case 'Employment Pathways':
      IconComponent = EmploymentPathwaysIcon;
      break;
    case 'Relationships/Stakeholder Engagement':
      IconComponent = RelationshipsStakeholderEngagementIcon;
      break;
    default:
      break;
  }
  return (
    <BaseIcon
      IconComponent={IconComponent}
      IconType={REPORT_TYPES as unknown as string[]}
      title={reportType}
    />
  );
});

export default ReportTypeIcon;
