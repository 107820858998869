import { useMsal } from '@azure/msal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import HookFormInput from '@/components/HookFormInput';
import { usePostData } from '@/helpers/hooks';
import { useConfirm } from '@/store/useConfirmDialogStore';

interface Props {
  handleForgotPWClick: (b: boolean) => void;
}

interface LoginUser {
  email: string;
  password: string;
}

const LoginForm: React.FC<Props> = ({ handleForgotPWClick }: Props) => {
  const formSchema = z.object({
    email: z.string().email().min(1, 'Please provide your email.'),
    password: z.string().min(1, 'Please provide your password.'),
  });
  const [showMFA, setShowMFA] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  type FormFields = z.infer<typeof formSchema>;

  const methods = useForm<FormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: { email: '', password: '' },
  });
  const loginMutate = usePostData<string, any, LoginUser>({ url: '/login/login' });
  const validateMFAMutate = usePostData<any, any, { email: string; code: string }>({
    url: '/login/validateMFA',
    options: {
      onSuccess: () => {
        window.location.href = '/home';
      },
      onError: () => {
        enqueueSnackbar('Validation failed. Please try again.', {
          variant: 'error',
        });
      },
    },
  });
  const { handleSubmit } = methods;

  const handleVerifyClick = async () => {
    await validateMFAMutate.mutateAsync({ email: email, code: code });
  };
  const confirm = useConfirm();

  const onSubmit = async (data: FormFields) => {
    const userName = data.email;
    const passWord = data.password;
    try {
      const result = await loginMutate.mutateAsync({
        email: userName,
        password: passWord,
      });
      if (result) {
        if (result === 'signedIn') {
          window.location.href = '/home';
        } else {
          await confirm({
            title: 'Error',
            message: result,
            isModal: true,
          });
        }
      } else {
        setShowMFA(true);
        setEmail(data.email);
      }
    } finally {
      // Do nothing
    }
  };
  return (
    <>
      {showMFA ? (
        <>
          <Typography variant={'h3'} mt={0}>
            Multi-factor authentication
          </Typography>
          <Typography
            variant={'body2'}
            sx={{
              mt: 3,
              mb: 0,
            }}
          >
            A verification code was sent to your device.
          </Typography>
          <Typography
            variant={'body2'}
            sx={{
              mt: 0,
              mb: 3,
            }}
          >
            Please enter the code below:
          </Typography>
          <TextField
            size={'small'}
            label={'Verification code'}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          >
            Verification code
          </TextField>
          <Button
            variant={'contained'}
            sx={{ marginLeft: 4 }}
            onClick={handleVerifyClick}
          >
            Verify
          </Button>
        </>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} id="login-form">
              <HookFormInput
                name={'email'}
                label={'Email address (REQUIRED)'}
                data-testid="email-address"
                sx={{
                  '& .MuiInputBase-root': {
                    height: '54px',
                    backgroundColor: (theme) => theme.palette.backgroundBrown.dark,
                    borderRadius: '8px',
                    '& fieldset': {
                      border: '1px solid white',
                    },
                  },
                }}
              />
              <HookFormInput
                name={'password'}
                label={'Password (REQUIRED)'}
                data-testid="password"
                forPassword
                sx={{
                  marginTop: 1,
                  '& .MuiInputBase-root': {
                    height: '54px',
                    backgroundColor: (theme) => theme.palette.backgroundBrown.dark,
                    borderRadius: '8px',
                    '& fieldset': {
                      border: '1px solid white',
                    },
                  },
                }}
              />
              <div className="mt-6">
                <Button
                  className="h-[52px] w-full"
                  type={'submit'}
                  form="login-form"
                  variant={'contained'}
                  fullWidth
                  sx={{
                    fontSize: 15,
                    px: 4,
                    py: 2,
                  }}
                >
                  Sign in
                </Button>
                <Button
                  sx={{ textTransform: 'none', marginTop: 0.5 }}
                  fullWidth
                  onClick={() => handleForgotPWClick(true)}
                >
                  Forgotten password?
                </Button>
              </div>
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
};

export default LoginForm;
