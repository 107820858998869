import { DropdownOption } from '@/components/dropdownOption.type';
import { formatMonthYear } from '@/helpers/dateUtils';
import { getDeliverableDueDateColorByDate } from '@/helpers/utils';
import { ourJourneyFilters, strategyItemFilters } from '@/types/itemFilter';

export const getDeliverableDueItems = (targetEndDates: string[]): DropdownOption[] => {
  const dueItems = targetEndDates.map((date): DropdownOption => {
    const parsedDate = new Date(date);
    return {
      id: date,
      title: formatMonthYear(parsedDate.toString()),
      color: getDeliverableDueDateColorByDate(parsedDate),
    };
  });

  const uniqueDueItems = [
    ...new Map(
      dueItems
        .filter((item): item is DropdownOption => item !== undefined)
        .sort((a, b) => new Date(a.id).getTime() - new Date(b.id).getTime())
        .map((item) => [item.title, item]),
    ).values(),
  ];

  const nextThreeMonthsOption: DropdownOption = {
    id: 'next-three-months',
    title: 'Next Three Months',
    color: 'green', // or any color you prefer
  };

  return [nextThreeMonthsOption, ...uniqueDueItems];
};

export interface DeliverableDueMonth {
  id: string;
}

export const filterDeliverablesByDueDate = (
  deliverableDueMonths: DeliverableDueMonth[],
  deliverableDueNumber: number,
): string[] => {
  const now = new Date();
  const monthsLater = new Date();
  monthsLater.setMonth(now.getMonth() + deliverableDueNumber);

  const filteredDeliverables = deliverableDueMonths.filter((item) => {
    const date = new Date(item.id);
    return date >= now && date <= monthsLater;
  });

  return deliverableDueNumber === 3
    ? [...filteredDeliverables.map((x) => x.id), 'next-three-months']
    : filteredDeliverables.map((x) => x.id);
};

export const updateDeliverableDueFilter = (
  items: string[],
  updateFilters: (
    name: keyof (strategyItemFilters | ourJourneyFilters),
    value: unknown,
  ) => void,
  deliverableDueFilter?: string[],
): void => {
  const nextThreeMonthsSelected =
    !deliverableDueFilter?.includes('next-three-months') &&
    items.at(-1) === 'next-three-months';

  if (nextThreeMonthsSelected) {
    updateFilters('deliverableDue', ['3']);
  } else {
    const itemsToUpdate = items.filter((x) => x !== 'next-three-months');

    updateFilters('deliverableDue', itemsToUpdate);
  }
};
