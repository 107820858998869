import { z } from 'zod';

import { StatusZodType } from '@/components/status/statusTypes';

export const ourJourneySearchSchema = z.object({
  responseStatus: z
    .array(z.enum(['responded', 'unresponded']))
    .optional()
    .default(['responded']),
  deliverableDue: z.array(z.string()).optional(),
  period: z.array(z.string()).optional(),
  businessUnit: z.array(z.string()).optional(),
  reportType: z.array(z.string()).optional(),
  actionArea: z.array(z.string()).optional(),
  questionType: z.array(z.string()).optional(),
  deliverableStatus: z.array(StatusZodType).optional(),
  businessUnitGroup: z.array(z.string()).optional(),
  focusAreaSecondary: z.array(z.string()).optional(),
});

export type ourJourneyFilters = z.infer<typeof ourJourneySearchSchema>;

export const strategiesSearchSchema = z.object({
  strategyId: z.string().uuid().optional(),
  actionId: z.string().uuid().optional(),
  deliverableId: z.string().uuid().optional(),
  contributionId: z.string().uuid().optional(),
  businessUnit: z.array(z.string().uuid()).optional(),
  status: z.array(z.string()).optional(),
  deliverableDue: z.array(z.string()).optional(),
  actionArea: z.array(z.string().uuid()).optional(),
  responseType: z.array(z.string()).optional(),
  frequency: z.array(z.string()).optional(),
});
export type strategyItemFilters = z.infer<typeof strategiesSearchSchema>;

export const songlineSearchSchema = z.object({
  milestones: z.string().optional(),
});
export type songlineItemFilters = z.infer<typeof songlineSearchSchema>;

export const rootSearchSchema = z.object({
  userId: z.string().optional(),
  resetuser: z.string().optional(),
  token: z.string().optional(),
  returnUrl: z.string().optional(),
});
export type rootItemFilters = z.infer<typeof rootSearchSchema>;

export const vacancySearchSchema = z.object({
  name: z.string().optional(),
});
export type vacancyItemFilters = z.infer<typeof vacancySearchSchema>;

export const progressUpdateSearchSchema = z
  .object({
    status: z.array(z.enum(['Approved', 'In progress', 'Approval requested'])).optional(),
    businessUnit: z.array(z.string()).optional(),
    member: z.array(z.string()).optional(),
    tab: z.enum(['progress', 'calendar', 'engagement']).default('progress'),
    filter: z.enum(['pending', 'all']).optional(),
    showFuture: z.boolean().optional(),
  })
  .transform((data) => {
    const transformed = { ...data };

    // Apply defaults based on tab value
    if (data.tab === 'progress' && data.showFuture === undefined) {
      transformed.showFuture = false;
      transformed.filter = 'all';
    }

    return transformed;
  });
export type progressUpdateItemFilters = z.infer<typeof progressUpdateSearchSchema>;

export const collaborateEditSearchSchema = z.object({
  progress: z.string(),
  q: z.string().optional(),
});
export type collaborateEditItemFilters = z.infer<typeof collaborateEditSearchSchema>;

export const engagementSearchSchema = z.object({
  // showFuture: z.boolean().optional(),
  period: z.array(z.string()).optional(),
});
export type engagementItemFilters = z.infer<typeof engagementSearchSchema>;
export const knowledgebaseSearchSchema = z.object({
  category: z.string().optional(),
  subcategory: z.string().optional(),
});
export type knowledgebaseItemFilters = z.infer<typeof knowledgebaseSearchSchema>;
