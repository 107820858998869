import Avatar, { AvatarProps } from '@mui/material/Avatar'; // Import AvatarProps
import * as React from 'react';
import { forwardRef } from 'react';

import { useFetchData } from '@/helpers/hooks';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';
import { useShallow } from 'zustand/react/shallow';
type Props = {
  userId?: string;
  initials?: string;
} & AvatarProps; // Extend Props with AvatarProps

type Result = string;

const ProfileAvatar = forwardRef<HTMLDivElement, Props>(
  ({ userId, initials, ...props }, ref) => {
    const globalSettings = useGlobalSettingsStore(
      useShallow((state) => state.globalSettings),
    );

    const getUserProfile = useFetchData<Result>(
      ['profile', userId],
      `user/getprofilepic?userId=${userId}`,
      { enabled: !!userId },
    );

    const avatarSrc = userId ? getUserProfile.data : globalSettings.profilePic;

    return (
      <Avatar {...props} ref={ref} src={avatarSrc}>
        {initials}
      </Avatar>
    );
  },
);

ProfileAvatar.displayName = 'ProfileAvatar';

export default ProfileAvatar;
