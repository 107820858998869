import { zodResolver } from '@hookform/resolvers/zod';
import { DialogContentText, Grid, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { GridRowId } from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useShallow } from 'zustand/react/shallow';
import HookFormAutoComplete from '@/components/HookFormAutoComplete';
import HookFormInput from '@/components/HookFormInput';
import HookFormSelect from '@/components/HookFormSelect';
import HookFormSwitch from '@/components/HookFormSwitch';
import useAddNewUserContext from '@/contexts/useAddNewUserContext';
import { usePostData, usePutData } from '@/helpers/hooks';
import { getItemsDirtyData } from '@/helpers/utils';
import { RolesResponse } from '@/pages/globalAdmin/roles/Roles';
import {
  GLOBAL_FLAG,
  NEW_PROGRESS_UPDATE_FLAG,
  PENDING_PROGRESS_UPDATE_FLAG,
} from '@/pages/user/profileConsts';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';
import { useConfirm } from '@/store/useConfirmDialogStore';
import { useMatch } from '@tanstack/react-router';

export interface UserInfo {
  id?: string;
  isGlobalAdmin: boolean;
  allowMultiSub: boolean;
  isActive: boolean;
  firstName: string;
  lastName: string;
  email?: string;
  mobile?: string;
  subscriptionId?: string;
  subscriptions?: string[];
  role?: RolesResponse;
  isNew?: boolean;
  organisationName?: string;
  lastLogin?: Date;
  excludeEmail: number;
  ssoLogin?: boolean;
}

const NewUserDialog: React.FC = () => {
  const { roles, subscriptions, user, setUser } = useAddNewUserContext();

  const globalAdminPage = !!useMatch({ from: '/global_admin/users', shouldThrow: false });

  const globalSettings = useGlobalSettingsStore(
    useShallow((state) => state.globalSettings),
  );

  const { enqueueSnackbar } = useSnackbar();

  const confirm = useConfirm();

  const formSchema = z
    .object({
      isGlobalAdmin: z.boolean(),
      allowMultiSub: z.boolean(),
      isActive: z.boolean(),
      firstName: z.string().min(1, 'First name is required'),
      lastName: z.string().min(1, 'Last name is required'),
      email: z.string().email().min(1, 'Email is required'),
      mobile: z.string().optional(),
      subscriptionId: z.string().optional(),
      roleId: z.string().optional(),
      excludeEmail: z.number().array(),
      subscriptions: z.array(z.string()).optional(),
    })
    .superRefine((data, ctx) => {
      if (!data.isGlobalAdmin && !data.roleId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Role is required',
          path: ['roleId'],
        });
      }

      if (!data.isGlobalAdmin && user?.isNew && !data.subscriptionId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select the subscription associated with the user',
          path: ['subscriptionId'],
        });
      }

      if (data.allowMultiSub && (!data.subscriptions || data.subscriptions.length < 1)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Subscriptions must have at least one item',
          path: ['subscriptions'],
        });
      }

      return z.NEVER;
    });

  type FormFields = z.infer<typeof formSchema>;

  const defaultValues: FormFields = useMemo(() => {
    const selectedFlags = [];
    if (user && user?.excludeEmail & PENDING_PROGRESS_UPDATE_FLAG) {
      selectedFlags.push(PENDING_PROGRESS_UPDATE_FLAG);
    }

    if (user && user?.excludeEmail & NEW_PROGRESS_UPDATE_FLAG) {
      selectedFlags.push(NEW_PROGRESS_UPDATE_FLAG);
    }
    if (user && user?.excludeEmail & GLOBAL_FLAG) {
      selectedFlags.push(GLOBAL_FLAG);
    }

    return {
      isGlobalAdmin: user?.isGlobalAdmin || false,
      allowMultiSub: user?.allowMultiSub || false,
      isActive: user?.isNew ? true : !!user?.isActive,
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      mobile: user?.mobile || '',
      subscriptionId: user?.subscriptionId || globalSettings.organisationId,
      roleId: user?.role?.id || '',
      excludeEmail: selectedFlags,
      subscriptions: user?.subscriptions || [],
    };
  }, [globalSettings.organisationId, user]);

  const queryClient = useQueryClient();

  const methods = useForm<FormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const { handleSubmit, formState, watch, reset } = methods;
  React.useEffect(() => {
    reset(defaultValues);
  }, [user, reset, defaultValues]);
  const handleClose = () => {
    setUser(undefined);
  };

  const updateUserMutation = usePutData<
    Partial<UserInfo>,
    unknown,
    Partial<UserInfo>,
    unknown
  >(`/User/UpdateUser/${user?.id}`, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['users'] });
      handleClose();
    },
  });
  const addUserMutation = usePostData<Partial<UserInfo>, unknown, Partial<UserInfo>>({
    url: `/User/CreateUser`,
    options: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['users'] });
        handleClose();
        enqueueSnackbar(
          'User created successfully and an invitation email was sent to the user',
          {
            variant: 'success',
          },
        );
      },
    },
  });

  const resetMutation = usePostData<any, unknown, { email: string }>({
    url: '/Login/RequestResetPassword',
    options: {
      onSuccess: async () => {},
    },
  });
  const invitationMutation = usePostData<any, unknown, { email: string }>({
    url: '/Login/ResendInvitation',
    options: {
      onSuccess: async () => {
        enqueueSnackbar('Invitation email sent successfully', {
          variant: 'success',
        });
      },
    },
  });

  const onSubmit = async (data: FormFields) => {
    const excludeEmailChanged = formState.dirtyFields.excludeEmail;
    if (excludeEmailChanged) {
      const nonProductionMessage =
          process.env.NODE_ENV !== 'production'
              ? 'This change will affect the production environment.'
              : '';

      const confirmed = await confirm({
        message: `You are about to update the exclude email settings. ${nonProductionMessage}Are you sure you want to proceed?`,
        title: 'Staging - update',
      });

      if (!confirmed) {
        return;
      }
    }

    if (user && !user?.isNew) {
      const dirtyData = getItemsDirtyData(data, formState.dirtyFields);
      const payload = {
        id: user.id,
        ...dirtyData,
        excludeEmail: data.excludeEmail.reduce((a, c) => {
          return a + c;
        }, 0),
      };
      await updateUserMutation.mutateAsync(payload);
    } else {
      const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        mobile: data.mobile,
        roleId: data.roleId,
        isGlobalAdmin: data.isGlobalAdmin,
        allowMultiSub: data.allowMultiSub,
        subscriptions: data.subscriptions ?? [],
        isActive: data.isActive,
        subscriptionId: !data.subscriptionId ? undefined : data.subscriptionId,
        excludeEmail: data.excludeEmail.reduce((a, c) => {
          return a + c;
        }, 0),
      };
      await addUserMutation.mutateAsync(payload);
      handleClose();
    }
  };

  const handleReset = async () => {
    const confirmed = await confirm({
      message: `In order to initiate the password reset process, an email will be sent to the user (${user?.email}).\n Do you wish to proceed?`,
      title: 'Are you sure?',
    });
    if (confirmed && user) {
      await resetMutation.mutateAsync({ email: user.email! });
    }
  };

  const isGlobalAdmin = watch('isGlobalAdmin');

  const allowMultiSub = watch('allowMultiSub');

  if (user === undefined) {
    return null;
  }

  const sendInvitation = async () => {
    const confirmed = await confirm({
      message: `An invitation email will be sent to the user (${user?.email}).\n Do you wish to proceed?`,
      title: 'Resend Invitation?',
    });
    if (confirmed && user) {
      await invitationMutation.mutateAsync({ email: user.email! });
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth>
      <FormProvider {...methods}>
        <DialogTitle fontSize={16} sx={{ paddingBottom: 0 }}>
          {user?.isNew ? 'New' : 'Update'} User
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>Please insert the user details</DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)} id="user-update-form">
            <Grid container>
              {globalSettings.role === 'Global Admin' && (
                <>
                  {user?.isNew && (
                    <Grid item xs={12}>
                      <HookFormSwitch
                        label={'Global Admin'}
                        name={'isGlobalAdmin'}
                        disabled={!user?.isNew}
                      ></HookFormSwitch>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <HookFormSwitch
                      label={'Allow Multi Subscription'}
                      name={'allowMultiSub'}
                    ></HookFormSwitch>
                  </Grid>
                  <Grid item xs={6}>
                    <HookFormAutoComplete
                      label={'Subscriptions'}
                      name={'subscriptions'}
                      disabled={!allowMultiSub}
                      multiple
                      options={
                        globalSettings.allOrganisations?.map((x) => ({
                          title: x,
                          id: x,
                        })) || []
                      }
                    ></HookFormAutoComplete>
                  </Grid>
                </>
              )}
              {subscriptions !== undefined && subscriptions.length > 0 && user?.isNew && (
                <Grid
                  item
                  xs={6}
                  sx={{
                    pb: 2,
                  }}
                >
                  <HookFormAutoComplete
                    label={'Subscription'}
                    name={'subscriptionId'}
                    disabled={!user?.isNew || !globalAdminPage}
                    options={subscriptions.map((x) => ({
                      title: x.organisationName,
                      id: x.id,
                    }))}
                  ></HookFormAutoComplete>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  pb: 2,
                }}
              >
                <HookFormSwitch
                  label={'User is active'}
                  name={'isActive'}
                ></HookFormSwitch>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  pb: 2,
                  pr: 1,
                }}
              >
                <HookFormInput label={'First Name'} name={'firstName'}></HookFormInput>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  pb: 2,
                }}
              >
                <HookFormInput label={'Last Name'} name={'lastName'}></HookFormInput>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  pb: 2,
                }}
              >
                <HookFormInput label={'Email'} name={'email'}></HookFormInput>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  pb: 2,
                }}
              >
                <HookFormInput label={'Mobile'} name={'mobile'}></HookFormInput>
              </Grid>
              {!isGlobalAdmin && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    pb: 2,
                  }}
                >
                  <HookFormAutoComplete
                    label={'Role'}
                    name={'roleId'}
                    options={
                      roles?.map((x) => ({
                        title: x.name,
                        id: x.id,
                      })) || []
                    }
                  ></HookFormAutoComplete>
                </Grid>
              )}
              <Grid item xs={12}>
                <HookFormSelect label={'Exclude Email'} name={'excludeEmail'} multiple>
                  <MenuItem value={PENDING_PROGRESS_UPDATE_FLAG}>
                    Pending progress updates
                  </MenuItem>
                  <MenuItem value={NEW_PROGRESS_UPDATE_FLAG}>
                    New progress updates
                  </MenuItem>
                  <MenuItem value={GLOBAL_FLAG}>All Emails</MenuItem>
                </HookFormSelect>
              </Grid>
              <Grid item xs={12}>
                <Typography>Last login:</Typography>
                <Typography>
                  {user?.lastLogin
                    ? dayjs(user?.lastLogin).format('dddd, MMMM D, YYYY h:mm A')
                    : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          {!user?.isNew && user?.email && (
            <>
              <Button onClick={handleReset} variant="contained" color="warning">
                Reset Password
              </Button>
              <Button
                onClick={sendInvitation}
                sx={{ marginRight: 'auto' }}
                variant="contained"
                color="primary"
              >
                Resend Invitation
              </Button>
            </>
          )}

          <Button onClick={handleClose}>Cancel</Button>
          <Button
            form="user-update-form"
            type="submit"
            variant="contained"
            color="success"
            disabled={methods.formState.isSubmitting}
          >
            Save
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
export default NewUserDialog;
