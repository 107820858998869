import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ImageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.87842 10.5C8.22314 10.5 8.56448 10.4418 8.88296 10.3287C9.20144 10.2157 9.49082 10.0499 9.73457 9.84099C9.97833 9.63206 10.1717 9.38402 10.3036 9.11104C10.4355 8.83806 10.5034 8.54547 10.5034 8.25C10.5034 7.95453 10.4355 7.66194 10.3036 7.38896C10.1717 7.11598 9.97833 6.86794 9.73457 6.65901C9.49082 6.45008 9.20144 6.28434 8.88296 6.17127C8.56448 6.0582 8.22314 6 7.87842 6C7.18222 6 6.51455 6.23705 6.02226 6.65901C5.52998 7.08097 5.25342 7.65326 5.25342 8.25C5.25342 8.84674 5.52998 9.41903 6.02226 9.84099C6.51455 10.2629 7.18222 10.5 7.87842 10.5Z"
          fill="#9C5738"
        />
        <path
          d="M21 21C21 21.7956 20.6313 22.5587 19.9749 23.1213C19.3185 23.6839 18.4283 24 17.5 24H3.5C2.57174 24 1.6815 23.6839 1.02513 23.1213C0.368749 22.5587 0 21.7956 0 21V3C0 2.20435 0.368749 1.44129 1.02513 0.87868C1.6815 0.316071 2.57174 0 3.5 0L13.125 0L21 6.75V21ZM3.5 1.5C3.03587 1.5 2.59075 1.65804 2.26256 1.93934C1.93437 2.22064 1.75 2.60218 1.75 3V18L5.642 14.664C5.77993 14.5461 5.95983 14.4709 6.1532 14.4506C6.34657 14.4302 6.54235 14.4657 6.7095 14.5515L10.5 16.5L14.2747 11.97C14.3486 11.8815 14.4441 11.8078 14.5545 11.754C14.6649 11.7003 14.7877 11.6678 14.9142 11.6588C15.0408 11.6498 15.1681 11.6645 15.2873 11.702C15.4066 11.7394 15.5149 11.7986 15.6047 11.8755L19.25 15V6.75H15.75C15.0538 6.75 14.3861 6.51295 13.8938 6.09099C13.4016 5.66903 13.125 5.09674 13.125 4.5V1.5H3.5Z"
          fill="#9C5738"
        />
      </svg>
    </SvgIcon>
  );
};
