import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Divider, LinearProgress, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useMemo, useState } from 'react';
import zxcvbn from 'zxcvbn-typescript';

import { usePostData } from '@/helpers/hooks';

interface Props {
  resetUserId: string;
  resetToken: string;
  onResetSuccess: () => void;
}

const EmailResetForm: React.FC<Props> = ({
  resetUserId,
  resetToken,
  onResetSuccess,
}: Props) => {
  const message = ['Insecure', 'Weak', 'Average', 'Strong', 'Very strong'];
  const color = ['error', 'warning', 'warning', 'success', 'success'];

  const [password, setPassword] = useState<string>('');
  const [displayPassword, setDisplayPassword] = useState<boolean>(false);
  const [hasCondition1, setHasCondition1] = useState<boolean>(false);
  const [hasCondition2, setHasCondition2] = useState<boolean>(false);
  const [hasCondition3, setHasCondition3] = useState<boolean>(false);
  const [hasCondition4, setHasCondition4] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const passwordStrength = useMemo(() => {
    let base = 0;
    // Required Length: 8 characters
    if (password.length >= 8) {
      base++;
      setHasCondition1(true);
    } else setHasCondition1(false);

    // Must have 1 Uppercase and 1 Lowercase character
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
      base++;
      setHasCondition2(true);
    } else setHasCondition2(false);

    // Must have 1 number
    if (password.match(/\d+/)) {
      base++;
      setHasCondition3(true);
    } else setHasCondition3(false);

    // Special characters
    if (password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
      base += 1;
      setHasCondition4(true);
    } else setHasCondition4(false);
    return base >= 4 ? zxcvbn(password).score : 0;
  }, [password]);

  const resetPasswordMutation = usePostData<any, any, any>({
    url: `/Login/ProcessResetPassword`,
    options: {
      onSuccess: () => {
        setIsSubmitted(true);
        onResetSuccess();
      },
    },
  });
  const handleResetPassword = async () => {
    await resetPasswordMutation.mutateAsync({ resetUserId, resetToken, password });
  };
  return (
    <>
      <Typography
        sx={{
          fontSize: 21,
          fontWeight: 700,
          marginTop: -4,
        }}
      >
        Reset Password
      </Typography>
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 400,
          mb: 3,
        }}
      >
        Create a new password for your weavr account by filling out the form below.
      </Typography>
      {isSubmitted ? (
        <>
          <Divider></Divider>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.backgroundBrown.dark,
              borderRadius: '10px',
              padding: 1,
              marginY: 2,
            }}
          >
            <Typography
              align={'center'}
              sx={{
                color: 'success.main',
                display: 'inline',
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              Your password has been reset.
              <Button
                variant={'text'}
                sx={{
                  textTransform: 'none',
                  lineHeight: '19px',
                  padding: 0,
                  paddingBottom: '1px',
                }}
              >
                Click here
              </Button>
              to go back to log in page.
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <TextField
            slotProps={{
              input: {
                endAdornment: (
                  <Button
                    variant={'text'}
                    sx={{ textTransform: 'none' }}
                    onClick={() => setDisplayPassword(!displayPassword)}
                  >
                    {displayPassword ? 'Hide' : 'Show'}
                  </Button>
                ),
              },
            }}
            sx={{
              marginTop: 1,
              '& .MuiInputBase-root': {
                backgroundColor: (theme) => theme.palette.backgroundBrown.dark,
                borderRadius: '8px',
                '& fieldset': {
                  border: '1px solid white',
                },
              },
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label={'Password'}
            type={displayPassword ? 'text' : 'password'}
            fullWidth
          />
          {password.length > 0 ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="body2"
                sx={{
                  mr: 1,
                }}
              >
                Password Strength
              </Typography>
              <Box sx={{ width: '30%', mr: 1 }}>
                <LinearProgress
                  variant="determinate"
                  value={10 + passwordStrength * 22.5}
                  color={color[passwordStrength] as any}
                />
              </Box>
              <Typography
                variant="body2"
                sx={{
                  color: `${color[passwordStrength]}.main`,
                }}
              >
                {message[passwordStrength]}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ height: '40px' }}></Box>
          )}
          <Typography
            variant="body2"
            sx={{
              mt: 0,
            }}
          >
            Must contain at least:
          </Typography>
          <Typography
            variant="body2"
            sx={{
              my: 0,
            }}
          >
            {hasCondition1 ? (
              <CheckIcon color={'success'} sx={{ marginRight: 1 }} />
            ) : (
              <ClearIcon color={'error'} sx={{ marginRight: 1 }} />
            )}
            8 characters
          </Typography>
          <Typography
            variant="body2"
            sx={{
              my: 0,
            }}
          >
            {hasCondition2 ? (
              <CheckIcon color={'success'} sx={{ marginRight: 1 }} />
            ) : (
              <ClearIcon color={'error'} sx={{ marginRight: 1 }} />
            )}
            1 uppercase and 1 lowercase character
          </Typography>
          <Typography
            variant="body2"
            sx={{
              my: 0,
            }}
          >
            {hasCondition3 ? (
              <CheckIcon color={'success'} sx={{ marginRight: 1 }} />
            ) : (
              <ClearIcon color={'error'} sx={{ marginRight: 1 }} />
            )}
            1 number
          </Typography>
          <Typography
            variant="body2"
            sx={{
              my: 0,
              mb: 1,
            }}
          >
            {hasCondition4 ? (
              <CheckIcon color={'success'} sx={{ marginRight: 1 }} />
            ) : (
              <ClearIcon color={'error'} sx={{ marginRight: 1 }} />
            )}
            1 special character
          </Typography>
          <Button
            variant={'contained'}
            fullWidth
            onClick={handleResetPassword}
            disabled={!hasCondition1 || !hasCondition2 || !hasCondition3}
          >
            Reset password
          </Button>
          <Button fullWidth onClick={onResetSuccess} sx={{ textTransform: 'none' }}>
            Back to login
          </Button>
        </>
      )}
    </>
  );
};

export default EmailResetForm;
