import { Link } from '@tanstack/react-router';
import { forwardRef } from 'react';

import { cn } from '@/lib/utils';

const NavigationMenuListItem = forwardRef<
  React.ElementRef<typeof Link>,
  React.ComponentPropsWithoutRef<typeof Link> & { title: string; description?: string }
>(({ className, title, description, ...props }, ref) => {
  return (
    <li>
      <Link
        ref={ref}
        className={cn(
          'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
          className,
        )}
        activeProps={{
          className: 'bg-accent text-accent-foreground',
        }}
        {...props}
      >
        <div className="text-sm font-medium leading-none">{title}</div>
        {description && (
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {description}
          </p>
        )}
      </Link>
    </li>
  );
});
NavigationMenuListItem.displayName = 'ListItem';

export default NavigationMenuListItem;
