import { zodResolver } from '@hookform/resolvers/zod';
import { DialogContentText, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import * as React from 'react';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { DropdownOption } from '@/components/dropdownOption.type';
import HookFormAutoComplete from '@/components/HookFormAutoComplete';
import { usePostData } from '@/helpers/hooks';
import { naturalSort } from '@/helpers/utils';
import { Deliverable } from '@/pages/strategies/types/deliverable.types';
import { strategyItemFilters } from '@/types/itemFilter';

interface Props {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  strategies: DropdownOption[];
  deliverables: Deliverable[];
  order: string;
  currentStrategyId?: string;
}

interface LinkDeliverablePayload {
  actionId: string;
  deliverableId: string;
  order: string;
}

export default function LinkDeliverableDialog({
  isOpen,
  setOpen,
  strategies,
  deliverables,
  order,
  currentStrategyId,
}: Props) {
  const routeApi = getRouteApi('/strategies');
  const { actionId } = routeApi.useSearch();

  const queryClient = useQueryClient();
  const linkDeliverableMutation = usePostData<
    string,
    unknown,
    LinkDeliverablePayload,
    unknown
  >({
    url: `/Strategy/LinkDeliverable`,
    options: {
      onSuccess: async () => {
        setOpen(false);
        await queryClient.invalidateQueries({ queryKey: ['strategyData'] });
      },
    },
  });
  const selectedStrategyId = strategies[0].id;
  const formSchema = z.object({
    strategyId: z.string().min(1, 'Please select a strategy'),
    deliverableId: z.string().min(1, 'Please select a deliverable'),
  });

  type FormFields = z.infer<typeof formSchema>;
  const methods = useForm<FormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: { strategyId: selectedStrategyId, deliverableId: '' },
  });

  const { handleSubmit } = methods;

  const filteredDeliverable = useMemo(() => {
    const d = deliverables
      .filter((x) => x.strategyId === selectedStrategyId)
      .map((x) => ({ id: x.id, title: `${x.actionOrder}.${x.order} ${x.name}` }));
    return naturalSort(d).asc((o) => o.title);
  }, [deliverables, selectedStrategyId]);

  const onSubmit = async (data: FormFields) => {
    const payload = {
      actionId: actionId!,
      deliverableId: data.deliverableId,
      order: order,
      strategyId: currentStrategyId,
    };
    await linkDeliverableMutation.mutateAsync(payload);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Add a linked deliverable</DialogTitle>
      <DialogContent>
        <DialogContentText mb={1}>
          Please insert select the Strategy and deliverable you wish to link
        </DialogContentText>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} id="link-deliverable-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HookFormAutoComplete
                  name={'strategyId'}
                  label={'Strategy'}
                  disabled
                  options={strategies}
                ></HookFormAutoComplete>
              </Grid>
              <Grid item xs={12}>
                <HookFormAutoComplete
                  disablePortal={false}
                  name={'deliverableId'}
                  label={'Deliverable'}
                  options={filteredDeliverable}
                ></HookFormAutoComplete>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" form="link-deliverable-form">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
