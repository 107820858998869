import { Divider } from '@mui/material';
import { Link, useMatches } from '@tanstack/react-router';
import * as React from 'react';
import { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';

import NavigationMenuListItem from '@/components/navigation/NavigationMenuListItem';
import { getPages, Page } from '@/components/navigation/navPages';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu';
import { useVisiblePages } from '@/hooks/useNavigationVisiblePages';
import { cn } from '@/lib/utils';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';

const NavMenu = (pages: Page[]) => {
  const matches = useMatches();

  const isActivePage = (key: string) => {
    const match = matches.filter((x) => x.id !== '__root__')[0];
    return match.fullPath.includes(key);
  };

  const globalSettings = useGlobalSettingsStore(
    useShallow((state) => state.globalSettings),
  );
  const userRole = globalSettings.role;

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {pages &&
          pages?.map((page, index) => {
            const pageName = typeof page === 'string' ? page : Object.keys(page)[0];
            if (typeof page === 'string' || !Object.values(page)[0]?.itemSets) {
              const pagePath = `/${pageName.toLowerCase()}`;
              return (
                <NavigationMenuItem key={pageName}>
                  <Link
                    to={pagePath}
                    className={navigationMenuTriggerStyle()}
                    activeProps={{
                      className:
                        'border-b-2 border-b-brown-dark bg-inherit text-accent-foreground',
                    }}
                  >
                    {pageName}
                  </Link>
                </NavigationMenuItem>
              );
            } else {
              const key = Object.keys(page)[0] as string;
              const subPages = page[key];
              return (
                subPages && (
                  <NavigationMenuItem key={`${key}-${index}`}>
                    <NavigationMenuTrigger
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      className={cn({
                        'border-b-2 border-b-brown-dark bg-accent text-accent-foreground':
                          isActivePage(`/${key.replace(' ', '_').toLowerCase()}`),
                      })}
                    >
                      {key}
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <div className="flex w-[400px] overflow-auto md:w-[500px] lg:w-[600px]">
                        <ul className="flex-grow p-4">
                          {subPages.itemSets?.map((itemSet, setIndex) => {
                            if (userRole && itemSet.roles.includes(userRole)) {
                              return (
                                <React.Fragment key={`itemset-${setIndex}`}>
                                  {setIndex > 0 && <Divider className="my-2" />}
                                  {itemSet.items.map((item, itemIndex) => {
                                    const isString = typeof item === 'string';
                                    const title = isString ? item : item.name;
                                    const description = isString
                                      ? undefined
                                      : item.description;
                                    const url = isString ? undefined : item.url;

                                    return (
                                      <NavigationMenuListItem
                                        key={`${key}-${title}-${itemIndex}`}
                                        title={title}
                                        description={description}
                                        to={
                                          url
                                            ? url
                                            : `/${key.replace(' ', '_')}/${title
                                                .toLowerCase()
                                                .replace(' ', '_')}`
                                        }
                                      >
                                        {title}
                                      </NavigationMenuListItem>
                                    );
                                  })}
                                </React.Fragment>
                              );
                            }
                            return null;
                          })}
                        </ul>
                        {subPages.image && (
                          <div className="flex-shrink-0">
                            <img
                              src={subPages.image}
                              className="h-auto w-full"
                              alt={`${key} Section`}
                            />
                          </div>
                        )}
                      </div>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                )
              );
            }
          })}
        <NavigationMenuIndicator />
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default function NavigationMenuShad() {
  const globalSettings = useGlobalSettingsStore(
    useShallow((state) => state.globalSettings),
  );

  const pages = getPages(globalSettings.enabledFeatures);

  const userRole = globalSettings.role;

  const visiblePages = useVisiblePages(pages, userRole);

  const pagesShowAtEnd = useMemo(
    () =>
      visiblePages.filter((page) => {
        if (typeof page !== 'string') {
          return Object.values(page)[0]?.showAtEnd;
        }
        return false;
      }),
    [visiblePages],
  );

  const pagesWithItems = useMemo(
    () =>
      visiblePages.filter((page) => {
        if (pagesShowAtEnd.includes(page)) {
          return false;
        }
        if (typeof page !== 'string') {
          return Object.values(page)[0]?.itemSets;
        }
        return false;
      }),
    [visiblePages, pagesShowAtEnd],
  );

  const pagesWithoutItems = useMemo(
    () =>
      visiblePages.filter((page) => {
        if (pagesShowAtEnd.includes(page)) {
          return false;
        }

        if (typeof page !== 'string') {
          return !Object.values(page)[0]?.itemSets;
        }
        return true;
      }),
    [visiblePages, pagesShowAtEnd],
  );

  return (
    <div className="flex flex-wrap">
      {NavMenu(pagesWithoutItems)}
      <div className="order-last w-full lg:order-none lg:w-auto">
        {NavMenu(pagesWithItems)}
      </div>
      {NavMenu(pagesShowAtEnd)}
    </div>
  );
}
