import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EmailIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.06667 4.97998L9.44167 10.7633C9.59479 10.9015 9.79373 10.978 10 10.978C10.2063 10.978 10.4052 10.9015 10.5583 10.7633L16.9333 4.97998M12.3333 9.43831L14.1667 11.2716M7.66667 9.43831L5.83333 11.2716M16.6667 16.2716H3.33333C3.11232 16.2716 2.90036 16.1839 2.74408 16.0276C2.5878 15.8713 2.5 15.6593 2.5 15.4383V5.43831C2.5 5.2173 2.5878 5.00534 2.74408 4.84906C2.90036 4.69278 3.11232 4.60498 3.33333 4.60498H16.6667C16.8877 4.60498 17.0996 4.69278 17.2559 4.84906C17.4122 5.00534 17.5 5.2173 17.5 5.43831V15.4383C17.5 15.6593 17.4122 15.8713 17.2559 16.0276C17.0996 16.1839 16.8877 16.2716 16.6667 16.2716Z"
          stroke="#808080"
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
