import { Icon, IconButton, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { getRouteApi } from '@tanstack/react-router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { getIconComponent } from '@/components/icons/MUIIconPicker';
import useUpdateFilters from '@/hooks/useUpdateFilters';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import { strategyItemFilters } from '@/types/itemFilter';

export type HierarchyItem = {
  id: string;
  name: string;
  iconName?: string;
  backgroundColor?: string;
  subordinates?: HierarchyItem[];
};

type HierarchyProps = {
  data: HierarchyItem;
  setDrawerOpen: (b: boolean) => void;
};

type NodePosition = {
  x: number;
  y: number;
  width: number;
  height: number;
};

const NODE_WIDTH = 60;
const NODE_HEIGHT = 60;
const VERTICAL_SPACING = 30;
const HORIZONTAL_SPACING = 30;

const HierarchyNode: React.FC<{
  item: HierarchyItem;
  position: NodePosition;
  isHighlighted: boolean;
  onClick: () => void;
}> = ({ item, position, isHighlighted, onClick }) => {
  const IconComponent = getIconComponent(item.iconName || '');
  return (
    <div role="button" tabIndex={0}>
      <IconButton
        className={`cursor-pointer rounded-lg`}
        sx={{
          backgroundColor: (theme) =>
            item.backgroundColor ?? theme.palette.brownPrimary.main,
          position: 'absolute',
          left: position.x,
          top: position.y,
          width: position.width,
          height: position.height,
          border: '3px solid',
          borderColor: (theme) =>
            isHighlighted ? theme.palette.brownPrimary.light : 'lightgrey',
          '@keyframes breathe': {
            '0%, 100%': {
              boxShadow: (theme) =>
                `0 0 0 3px ${theme.palette.brownPrimary.light}, 0 0 20px 3px ${theme.palette.brownPrimary.main}`,
            },
            '50%': {
              boxShadow: (theme) =>
                `0 0 0 1px ${theme.palette.brownPrimary.light}, 0 0 10px 1px ${theme.palette.brownPrimary.main}`,
            },
          },
          animation: isHighlighted ? 'breathe 3s ease-in-out infinite' : 'none',
          '&:hover': {
            backgroundColor: (theme) =>
              item.backgroundColor ?? theme.palette.brownPrimary.main,
            border: '5px solid',
            borderColor: (theme) => theme.palette.brownPrimary.light,
          },
        }}
        onClick={onClick}
      >
        <Tooltip title={item.name}>
          <IconComponent style={{ color: 'white', fontSize: 28 }}></IconComponent>
        </Tooltip>
      </IconButton>
    </div>
  );
};

const HierarchyChart: React.FC<HierarchyProps> = ({ data, setDrawerOpen }) => {
  const theme = useTheme();
  const routeApi = getRouteApi('/strategies');
  const updateFilters = useUpdateFilters<strategyItemFilters>(routeApi);
  const { strategyId } = routeApi.useSearch();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [nodes, setNodes] = useState<{ item: HierarchyItem; position: NodePosition }[]>(
    [],
  );
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const { clearActiveItems } = useStrategyStore(
    useShallow((state) => ({
      clearActiveItems: state.clearActiveItems,
    })),
  );

  const calculatePositions = useCallback(
    (
      item: HierarchyItem,
      x: number,
      y: number,
      level: number,
    ): {
      nodes: { item: HierarchyItem; position: NodePosition }[];
      width: number;
      height: number;
    } => {
      const node = { item, position: { x, y, width: NODE_WIDTH, height: NODE_HEIGHT } };
      const items = [node];

      if (item.subordinates && item.subordinates.length > 0) {
        const childrenData = item.subordinates.map((subordinate) =>
          calculatePositions(
            subordinate,
            0,
            y + NODE_HEIGHT + VERTICAL_SPACING,
            level + 1,
          ),
        );

        const totalChildrenWidth = childrenData.reduce(
          (sum, child) => sum + child.width,
          0,
        );
        const totalSpacing = (childrenData.length - 1) * HORIZONTAL_SPACING;
        const totalWidth = Math.max(NODE_WIDTH, totalChildrenWidth + totalSpacing);

        let childX = x + (totalWidth - totalChildrenWidth - totalSpacing) / 2;
        const maxChildHeight = Math.max(...childrenData.map((child) => child.height));

        childrenData.forEach((childResult) => {
          childResult.nodes.forEach((childNode) => {
            childNode.position.x += childX;
          });
          items.push(...childResult.nodes);
          childX += childResult.width + HORIZONTAL_SPACING;
        });

        node.position.x = x + (totalWidth - NODE_WIDTH) / 2;

        return {
          nodes: items,
          width: totalWidth,
          height: NODE_HEIGHT + VERTICAL_SPACING + maxChildHeight,
        };
      }

      return { nodes: items, width: NODE_WIDTH, height: NODE_HEIGHT };
    },
    [],
  );

  useEffect(() => {
    const { nodes, width, height } = calculatePositions(data, 0, 0, 0);
    setNodes(nodes);
    setDimensions({ width, height });
  }, [data]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (canvas && ctx) {
      canvas.width = dimensions.width;
      canvas.height = dimensions.height;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.strokeStyle = theme.palette.brownPrimary.main;
      ctx.lineWidth = 1.5;
      const drawConnections = (item: HierarchyItem) => {
        const parentNode = nodes.find((n) => n.item.id === item.id);
        if (parentNode && item.subordinates) {
          const parentCenterX = parentNode.position.x + NODE_WIDTH / 2;
          const parentBottomY = parentNode.position.y + NODE_HEIGHT;

          item.subordinates.forEach((subordinate) => {
            const childNode = nodes.find((n) => n.item.id === subordinate.id);
            if (childNode) {
              const childCenterX = childNode.position.x + NODE_WIDTH / 2;
              const childTopY = childNode.position.y;

              const midY = (parentBottomY + childTopY) / 2;
              const cornerRadius = 10;

              ctx.beginPath();
              ctx.moveTo(parentCenterX, parentBottomY);
              ctx.lineTo(parentCenterX, midY);

              // Straight line in the middle
              ctx.lineTo(
                childCenterX - Math.sign(childCenterX - parentCenterX) * cornerRadius,
                midY,
              );

              // Curve from midpoint to child
              ctx.quadraticCurveTo(childCenterX, midY, childCenterX, midY + cornerRadius);
              ctx.lineTo(childCenterX, childTopY);
              ctx.stroke();
              drawConnections(subordinate);
            }
          });
        }
      };

      drawConnections(data);
    }
  }, [nodes, dimensions, data, theme.palette.brownPrimary.main]);

  const handleNodeClick = (id: string) => {
    clearActiveItems(updateFilters);
    updateFilters('strategyId', id);
    updateFilters('contributionId', undefined);
    updateFilters('deliverableId', undefined);
    updateFilters('actionId', undefined);
    setDrawerOpen(false);
  };

  return (
    <div
      ref={containerRef}
      className="relative m-4"
      style={{ width: dimensions.width, height: dimensions.height }}
    >
      <canvas ref={canvasRef} className="absolute left-0 top-0" />
      {nodes.map((node) => (
        <HierarchyNode
          key={node.item.id}
          item={node.item}
          position={node.position}
          isHighlighted={node.item.id === (strategyId ?? '')}
          onClick={() => handleNodeClick(node.item.id)}
        />
      ))}
    </div>
  );
};

export default HierarchyChart;
