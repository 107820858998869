import { CloseOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
} from '@mui/material';

import ContactUsForm from '@/components/loginPage/ContactUsForm';

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  defaultSelect?: string;
};

const ContactUsDialog = ({ open, setOpen, defaultSelect }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '50px',
          padding: '10px',
        },
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          pr: '24px',
        }}
      >
        <DialogTitle fontSize={18} fontWeight={600} sx={{ paddingBottom: 0 }}>
          Let&apos;s yarn!
        </DialogTitle>

        <CloseOutlined
          fill="#040921"
          sx={{ opacity: 0.3, cursor: 'pointer', width: 24, height: 24 }}
          onClick={() => setOpen(false)}
        />
      </Stack>
      <DialogContent sx={{ pt: 0 }}>
        <DialogContentText fontSize={14} mb={2}>
          Please insert your details.
        </DialogContentText>
        <Divider sx={{ mb: 3 }} />
        <ContactUsForm
          isModal={true}
          handleOpen={setOpen}
          defaultSelect={defaultSelect}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ContactUsDialog;
