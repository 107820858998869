import { zodResolver } from '@hookform/resolvers/zod';
import { DialogContentText, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GridRowId } from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useDeleteData, usePostData, usePutData } from '@/helpers/hooks';
import { useConfirm } from '@/store/useConfirmDialogStore';

import HookFormInput from '../../components/HookFormInput';

export interface Group {
  id?: string;
  name: string;
  description: string;
  isNew?: boolean;
}

interface Props {
  group?: Group;
  selectedIds?: GridRowId[];
  onClose?: (name?: string) => void;
}

const NewGroupDialog = ({ group, selectedIds, onClose }: Props) => {
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const isNew = !group || group?.isNew;
  const addToMutation = usePostData<Partial<Group>, unknown, Partial<Group>, unknown>({
    url: `/Group/AddToGroup`,
  });
  const updateGroupMutation = usePutData<
    Partial<Group>,
    unknown,
    Partial<Group>,
    unknown
  >(`/Group/UpdateGroup/${group?.id}`, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['businessUnits'] });
      await queryClient.invalidateQueries({ queryKey: ['groups'] });
      handleClose();
    },
  });
  const deleteGroupMutation = useDeleteData<string>(`/Group/DeleteGroup`, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['businessUnits'] });
      await queryClient.invalidateQueries({ queryKey: ['groups'] });
      handleClose();
    },
  });

  const formSchema = z.object({
    name: z.string().min(1, 'Group Name is required'),
    description: z.string().optional(),
  });
  const memoizedDefaultValue = useMemo(() => {
    return {
      name: group?.name ?? '',
      description: group?.description ?? '',
    };
  }, [group]);

  type FormFields = z.infer<typeof formSchema>;
  const methods = useForm<FormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: memoizedDefaultValue,
  });

  useEffect(() => {
    methods.reset(memoizedDefaultValue, { keepValues: false });
  }, [memoizedDefaultValue, methods]);
  const handleClose = (name?: string) => {
    onClose?.(name);
  };

  const handleDeleteClick = async () => {
    const confirmed = await confirm({
      title: 'Are you sure?',
      message:
        'Are you sure you want to delete thg group?\n All Business Units in the group will be ungrouped.',
    });
    if (confirmed) {
      await deleteGroupMutation.mutateAsync(group!.id!);
    }
  };
  const onSubmit = async (data: FormFields) => {
    if (!isNew) {
      const dirtyData: Partial<FormFields> = {};
      Object.keys(methods.formState.dirtyFields).forEach((field) => {
        dirtyData[field as keyof FormFields] = data[field as keyof FormFields];
      });
      const payload = {
        id: group?.id,
        ...dirtyData,
      };
      await updateGroupMutation.mutateAsync(payload);
    } else {
      const payload = {
        name: data.name,
        description: data.description,
        businessUnitIds: selectedIds,
      };
      await addToMutation.mutateAsync(payload, {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: ['businessUnits'] });
          await queryClient.invalidateQueries({ queryKey: ['DropdownOptions'] });
          await queryClient.invalidateQueries({ queryKey: ['groups'] });
          const data = queryClient.getQueryData(['groups']) as Group[];
          handleClose(data.find((x) => x.name === payload.name)?.id);
        },
      });
    }
  };
  return (
    <>
      <Dialog open={true} onClose={() => handleClose()} fullWidth>
        <FormProvider {...methods}>
          <DialogTitle fontSize={16} sx={{ paddingBottom: 0 }}>
            {group?.isNew ? 'New' : 'Update'} Group
          </DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>Please insert details your group</DialogContentText>
            <form onSubmit={methods.handleSubmit(onSubmit)} id="group-update-form">
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    pb: 2,
                  }}
                >
                  <HookFormInput label={'Name'} name={'name'}></HookFormInput>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    pb: 2,
                  }}
                >
                  <HookFormInput
                    label={'Description'}
                    name={'description'}
                    multiline
                    rows={3}
                  ></HookFormInput>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            {!isNew && (
              <Button
                color={'error'}
                disabled={methods.formState.isSubmitting}
                sx={{ marginLeft: '4px' }}
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            )}
            <Button onClick={() => handleClose()} sx={{ marginLeft: 'auto' }}>
              Cancel
            </Button>
            <Button
              form="group-update-form"
              type="submit"
              disabled={methods.formState.isSubmitting}
            >
              Save
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};
NewGroupDialog.displayName = 'NewGroupDialog';

export default NewGroupDialog;
