import React from 'react';

import { FittedTableProps } from './customComponentTypes';

const FittedTable: React.FC<FittedTableProps> = ({ children }) => {
  if (!children) return null;
  // Ensure children is treated as an array and filter out non-string elements
  const stringChildren = React.Children.toArray(children).filter(
    (child) => typeof child === 'string',
  ) as string[];

  // Process each string child
  const rows = stringChildren.flatMap((child) =>
    child.split('\n').map((row) => row.split('|').filter((cell) => cell.trim() !== '')),
  );

  return (
    <div
      className="mb-3"
      style={{
        overflowX: 'auto',
        width: '100%',
      }}
    >
      <table
        style={{
          width: '100%',
          borderCollapse: 'separate',
          borderSpacing: 0,
          border: '1px solid #ddd',
        }}
      >
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => {
                return (
                  <td
                    key={cellIndex}
                    className="text-black"
                    style={{
                      padding: '8px',
                      backgroundColor: cellIndex === 0 ? '#fae2d5' : 'transparent',
                      border: '1px solid #ddd',
                      borderLeft: cellIndex === 0 ? 'none' : '1px solid #ddd',
                      borderTop: rowIndex === 0 ? 'none' : '1px solid #ddd',
                    }}
                  >
                    {cell.trim()}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default FittedTable;
