import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew'; // Replacement for AdaptIcon
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import BuildIcon from '@mui/icons-material/Build';
import ChatIcon from '@mui/icons-material/Chat';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'; // Replacement for CommunityIcon
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import GroupIcon from '@mui/icons-material/Group'; // Replacement for RelationshipIcon
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import HealingIcon from '@mui/icons-material/Healing'; // Replacement for HealthAndSafetyIcon
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import PaymentIcon from '@mui/icons-material/Payment';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PolicyIcon from '@mui/icons-material/Policy'; // Replacement for GovernanceIcon
import SecurityIcon from '@mui/icons-material/Security'; // Replacement for SafetyIcon
import TransformIcon from '@mui/icons-material/Transform';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'; // Replacement for RespectIcon
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import WifiIcon from '@mui/icons-material/Wifi';
import WorkIcon from '@mui/icons-material/Work';
import { SvgIconProps } from '@mui/material';
import React, { memo } from 'react';

import BaseIcon from '@/pages/strategies/Icons/BaseIcon';

const ACTION_TYPES = [
  'Relationships',
  'Respect',
  'Governance',
  'Opportunities',
  'Procurement and Grants',
  'First Nations Workforce',
  'Economic Participation',
  'Social Outcomes',
  'Structural Reform',
  'Language and Communication',
  'Capability and Capacity',
  'Being Adaptable and Responsive',
  'Cultural Growth',
  'BU Additional',
  'Cultural capability and cultural safety',
  'Digital Inclusion',
  'Attraction & Recruitment',
  'Health and Ageing',
  'Cultural governance',
  'Our Partners',
  'Our Capability',
  'Stakeholder relationships',
  'Stronger Communities',
  'Our People',
  'Respect for Culture & Heritage',
] as const;

interface ActionTypeIconProps extends SvgIconProps {
  actionAreaType: string;
}

const ActionAreaIcon: React.FC<ActionTypeIconProps> = memo(({ actionAreaType }) => {
  if (actionAreaType === undefined) {
    return null;
  }

  let IconComponent: React.ElementType | undefined = undefined;

  switch (actionAreaType) {
    case 'Relationships':
      IconComponent = HandshakeRoundedIcon;
      break;
    case 'Respect':
      IconComponent = VolunteerActivismRoundedIcon;
      break;
    case 'Governance':
      IconComponent = BarChartRoundedIcon;
      break;
    case 'Opportunities':
      IconComponent = Groups2RoundedIcon;
      break;
    case 'Procurement and Grants':
      IconComponent = PaymentIcon;
      break;
    case 'First Nations Workforce':
      IconComponent = WorkIcon;
      break;
    case 'Economic Participation':
      IconComponent = TrendingUpIcon;
      break;
    case 'Social Outcomes':
      IconComponent = EmojiEmotionsIcon;
      break;
    case 'Structural Reform':
      IconComponent = TransformIcon;
      break;
    case 'Language and Communication':
      IconComponent = ChatIcon;
      break;
    case 'Capability and Capacity':
      IconComponent = FitnessCenterIcon;
      break;
    case 'Being Adaptable and Responsive':
      IconComponent = AutorenewIcon;
      break;
    case 'Cultural Growth':
      IconComponent = NaturePeopleIcon;
      break;
    case 'BU Additional':
      IconComponent = AddIcon;
      break;
    case 'Cultural capability and cultural safety':
      IconComponent = SecurityIcon;
      break;
    case 'Digital Inclusion':
      IconComponent = WifiIcon;
      break;
    case 'Attraction & Recruitment':
      IconComponent = GroupAddIcon;
      break;
    case 'Health and Ageing':
      IconComponent = HealingIcon;
      break;
    case 'Cultural governance':
      IconComponent = PolicyIcon;
      break;
    case 'Our Partners':
      IconComponent = GroupWorkIcon;
      break;
    case 'Our Capability':
      IconComponent = BuildIcon;
      break;
    case 'Stakeholder relationships':
      IconComponent = GroupIcon;
      break;
    case 'Stronger Communities':
      IconComponent = EmojiPeopleIcon;
      break;
    case 'Our People':
      IconComponent = PeopleOutlineIcon;
      break;
    case 'Respect for Culture & Heritage':
      IconComponent = VerifiedUserIcon;
      break;
    default:
      break;
  }
  return (
    <BaseIcon
      IconComponent={IconComponent}
      IconType={ACTION_TYPES as unknown as string[]}
      title={actionAreaType}
    />
  );
});

export default ActionAreaIcon;
