import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';

type TypographyProps = React.ComponentProps<typeof Typography>;

interface OverflowTipProps extends TypographyProps {
  children: React.ReactNode;
  maxLine?: number;
  showIcon?: boolean;
}

const OverflowTypography: React.FC<OverflowTipProps> = ({
  children,
  maxLine = 1,
  sx,
  showIcon = false,
  ...restProps
}) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkOverflow = () => {
      if (textElementRef.current) {
        setIsOverflow(
          textElementRef.current.scrollHeight > textElementRef.current.clientHeight ||
            textElementRef.current.scrollWidth > textElementRef.current.clientWidth,
        );
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <>
      {!showIcon ? (
        <Tooltip
          title={
            isOverflowed ? (
              <Typography
                sx={{
                  color: '#fff',
                }}
              >
                {' '}
                {children}{' '}
              </Typography>
            ) : (
              ''
            )
          }
        >
          <Typography
            {...restProps}
            ref={textElementRef}
            sx={{
              ...sx,
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: maxLine,
              textWrap: 'wrap',
              textAlign: 'left',
            }}
          >
            {children}
          </Typography>
        </Tooltip>
      ) : (
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            {...restProps}
            ref={textElementRef}
            sx={{
              ...sx,
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: maxLine,
              textWrap: 'wrap',
            }}
          >
            {children}
          </Typography>
          {isOverflowed && (
            <Tooltip
              title={
                <Typography
                  sx={{
                    color: '#fff',
                  }}
                >
                  {children}
                </Typography>
              }
            >
              <InfoOutlinedIcon
                color={'primary'}
                sx={{ marginTop: 2 }}
              ></InfoOutlinedIcon>
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};

export default OverflowTypography;
