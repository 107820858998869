import { FC, PropsWithChildren, useMemo, useState } from 'react';

import useSubscriptionsAndRoles from '@/hooks/useSubscriptionsAndRoles';
import { UserInfo } from '@/pages/globalAdmin/users/NewUserDialog';

import AddNewUserContext from './AddNewUserContext';

const AddNewUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { subscriptions, roles } = useSubscriptionsAndRoles();

  const [selectedUser, setSelectedUser] = useState<UserInfo | undefined>(undefined);

  const setUser = (user: UserInfo | null | undefined) => {
    if (user === null) {
      setSelectedUser({
        firstName: '',
        lastName: '',
        isGlobalAdmin: false,
        ssoLogin: false,
        allowMultiSub: false,
        subscriptions: [],
        isActive: false,
        isNew: true,
        excludeEmail: 0,
      });
    } else {
      setSelectedUser(user);
    }
  };

  const values = useMemo(
    () => ({
      subscriptions,
      roles,
      user: selectedUser,
      setUser,
    }),
    [roles, selectedUser, subscriptions],
  );

  return (
    <AddNewUserContext.Provider value={values}>{children}</AddNewUserContext.Provider>
  );
};

export default AddNewUserProvider;
