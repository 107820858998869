import { addMonths, getMonth, getYear, isAfter, sub } from 'date-fns';
import dayjs from 'dayjs';

import { ContributionPeriod, Periodicity } from '@/pages/strategies/types/Contribution.types';

export const isSameMonthYear = (date1: Date, date2: Date) => {
    return getYear(date1) === getYear(date2) && getMonth(date1) === getMonth(date2);
};

export function formatDate(utcDateString: string | Date): string {
    const utcDate = new Date(utcDateString);
    const localDateString = utcDate.toLocaleString('au', {
        dateStyle: 'medium',
    });
    return localDateString;
}

export function formatDateYearMonthDay(utcDateString: string | Date): string {
    const date = new Date(utcDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function formatDateTime(utcDateString: string | Date): string {
    const utcDate = new Date(utcDateString);
    const localDateString = utcDate.toLocaleString('au', {
        dateStyle: 'medium',
        timeStyle: 'short',
    });
    return localDateString;
}

export function parseDate(dateStr: string) {
    const [year, month, day] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
}
function monthToString(month: number): string {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return months[month - 1];
}

export function formatDateTimeToMonthString(
    utcDateString: string | Date,
    format: 'number' | 'string' = 'number',
): string {
    const utcDate = new Date(utcDateString);
    const month = utcDate.getMonth() + 1;
    const year = utcDate.getFullYear();
    if (format === 'number') return `${month}/${year}`;
    return `${monthToString(month)} ${year}`;
}

interface Sortable {
    createdDate?: string;
}

export function sortByCreatedDate<T extends Sortable>(arr: T[]): T[] {
    return arr.sort((a, b) => {
        if (!a.createdDate || !b.createdDate) return 0;
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);

        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            return 0;
        }
        return dateA.getTime() - dateB.getTime();
    });
}

const rtf = new Intl.RelativeTimeFormat('au', { numeric: 'auto' });

export function formatRelativeTime(dateString: Date) {
    const date = new Date(dateString);
    const now = new Date();
    const diffInMs = now.getTime() - date.getTime();

    const diffInSeconds = Math.round(diffInMs / 1000);
    const diffInMinutes = Math.round(diffInMs / (1000 * 60));
    const diffInHours = Math.round(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24));

    if (Math.abs(diffInSeconds) < 60) {
        return rtf.format(-diffInMinutes, 'second');
    } else if (Math.abs(diffInMinutes) < 60) {
        return rtf.format(-diffInMinutes, 'minute');
    } else if (Math.abs(diffInHours) < 24) {
        return rtf.format(-diffInHours, 'hour');
    } else {
        return rtf.format(-diffInDays, 'day');
    }
}

export const formatDuration = (startDate: Date | string, endDate: Date | string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const years = end.getFullYear() - start.getFullYear();
    const months = end.getMonth() - start.getMonth();
    const duration = [];

    if (years > 0) {
        duration.push(`${years} year${years > 1 ? 's' : ''}`);
    }

    if (months > 0) {
        duration.push(`${months} month${months > 1 ? 's' : ''}`);
    }

    return duration.join(' ');
};
/*

const removeTimeFromDate = (dateString: Date | string) => {
  const date = parseISO(dateString.toString());
  return setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0), 0);
};

function getPercentage(startDate: Date, endDate: Date): number {
  const current: Date = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  const totalDifference = end.getTime() - start.getTime();
  const elapsedDifference = current.getTime() - start.getTime();

  // Handle case where current date is before start or after end
  if (elapsedDifference <= 0) {
    return 0;
  } else if (elapsedDifference >= totalDifference) {
    return 1;
  }

  return Number((elapsedDifference / totalDifference).toFixed(2));
}

const getContributionDates = (
    startDate: Date | string,
    endDate: Date | string,
    periodicty: Periodicty,
) => {
  const monthToAdd = getMonthToAddByPeriodicity(periodicty);
  const dates: Date[] = [];
  let current = dayjs(startDate);
  while (!current.isAfter(endDate, 'M')) {
    dates.push(current.toDate());
    current = current.add(monthToAdd, 'M');
  }
  return dates;
};

*/

export function formatMonthYear(dateString: string) {
    const date = new Date(dateString);
    return `${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear()}`;
}

export function isEndDateMatchPeriod(
    fromDate: Date | string,
    endDate: Date | string,
    period: Periodicity,
) {
    if (period === Periodicity.Once) return true;

    const monthToAdd = getMonthToAddByPeriodicity(period);
    let start = dayjs(fromDate); const end = dayjs(endDate);


    while (start.isBefore(end)) {
        start = start.add(monthToAdd, 'month');
        if (start.isSame(end, 'month')) return true;
    }
    return false;
}
export const checkPeriodConflictDates = (contributionPeriodId: string, periods: ContributionPeriod[],
    fromDate: Date, periodicity: Periodicity, toDate?: Date) => {
    let conflicts = false;
    const newFromDate =dayjs(fromDate);
    const newToDate = toDate ? dayjs(toDate) : newFromDate;

    const newPeriodDates = getDatesForPeriod(newFromDate.toDate(), newToDate.toDate(), periodicity);

    const filteredPeriods = periods.filter(period => period.id !== contributionPeriodId);

    filteredPeriods.forEach(period => {
        if (conflicts) return;

        const existingFromDate = dayjs(period.fromDate) ;
        const existingToDate = period.toDate ? dayjs(period.toDate) : existingFromDate;
        const existingPeriodDates = getDatesForPeriod(existingFromDate.toDate(), existingToDate.toDate(), period.periodicity);

        existingPeriodDates.forEach(date => {
            const formattedDate = dayjs(date).startOf('month');
            const formattedNewDates = newPeriodDates.map(d => dayjs(d).startOf('month'));

            if (formattedNewDates.some(newDate => newDate.isSame(formattedDate, 'month'))) {
                conflicts = true;
                return;
            }
        });
    });

    return conflicts;
};
export function getDatesForPeriod(fromDate: Date, toDate: Date, periodicity: Periodicity): Date[] {
    if (fromDate > toDate) return [];

    const dates: Date[] = [];
    let currentDate = new Date(fromDate);

    while (currentDate <= toDate) {
        dates.push(new Date(currentDate)); // Push a copy to avoid mutating `currentDate`

        switch (periodicity) {
            case Periodicity.Once:
                return dates; // Only one date needed
            case Periodicity.Monthly:
                currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
                break;
            case Periodicity.Quarterly:
                currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3));
                break;
            case Periodicity.TwiceAYear:
                currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 6));
                break;
            case Periodicity.Annual:
                currentDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
                break;
            default:
                return [];
        }
    }

    return dates;
}

export function getAvailableToDate(
    fromDate: Date | string,
    endDate: Date | string,
    period: Periodicity,
) {
    if (fromDate === undefined || endDate === undefined || period === undefined) {
        return [];
    }
    const monthToAdd = getMonthToAddByPeriodicity(period);
    const dates = [];
    if (monthToAdd > 0) {
        let start = dayjs(fromDate);
        while (!start.isAfter(dayjs(endDate), 'month')) {
            dates.push(start.toDate());
            start = start.add(monthToAdd, 'month');
        }
    }
    return dates;
}
function getMonthToAddByPeriodicity(period: Periodicity): number {
    switch (period) {
        case Periodicity.Monthly:
            return 1;
        case Periodicity.Quarterly:
            return 3;
        case Periodicity.TwiceAYear:
            return 6;
        case Periodicity.Annual:
            return 12;
        case Periodicity.Once:
            return 0;
        default:
            throw new Error('Invalid periodicity');
    }
}

export const isFuture = (currentDate: Date, period: Date) => {
    const periodDate = addMonths(period, 1);
    const targetDate = sub(periodDate, { days: 1 });
    return isAfter(targetDate, currentDate);
};

export const getMonthsOfQuarter = (quarter: number) => {
    const startMonth = (quarter - 1) * 3;
    const endMonth = startMonth + 2;

    const months = [];
    for (let month = startMonth; month <= endMonth; month++) {
        months.push(dayjs().month(month).format('MMM'));
    }

    return months;
};
