import { ErrorRounded } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  CircularProgress,
  InputAdornment,
  styled,
  TextareaAutosize,
} from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ZodTypeAny } from 'zod';

import useDisplayFeedback from '@/components/hooks/useDisplayFeedback';
import { isFieldRequired } from '@/helpers/formUtils';

type TextareaAutosizeProps = React.ComponentProps<typeof TextareaAutosize>;

interface Props extends TextareaAutosizeProps {
  name: string;
  label?: string;
  schema?: ZodTypeAny;
  onKeyUpInput?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  showCharCount?: boolean; // New prop to control character counter visibility
}

const StyledTextareaWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  marginTop: '0.8rem',
}));

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  padding: '10px',
  paddingTop: '16px', // Add padding to the top to make room for the label
  fontSize: '15px',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.grey[300]}`,
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
  },
  '&.error': {
    borderColor: theme.palette.error.main,
  },
}));

const StyledLabel = styled('label')(({ theme }) => ({
  position: 'absolute',
  left: '10px',
  top: '-8px',
  fontSize: '12px',
  padding: '0 4px',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.secondary,
  pointerEvents: 'none',
  transition: 'all 0.2s',
}));

const StyledError = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '12px',
  marginTop: '4px',
}));

const StyledCharCount = styled('span')(({ theme }) => ({
  position: 'absolute',
  bottom: '-0.2rem',
  right: '1rem',
  fontSize: '12px',
  padding: '0 8px',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.secondary,
  zIndex: 1,
}));

const HookFormAutoSizeInput = ({
  name,
  label,
  schema,
  onKeyUpInput,
  showCharCount,
  ...restProps
}: Props) => {
  const { control, formState } = useFormContext();

  const displayFeedback = useDisplayFeedback({ formState, name });

  const required = schema !== undefined && isFieldRequired(schema, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error: fieldError } }) => {
        return (
          <div className="flex-grow">
            <StyledTextareaWrapper>
              <StyledTextareaAutosize
                {...field}
                {...restProps}
                minRows={restProps.minRows ?? 1}
                onKeyUp={onKeyUpInput}
                ref={field.ref} // Use ref instead of inputRef
                className={fieldError !== undefined ? 'error' : ''}
              />
              {label && (
                <StyledLabel>
                  {label}
                  {required && <span style={{ color: 'red' }}> *</span>}
                </StyledLabel>
              )}
              {displayFeedback === 'submitting' && (
                <InputAdornment
                  position="end"
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                  }}
                >
                  <CircularProgress size={20} />
                </InputAdornment>
              )}
              {displayFeedback === 'success' && (
                <InputAdornment
                  position="end"
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                  }}
                >
                  <CheckCircleIcon color="success" />
                </InputAdornment>
              )}
              {displayFeedback === 'error' && (
                <InputAdornment
                  position="end"
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                  }}
                >
                  <ErrorRounded color="error" />
                </InputAdornment>
              )}
              {showCharCount && (
                <StyledCharCount>{field.value?.length || 0}</StyledCharCount>
              )}
            </StyledTextareaWrapper>
            {fieldError?.message && <StyledError>{fieldError?.message}</StyledError>}
          </div>
        );
      }}
    />
  );
};

export default HookFormAutoSizeInput;
