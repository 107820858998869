import RefreshIcon from '@mui/icons-material/Refresh';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { IconButton, Tooltip } from '@mui/material';
import React, { HTMLAttributes } from 'react';

import { cn } from '@/lib/utils';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import { useShallow } from 'zustand/react/shallow';
interface PlusMinusIconProps extends HTMLAttributes<HTMLDivElement> {
  setSize: (size: number) => void;
}

const PlusMinusIcon = ({ setSize, ...props }: PlusMinusIconProps) => {
  const { className, ...itemProps } = props;

  const resetPanelSizes = useStrategyStore(useShallow((state) => state.resetPanelSizes));
  return (
    <div className={cn('flex items-center gap-1', className)} {...itemProps}>
      <Tooltip title="Decrease size">
        <IconButton
          size="small"
          sx={{ backgroundColor: 'white!important' }}
          color="info"
          onClick={() => setSize(-10)}
        >
          <UnfoldLessIcon className="rotate-90" fontSize="inherit" color="error" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Increase size" onClick={() => setSize(+10)}>
        <IconButton size="small" sx={{ backgroundColor: 'whitesmoke!important' }}>
          <UnfoldMoreIcon className="rotate-90" fontSize="inherit" color="success" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Reset size">
        <IconButton
          size="small"
          sx={{ backgroundColor: 'white!important' }}
          color="info"
          onClick={resetPanelSizes}
        >
          <RefreshIcon fontSize="inherit" color="primary" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default PlusMinusIcon;
