import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import { MouseEventHandler } from 'react';

type DeleteIconRedProps = {
  handleActionDelete?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

const DeleteIconRed = ({ handleActionDelete, disabled = false }: DeleteIconRedProps) => {
  return (
    <Tooltip title="Delete">
      <IconButton
        onClick={handleActionDelete}
        sx={{
          backgroundColor: '#d32f2f',
          '&:hover': {
            backgroundColor: '#8b0000', // dark red on hover
          },
          '&:disabled': {
            backgroundColor: '#d1a6a6', // dark red on hover
          },
        }}
        disabled={disabled}
      >
        <DeleteIcon sx={{ color: 'white!important' }}></DeleteIcon>
      </IconButton>
    </Tooltip>
  );
};

export default DeleteIconRed;
