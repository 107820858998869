import { RouteApi, useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';

const useUpdateFilters = <T>(routeApi: RouteApi<string, any>) => {
  const navigate = useNavigate({ from: routeApi.id });

  return useCallback(
    (name: keyof T, value: unknown) => {
      if (Array.isArray(value)) {
        navigate({
          // @ts-expect-error type validation
          search: (prev: any) => ({
            ...prev,
            [name]: value.length > 0 ? value : undefined,
          }),
        });
      } else {
        navigate({
          // @ts-expect-error type validation
          search: (prev: any) => ({ ...prev, [name]: value }),
        });
      }
    },
    [navigate],
  );
};

export default useUpdateFilters;
