import { create } from 'zustand';

interface NewsLetterStore {
  open: boolean;
  setOpen: () => void;
  onClose: () => void;
  toggleOpen: () => void;
}

export const useNewsLetterStore = create<NewsLetterStore>((set) => ({
  open: false,
  setOpen: () => {
    set({ open: true });
  },
  toggleOpen: () => {
    set((state) => ({ open: !state.open }));
  },
  onClose: () => {
    set({ open: false });
  },
}));
