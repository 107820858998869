import { useRouter } from '@tanstack/react-router';
import React, { useEffect, useState } from 'react';

interface ErrorComponentProps {
  error: Error;
  fallbackUI?: React.ReactNode;
  errorInfo?: React.ErrorInfo | null;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({
  error,
  fallbackUI,
  errorInfo,
}) => {
  const [fact, setFact] = useState<string | null>(null);
  const router = useRouter();

  const fetchRandomFact = async () => {
    try {
      const response = await fetch('https://api.api-ninjas.com/v1/facts', {
        headers: { 'X-Api-Key': 'U6kyVnEzg40Q0v43boAbvA==bgJx5VfW0Qxff0JE' },
      });
      const data = await response.json();
      setFact(data[0]?.fact || 'No fact available.');
    } catch (e) {
      setFact('Failed to fetch a random fact.');
    }
  };

  useEffect(() => {
    fetchRandomFact();
  }, []);
  
  if (fallbackUI) {
    return fallbackUI;
  }

  return (
    <div className="pl-5 pt-5">
      <h2>
        {process.env.MODE === 'production'
          ? 'An error has occurred and developers have been notified. Please refresh the page.'
          : `An error has occurred: ${error?.message}`}
      </h2>
      <div>
        <p>Did you know</p>
        <p>{fact}</p>
      </div>
      {process.env.MODE !== 'production' && errorInfo && (
        <details>
          <summary>Click to see more details.</summary>
          <pre>{error?.toString()}</pre>
          <pre>{errorInfo?.componentStack}</pre>
        </details>
      )}

      <button onClick={() => router.invalidate()}>Try Again</button>
    </div>
  );
};

export default ErrorComponent;
