import { Periodicity } from '@/pages/strategies/types/Contribution.types';
import { createNewSortInstance } from 'fast-sort';
import { isEqual } from 'lodash-es';
import { camelCase, startCase } from 'lodash';

export function isValidURL(url: string) {
  try {
    const regex = /^(https?|http):\/\/[^\s/$.?#].[^\s]*$/i;
    return regex.test(url);
  } catch (error) {
    return false;
  }
}

export function getMentionStyledText(comment: string): string {
  const regex = /@\[.+?\]\(.+?\)/gm;
  const displayRegex = /@\[.+?\]/g;
  const idRegex = /\(.+?\)/g;
  const matches = comment.match(regex);
  const arr: { id: string; display: string }[] = [];
  matches &&
    matches.forEach((m) => {
      const id = m.match(idRegex)?.[0]?.replace('(', '')?.replace(')', '');
      const display = m.match(displayRegex)?.[0]?.replace('@[', '')?.replace(']', '');

      if (id && display) {
        arr.push({ id: id, display: display });
      }
    });

  const newComment = comment.split(regex);
  let output = '';
  for (let i = 0; i < newComment.length; i++) {
    const c = newComment[i];
    if (i === newComment.length - 1) output += c;
    else output += c + `<span style='background-color: #cee4e5'>${arr[i].display}</span>`;
  }
  return output;
}

/*
function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to read file as ArrayBuffer.'));
      }
    };
    reader.onerror = () => {
      reject(reader.error || new Error('Failed to read file as ArrayBuffer.'));
    };
    reader.readAsArrayBuffer(file);
  });
}
*/

export function getRandomUserColor(str: string) {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();
  return `#${'00000'.substring(0, 6 - c.length)}${c}`;
}
export function getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function ordinalSuffix(i: number) {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export const getFrequency = (val: Periodicity) => {
  switch (val.toString()) {
    case '0':
      return 'Once';
    case '2':
      return 'Monthly';
    case '4':
      return 'Quarterly';
    case '8':
      return 'Twice a year';
    case '16':
      return 'Annual';
  }
};

export function compareObjects<T extends object>(oldVal: T, newVal: T): Partial<T> {
  const differences: Partial<T> = {};

  // Check keys in oldVal
  for (const key in oldVal) {
    const value1 = oldVal[key];
    const value2 = newVal[key];

    if (!isEqual(value1, value2)) differences[key] = value2;
  }

  // Check keys in newVal that are not in oldVal
  for (const key in newVal) {
    if (!(key in oldVal)) {
      differences[key] = newVal[key];
    }
  }

  return differences;
}

export const getItemsDirtyData = <
  TData extends Partial<Record<keyof TDirtyItems, unknown>>,
  TDirtyItems extends Record<string, unknown>,
>(
  data: TData,
  dirtyItems: TDirtyItems,
): Partial<TData> => {
  const dirtyItemsEntries = Object.entries(dirtyItems);

  return dirtyItemsEntries.reduce((dirtyData, [name, value]) => {
    if (typeof value !== 'object') {
      return { ...dirtyData, [name]: data[name] };
    }

    return {
      ...dirtyData,
      [name]: getItemsDirtyData(data[name] as TData, dirtyItems[name] as TDirtyItems),
    };
  }, {});
};

export const emptyGuid = '00000000-0000-0000-0000-000000000000';

export const naturalSort = createNewSortInstance({
  comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
});

export const naturalSortByTwo = (v1: any, v2: any) => {
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  return collator.compare(v1, v2);
};
export const threeDigitSeparator = (value: any) => {
  try {
    if (!value) {
      return '';
    }

    const isValueTypeSuitable = typeof value === 'number' || typeof value === 'string';
    if (!isValueTypeSuitable) {
      return '';
    }

    // Convsert the `value` to string
    // Value += '';
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    // Return value.toLocaleString('en')
  } catch (e) {
    return '';
  }
};

export const getDeliverableDueDateColorByDate = (date: Date): string => {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();

  const itemMonth = date.getMonth();
  const itemYear = date.getFullYear();

  if (itemYear < currentYear || (itemYear === currentYear && itemMonth < currentMonth)) {
    return '#a9a9a9'; // more visible gray
  } else if (itemYear === currentYear && itemMonth === currentMonth) {
    return '#008000'; // darker green
  } else if (itemYear === currentYear && itemMonth <= currentMonth + 3) {
    return 'red';
  } else {
    return 'default';
  }
};

export const toPascalCase = (input: string): string => {
  return startCase(camelCase(input)).replace(/ /g, '');
};
