// import { CircularProgress } from '@mui/material';
import React from 'react';
import ReactLoading from 'react-loading';

const LoadingCircular = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <ReactLoading type="bubbles" height={150} width={150} color="#9C5738" />
    </div>
  );
};

export default LoadingCircular;
