export const Role = {
  Admin: 'Administrator',
  GlobalAdmin: 'Global Admin',
  Collaborator: 'Collaborator',
  Designer: 'Designer',
  LightUser: 'Light User',
} as const;

export type Role = (typeof Role)[keyof typeof Role];

export type FeatureFlag =
  | 'Blogs'
  | 'Dashboard'
  | 'NewsLetterSideBar'
  | 'KnowledgeBase'
  | 'CaseStudies'
  | 'WebinarEmails';

export type Settings = {
  organisationName: string | undefined;
  organisationId: string | undefined;
  role: Role | undefined;
  userName: string | undefined;
  userEmail: string | undefined;
  allOrganisations: string[] | undefined;
  allRoles: string[] | undefined;
  isGlobalAdmin: boolean;
  profilePic: string | undefined;
  allowedModules: string[];
  enabledFeatures: FeatureFlag[];
  allowAddPrimaryFocusAreas: boolean;
  allowAddSecondaryFocusAreas: boolean;
  userUnsubscribeStatus: string | undefined;
  strategyEditRoles: string;
};

export const defaultSettings: Settings = {
  organisationName: undefined,
  organisationId: undefined,
  role: undefined,
  isGlobalAdmin: false,
  userName: undefined,
  userEmail: undefined,
  allOrganisations: undefined,
  userUnsubscribeStatus: undefined,
  allRoles: undefined,
  profilePic: undefined,
  allowedModules: [],
  enabledFeatures: [],
  allowAddPrimaryFocusAreas: true,
  allowAddSecondaryFocusAreas: false,
  strategyEditRoles: '',
};
