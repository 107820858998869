import { zodResolver } from '@hookform/resolvers/zod';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, keyframes, Typography, useTheme } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import ConfettiComponent from '@/components/ConfettiComponent';
import HookFormInput from '@/components/HookFormInput';
import { usePostData } from '@/helpers/hooks';
import WeavrLogo from '@/public/images/weavr_logo.png';
import { useState } from 'react';
import Cookies from 'js-cookie';
import Grid from '@mui/material/Grid2';

export const formSchema = z.object({
  email: z
    .string()
    .email({ message: 'Invalid email address' })
    .min(1, { message: 'Email is required' }),
  firstname: z.string().optional(),
  surname: z.string().optional(),
});

export type FormFields = z.infer<typeof formSchema>;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

interface NewsletterFormProps {
  onClose: () => void;
  variant?: 'sidebar' | 'inline';
}

export const NewsletterForm: React.FC<NewsletterFormProps> = ({
  onClose,
  variant = 'sidebar',
}) => {
  const theme = useTheme();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const methods = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      firstname: '',
      surname: '',
    },
  });

  const { handleSubmit, reset } = methods;

  const subscribeMutate = usePostData<any, any, FormFields>({
    url: '/Hubspot/CreateContact',
  });

  const onSubmit = (data: FormFields) => {
    subscribeMutate.mutate(data);
    setIsSubmitted(true);
    setTimeout(() => {
      reset();
      setIsSubmitted(false);
      onClose();
    }, 5000);
  };

  return (
    <>
      {!isSubmitted ? (
        <>
          <div className="flex items-center gap-4">
            <img
              loading="lazy"
              src={WeavrLogo}
              alt="weavr logo"
              className={`object-center ${variant === 'sidebar' ? 'w-[65px]' : 'w-[55px]'}`}
            />
            <Typography
              variant="h6"
              noWrap
              sx={{
                fontWeight: 800,
                fontSize: '2rem',
                color: variant === 'sidebar' ? '#9c5915' : theme.palette.primary.main,
              }}
            >
              weavr.
            </Typography>
          </div>
          <div className={`flex ${variant === 'sidebar' ? 'justify-center' : ''}`}>
            <img
              src="https://weavrmedia.blob.core.windows.net/blogs/a62dec11-2183-44ef-9741-b6d185d447c7"
              alt="newsletter"
              className={`object-cover ${
                variant === 'sidebar' ? 'h-[200px] w-[200px]' : 'h-[185px] w-[185px]'
              }`}
            />
          </div>
          <Typography
            variant={variant === 'sidebar' ? 'h4' : 'body1'}
            align={variant === 'sidebar' ? 'center' : 'left'}
            sx={
              variant === 'inline'
                ? {
                    color: theme.palette.primary.dark,
                    fontWeight: 600,
                    fontSize: '1.8rem',
                  }
                : {}
            }
          >
            Get on the weavr grapevine
          </Typography>
          <Typography
            variant={variant === 'sidebar' ? 'h6' : 'body1'}
            align={variant === 'sidebar' ? 'center' : 'left'}
          >
            Subscribe to our newsletter for the latest updates, exclusive content and all
            things celebrating First Nations and Diversity, Equity and Inclusion
            deadliness!
          </Typography>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Grid container spacing={2}>
                <Grid size={variant === 'sidebar' ? 12 : 6}>
                  <HookFormInput
                    name={'firstname'}
                    label={'First Name'}
                    sx={{
                      '& .MuiInputBase-root':
                        variant === 'sidebar'
                          ? 'unset'
                          : {
                              backgroundColor: (theme) =>
                                theme.palette.backgroundBrown.dark,
                              '& fieldset': {
                                border: '1px solid white',
                              },
                            },
                    }}
                  />
                </Grid>
                <Grid size={variant === 'sidebar' ? 12 : 6}>
                  <HookFormInput
                    name={'surname'}
                    label="Last Name"
                    sx={{
                      '& .MuiInputBase-root':
                        variant === 'sidebar'
                          ? 'unset'
                          : {
                              backgroundColor: (theme) =>
                                theme.palette.backgroundBrown.dark,
                              '& fieldset': {
                                border: '1px solid white',
                              },
                            },
                    }}
                  />
                </Grid>
                <Grid size={12}>
                  <HookFormInput
                    name={'email'}
                    label="Email"
                    sx={{
                      '& .MuiInputBase-root':
                        variant === 'sidebar'
                          ? 'unset'
                          : {
                              backgroundColor: (theme) =>
                                theme.palette.backgroundBrown.dark,
                              '& fieldset': {
                                border: '1px solid white',
                              },
                            },
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 4 }}
              >
                Subscribe
              </Button>
            </form>
          </FormProvider>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            animation: `${fadeIn} 0.5s ${theme.transitions.easing.easeOut}`,
          }}
        >
          <CheckCircleOutlineIcon
            sx={{
              fontSize: 60,
              color: theme.palette.success.main,
              mb: 2,
            }}
          />
          <Typography variant="h5" align="center" gutterBottom>
            Thank You!
          </Typography>
          <Typography variant="body1" align="center">
            You&apos;ve successfully subscribed to our newsletter. We&apos;re excited to
            share our latest updates with you!
          </Typography>
        </Box>
      )}
      <ConfettiComponent show={isSubmitted} />
    </>
  );
};
