declare global {
  interface String {
    splitAtFirstDash(): [string, string];
  }
}

String.prototype.splitAtFirstDash = function (): [string, string] {
  const firstDashIndex = this.indexOf('-');
  if (firstDashIndex === -1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return [this, ''];
  }

  const firstPart = this.substring(0, firstDashIndex);
  const remainingPart = this.substring(firstDashIndex + 1);

  return [firstPart, remainingPart];
};

export {};
