import { useEffect, useState } from 'react';
import { FieldValues, FormState } from 'react-hook-form';

type DisplayFeedbackTypes = 'none' | 'submitting' | 'success' | 'error';

const useDisplayFeedback = ({
  formState,
  name,
}: {
  formState: FormState<FieldValues>;
  name: string;
}) => {
  const { dirtyFields, isSubmitting, isSubmitSuccessful, errors, submitCount } =
    formState;
  const [displayFeedback, setDisplayFeedback] = useState<DisplayFeedbackTypes>('none');

  const isCurrentDirty = name in dirtyFields && dirtyFields[name];

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (isSubmitting && isCurrentDirty && !errors[name]) {
      setDisplayFeedback('submitting');
    } else if (isCurrentDirty && submitCount > 0) {
      if (errors[name]) {
        setDisplayFeedback('error');
      } else if (isSubmitSuccessful) {
        setDisplayFeedback('success');
        timeoutId = setTimeout(() => {
          setDisplayFeedback('none');
        }, 2000);
      } else {
        setDisplayFeedback('none');
      }
    } else {
      setDisplayFeedback('none');
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isCurrentDirty, isSubmitSuccessful, isSubmitting, errors, name, submitCount]);

  return displayFeedback;
};

export default useDisplayFeedback;
