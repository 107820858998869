import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FirstNationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="512.000000pt"
        height="512.000000pt"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="currentColor"
          stroke="none"
        >
          <path
            d="M210 2560 l0 -1810 2350 0 2350 0 0 1810 0 1810 -2350 0 -2350 0 0
-1810z m4270 640 l0 -750 -530 2 -530 3 -4 135 c-3 100 -9 154 -25 210 -86
296 -320 533 -606 614 -507 144 -1008 -148 -1131 -660 -11 -43 -17 -113 -17
-186 l-1 -118 -498 0 -498 0 0 750 0 750 1920 0 1920 0 0 -750z m-1855 -185
c189 -50 325 -198 355 -387 48 -306 -232 -580 -538 -528 -374 63 -524 519
-257 786 120 121 284 169 440 129z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default FirstNationIcon;