import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SsoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_7310_5902)">
          <path d="M7.60412 8.57922H0V0.975098H7.60412V8.57922Z" fill="#F1511B" />
          <path d="M16.0025 8.57922H8.39844V0.975098H16.0025V8.57922Z" fill="#80CC28" />
          <path d="M7.60394 16.9752H0V9.37109H7.60394V16.9752Z" fill="#00ADEF" />
          <path d="M16.0025 16.9752H8.39844V9.37109H16.0025V16.9752Z" fill="#FBBC09" />
        </g>
        <defs>
          <clipPath id="clip0_7310_5902">
            <rect width="16" height="16" fill="white" transform="translate(0 0.975098)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
