import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  styled,
  TextField,
} from '@mui/material';
import React, { memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ZodTypeAny } from 'zod';

import HookFormAutoSizeInput from '@/components/HookFormAutoSizeInput';
import useDisplayFeedback from '@/components/hooks/useDisplayFeedback';
import { isFieldRequired } from '@/helpers/formUtils';
import { ErrorRounded } from '@mui/icons-material';

type TextFieldProps = React.ComponentProps<typeof TextField>;

interface Props extends TextFieldProps {
  name: string;
  label?: string;
  forPassword?: boolean;
  prefix?: string;
  forceInput?: boolean;
  schema?: ZodTypeAny;
  onKeyUpInput?: (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  onSelectInput?: (event: any) => void;
}

const StyledTextField = styled(TextField)(() => ({
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& input::-ms-reveal': {
    display: 'none',
  },
}));

const HookFormInput = ({
  name,
  label,
  prefix,
  hidden,
  schema,
  forPassword,
  onKeyUpInput,
  onSelectInput,
  multiline,
  forceInput,
  ...restProps
}: Props) => {
  const { control, formState } = useFormContext();

  const displayFeedback = useDisplayFeedback({ formState, name });
  const [type, setType] = useState<string>(forPassword ? 'password' : 'text');

  // If multiline is true, return HookFormAutoSize
  if (multiline && !forceInput) {
    return (
      <HookFormAutoSizeInput
        name={name}
        label={label}
        schema={schema}
        minRows={restProps.rows}
        onKeyUpInput={onKeyUpInput}
        {...(restProps as any)} // Cast to any to avoid type conflicts
      />
    );
  }

  const required = schema !== undefined && isFieldRequired(schema, name);
  const toggleShowPassword = () => {
    if (forPassword) {
      if (type === 'password') setType('text');
      if (type === 'text') setType('password');
    }
  };
  const isFormSubmitting = formState.isSubmitting;
  if (hidden) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <StyledTextField
            label={
              required && !restProps.disabled ? (
                <span>
                  {label} <span style={{ color: 'red' }}>*</span>
                </span>
              ) : (
                label
              )
            }
            size={'small'}
            error={!!error}
            {...field}
            fullWidth
            multiline={multiline}
            rows={restProps.rows}
            helperText={error?.message}
            type={type}
            slotProps={{
              inputLabel: {
                style: { fontSize: 15 },
              },
              input: {
                onKeyUp: onKeyUpInput,
                onSelect: onSelectInput,
                style: {
                  fontSize: 15,
                },
                startAdornment: prefix ? (
                  <InputAdornment
                    position="start"
                    className="flex-shrink-0"
                    sx={{ color: 'red!important' }}
                  >
                    {prefix}
                  </InputAdornment>
                ) : undefined,
                endAdornment:
                  forPassword && !isFormSubmitting ? (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                        edge="end"
                      >
                        {type === 'password' ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ) : displayFeedback === 'submitting' ? (
                    <div style={{ height: 20, paddingLeft: 5 }}>
                      <CircularProgress size={20} />
                    </div>
                  ) : displayFeedback === 'success' ? (
                    <CheckCircleIcon color="success" />
                  ) : displayFeedback === 'error' ? (
                    <ErrorRounded color="error" />
                  ) : undefined,
              },
            }}
            {...restProps}
          ></StyledTextField>
        );
      }}
    />
  );
};

export default memo(HookFormInput);
