import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { useConfirm } from '@/store/useConfirmDialogStore';
import { ConfirmOptions, ConfirmResult } from '@/types/confirmDialogTypes';

import { deleteRequest, get, post, put } from './axiosHelper';

const getErrorMessage = (error: any) => {
  const errorData = error?.response?.data;
  const potentialMessages = ['detail', 'message', 'title'];

  if (errorData?.errors) {
    let errorMessage = 'The following validation errors occurred:\n';
    for (const key in errorData.errors) {
      errorMessage += `${key}: ${errorData.errors[key].join(', ')}\n`;
    }
    return errorMessage;
  }

  for (const key of potentialMessages) {
    if (typeof errorData?.[key] === 'string') {
      return errorData[key];
    }
  }

  return typeof errorData === 'string'
    ? errorData
    : 'An error occurred, please try again later.';
};

const handleError = async (
  error: any,
  confirm: <T extends ConfirmOptions>(options: T) => Promise<ConfirmResult<T>>,
) => {
  switch (error?.response?.status) {
    case 400:
      await confirm({
        title: 'Error',
        message: getErrorMessage(error),
        isModal: true,
      });
      break;
    case 401:
      window.location.reload();
      break;
    case 403:
      await confirm({
        title: 'Error',
        message: 'You do not have permission to perform this action.',
        isModal: true,
      });
      break;
    case 404: {
      const featureDisabledRegex = /The requested feature\(s\) (.*) are not available\./;
      const match = error.response.data.match(featureDisabledRegex);
      if (match) {
        return Promise.reject();
      }

      await confirm({
        title: 'Error',
        message: 'The requested resource was not found.',
        isModal: true,
      });
      break;
    }
    case 500:
      await confirm({
        title: 'Error',
        message: 'An error occurred, please try again later.',
        isModal: true,
      });
      break;
    default:
      await confirm({
        title: 'Error',
        message:
          error?.response?.data?.message ?? 'An error occurred, please try again later.',
        isModal: true,
      });
      break;
  }
};

const downloadFile = (response: any): boolean => {
  if (response?.headers === undefined) return false;
  const contentType = response?.headers['content-type'];
  if (!(contentType && contentType.includes('application'))) {
    return false;
  }
  if (!response.download && !contentType.includes('vnd')) return false;

  const blob = new Blob([response.data], { type: contentType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;

  // Get the filename from the Content-Disposition header
  const contentDisposition = response.headers['content-disposition'];
  let filename = 'download';
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
  return true;
};

export const useFetchData = <T>(
  key: readonly unknown[],
  url: string,
  options?: Omit<UseQueryOptions<T, Error, T, QueryKey>, 'queryKey'>,
  axiosConfig?: AxiosRequestConfig,
) => {
  const queryClient = useQueryClient();
  return useQuery<T, Error>({
    queryKey: key,
    queryFn: async () => {
      const response = await get<T>(url, {
        ...axiosConfig,
        headers: { ...axiosConfig?.headers },
      });

      if (response.status === 205) {
        await queryClient.invalidateQueries();
      }
      return response.data;
    },
    ...options,
  });
};

export const useFetchDataWithSuspense = <T>(
  key: QueryKey,
  url: string,
  options?: Omit<UseQueryOptions<T, Error, T, QueryKey>, 'queryKey' | 'queryFn'>,
  axiosConfig?: AxiosRequestConfig,
) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery<T>({
    queryKey: key,
    queryFn: async () => {
      const response = await get<T>(url, {
        ...axiosConfig,
        headers: { ...axiosConfig?.headers },
      });

      if (response.status === 205) {
        await queryClient.invalidateQueries();
      }
      return response.data;
    },
    ...options,
  });
};

type CustomMutationOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'> & {
  meta?: {
    disableAutoInvalidate?: boolean;
  };
};
export const usePutData = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  url: string,
  options?: CustomMutationOptions<TData, TError, TVariables, TContext>,
  axiosConfig?: AxiosRequestConfig,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: TVariables) => {
      const response = await put<TData>(url, data, {
        ...axiosConfig,
        headers: { ...axiosConfig?.headers },
      });
      if (response.status === 205) {
        await queryClient.invalidateQueries();
      }
      return response.data;
    },
    ...options,
    meta: {
      ...options?.meta,
      disableAutoInvalidate: options?.meta?.disableAutoInvalidate,
    },
    onError: async (error: any, variables: TVariables, context: TContext | undefined) => {
      options?.onError?.(error, variables, context);
      await handleError(error, confirm);
    },
  });
};

interface UsePostDataOptions<TData, TError, TVariables, TContext> {
  url: string;
  options?: CustomMutationOptions<TData, TError, TVariables, TContext>;
  axiosConfig?: AxiosRequestConfig;
}

export const usePostData = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>({
  url,
  options,
  axiosConfig,
}: UsePostDataOptions<TData, TError, TVariables, TContext>): UseMutationResult<
  TData,
  TError,
  TVariables,
  TContext
> => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: TVariables) => {
      const response = await post<TData>(url, data, {
        ...axiosConfig,
        headers: { ...axiosConfig?.headers },
      });
      if (downloadFile(response)) {
        return response.data;
      }
      if (response.status === 205) {
        await queryClient.invalidateQueries();
      }
      return response.data;
    },
    ...options,
    onError: async (error: any, variables: TVariables, context: TContext | undefined) => {
      options?.onError?.(error, variables, context);
      await handleError(error, confirm);
    },
  });
};

export const useDeleteData = <TData = unknown, TError = unknown, TContext = unknown>(
  url: string,
  options?: CustomMutationOptions<TData, TError, string, TContext>,
  axiosConfig?: AxiosRequestConfig,
): UseMutationResult<TData, TError, string, TContext> => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await deleteRequest<TData>(`${url}?id=${id}`, null, {
        ...axiosConfig,
        headers: { ...axiosConfig?.headers },
      });
      if (response.status === 205) {
        await queryClient.invalidateQueries();
      }
      return response.data;
    },
    ...options,
    onError: async (error: any, variables: string, context: TContext | undefined) => {
      options?.onError?.(error, variables, context);
      await handleError(error, confirm);
    },
  });
};

export const useBulkDeleteData = <TData = unknown, TError = unknown, TContext = unknown>(
  url: string,
  options?: CustomMutationOptions<TData, TError, string[], TContext>,
  axiosConfig?: AxiosRequestConfig,
): UseMutationResult<TData, TError, string[], TContext> => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (ids: string[]) => {
      const response = await deleteRequest<TData>(
        `${url}`,
        { ids },
        {
          ...axiosConfig,
          headers: { ...axiosConfig?.headers },
        },
      );
      if (response.status === 205) {
        await queryClient.invalidateQueries();
      }
      return response.data;
    },
    ...options,
    onError: async (error: any, variables: string[], context: TContext | undefined) => {
      options?.onError?.(error, variables, context);
      await handleError(error, confirm);
    },
  });
};
