import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EmailIconColored = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.675 5.33344L11.0583 11.3001C10.9052 11.4383 10.7063 11.5148 10.5 11.5148C10.2937 11.5148 10.0948 11.4383 9.94167 11.3001L3.325 5.33344C3.22635 5.40933 3.14595 5.50636 3.08971 5.6174C3.03347 5.72844 3.00281 5.85066 3 5.9751V15.9751C3 16.1961 3.0878 16.4081 3.24408 16.5644C3.40036 16.7206 3.61232 16.8084 3.83333 16.8084H17.1667C17.3877 16.8084 17.5996 16.7206 17.7559 16.5644C17.9122 16.4081 18 16.1961 18 15.9751V5.9751C17.9972 5.85066 17.9665 5.72844 17.9103 5.6174C17.8541 5.50636 17.7737 5.40933 17.675 5.33344Z"
          fill="#E9A600"
          stroke="black"
        />
        <path
          d="M3.83333 5.14178C3.61232 5.14178 3.40036 5.22958 3.24408 5.38586C3.0878 5.54214 3 5.7541 3 5.97512V15.9751C3 16.1961 3.0878 16.4081 3.24408 16.5644C3.40036 16.7207 3.61232 16.8085 3.83333 16.8085H17.1667C17.3877 16.8085 17.5996 16.7207 17.7559 16.5644C17.9122 16.4081 18 16.1961 18 15.9751V5.97512C18 5.7541 17.9122 5.54214 17.7559 5.38586C17.5996 5.22958 17.3877 5.14178 17.1667 5.14178M3.83333 5.14178H17.1667M3.83333 5.14178C3.6535 5.14463 3.47891 5.20283 3.33333 5.30845L9.94167 11.3001C10.0948 11.4383 10.2937 11.5148 10.5 11.5148C10.7063 11.5148 10.9052 11.4383 11.0583 11.3001L17.6667 5.30845C17.5211 5.20283 17.3465 5.14463 17.1667 5.14178M12.8333 9.97512L14.6667 11.8085M8.16667 9.97512L6.33333 11.8085"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
