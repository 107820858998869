import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HomosexualIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="200.000000pt"
        height="200.000000pt"
        viewBox="0 0 200.000000 200.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
          fill="currentColor"
          stroke="none"
        >
          <path
            d="M1080 1810 l0 -90 107 0 107 0 -73 -74 -74 -75 -37 19 c-60 30 -151
43 -222 30 -250 -46 -387 -307 -280 -533 50 -105 158 -192 264 -212 34 -7 38
-5 62 32 13 21 28 50 31 64 6 25 4 27 -53 38 -125 24 -202 118 -202 245 1 68
27 123 83 173 104 90 244 80 340 -26 43 -48 61 -107 54 -182 -3 -33 1 -79 9
-110 8 -29 14 -71 14 -93 l0 -39 25 23 c14 14 39 52 56 85 50 102 49 208 -2
333 l-13 32 69 70 70 71 5 -103 5 -103 88 -3 87 -3 0 261 0 260 -260 0 -260 0
0 -90z"
          />
          <path
            d="M803 1368 c-22 -23 -43 -69 -43 -92 0 -8 18 -16 45 -19 188 -26 276
-240 161 -391 -13 -17 -47 -44 -75 -60 -115 -62 -268 -17 -327 96 -22 42 -34
119 -26 158 5 21 3 54 -5 85 -7 27 -13 72 -13 99 l0 49 -25 -23 c-14 -14 -40
-53 -57 -88 -42 -85 -50 -199 -20 -284 40 -112 121 -198 225 -235 l47 -18 0
-105 0 -104 -73 72 c-40 39 -77 72 -83 72 -5 0 -35 -26 -67 -58 l-57 -58 180
-179 c100 -99 183 -182 186 -185 7 -6 364 352 364 365 0 16 -104 115 -120 115
-8 0 -45 -30 -82 -67 l-68 -67 0 100 0 100 58 24 c70 29 158 114 190 184 69
146 39 317 -74 430 -48 48 -130 93 -185 102 -27 5 -38 1 -56 -18z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default HomosexualIcon;
