import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Slide, useTheme } from '@mui/material';
import Cookies from 'js-cookie';
import React from 'react';

import { NewsletterForm } from './NewsletterForm';

interface NewsletterSidebarProps {
  open: boolean;
  onClose: () => void;
}

const NewsletterSidebar: React.FC<NewsletterSidebarProps> = ({ open, onClose }) => {
  const theme = useTheme();

  const handleClose = () => {
    onClose();
  };

  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          width: '450px',
          maxWidth: '100%',
          bgcolor: 'background.paper',
          boxShadow: 3,
          p: 5,
          overflowY: 'auto',
          overflowX: 'hidden',
          zIndex: 9999,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ position: 'relative', mb: 4 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: -theme.spacing(1),
              color: theme.palette.grey[500],
              transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
              '&:hover': {
                transform: 'rotate(90deg)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <NewsletterForm onClose={onClose} variant="sidebar" />
        </Box>
      </Box>
    </Slide>
  );
};

export default NewsletterSidebar;
