import { useMsal } from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { get } from '@/helpers/axiosHelper';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';

const useAuth = (): [boolean, boolean] => {
  const { accounts, instance } = useMsal();

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const userEmail = useGlobalSettingsStore(
    useShallow((state) => state?.globalSettings?.userEmail),
  );

  useEffect(() => {
    const checkAuth = async (retryCount = 0) => {
      try {
        if (accounts.length > 0) {
          // MSAL authentication successful
          setIsAuthenticated(true);
        } else {
          // Check server-side login
          const { data: response } = await get<boolean>('Login/IsLoggedIn');
          setIsAuthenticated(response);
        }
      } catch (error) {
        if (retryCount < 3) {
          setTimeout(() => checkAuth(retryCount + 1), 1000);
        }
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [accounts]);

  useEffect(() => {
    if (userEmail) {
      Sentry.setUser({ id: userEmail, email: userEmail });
    }
  }, [userEmail]);

  return [isAuthenticated, isLoading];
};

export default useAuth;
