import { zodResolver } from '@hookform/resolvers/zod';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import {
  Backdrop,
  Box,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { debounce, isEmpty } from 'lodash-es';
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useShallow } from 'zustand/react/shallow';

import ReactHookFormInput from '@/components/HookFormInput';
import BasicMenu from '@/components/mui/ButtonMenu';
import { formatDateTimeToMonthString } from '@/helpers/dateUtils';
import { useDeleteData, usePostData, usePutData } from '@/helpers/hooks';
import { compareObjects, emptyGuid } from '@/helpers/utils';
import DeliverableCardContent from '@/pages/strategies/DeliverableCardContent';
import FormHookStatusDropdown from '@/pages/strategies/FormHookStatusDropdown';
import HeaderDiv from '@/pages/strategies/HeaderDiv';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import {
  Deliverable,
  DeliverableCardProp,
} from '@/pages/strategies/types/deliverable.types';
import { DropdownOptions } from '@/pages/strategies/types/Strategy.types';
import CardCountChip from '@/pages/strategies/wrapperCard/CardCountChip';
import { useConfirm } from '@/store/useConfirmDialogStore';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';
import { strategyItemFilters } from '@/types/itemFilter';
import { ResponseRequireConfirmation, ResponseWithValue } from '@/types/responseTypes';

import StyledCard from './wrapperCard/StyledCard';

const DeliverableCard = memo(
  forwardRef<HTMLDivElement, DeliverableCardProp>((props, ref) => {
    const {
      isActive,
      item,
      onHeaderClick,
      dropdownOptions,
      setAddingDeliverable,
      setActiveDeliverables,
      isSelected,
      handleCheckboxClick,
      showLinkedDeliverable,
      contributionCount,
      existedDeliverableOrder,
      deliverableWithChildren: deliverableWithChildren,
      disabled,
    } = props;
    const routeApi = getRouteApi('/strategies');
    const { actionId: primaryActionId, deliverableId: primaryDeliverableId } =
      routeApi.useSearch();
    const navigate = useNavigate({ from: routeApi.id });
    const updateFilters = useCallback(
      (name: keyof strategyItemFilters, value: unknown) => {
        navigate({ search: (prev: any) => ({ ...prev, [name]: value }) });
      },
      [navigate],
    );
    const confirm = useConfirm();
    const queryClient = useQueryClient();
    const isLinked = !!item.linkedDeliverableId;

    const globalSettings = useGlobalSettingsStore(
      useShallow((state) => state.globalSettings),
    );
    const isGlobalAdmin = globalSettings.role === 'Global Admin';

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const defaultValues = useMemo(
      () => ({
        statusId: item.statusId,
        order: item.order,
        targetEndDate: item.targetEndDate ? new Date(item.targetEndDate) : undefined,
        name: item.name ?? '',
        description: item.description ?? '',
        businessUnitIds: item.businessUnitIds,
        deliverableIndicatorId: item.deliverableIndicatorId ?? '',
        groupedById: item.groupedById ?? undefined,
        answerableById: !item.groupedById
          ? undefined
          : (item.answerableById ?? item.strategyId),
      }),
      [item],
    );

    const formSchema = z
      .object({
        order: z.coerce
          .string()
          .min(1, 'Deliverable order is required')
          .refine(
            (value) =>
              !existedDeliverableOrder.filter((x) => x !== item.order).includes(value),
            'Order exists',
          ),
        statusId: z.string(),
        name: z.string().min(1, 'Deliverable name is required'),
        description: z.string(),
        targetEndDate: z
          .date()
          .optional()
          .refine((value) => isLinked || !!value, {
            message: 'Expected End Date is required',
          }),
        businessUnitIds: z.string().array(),
        deliverableIndicatorId: z.string().optional(),
        groupedById: z.string().uuid().optional(),
        answerableById: z.string().uuid().optional(),
      })
      .refine((data) => !data.groupedById || !!data.answerableById, {
        message: 'Answerable By is required',
        path: ['answerableById'],
      });

    type DeliverableFormFields = z.infer<typeof formSchema>;

    const methods = useForm<DeliverableFormFields>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: zodResolver(formSchema),
      defaultValues: defaultValues,
    });

    const { primaryStrategy } = useStrategyStore(
      useShallow((state) => ({
        primaryStrategy: state.primaryStrategy,
      })),
    );

    const allowEditStrategy = useStrategyStore(
      useShallow((state) => state.getAllowEditStrategy()),
    );

    const { handleSubmit, formState, trigger } = methods;
    const answerableInLinked =
      !!item.groupedById && item.answerableById !== primaryStrategy?.id;

    useEffect(() => {
      if (!item.isNew && !item.targetEndDate) trigger('targetEndDate');
    }, [item, trigger]);

    useEffect(() => {
      methods.reset({ ...defaultValues });
    }, [defaultValues, methods]);

    const updateDeliverableMutation = usePutData<
      ResponseRequireConfirmation,
      unknown,
      Partial<Deliverable>,
      unknown
    >(`/Strategy/Deliverable`);

    const addDeliverableMutation = usePostData<
      ResponseWithValue,
      unknown,
      Deliverable,
      string
    >({
      url: `/Strategy/Deliverable?actionId=${primaryActionId}`,
    });

    const isDateOutsideStrategyPeriod = (date: Date | undefined) => {
      if (
        !date ||
        !primaryStrategy ||
        !primaryStrategy.startDate ||
        !primaryStrategy.endDate
      )
        return false;
      const strategyStartDate = new Date(primaryStrategy.startDate);
      const strategyEndDate = new Date(primaryStrategy.endDate);
      return date < strategyStartDate || date > strategyEndDate;
    };

    const onBlur = useCallback(
      debounce(async (data: DeliverableFormFields) => {
        if (disabled) return;

        if (!item.isNew && !isSubmitting) {
          const dirtyFields = compareObjects(
            formState.defaultValues as DeliverableFormFields,
            data,
          );
          if (!isEmpty(dirtyFields)) {
            setIsSubmitting(true);

            const payload = { id: item.id, ...dirtyFields } as Partial<Deliverable>;
            if (payload?.targetEndDate) {
              if (isDateOutsideStrategyPeriod(new Date(payload.targetEndDate))) {
                const confirmed = await confirm({
                  title: 'Date Outside Strategy Period',
                  message:
                    'The Expected End Date for this Deliverable falls outside the current Strategy period. This may affect reporting and alignment with overall Strategy timelines. Are you sure you want to proceed?',
                  confirmButtonText: 'Yes',
                  cancelButtonText: 'No',
                });

                if (!confirmed) {
                  methods.reset({ ...defaultValues });
                  setIsSubmitting(false);
                  return;
                }
              }
              payload.targetEndDate = formatDateTimeToMonthString(payload.targetEndDate);
            }

            try {
              await updateDeliverableMutation.mutateAsync(payload, {
                onSuccess: async (res) => {
                  if (!res.requireConfirmation) {
                    await queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                    return;
                  }

                  const handleConfirmation = async (
                    title: string,
                    message: string,
                    isModal = false,
                  ) => {
                    return await confirm({
                      title,
                      message,
                      isModal,
                      confirmButtonText: 'Confirm',
                      size: 'lg',
                    });
                  };

                  const handleConfirmedUpdate = async (
                    confirmedPayload: typeof payload,
                  ) => {
                    await updateDeliverableMutation.mutateAsync(confirmedPayload, {
                      onSuccess: async () => {
                        await queryClient.invalidateQueries({
                          queryKey: ['strategyData'],
                        });
                      },
                    });
                  };

                  let confirmed = false;
                  const confirmedPayload = { ...payload, confirmOverride: true };

                  switch (res.type) {
                    case 'ApprovedProgressUpdates':
                      const multiConfirm = await confirm({
                        title: 'Existing Approved Progress Updates',
                        message: res.message ?? '',
                        confirmButtonText: 'Only to Not Approved (recommended)',
                        altButtonText: 'Apply to All Progress Updates',
                        altButtonColor: 'info',
                        hasAltOption: true,
                        size: 'lg',
                      });

                      if (multiConfirm.confirmed) {
                        if (multiConfirm.altButtonText === multiConfirm.comment) {
                          const confirmPayloadWithAllProgressUpdates = {
                            ...confirmedPayload,
                            applyToAll: true,
                          };
                          await handleConfirmedUpdate(
                            confirmPayloadWithAllProgressUpdates,
                          );
                        } else {
                          await handleConfirmedUpdate(confirmedPayload);
                        }
                      } else {
                        methods.reset({ ...defaultValues });
                      }
                      break;

                    case 'DeleteProgressResponses':
                      confirmed = await handleConfirmation(
                        'Are you sure',
                        res.message ?? '',
                      );
                      if (confirmed) {
                        await handleConfirmedUpdate(confirmedPayload);
                      } else {
                        methods.reset({ ...defaultValues });
                      }
                      break;

                    case 'DeliverableStatusUpdate':
                      confirmed = await handleConfirmation(
                        'Are you sure',
                        'There are incomplete responses in this deliverable. Are you sure you want to change the status?',
                      );
                      if (confirmed) {
                        await handleConfirmedUpdate(confirmedPayload);
                      } else {
                        methods.reset({ ...defaultValues });
                      }
                      break;

                    default:
                      console.warn('Unhandled confirmation type:', res.type);
                      break;
                  }
                },
              });
            } finally {
              setIsSubmitting(false);
            }
          }
        }
      }, 800),
      [
        item,
        formState,
        updateDeliverableMutation,
        queryClient,
        methods,
        defaultValues,
        isDateOutsideStrategyPeriod,
        confirm,
        isGlobalAdmin,
        isSubmitting,
      ],
    );

    const onSubmit = async (data: DeliverableFormFields) => {
      if (disabled) return;

      setIsSubmitting(true);
      try {
        if (isDateOutsideStrategyPeriod(data.targetEndDate)) {
          const confirmed = await confirm({
            title: 'Date Outside Strategy Period',
            message:
              'The Expected End Date for this Deliverable falls outside the current Strategy period. This may affect reporting and alignment with overall Strategy timelines. Are you sure you want to proceed?',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          });

          if (!confirmed) {
            return;
          }
        }
        if (item.isNew) {
          const payload = {
            id: emptyGuid,
            order: data.order,
            name: data.name ?? '',
            description: data.description ?? '',
            statusId: data.statusId,
            businessUnitIds: data.businessUnitIds,
            targetEndDate: formatDateTimeToMonthString(data.targetEndDate!),
            deliverableIndicatorId: data.deliverableIndicatorId,
            groupedById: data.groupedById,
            answerableById: data.answerableById,
          } as Deliverable;

          await addDeliverableMutation.mutateAsync(payload, {
            onSuccess: async (res) => {
              onHeaderClick({ ...item, id: res.value as any as string });
              setAddingDeliverable?.(null);
            },
          });
        }
      } catch (error) {
        console.error('Error submitting deliverable:', error);
      } finally {
        setIsSubmitting(false);
      }
    };

    const handleLastActionClick = () => {
      updateFilters('actionId', item.actionId);
      if (isActive && !item.isNew) updateFilters('deliverableId', item.id);
    };

    const deleteDeliverableMutation = useDeleteData<unknown, unknown>(
      `/Strategy/Deliverable`,
      {
        onSuccess: () => {
          setActiveDeliverables?.([]);
          queryClient.invalidateQueries({ queryKey: ['strategyData'] });
        },
      },
    );

    const handleDeliverableDelete = useCallback(() => {
      if (disabled) return;

      confirm({
        title: 'Delete Deliverable',
        message:
          'Are you sure you want to delete this Deliverable? \n' +
          'Please note, the Deliverable will be deleted from any associated Actions, or Progress Update records.',
      }).then((result) => {
        if (result && item) {
          deleteDeliverableMutation.mutate(item.id as string);
        }
      });
    }, [confirm, deleteDeliverableMutation, item]);

    const handleUnlink = async () => {
      if (disabled) return;

      const confirmed = await confirm({
        title: 'Are you sure?',
        message: 'Do you wish to delete this linked deliverable?',
      });
      if (confirmed) await deleteDeliverableMutation.mutateAsync(item.id);
    };

    return (
      <FormProvider {...methods}>
        <form
          onBlur={() => {
            if (!item.isNew) {
              handleSubmit(onBlur)();
            }
          }}
          onSubmit={handleSubmit(onSubmit)}
          id="deliverable-form"
        >
          <StyledCard
            isActive={isActive}
            groupedById={item.groupedById}
            className={
              primaryDeliverableId === item.id || item.isNew ? 'gradient-border' : ''
            }
            ref={ref}
          >
            <CardContent sx={{ padding: '0!important', containerType: 'inline-size' }}>
              <HeaderDiv>
                {!item.isNew && !item.groupedById && (
                  <Checkbox
                    checked={isSelected}
                    onChange={() => handleCheckboxClick?.(item.id)}
                    disabled={disabled}
                  ></Checkbox>
                )}
                {item.groupedById && (
                  <Tooltip title="Check grouped by Deliverable">
                    <IconButton
                      onClick={() => {
                        showLinkedDeliverable?.(item.id);
                      }}
                      disabled={disabled}
                    >
                      <InsertLinkIcon></InsertLinkIcon>
                    </IconButton>
                  </Tooltip>
                )}
                {isActive || (item.isNew ?? false) ? (
                  <div style={{ width: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexGrow: 1,
                        containerType: 'inline-size',
                        '@container (max-width: 300px)': {
                          flexDirection: 'column',
                        },
                      }}
                    >
                      <ReactHookFormInput
                        onMouseDown={handleLastActionClick}
                        name={'order'}
                        label={'Order'}
                        style={{ marginRight: '3px', width: '60px' }}
                        disabled={!allowEditStrategy || disabled}
                      />
                      <ReactHookFormInput
                        onMouseDown={handleLastActionClick}
                        name={'name'}
                        label={'Name'}
                        disabled={
                          !allowEditStrategy ||
                          disabled ||
                          (item.hasChildren &&
                            item.answerableById !== primaryStrategy?.id)
                        }
                        multiline={true}
                      />
                    </Box>
                  </div>
                ) : (
                  <Typography style={{ color: 'black' }}>
                    {item.actionOrder}.{item.order} {item.name}
                  </Typography>
                )}
                {isLinked && (
                  <Tooltip title="Check parent Deliverable">
                    <IconButton
                      onClick={() => {
                        showLinkedDeliverable?.(item.linkedDeliverableId!);
                      }}
                      disabled={disabled}
                    >
                      <InsertLinkIcon></InsertLinkIcon>
                    </IconButton>
                  </Tooltip>
                )}
                {deliverableWithChildren && (
                  <BasicMenu
                    buttonLabel={
                      <Tooltip title="Check child Deliverable">
                        <InsertLinkIcon color={'action'}></InsertLinkIcon>
                      </Tooltip>
                    }
                    menuItems={deliverableWithChildren.childrenIds}
                    menuAction={(id) => {
                      showLinkedDeliverable?.(id);
                    }}
                    disabled={disabled}
                  />
                )}
                {!!item.groupedById && !isActive && answerableInLinked && (
                  <Chip
                    label={'Linked'}
                    color={'info'}
                    sx={{ marginLeft: 1 }}
                    variant={'outlined'}
                  ></Chip>
                )}

                <div className="ml-auto flex shrink-0 items-center gap-2">
                  {!isActive && !isLinked && item.targetEndDate === undefined && (
                    <Tooltip title="Expected End Date is missing">
                      <ErrorOutlineIcon color="error" />
                    </Tooltip>
                  )}
                  {!isActive && !isLinked && contributionCount !== undefined && (
                    <>
                      <CardCountChip
                        title="Business Units"
                        count={item.businessUnitIds.length}
                      />
                      <CardCountChip title="Contributions" count={contributionCount} />
                    </>
                  )}
                  {dropdownOptions && (
                    <FormHookStatusDropdown
                      dropdownOptions={dropdownOptions as DropdownOptions}
                      name={'statusId'}
                      disabled={
                        isLinked || disabled || item.strategyId !== primaryStrategy?.id
                      }
                    />
                  )}
                  <IconButton
                    onClick={() => {
                      if (item.strategyId !== primaryStrategy?.id)
                        showLinkedDeliverable?.(item.id);
                      else onHeaderClick(item);
                    }}
                  >
                    {isActive ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </div>
              </HeaderDiv>

              <Collapse in={isActive || (item.isNew ?? false)} mountOnEnter unmountOnExit>
                <DeliverableCardContent
                  item={item}
                  dropdownOptions={dropdownOptions}
                  onDeleteClick={handleDeliverableDelete}
                  setAddingDeliverable={setAddingDeliverable}
                  handleLastActionClick={handleLastActionClick}
                  isLinked={isLinked}
                  handleUnlink={handleUnlink}
                  disabled={disabled}
                />
              </Collapse>
            </CardContent>
          </StyledCard>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isSubmitting}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </form>
      </FormProvider>
    );
  }),
  (prev, next) =>
    prev.item === next.item &&
    prev.isActive == next.isActive &&
    prev.disabled == next.disabled,
);
DeliverableCard.displayName = 'DeliverableCard';
export default DeliverableCard;
