import React from 'react';
import { Chip as MuiChip } from '@mui/material';
import { ChipProps } from '@/pages/contentBuilder/customComponents/customComponentTypes';
import { Link } from '@tanstack/react-router';
import styled from '@emotion/styled';

const StyledLink = styled(Link)({
  display: 'inline-flex',
  textDecoration: 'none',
  marginLeft: '1rem',
  '& .MuiChip-root': {
    height: '100%',
    padding: '5px',
    margin: '3px',
  },
});

const StyledChip = styled(MuiChip)({
  marginLeft: '1rem',
});

export const Chip: React.FC<ChipProps> = ({
  label,
  color = 'default',
  onClick,
  href,
}) => {
  if (href) {
    return (
      <StyledLink to={href} target="_blank" rel="noopener noreferrer">
        <MuiChip
          label={label}
          color={color}
          clickable
          sx={{
            height: '100%',
            '& .MuiChip-label': {
              paddingTop: '3px',
              paddingBottom: '3px',
            },
          }}
        />
      </StyledLink>
    );
  }

  return (
    <StyledChip label={label} color={color} onClick={onClick} clickable={!!onClick} />
  );
};
