import { useMsal } from '@azure/msal-react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, FormControl, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  getRouteApi,
  Link as RouterLink,
  useNavigate,
  useRouter,
  useSearch,
  useMatches,
} from '@tanstack/react-router';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { validate } from 'uuid';
import { useShallow } from 'zustand/react/shallow';

import AcknowledgementPopup from '@/components/AcknowledgementPopup';
import ContactUsDialog from '@/components/loginPage/ContactUsDialog';
import LoginDialog from '@/components/navigation/LoginDialog';
import NavigationMenuShad from '@/components/navigation/NavigationMenu';
import NavigationMenuShadUnauth from '@/components/navigation/NavigationMenuUnauth';
import ProfileAvatar from '@/components/user/ProfileAvatar';
import { Role } from '@/contexts/Settings.types';
import { usePostData } from '@/helpers/hooks';
import { capitalizeFirstLetter } from '@/helpers/stringUtils';
import Notification from '@/pages/Notification';
import NavbarBg from '@/public/images/navbar-bg.png';
import WeavrLogo from '@/public/images/weavr_logo.png';
import HamburgerMenu from '@/public/svgs/Hamburger.svg?react';
import useGlobalSettingsActions from '@/store/useGlobalSettingsActions';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';

import MenuPopup from './MenuPopup';

const settings = ['Profile', 'Logout'];

function NavBar() {
  const { resetuser: userId, token } = useSearch({ strict: false });

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [isContactUsOpen, setIsContactUsOpen] = useState<boolean>(false);
  const [isLoginOpen, setIsLoginOpen] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { isAuthenticated, globalSettings } = useGlobalSettingsStore(
    useShallow((state) => ({
      globalSettings: state.globalSettings,
      isAuthenticated: state.isAuthenticated,
    })),
  );
  const { updateSettings, resetSettings } = useGlobalSettingsActions();

  const navigate = useNavigate();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const { instance, accounts } = useMsal();

  const logoutMutation = usePostData<boolean, unknown, unknown, unknown>({
    url: '/login/logout',
  });

  const handleLogoutRedirect = async () => {
    const logoutRequest = {
      postLogoutRedirectUri: '/',
    };
    if (accounts.length > 0) {
      await instance.logoutRedirect(logoutRequest);
    } else {
      const result = await logoutMutation.mutateAsync({});
      if (result) {
        window.location.href = '/';
      }
    }
  };

  const handleCloseUserMenu = (setting: unknown) => {
    setAnchorElUser(null);
    if (typeof setting !== 'string') return;
    switch (setting?.toLowerCase()) {
      case 'logout':
        handleLogoutRedirect();
        break;
      case 'profile':
        navigate({ to: '/profile' });
        break;
      default:
        console.warn(`Unknown setting: ${setting}`);
    }
  };

  const matches = useMatches();
  const pathNames = matches[1].pathname.split('/').filter((x) => x);

  const breadcrumbLinks = pathNames.map((value, index) => {
    const last = index === pathNames.length - 1;
    const to = `/${pathNames.slice(0, index + 1).join('/')}`;
    const replacedValue = validate(value) ? 'Edit' : value; // if the value is a GUID, display Edit in the breadcrumbs
    return last ? (
      <Typography key={to} className="text-black">
        {decodeURIComponent(capitalizeFirstLetter(replacedValue.replace(/_/g, ' ')))}
      </Typography>
    ) : (
      <Link
        underline="hover"
        sx={{ color: (theme) => theme.palette.brownPrimary.main }}
        to={value}
        component={RouterLink}
        key={value}
      >
        {capitalizeFirstLetter(value.replace(/_/g, ' '))}
      </Link>
    );
  });

  useEffect(() => {
    if (userId && token) setIsLoginOpen(true);
  }, [token, userId]);

  return (
    <>
      <header className="sticky top-0 z-50 flex items-center gap-5 rounded-b-lg bg-rose/50 px-10 py-2 backdrop-blur-[12px] backdrop-filter max-md:px-5 max-sm:flex-wrap">
        <div className="flex w-full items-center gap-4 pr-16 max-md:pr-5 max-sm:pr-0 md:w-auto">
          <div className="flex flex-shrink-0 flex-row items-center justify-start gap-3">
            <img
              src={WeavrLogo}
              alt="Weavr Logo"
              width={50}
              className="h-[50px] transition-transform duration-5000 hover:rotate-[1440deg]"
            />
            <Typography
              variant="h6"
              noWrap
              component={RouterLink}
              to="/"
              sx={{
                display: { md: 'flex' },
                fontWeight: 800,
                fontSize: { xs: '2rem', md: '3.2rem' },
                color: '#9c5915',
                textDecoration: 'none',
              }}
            >
              weavr.
            </Typography>
          </div>
          <div className="ml-auto flex items-center gap-2 md:hidden">
            {isAuthenticated && <Notification />}
            <IconButton onClick={() => setIsMenuOpen(true)}>
              <HamburgerMenu />
            </IconButton>
          </div>
        </div>
        <div className="hidden w-full items-center md:flex">
          {!isAuthenticated ? <NavigationMenuShadUnauth /> : <NavigationMenuShad />}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              marginLeft: 'auto',
              marginRight: '1rem',
              gap: 1,
              alignItems: 'center',
            }}
          >
            {globalSettings?.allOrganisations &&
            globalSettings?.allOrganisations.length > 0 ? (
              <>
                {globalSettings?.isGlobalAdmin && (
                  <FormControl size={'small'} sx={{ width: 150 }}>
                    <InputLabel>Role</InputLabel>
                    <Select
                      label="Role"
                      value={globalSettings?.role}
                      onChange={(e) => {
                        const newSettings = {
                          role: e.target.value as Role,
                        };
                        updateSettings(newSettings);
                      }}
                    >
                      <MenuItem value={'Global Admin'} key={'Global Admin'}>
                        Global Admin
                      </MenuItem>
                      {globalSettings.allRoles?.map((g) => (
                        <MenuItem value={g} key={g}>
                          {g}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <FormControl size={'small'} fullWidth sx={{ width: 150 }}>
                  <InputLabel>Subscription</InputLabel>
                  <Select
                    label="Subscription"
                    value={globalSettings?.organisationName}
                    onChange={async (e) => {
                      const newSettings = {
                        organisationName: e.target.value,
                      };
                      await updateSettings(newSettings);
                    }}
                  >
                    {globalSettings.allOrganisations?.map((g) => (
                      <MenuItem value={g} key={g}>
                        {g}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              globalSettings?.organisationName && (
                <>
                  <div className="mr-2 text-pretty py-2 font-bold">
                    {globalSettings.userEmail}
                  </div>
                  <div className="mr-4 rounded-3xl bg-brown-light px-4 py-2 font-bold text-brown-dark">
                    {globalSettings.organisationName}
                  </div>
                </>
              )
            )}
            {isAuthenticated && <Notification />}
          </Box>
          {!isAuthenticated ? (
            <div className="ml-auto flex-shrink-0">
              <Button
                variant="contained"
                onClick={() => setIsContactUsOpen(true)}
                sx={{
                  border: '2px solid #A76337',
                  backgroundColor: '#B16B3D',
                  boxShadow: '0px 0px 12px 0px #00000040 inset',
                  width: '150px',
                  height: '48px',
                }}
              >
                Let&apos;s yarn!
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#232323',
                  height: '48px',
                  width: 104,
                  marginLeft: '1rem',
                }}
                onClick={() => setIsLoginOpen(true)}
              >
                Sign in
              </Button>
            </div>
          ) : (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <ProfileAvatar alt="profile picture" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                    <Typography
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </div>
      </header>
      {isAuthenticated && breadcrumbLinks.length > 0 && (
        <Box
          sx={{
            position: 'relative',
            height: 44,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              inset: 0,
              paddingBottom: '1rem',
              '&::after': {
                content: '""',
                position: 'absolute',
                inset: 0,
                opacity: 0.7,
                backgroundImage: `url(${NavbarBg})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',

                zIndex: -2,
              },
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: '50%', // Change this line
              backgroundColor: '#F8EBE3',
              opacity: 0.6,
              filter: 'blur(25px)', // Apply blur effect
              backdropFilter: 'blur(25px)', // Add the blur back in
              zIndex: -1, // Add this line to move the overlay behind the breadcrumbs
            }}
          ></Box>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{
              px: 4,
              py: 2,
              mb: 4,
              position: 'relative',
            }}
          >
            {breadcrumbLinks.map((breadcrumbLink, i) => (
              <div key={i}>{breadcrumbLink}</div>
            ))}
          </Breadcrumbs>
        </Box>
      )}
      <ContactUsDialog open={isContactUsOpen} setOpen={setIsContactUsOpen} />
      <LoginDialog
        open={isLoginOpen}
        setOpen={setIsLoginOpen}
        resetUserId={userId}
        resetToken={token}
      />
      {!isAuthenticated && <AcknowledgementPopup />}
      <MenuPopup
        isOpen={isMenuOpen}
        close={() => setIsMenuOpen(false)}
        onDialogOpen={() => setIsContactUsOpen(true)}
      />
    </>
  );
}

export default NavBar;
