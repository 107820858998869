import { Alert, IconButton } from '@mui/material';
import { Link } from '@tanstack/react-router';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';
import { useShallow } from 'zustand/react/shallow';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';

const COOKIE_NAME = 'unsubscribe-banner-dismissed';
const COOKIE_EXPIRY_DAYS = 60; // 2 months

export default function UnsubscribeBanner() {
  const [isDismissed, setIsDismissed] = useState(true);
  const { userUnsubscribeStatus } = useGlobalSettingsStore(
    useShallow((state) => ({
      userUnsubscribeStatus: state.globalSettings.userUnsubscribeStatus,
    })),
  );

  useEffect(() => {
    const cookieValue = Cookies.get(COOKIE_NAME);
    setIsDismissed(!!cookieValue);
  }, []);

  if (!userUnsubscribeStatus || userUnsubscribeStatus === 'None' || isDismissed)
    return null;

  function handleDismiss() {
    Cookies.set(COOKIE_NAME, 'true', {
      expires: COOKIE_EXPIRY_DAYS,
      sameSite: 'strict',
      secure: true,
    });
    setIsDismissed(true);
  }

  return (
    <Alert
      severity="warning"
      className="mx-10 rounded-none border-b border-yellow-100"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handleDismiss}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      <span>
        Your email preferences are currently set to unsubscribe from{' '}
        {userUnsubscribeStatus.toLowerCase()} communications. To modify these settings,
        please visit your{' '}
        <Link to="/profile" className="text-amber-700 underline hover:text-amber-900">
          Profile
        </Link>{' '}
        page.
      </span>
    </Alert>
  );
}
