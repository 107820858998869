import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ViewModeIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="8" height="8" transform="translate(3 3)" fill="#A76337" />
      <rect width="8" height="8" transform="translate(13 3)" fill="#E9A600" />
      <rect width="8" height="8" transform="translate(3 13)" fill="#F25022" />
      <rect
        x="13.25"
        y="13.25"
        width="7.5"
        height="7.5"
        stroke="#492B18"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default ViewModeIcon;
