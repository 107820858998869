import { saveAs } from 'file-saver';

export async function downloadFile(url: string, fileName: string): Promise<void> {
  const response = await fetch(url);
  const blob = await response.blob();
  saveAs(blob, fileName);
}

export const isValidUploadedImageType = (file: File) => {
  const fileExtension = file.name.split('.').at(-1) || '';

  const validFileExtensions = ['jpeg', 'png', 'jpg', 'JPG', 'webp'];

  return validFileExtensions.includes(fileExtension);
};

export const showImageSizeWithLetter = (file: File) => {
  const oneKB = 1_024;
  const oneMB = 1_024 * oneKB;
  const oneGB = 1_024 * oneMB;

  if (file.size < oneKB) return file.size + 'B';
  if (file.size < oneMB) return (file.size / oneKB).toFixed(1) + ' KB';
  if (file.size < oneGB) return (file.size / oneMB).toFixed(1) + ' MB';
};

export const captureUserScreenshot = async (): Promise<File | null> => {
  try {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: {
        displaySurface: 'monitor',
      },
    });

    const video = document.createElement('video');
    video.srcObject = stream;

    await video.play();

    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext('2d');
    context?.drawImage(video, 0, 0, canvas.width, canvas.height);

    const blob = await new Promise<Blob>((resolve, reject) => {
      canvas.toBlob((b) => {
        if (b) {
          resolve(b);
        } else {
          reject(new Error('Failed to create blob'));
        }
      }, 'image/png');
    });

    const file = new File([blob], `screenshot-${new Date().getTime()}.png`, {
      type: 'image/png',
    });

    stream.getTracks().forEach((track) => track.stop());

    return file;
  } catch (error) {
    console.error('Error capturing screen:', error);
    return null;
  }
};
