import { create } from 'zustand';

import { defaultSettings, Settings } from '@/contexts/Settings.types';

type SettingsStore = {
  globalSettings: Settings;
  isAuthenticated: boolean;
  updateSettings: (newSettings: Partial<Settings>) => Promise<void>;
  resetSettings: () => Promise<void>;
  setGlobalSettings: (globalSettings: Settings) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};

export const useGlobalSettingsStore = create<SettingsStore>()((set) => ({
  globalSettings: defaultSettings,
  isAuthenticated: false,
  updateSettings: async (newSettings) => {
    set((state) => ({
      ...state,
      globalSettings: { ...state.globalSettings, ...newSettings },
    }));
  },
  resetSettings: async () => {
    set({ globalSettings: defaultSettings });
  },
  setIsAuthenticated: (isAuthenticated) => {
    set((state) => ({
      ...state,
      isAuthenticated,
    }));
  },
  setGlobalSettings: (globalSettings) => {
    set((state) => ({
      ...state,
      globalSettings,
    }));
  },
}));
