import { DropdownOption } from '@/components/dropdownOption.type';
import { DropdownOptions } from '@/pages/strategies/types/Strategy.types';

export type ContributionCardProps = {
  isActive: boolean;
  item: Contribution;
  primaryDeliverableId?: string;
  dropdownOptions: DropdownOptions | undefined;
  setAddingContribution: (c: Contribution | null) => void;
  setActiveContributions?: (ids: []) => void;
  handleHeaderClick: (c: Contribution) => void;
  isSelected?: boolean;
  handleCheckboxClick?: (id: string) => void;
  existedContributionOrder: string[];
  businessUnits: string[];
  disabled?: boolean;
};

export type Contribution = {
  id: string;
  actionOrder?: string;
  deliverableOrder?: string;
  actionId?: string;
  order: string;
  deliverableId: string;
  name: string;
  responseType: string;
  reportTypeIds: string[];
  responseOptions: DropdownOption[];
  contributionPeriods: ContributionPeriod[];
  contributionSubIndicator?: string;
  holdById?: string;
  isNew?: boolean;
  strategyId?: string;
  isActive?: boolean;
  achievedDate?: Date;
  autoStatusUpdate?: boolean;
  businessUnitIds?: string[];
};

export enum Periodicity {
  Once = 0,
  Monthly = 2,
  Quarterly = 4,
  TwiceAYear = 8,
  Annual = 16,
}

export type ContributionPeriod = {
  id: string;
  fromDate?: string;
  toDate?: string;
  periodicity: Periodicity;
  contributionId?: string;
  isNew?: boolean;
  overrideBUIds?: string[];
  progressResponseCount?: number;
};

export const responseTypesNoOption = ['Number', 'Currency', 'Status'];
