import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useShallow } from 'zustand/react/shallow';

import HookFormMentionTextField from '@/components/HookFormMentionTextField';
import { CopyIcon } from '@/components/icons/CopyIcon';
import OverflowTypography from '@/components/mui/OverflowTypography';
import { usePostData } from '@/helpers/hooks';

import { formatDateTimeToMonthString } from '../../../helpers/dateUtils';
import { DeliverableWithLastContribution } from '../BulkUpdatePanel';
import ContributionCardContent from '../ContributionCardContent';
import { useStrategyStore } from '../store/useStrategyStore';
import { Contribution, Periodicity } from '../types/Contribution.types';

interface BulkCreateQuestionProps {
  setOpen: (open: boolean) => void;
  open: boolean;
  deliverables: DeliverableWithLastContribution[];
}

interface Payload {
  deliverables: DeliverableWithLastContribution[];
  contribution: any;
}

export default function BulkCreateContribution({
  setOpen,
  open,
  deliverables,
}: BulkCreateQuestionProps) {
  const { dropdownOptionSchema, strategyStart, strategyEnd } = useStrategyStore(
    useShallow((state) => ({
      strategyStart: state.strategyStart,
      strategyEnd: state.strategyEnd,
      dropdownOptionSchema: state.dropdownOptions,
    })),
  );

    const formSchema = z
        .object({
            responseType: z.string().optional(),
            name: z.string().optional(),
            reportTypeIds: z.string().array().optional(),
            newPeriodFrom: z
                .date()
                .min(
                    dayjs(strategyStart).startOf('month').toDate(),
                    'From date cannot be earlier than Strategy start date',
                )
                .optional(),
            newPeriodTo: z
                .date()
                .max(
                    dayjs(strategyEnd).endOf('month').toDate(),
                    'To date cannot be after Strategy end date',
                )
                .optional(),
            newPeriodFrequency: z.nativeEnum(Periodicity).optional(),
            subIndicator: z.string().optional(),
            holdById: z.string().optional(),
            autoStatusUpdate: z.boolean().optional(),
            comment: z.string().min(1, 'Contribution Name is required'),
        })
        .superRefine((data, ctx) => {
            if (
                data.newPeriodFrequency !== Periodicity.Once &&
                data.newPeriodFrom &&
                data.newPeriodTo &&
                dayjs(data.newPeriodTo).isSame(dayjs(data.newPeriodFrom), 'month') &&
                dayjs(data.newPeriodTo).year() === dayjs(data.newPeriodFrom).year()
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'To date cannot be in the same month and year as From date.',
                    path: ['newPeriodTo'],
                });
            }

            if (
                data.newPeriodFrequency === Periodicity.Once &&
                data.newPeriodFrom &&
                dayjs(data.newPeriodFrom).isAfter(dayjs(strategyEnd))
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'From date cannot be after Strategy end date when frequency is Once.',
                    path: ['newPeriodFrom'],
                });
            }
        });


  const item: Contribution = {
    id: '',
    name: '',
    isNew: true,
    deliverableId: '',
    contributionPeriods: [],
    reportTypeIds: [],
    responseOptions: [],
    responseType: 'Status',
    actionOrder: '',
    deliverableOrder: '',
    order: '0',
    isActive: true,
    contributionSubIndicator: '',
    holdById: '',
    autoStatusUpdate: false,
  };

  const [isSaving, setIsSaving] = useState(false);

  type FormFields = z.infer<typeof formSchema>;

  const methods = useForm<FormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: {
      responseType: 'Status',
      name: item.name ?? '',
      reportTypeIds: item.reportTypeIds,
      newPeriodFrom: item.isNew ? new Date() : undefined,
      newPeriodTo: item.isNew ? new Date() : undefined,
      newPeriodFrequency: item.isNew ? Periodicity.Monthly : undefined,
      subIndicator: '',
      holdById: item.holdById ?? '',
      autoStatusUpdate: item.autoStatusUpdate,
      comment: 'Provide the current status of: @[Deliverable Name](1)',
    },
  });

  useEffect(() => {
    if (!open) {
      methods.reset();
    } else {
      setIsSaving(false);
    }
  }, [open]);

  const queryClient = useQueryClient();
  const { handleSubmit } = methods;

  const handleClose = () => {
    setOpen(false);
  };

  const mutation = usePostData<unknown, unknown, Payload>({
    url: '/Bulk/BulkCreateContribution',
  });

  const onSubmit = async (data: FormFields) => {
    if (!data) {
      console.error('Child data is not available');
      return;
    }

    const contribution = {
      order: '0',
      reportTypeIds: data.reportTypeIds,
      responseType: 'Status',
      name: '',
      newPeriodFromDate: formatDateTimeToMonthString(data.newPeriodFrom as Date),
      newPeriodToDate: formatDateTimeToMonthString(data.newPeriodTo as Date),
      newPeriodFrequency: data.newPeriodFrequency,
      subIndicator: data.subIndicator,
    };

    const payload: Payload = {
      deliverables: deliverables.map((deliverable) => ({
        ...deliverable,
        name: data.comment.replaceAll('@[Deliverable Name](1)', deliverable.name),
      })),
      contribution: contribution,
    };

    setIsSaving(true);
    await mutation.mutateAsync(payload, {
      onSuccess: async (_response) => {
        handleClose();
      },
      onError: (error) => {
        console.error('Mutation Error:', error);
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id={'question-create-form'}>
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
          <DialogTitle mb={0}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CopyIcon sx={{ width: 44, height: 44 }} />
                <Box
                  sx={{
                    ml: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: '600',
                      fontSize: '1rem',
                      color: (theme) => theme.palette.brownDark.main,
                    }}
                  >
                    Bulk Create Question
                  </Typography>
                  <Typography>
                    Create questions in bulk for selected deliverables.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent>
            <Grid
              container
              sx={{
                mt: 1,
              }}
            >
              <Grid item xs={12}>
                <Divider />
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <OverflowTypography
                      variant="h6"
                      fontSize="1rem"
                      sx={{
                        color: (theme) => theme.palette.brownDark.main,
                      }}
                      maxLine={1}
                      mb={2}
                      mx={1}
                      pb={2}
                    >
                      Question Details
                    </OverflowTypography>
                  </Grid>
                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12} sx={{ paddingInline: 2 }}>
                      <HookFormMentionTextField
                        name="comment"
                        mentionItems={[{ id: '1', display: 'Deliverable Name' }]}
                        nonRemovableMentions={['1']}
                      />
                    </Grid>
                    <ContributionCardContent
                      item={item}
                      dropdownOptions={dropdownOptionSchema}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2 }} />
            <Box
              sx={{
                mt: 2,
              }}
            >
              <Typography
                variant="h6"
                component="h3"
                sx={{
                  fontSize: '1rem',
                  color: (theme) => theme.palette.brownDark.main,
                }}
              >
                The following questions will be created:
              </Typography>
              <Box
                sx={{
                  mt: 1,
                }}
              >
                {deliverables.map((deliverable) => (
                  <Typography
                    key={deliverable.id}
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.brownDark.light,
                    }}
                  >
                    {`${deliverable.actionOrder}.${deliverable.order}.${deliverable.lastContributionOrder} ${methods.watch('comment').replaceAll('@[Deliverable Name](1)', deliverable.name)}`}
                  </Typography>
                ))}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              form="question-create-form"
              variant="contained"
              color="primary"
              disabled={isSaving}
            >
              {isSaving ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
}
