import { Chip, Tooltip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip';

type CardCountChipProps = ChipProps & {
  count: number;
  title: string;
  bgColor?: string;
};
export default function CardCountChip({
  count,
  title,
  bgColor,
  ...props
}: CardCountChipProps) {
  const { ...restProps } = props;
  return (
    <Tooltip title={title}>
      <Chip
        label={count}
        size="small"
        sx={{
          backgroundColor: count === 0 ? '#ffcfbf' : (bgColor ?? '#d2e9b4'),
          minWidth: '24px',
          marginLeft: 1,
        }}
        {...restProps}
      />
    </Tooltip>
  );
}
