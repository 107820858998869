import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Badge, Button, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { formatDateTimeToMonthString, isEndDateMatchPeriod } from '@/helpers/dateUtils';
import { usePutData } from '@/helpers/hooks';
import BulkUpdateDeliverable from '@/pages/strategies/bulkUpdates/BulkUpdateDeliverable';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import { Action } from '@/pages/strategies/types/Action.types';
import { Contribution, Periodicity } from '@/pages/strategies/types/Contribution.types';
import { Deliverable } from '@/pages/strategies/types/deliverable.types';
import { useConfirm } from '@/store/useConfirmDialogStore';

import BulkCreateContribution from './bulkUpdates/BulkCreateContribution';
import useDeliverablesWithLastContribution from './bulkUpdates/UseDeliverablesWithLastContribution';
import UseGlobalSettingsActions from '@/store/useGlobalSettingsActions';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';

interface Props {
    selectedActions?: Action[];
    selectedDeliverables?: Deliverable[];
    selectedContributions?: Contribution[];
}

interface BulkUpdatePayload {
    actionIds?: string[];
    deliverableIds?: string[];
    assignBusinessUnitIds?: string[];
    unassignBusinessUnitIds?: string[];
    contributionIds?: string[];
    periodicity?: Periodicity;
    fromDate?: string;
    toDate?: string;
    overrideBusinessUnitIds?: string[];
    reportTypeIds?: string[];
    toDelete?: boolean;
    statusId?: string;
    actionAreaId?: string;
}
export interface DeliverableWithLastContribution {
    id: string;
    name: string;
    actionOrder?: string;
    order: string;
    lastContributionOrder: string;
}

type BulkUpdateResponse = {
    requireConfirmation: boolean;
    message: string;
};

const BulkUpdatePanel = ({
    selectedActions,
    selectedDeliverables,
    selectedContributions,
}: Props) => {
    const confirm = useConfirm();
    const { enqueueSnackbar } = useSnackbar();
    const { dropdownOptions, strategyStart, strategyEnd, allContributions } =
        useStrategyStore(
            useShallow((state) => ({
                dropdownOptions: state.dropdownOptions,
                strategyStart: state.strategyStart,
                strategyEnd: state.strategyEnd,
                allContributions: state.allContributions,
            })),
        );
    const isGlobalAdmin =
        useGlobalSettingsStore.getState().globalSettings.role === 'Global Admin';

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openDeliverablePanel, setOpenDeliverablePanel] = useState(false);
    const [openContributionPanel, setOpenContributionPanel] = useState(false);

    const reportTypes = dropdownOptions?.reportTypes;
    const businessUnits = dropdownOptions?.businessUnits;
    const statuses = dropdownOptions?.statuses;
    const actionAreas = dropdownOptions?.actionAreas;

    const isAction = selectedActions && selectedActions?.length > 0;
    const isDeliverable = selectedDeliverables && selectedDeliverables?.length > 0;
    const isContribution = selectedContributions && selectedContributions?.length > 0;
    const deliverablesWithLastContribution = useDeliverablesWithLastContribution(
        selectedDeliverables || [],
        allContributions,
    );

    const queryClient = useQueryClient();
    const bulkUpdateActionsMutation = usePutData<
        string,
        unknown,
        BulkUpdatePayload,
        string
    >(`/Strategy/Actions`);
    const bulkUpdateDeliverablesMutation = usePutData<
        BulkUpdateResponse,
        unknown,
        BulkUpdatePayload,
        string
    >(`/Strategy/Deliverables`);

    const bulkUpdateContributionMutation = usePutData<
        { requireConfirmation: boolean; message: string },
        unknown,
        BulkUpdatePayload,
        string
    >(`/Contribution/BulkUpdateContributions`, {
        onSuccess: async (res, variables) => {
            if (res.requireConfirmation) {
                if (res.message.includes('are already Approved')) {
                    const msg = res.message;
                    const confirmed = await confirm({
                        title: 'Are you sure?',
                        message: msg,
                    });
                    if (confirmed) {
                        const confirmedPayload = { confirmApproved: true, ...variables };
                        await bulkUpdateContributionMutation.mutateAsync(confirmedPayload, {
                            onSuccess: () => {
                                queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                            },
                        });
                    }
                }
            } else {
                queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                enqueueSnackbar('The bulk update was executed successfully!', {
                    variant: 'success',
                });
            }
        },
        onError: () => {
            enqueueSnackbar('An error occurred while performing the bulk update process.', {
                variant: 'error',
            });
        },
    });

    const showValidationError = (contributionsNotMatch: Contribution[]) => {
        confirm({
            title: 'Error',
            message: `The following contributions: \n ${contributionsNotMatch
                .map((c) => `${c.actionOrder}.${c.deliverableOrder}.${c.order}`)
                .join(
                    ', ',
                )}\n do not align with the selected frequency. Please adjust the dates before proceeding with the bulk update. `,
            isModal: true,
        });
    };
    const checkSelectedDate = (date: Date | string) => {
        if (dayjs(date).isBefore(strategyStart) || dayjs(date).isAfter(strategyEnd)) {
            confirm({
                title: 'Error',
                message: 'The selected date must be in the strategy period, please try again.',
                isModal: true,
            });
            return false;
        } else return true;
    };
    const handleOptionSelect = async (
        option:
            | 'updateActionArea'
            | 'assignBu'
            | 'unassignBU'
            | 'updateFrom'
            | 'updateTo'
            | 'updatePeriod'
            | 'updateReportType'
            | 'overrideBU'
            | 'delete'
            | 'updateStatus'
            | 'bulkDuplicateDeliverable'
            | 'bulkCreateContribution',
    ) => {
        if (option === 'updateActionArea') {
            const confirmRes = await confirm({
                title: 'Bulk Update Action Area',
                message: `Please select the action area you wish to update for the selected ${selectedActions?.length} action(s)`,
                dropdownLabel: 'Action Area',
                dropdownList: actionAreas || [],
            });

            if (confirmRes.confirmed) {
                const payload: BulkUpdatePayload = {
                    actionIds: selectedActions?.map((a) => a.id),
                    actionAreaId: confirmRes.comment,
                };
                await bulkUpdateActionsMutation.mutateAsync(payload, {
                    onSuccess: async (res) => {
                        if (res && res.includes('are already Approved')) {
                            const msg = res.replace('20', '\n20').replace('By adding', '\n By adding');
                            const confirmed = await confirm({
                                title: 'Are you sure?',
                                message: msg,
                            });
                            if (confirmed) {
                                const confirmedPayload = { confirmApproved: true, ...payload };
                                await bulkUpdateDeliverablesMutation.mutateAsync(confirmedPayload, {
                                    onSuccess: () => {
                                        queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                                    },
                                });
                            }
                        } else {
                            queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                            enqueueSnackbar('The bulk update was executed successfully!', {
                                variant: 'success',
                            });
                        }
                    },
                    onError: () => {
                        enqueueSnackbar(
                            'An error occurred while performing the bulk update process.',
                            {
                                variant: 'error',
                            },
                        );
                    },
                });
            }
        }
        // Bulk assign Business Units
        else if (option === 'assignBu') {
            const confirmRes = await confirm({
                title: 'Bulk Assign Business Unit',
                message: `Please select the business units you wish to update for the selected ${selectedDeliverables?.length} deliverable(s)`,
                dropdownLabel: 'Business Unit',
                dropdownList: businessUnits || [],
                dropdownMultiple: true,
            });
            if (confirmRes.confirmed) {
                const payload: BulkUpdatePayload = {
                    deliverableIds: selectedDeliverables?.map((d) => d.id),
                    assignBusinessUnitIds: confirmRes.comment?.split(','),
                };
                await bulkUpdateDeliverablesMutation.mutateAsync(payload, {
                    onSuccess: async (res) => {
                        if (res && res.requireConfirmation) {
                            const msg = res.message;
                            const confirmed = await confirm({
                                title: 'Are you sure?',
                                message: msg,
                            });
                            if (confirmed) {
                                const confirmedPayload = { confirmApproved: true, ...payload };
                                await bulkUpdateDeliverablesMutation.mutateAsync(confirmedPayload, {
                                    onSuccess: () => {
                                        queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                                    },
                                });
                            }
                        } else {
                            queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                            enqueueSnackbar('The bulk update was executed successfully!', {
                                variant: 'success',
                            });
                        }
                    },
                    onError: () => {
                        enqueueSnackbar(
                            'An error occurred while performing the bulk update process.',
                            {
                                variant: 'error',
                            },
                        );
                    },
                });
            }

            // Bulk unassign Business Units
        } else if (option === 'unassignBU') {
            const availableBUIds = new Set<string>();
            selectedDeliverables?.forEach((d) =>
                d.businessUnitIds?.forEach((id) => availableBUIds.add(id)),
            );
            const availableBUs = businessUnits?.filter((bu) => availableBUIds.has(bu.id));
            const confirmRes = await confirm({
                title: 'Bulk Unassign Business Unit',
                message: `Please select the business units you wish to update for the selected ${selectedDeliverables?.length} deliverable(s)`,
                dropdownLabel: 'Business Unit',
                dropdownList: availableBUs || [],
                dropdownMultiple: true,
            });
            if (confirmRes.confirmed) {
                const payload: BulkUpdatePayload = {
                    deliverableIds: selectedDeliverables?.map((d) => d.id),
                    unassignBusinessUnitIds: confirmRes.comment?.split(','),
                };
                await bulkUpdateDeliverablesMutation.mutateAsync(payload, {
                    onSuccess: async (res) => {
                        if (res && res.requireConfirmation) {
                            const msg = res.message;
                            const confirmed = await confirm({
                                title: 'Are you sure?',
                                message: msg,
                            });
                            if (confirmed) {
                                const confirmedPayload = { confirmApproved: true, ...payload };
                                await bulkUpdateDeliverablesMutation.mutateAsync(confirmedPayload, {
                                    onSuccess: () => {
                                        queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                                    },
                                });
                            }
                        } else {
                            queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                            enqueueSnackbar('The bulk update was executed successfully!', {
                                variant: 'success',
                            });
                        }
                    },
                    onError: () => {
                        enqueueSnackbar(
                            'An error occurred while performing the bulk update process.',
                            {
                                variant: 'error',
                            },
                        );
                    },
                });
            }

            // Bulk update contribution period periodicity
        } else if (option === 'updatePeriod') {
            const res = await confirm({
                title: 'Bulk Update Frequency',
                message: `Please select the frequency you wish to update for the selected ${selectedContributions?.length} contributions(s)`,
                dropdownLabel: 'Frequency',
                dropdownList: [
                    { title: 'Once', id: '0' },
                    { title: 'Monthly', id: '2' },
                    { title: 'Quarterly', id: '4' },
                    { title: 'Twice a Year', id: '8' },
                    { title: 'Annually', id: '16' },
                ],
            });
            if (res.confirmed) {
                const userConfirm = await confirm({
                    title: 'Confirm',
                    message:
                        'Modifying the contribution period will result in updates to Progress Updates for all related Business Units. Are you sure you want to proceed.',
                });
                if (userConfirm) {
                    const newPeriodicity = parseInt(res.comment) as Periodicity;
                    const periodicityName = Periodicity[newPeriodicity]; 

                    const contributionsNotMatch =
                        selectedContributions?.filter(
                            (c) =>
                                c.contributionPeriods[0].periodicity !== Periodicity.Once &&
                                !isEndDateMatchPeriod(
                                    c.contributionPeriods[0].fromDate!,
                                    c.contributionPeriods[0].toDate!,
                                    newPeriodicity                                ),
                        ) || [];
                    if (contributionsNotMatch.length) {
                        showValidationError(contributionsNotMatch);
                    } else {
                        const payload: BulkUpdatePayload = {
                            contributionIds: selectedContributions?.map((c) => c.id),
                            periodicity: parseInt(res.comment!),
                        };
                        await bulkUpdateContributionMutation.mutateAsync(payload);
                    }
                }
            }

            // Bulk update contribution period from date
        } else if (option === 'updateFrom') {
            const res = await confirm({
                title: 'Update Period From',
                message: 'Please select a new Period From date for the selected record/s.',
                showComment: true,
                commentType: 'date',
                selectedDate: new Date(),
                isMonthPicker: true,
            });
            if (res.confirmed) {
                if (checkSelectedDate(res.selectedDate!)) {
                    const userConfirm = await confirm({
                        title: 'Confirm',
                        message:
                            'Modifying the contribution period will result in updates to Progress Updates for all related Business Units. Are you sure you want to proceed.',
                    });
                    if (userConfirm) {
                        const contributionsNotMatch =
                            selectedContributions?.filter(
                                (c) =>
                                    !isEndDateMatchPeriod(
                                        res.selectedDate!,
                                        c.contributionPeriods[0].toDate!,
                                        c.contributionPeriods[0].periodicity,
                                    ),
                            ) || [];
                        if (contributionsNotMatch.length) {
                            showValidationError(contributionsNotMatch);
                        } else {
                            const payload: BulkUpdatePayload = {
                                contributionIds: selectedContributions?.map((c) => c.id),
                                fromDate: formatDateTimeToMonthString(res.selectedDate!),
                            };
                            await bulkUpdateContributionMutation.mutateAsync(payload);
                        }
                    }
                }
            }

            // Bulk update contribution period to date
        } else if (option === 'updateTo') {
            const res = await confirm({
                title: 'Update Period To',
                message: 'Please select a new Period To date for the selected record/s.',
                showComment: true,
                commentType: 'date',
                selectedDate: new Date(),
                isMonthPicker: true,
            });
            if (res.confirmed) {
                if (checkSelectedDate(res.selectedDate!)) {
                    const userConfirm = await confirm({
                        title: 'Confirm',
                        message:
                            'Modifying the contribution period will result in updates to Progress Updates for all related Business Units. Are you sure you want to proceed.',
                    });
                    if (userConfirm) {
                        const contributionsNotMatch =
                            selectedContributions?.filter(
                                (c) =>
                                    !isEndDateMatchPeriod(
                                        c.contributionPeriods[0].fromDate!,
                                        res.selectedDate!,
                                        c.contributionPeriods[0].periodicity,
                                    ),
                            ) || [];

                        if (contributionsNotMatch.length) {
                            showValidationError(contributionsNotMatch);
                        } else {
                            const payload: BulkUpdatePayload = {
                                contributionIds: selectedContributions?.map((c) => c.id),
                                toDate: formatDateTimeToMonthString(res.selectedDate!),
                            };
                            await bulkUpdateContributionMutation.mutateAsync(payload);
                        }
                    }
                }
            }
        } else if (option === 'overrideBU') {
            const confirmRes = await confirm({
                title: 'Override Business Unit',
                message: 'Please select override Business Units selected record/s.',
                dropdownLabel: 'Business Unit',
                dropdownList: businessUnits || [],
                dropdownMultiple: true,
            });
            if (confirmRes.confirmed) {
                const payload: BulkUpdatePayload = {
                    contributionIds: selectedContributions?.map((c) => c.id),
                    overrideBusinessUnitIds: confirmRes.comment?.split(','),
                };
                await bulkUpdateContributionMutation.mutateAsync(payload, {
                    onSuccess: async (res) => {
                        if (res && res.requireConfirmation) {
                            const msg = res.message;
                            await confirm({
                                title: 'Error',
                                message: msg,
                                isModal: true,
                            });
                        } else {
                            queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                            enqueueSnackbar('The bulk update was executed successfully!', {
                                variant: 'success',
                            });
                        }
                    },
                    onError: () => {
                        enqueueSnackbar(
                            'An error occurred while performing the bulk update process.',
                            {
                                variant: 'error',
                            },
                        );
                    },
                });
            }
        }
        else if (option === 'updateReportType') {
            const confirmRes = await confirm({
                title: 'Update Report Type',
                message: 'Please select Report Type selected record/s.',
                dropdownLabel: 'Report Type',
                dropdownList: reportTypes || [],
                dropdownMultiple: true,
            });
            if (confirmRes.confirmed) {
                const payload: BulkUpdatePayload = {
                    contributionIds: selectedContributions?.map((c) => c.id),
                    reportTypeIds: confirmRes.comment?.split(','),
                };
                await bulkUpdateContributionMutation.mutateAsync(payload, {
                    onSuccess: async (res) => {
                        if (res && res.requireConfirmation) {
                            const msg = res.message;
                            await confirm({
                                title: 'Error',
                                message: msg,
                                isModal: true,
                            });
                        } else {
                            queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                            enqueueSnackbar('The bulk update was executed successfully!', {
                                variant: 'success',
                            });
                        }
                    },
                    onError: () => {
                        enqueueSnackbar(
                            'An error occurred while performing the bulk update process.',
                            {
                                variant: 'error',
                            },
                        );
                    },
                });
            }
        } 
        else if (option === 'delete') {
            const res = await confirm({
                title: 'Confirm',
                message:
                    `Are you sure you want to delete this ${isAction ? 'Action(s)' : isDeliverable ? 'Deliverable(s)' : 'Contribution(s)'
                    }? \n` +
                    'Please note, the records will be deleted from any associated Strategies, Deliverables, or Progress Update.',
            });
            if (res) {
                if (isAction) {
                    const payload: BulkUpdatePayload = {
                        actionIds: selectedActions?.map((a) => a.id),
                        toDelete: true,
                    };
                    await bulkUpdateActionsMutation.mutateAsync(payload, {
                        onSuccess: () => {
                            queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                            enqueueSnackbar('The bulk update was executed successfully!', {
                                variant: 'success',
                            });
                        },
                        onError: () => {
                            enqueueSnackbar(
                                'An error occurred while performing the bulk update process.',
                                {
                                    variant: 'error',
                                },
                            );
                        },
                    });
                }
                if (isDeliverable) {
                    const payload: BulkUpdatePayload = {
                        deliverableIds: selectedDeliverables?.map((d) => d.id),
                        toDelete: true,
                    };
                    await bulkUpdateDeliverablesMutation.mutateAsync(payload, {
                        onSuccess: () => {
                            queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                            enqueueSnackbar('The bulk update was executed successfully!', {
                                variant: 'success',
                            });
                        },
                        onError: () => {
                            enqueueSnackbar(
                                'An error occurred while performing the bulk update process.',
                                {
                                    variant: 'error',
                                },
                            );
                        },
                    });
                } else {
                    const payload: BulkUpdatePayload = {
                        contributionIds: selectedContributions?.map((c) => c.id),
                        toDelete: true,
                    };
                    await bulkUpdateContributionMutation.mutateAsync(payload, {
                        onSuccess: () => {
                            queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                            enqueueSnackbar('The bulk update was executed successfully!', {
                                variant: 'success',
                            });
                        },
                        onError: () => {
                            enqueueSnackbar(
                                'An error occurred while performing the bulk update process.',
                                {
                                    variant: 'error',
                                },
                            );
                        },
                    });
                }
            }
        } else if (option === 'updateStatus' && isGlobalAdmin) {
            const res = await confirm({
                title: 'Bulk Change Status',
                message: `Please select the status you wish to update for the selected ${selectedDeliverables?.length} deliverable(s)`,
                dropdownLabel: 'Status',
                dropdownList: statuses || [],
            });
            if (res.confirmed) {
                const payload: BulkUpdatePayload = {
                    deliverableIds: selectedDeliverables?.map((d) => d.id),
                    statusId: res.comment,
                };
                await bulkUpdateDeliverablesMutation.mutateAsync(payload, {
                    onSuccess: () => {
                        queryClient.invalidateQueries({ queryKey: ['strategyData'] });
                        enqueueSnackbar('The bulk update was executed successfully!', {
                            variant: 'success',
                        });
                    },
                    onError: () => {
                        enqueueSnackbar(
                            'An error occurred while performing the bulk update process.',
                            {
                                variant: 'error',
                            },
                        );
                    },
                });
            }
        } else if (option === 'bulkDuplicateDeliverable') {
            setOpenDeliverablePanel(true);
        } else if (option === 'bulkCreateContribution') {
            setOpenContributionPanel(true);
        }
    };

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div
            style={{
                marginLeft: 150,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                minWidth: 140,
            }}
        >
            <div>
                <Badge
                    badgeContent={
                        selectedActions?.length ||
                        selectedDeliverables?.length ||
                        selectedContributions?.length
                    }
                    color="info"
                >
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'option-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        variant="outlined"
                    >
                        Bulk Update
                    </Button>
                </Badge>
                <Menu
                    id="option-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'option-button',
                    }}
                >
                    {isAction && (
                        <MenuItem onClick={() => handleOptionSelect('updateActionArea')}>
                            Update Action Area
                        </MenuItem>
                    )}
                    {isDeliverable && (
                        <MenuItem onClick={() => handleOptionSelect('assignBu')}>
                            Assign Business Unit
                        </MenuItem>
                    )}
                    {isDeliverable && (
                        <MenuItem onClick={() => handleOptionSelect('unassignBU')}>
                            Unassign Business Unit
                        </MenuItem>
                    )}
                    {isDeliverable && isGlobalAdmin && (
                        <MenuItem onClick={() => handleOptionSelect('updateStatus')}>
                            Update Status
                        </MenuItem>
                    )}
                    {isContribution && (
                        <MenuItem onClick={() => handleOptionSelect('updateFrom')}>
                            Update Period From
                        </MenuItem>
                    )}
                    {isContribution &&
                        selectedContributions?.every(
                            (c) => c.contributionPeriods[0].periodicity !== Periodicity.Once,
                        ) && (
                            <MenuItem onClick={() => handleOptionSelect('updateTo')}>
                                Update Period To
                            </MenuItem>
                        )}
                    {isContribution && (
                        <MenuItem onClick={() => handleOptionSelect('updatePeriod')}>
                            Update Frequency
                        </MenuItem>
                    )}
                    {isContribution && (
                        <MenuItem onClick={() => handleOptionSelect('updateReportType')}>
                            Update Report Type
                        </MenuItem>
                    )}
                    {isContribution && (
                        <MenuItem onClick={() => handleOptionSelect('overrideBU')}>
                            Override Business Unit
                        </MenuItem>
                    )}
                    {isDeliverable && (
                        <MenuItem onClick={() => handleOptionSelect('bulkDuplicateDeliverable')}>
                            Bulk Duplicate Deliverable
                        </MenuItem>
                    )}
                    {isDeliverable && (
                        <MenuItem onClick={() => handleOptionSelect('bulkCreateContribution')}>
                            Bulk Create Contribution
                        </MenuItem>
                    )}
                    <MenuItem onClick={() => handleOptionSelect('delete')}>Delete</MenuItem>
                </Menu>
            </div>
            <BulkUpdateDeliverable
                open={openDeliverablePanel}
                setOpen={() => setOpenDeliverablePanel((prev) => !prev)}
                deliverables={selectedDeliverables || []}
            />

            <BulkCreateContribution
                open={openContributionPanel}
                setOpen={(open) => setOpenContributionPanel(open)}
                deliverables={deliverablesWithLastContribution || []}
            />
        </div>
    );
};

BulkUpdatePanel.displayName = 'BulkUpdatePanel';
export default BulkUpdatePanel;
