import { styled, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type TextFieldProps = React.ComponentProps<typeof TextField>;

interface Props extends TextFieldProps {
  name: string;
  label?: string;
}
const CssTextField = styled(TextField)({
  backgroundColor: '#FAF2EC',
  marginBottom: '1.5rem',
  borderRadius: '4px',
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FAF2EC',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'lightgray',
    },
  },
});
const BookDemoFormInput = ({ name, label, ...restProps }: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <CssTextField
            {...field}
            label={label}
            fullWidth
            error={!!error}
            helperText={error?.message}
            {...restProps}
            slotProps={{
              input: {
                style: { fontSize: 15 },
              },
              inputLabel: {
                style: { fontSize: 15 },
              },
              formHelperText: {
                style: { backgroundColor: 'white', margin: 0 },
              },
            }}
          ></CssTextField>
        );
      }}
    />
  );
};
export default BookDemoFormInput;
