import { Button } from '@mui/material';
import React from 'react';

import InfoPopover from '@/pages/strategies/contributionInfo/InfoPopover';

const ContributionInfo = ({
  contributionId,
  disabled,
}: {
  contributionId: string;
  disabled: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  return (
    <>
      <Button
        variant={'text'}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{ paddingLeft: 0, textTransform: 'none' }}
      >
        Related Progress Updates
      </Button>
      {open && (
        <InfoPopover
          anchorEl={anchorEl!}
          disabled={disabled}
          contributionId={contributionId}
          handleClose={() => setAnchorEl(null)}
        />
      )}
    </>
  );
};

export default ContributionInfo;
