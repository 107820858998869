import {
  Outlet,
  ScrollRestoration,
  useNavigate,
  useRouter,
} from '@tanstack/react-router';
import { AnimatePresence } from 'framer-motion';
import React, { Suspense, useEffect } from 'react';
import ReactLoading from 'react-loading';

import ConfirmDialog from '@/components/ConfirmDialog';
import PageLayout from '@/components/PageLayout';
import useAuth from '@/hooks/useAuth';

function Root() {
  const navigate = useNavigate();
  const { state } = useRouter();
  const [isAuthenticated, isAuthenticateLoading] = useAuth();
  const isValidReturnUrl = (url: string) => {
    return url.startsWith('/') && !url.includes('://');
  };

  useEffect(() => {
    if (isAuthenticateLoading) return;
    const searchParams = new URLSearchParams(window.location.search);
    const returnUrl = searchParams.get('returnUrl');

    if (isAuthenticated) {
      if (returnUrl && isValidReturnUrl(decodeURIComponent(returnUrl))) {
        navigate({ to: decodeURIComponent(returnUrl) });
      } else {
        if (location.pathname === '/workspace/manage/progress/editor') {
          navigate({ to: '/collaborate/edit', search: (prev) => ({ ...prev }) as any });
        }
        if (location.pathname === '/') navigate({ to: '/home' });
      }
    } else {
      const publicPaths = ['/', '/terms', '/privacy_policy', '/security'];
      const isPublicPath =
        publicPaths.includes(window.location.pathname) ||
        location.pathname.startsWith('/casestudies') ||
        location.pathname.startsWith('/blogs') ||
        location.pathname.startsWith('/careers');

      if (!isPublicPath) {
        const currentPath = `${window.location.pathname}${window.location.search}`;
        const encodedReturnUrl = encodeURIComponent(currentPath);
        navigate({ to: '/', search: { returnUrl: encodedReturnUrl } });
      }
    }
  }, [isAuthenticateLoading, isAuthenticated, navigate]);

  useEffect(() => {
    if (state.location.pathname.includes('//')) {
      const normalizedPathname = state.location.pathname.replace('//', '/');
      navigate({
        to: normalizedPathname,
        search: state.location.search,
        replace: true,
      });
    }
  }, [state.location, navigate]);

  return (
    <>
      <PageLayout>
        <Suspense
          fallback={
            <div className="flex h-full items-center justify-center">
              <ReactLoading type="bubbles" height={150} width={150} color="#9C5738" />
            </div>
          }
        >
          <AnimatePresence mode="wait">
            <div className="h-full">
              <ScrollRestoration getKey={(location) => location.pathname} />
              <Outlet />
            </div>
          </AnimatePresence>
        </Suspense>
      </PageLayout>
      <ConfirmDialog />
    </>
  );
}

export default Root;
