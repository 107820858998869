import { Box, Card, CardContent } from '@mui/material';
import { memo } from 'react';

import { DropdownOption } from '@/components/dropdownOption.type';
import StrategyCard from '@/pages/strategies/strategyTopArea/StrategyCard';
import { DropdownOptions } from '@/pages/strategies/types/Strategy.types';

type StrategyTopAreaProps = {
  handleStrategyHeaderClick: (id?: string, isNew?: boolean) => void;
  dropdownOptions: DropdownOptions | undefined;
  selectableParentStrategies: DropdownOption[];
  isReadOnly: boolean;
};

// type IconName = 'Actions' | 'Deliverables' | 'Contributions';

const StrategyTopArea = memo(
  ({
    handleStrategyHeaderClick,
    dropdownOptions,
    selectableParentStrategies,
    isReadOnly,
  }: StrategyTopAreaProps) => {
    return (
      <Box className="flex gap-6">
        <Card className="flex-grow" id={'strategy-card'}>
          <CardContent className="!pt-0">
            <StrategyCard
              selectableParentStrategies={selectableParentStrategies}
              onHeaderClick={handleStrategyHeaderClick}
              dropdownOptions={dropdownOptions}
              disabled={isReadOnly}
            />
          </CardContent>
        </Card>
        {/*<Box
          id={'status-statistic'}
          sx={{
            display: { xs: 'none', lg: 'flex' },
            gap: 2,
          }}
        >
          {(['Actions', 'Deliverables', 'Contributions'] as IconName[]).map((title) => {
            const quantityValue = quantities[title];

            return quantityValue != null && quantityValue > 0 ? (
              <QuantityCard
                key={title}
                title={title}
                quantity={quantityValue}
                iconName={title}
              />
            ) : null;
          })}
        </Box>*/}
      </Box>
    );
  },
);

export default StrategyTopArea;
