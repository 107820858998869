import { ZodObject, ZodTypeAny } from 'zod';

// Type guard to check if the schema is a Zod object
function isZodObject(schema: ZodTypeAny): schema is ZodObject<any, any, any, any, any> {
  return schema instanceof ZodObject;
}

export const isFieldRequired = (schema: ZodTypeAny, fieldName: string): boolean => {
  if (isZodObject(schema)) {
    const [parentField, _index] = fieldName.split('.');
    const fieldSchema = schema.shape[parentField];

    // Check if the field is defined in the schema
    if (!fieldSchema) {
      throw new Error(`Field: ${fieldName} does not exist in the schema`);
    }

    // Check if the field is optional or has a default value
    if (fieldSchema.safeParse(undefined).success) {
      return false;
    }

    // Since field is not optional or default, it is required
    return true;
  }

  throw new Error('Provided schema does not describe an object with a shape.');
};
