import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { Libraries, useLoadScript } from '@react-google-maps/api';
import parse from 'autosuggest-highlight/parse';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
  lat?: number;
  lng?: number;
}

interface HooksGoogleMapsProps {
  onInputChange?: (input: PlaceType | null) => void;
  dataValue?: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
  name: string;
  label: string;
  schema?: any;
  onKeyUpInput?: (event: React.KeyboardEvent<HTMLInputElement>) => void;

  [x: string]: any;
}

const libraries: Libraries = ['places', 'visualization'];

export default function HooksGoogleMaps({
  name,
  label,
  schema,
  onKeyUpInput,
  dataValue = '',
  onInputChange,
  disabled = false,
  size = 'medium',
  ...restProps
}: HooksGoogleMapsProps) {
  const { control } = useFormContext();

  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState<string>(dataValue);
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);
  const autocompletePlace = useRef<google.maps.places.PlacesService | null>(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.VITE_APP_GOOGLE_MAPS_API_KEY!,
    libraries,
  });

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            (
              predictions: google.maps.places.AutocompletePrediction[],
              status: google.maps.places.PlacesServiceStatus,
            ) => {
              if (status !== google.maps.places.PlacesServiceStatus.OK) {
                callback([]);
                return;
              }
              callback(
                predictions.map(
                  (prediction: google.maps.places.AutocompletePrediction) => ({
                    description: prediction.description,
                    structured_formatting: prediction.structured_formatting,
                    place_id: prediction.place_id,
                  }),
                ),
              );
            },
          );
        },
        400,
      ),
    [],
  );

  useEffect(() => {
    const initialValue = {
      description: dataValue ?? '',
      place_id: '',
      structured_formatting: {
        main_text: dataValue ?? '',
        secondary_text: dataValue ?? '',
      },
    };

    setValue(initialValue);
    setOptions([initialValue]);
    setInputValue(dataValue ?? '');
  }, [dataValue]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
      autocompletePlace.current = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '' || inputValue === undefined || inputValue === null) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (dataValue !== inputValue) {
      fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];
          if (value) {
            newOptions = [value];
          }
          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      });
    }
    loaded.current = true;

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const submitNewInput = async (newValue: PlaceType | null) => {
    if (autocompletePlace?.current && newValue !== null) {
      const request = {
        placeId: newValue?.place_id ?? '',
        fields: ['geometry'],
      };

      try {
        const updatedValue = await new Promise<PlaceType | null>((resolve, reject) => {
          autocompletePlace.current?.getDetails(request, (place, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              newValue !== null
            ) {
              const result = {
                ...newValue,
                lat: place?.geometry?.location?.lat(),
                lng: place?.geometry?.location?.lng(),
              };
              resolve(result);
            } else {
              reject(status);
            }
          });
        });

        if (onInputChange) {
          onInputChange(updatedValue);
        }
      } catch (error) {
        console.error('Error getting place details:', error);
        if (onInputChange) {
          onInputChange(newValue);
        }
      }
    } else {
      if (onInputChange) {
        onInputChange(newValue);
      }
    }
  };

  if (loadError) {
    return 'Error loading maps';
  }
  if (!isLoaded) return 'Loading Maps';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          {...restProps}
          size={size}
          disabled={disabled}
          sx={{ width: 1 }}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option?.description || ''
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete={false}
          includeInputInList
          filterSelectedOptions
          value={value}
          noOptionsText="No locations"
          onChange={(event, newValue: PlaceType | null) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            field.onChange(newValue);
            submitNewInput(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            if (onKeyUpInput)
              onKeyUpInput(event as React.KeyboardEvent<HTMLInputElement>);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              fullWidth
              onKeyUp={(event) => {
                if (onKeyUpInput) {
                  onKeyUpInput(event as React.KeyboardEvent<HTMLInputElement>);
                }
              }}
            />
          )}
          renderOption={(props, option) => {
            if (!option || !option.structured_formatting) {
              return null;
            }

            const matches =
              option.structured_formatting.main_text_matched_substrings || [];
            const mainText = option.structured_formatting.main_text || '';

            const parts = parse(
              mainText,
              matches.map((match) => [match.offset, match.offset + match.length]),
            );

            return (
              <li {...props} key={option.place_id || 'default-key'}>
                <Grid
                  container
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    {parts.map((part, index) => (
                      <Box
                        key={`${option.place_id || 'default'}-${index}`}
                        component="span"
                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                      >
                        {part.text}
                      </Box>
                    ))}

                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                      }}
                    >
                      {option.structured_formatting.secondary_text || ''}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    />
  );
}
