import { Bedtime, Cancel, Info, RadioButtonUnchecked } from '@mui/icons-material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Warning from '@mui/icons-material/Warning';
import { SvgIconProps } from '@mui/material';
import { JSXElementConstructor, memo, ReactElement } from 'react';

import { useStatusColor } from '@/components/status/statusFunctions';
import { AnswerStatus, StatusType } from '@/components/status/statusTypes';

const StatusIcon = ({
  statusName,
}: {
  statusName: StatusType | AnswerStatus;
}): ReactElement<any, string | JSXElementConstructor<any>> | undefined => {
  const { getColor } = useStatusColor();
  const color = getColor(statusName) as SvgIconProps['color'];
  switch (statusName.toLocaleLowerCase()) {
    case 'on track':
    case 'on track for completion':
    case 'achieved':
    case 'completed':
    case 'yes':
      return <CheckCircle color={color} />;
    case 'action required':
    case 'at risk':
      return <Warning color={color} />;
    case 'partially met':
      return <Bedtime color={color} />;
    case 'in progress':
      return <Info color={color} />;
    case 'ongoing':
      return <Info color={color} />;
    case 'not achieved':
    case 'no':
      return <Cancel color={color} />;
    case 'not started':
    case 'not due':
      return <RadioButtonUnchecked color={color} />;
    default:
      return undefined;
  }
};

export default memo(StatusIcon);
