import Box from '@mui/material/Box';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CopyIcon = (props: SvgIconProps) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        borderRadius: '50%',
        p: 0,
        color: 'white',
      }}
    >
      <SvgIcon {...props}>
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.21"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 22V27.1333C0 36.4485 7.55146 44 16.8667 44H22H27.1333C36.4485 44 44 36.4485 44 27.1333V22V16.8667C44 7.55146 36.4485 0 27.1333 0H22H16.8667C7.55146 0 0 7.55146 0 16.8667V22Z"
            fill="#D98E32"
          />
          <path
            d="M29.125 32H18.625C17.7299 32 16.8715 31.6444 16.2385 31.0115C15.6056 30.3785 15.25 29.5201 15.25 28.625V18.125C15.25 17.2299 15.6056 16.3715 16.2385 15.7385C16.8715 15.1056 17.7299 14.75 18.625 14.75H29.125C30.0201 14.75 30.8785 15.1056 31.5115 15.7385C32.1444 16.3715 32.5 17.2299 32.5 18.125V28.625C32.5 29.5201 32.1444 30.3785 31.5115 31.0115C30.8785 31.6444 30.0201 32 29.125 32Z"
            fill="#A76337"
          />
          <path
            d="M17.5 13.25H28.5569C28.3235 12.5925 27.8924 12.0232 27.3228 11.6204C26.7531 11.2175 26.0727 11.0008 25.375 11H14.875C13.9799 11 13.1214 11.3556 12.4885 11.9885C11.8556 12.6214 11.5 13.4799 11.5 14.375V24.875C11.5008 25.5727 11.7175 26.2531 12.1204 26.8228C12.5232 27.3924 13.0925 27.8235 13.75 28.0569V17C13.75 16.0054 14.1451 15.0516 14.8483 14.3483C15.5516 13.6451 16.5054 13.25 17.5 13.25Z"
            fill="#A76337"
          />
        </svg>
      </SvgIcon>
    </Box>
  );
};
