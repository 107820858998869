import { create } from 'zustand';

import { DropdownOption } from '@/components/dropdownOption.type';
import { Action } from '@/pages/strategies/types/Action.types';
import { Deliverable } from '@/pages/strategies/types/deliverable.types';
import {
  allowEditStrategyRoles,
  DropdownOptions,
  StrategyFromResponse,
  SubIndicator,
} from '@/pages/strategies/types/Strategy.types';
import { Strategy } from '@/pages/strategies/types/StrategyCard.types';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';
import { strategyItemFilters } from '@/types/itemFilter';

import { Contribution } from '../types/Contribution.types';

export type Store = {
  div1: number;
  div2: number;
  div3: number;
  strategyEnd?: Date;
  strategyStart?: Date;
  deliverableExpectedDate?: Date;

  subIndicators?: any[];
  activeContributions?: any[];
  allowHoldByContribution?: boolean;
  deliverableBusinessUnits?: any[];
  allowLinkDeliverable?: boolean;
  parentStrategies?: any;
  selectableLinkedDeliverable?: any[];
  allowAutoUpdate?: boolean;
  defaultStatusId: string;
  dropdownOptions?: DropdownOptions;

  primaryStrategy: Strategy | null;
  setPrimaryStrategy: (strategy: Strategy) => void;

  filteredDeliverableStatus: string[];
  filteredDue: string[];
  filteredBusinessUnits: string[];
  filteredResponseTypes: string[];
  filteredDeliverableDue: string[];
  filteredActionAreas: string[];
  filteredFrequency: string[];
  selectedActionIds: string[];
  selectedDeliverableIds: string[];
  selectedContributionIds: string[];

  allStrategies: StrategyFromResponse[];
  setAllStrategies: (strategies: StrategyFromResponse[]) => void;
  allActions: Action[];
  setAllActions: (actions: Action[]) => void;
  allDeliverables: Deliverable[];
  setAllDeliverables: (deliverables: Deliverable[]) => void;
  allContributions: Contribution[];
  setAllContributions: (contributions: Contribution[]) => void;

  resetPanelSizes: () => void;
  setStrategyPanelSize: (size: number, div: 'div1' | 'div2' | 'div3') => void;
  setStrategyEnd: (date: Date) => void;
  setStrategyStart: (date: Date) => void;
  setDeliverableExpectedDate: (date: Date) => void;
  setSubIndicators: (value: SubIndicator[] | undefined) => void;
  setActiveContributions: (value: any[]) => void;
  setAllowHoldByContribution: (value: boolean) => void;
  setDeliverableBusinessUnits: (value: DropdownOption[] | undefined) => void;
  setAllowLinkDeliverable: (value: boolean) => void;
  setParentStrategies: (value: any) => void;
  setSelectableLinkedDeliverable: (value: any[]) => void;
  setAllowAutoUpdate: (value: boolean) => void;
  setDefaultStatusId: (value: string) => void;
  setDropdownOptions: (value: DropdownOptions | undefined) => void;

  setFilteredDeliverableStatus: (value: string[]) => void;
  setFilteredDue: (value: string[]) => void;
  setFilteredBusinessUnits: (value: string[]) => void;
  setFilteredActionAreas: (value: string[]) => void;
  setFilteredFrequency: (value: string[]) => void;
  setFilteredResponseTypes: (value: string[]) => void;
  setFilteredDeliverableDue: (value: string[]) => void;

  setSelectedActionIds: (value: string[]) => void;
  setSelectedDeliverableIds: (value: string[]) => void;
  setSelectedContributionIds: (value: string[]) => void;

  quantities: Record<'Actions' | 'Contributions' | 'Deliverables', number>;
  setQuantities: (
    value: Record<'Actions' | 'Contributions' | 'Deliverables', number>,
  ) => void;

  activeActions: string[];
  activeDeliverableIds: string[];
  activeContributionIds: string[];

  setActiveActions: (value: string[]) => void;
  setActiveDeliverableIds: (value: string[]) => void;
  setActiveContributionIds: (value: string[]) => void;
  clearActiveItems: (
    updateFilters: (name: keyof strategyItemFilters, value: unknown) => void,
  ) => void;

  getAllowEditStrategy: () => boolean;
};

export const useStrategyStore = create<Store>((set, get) => ({
  div1: JSON.parse(
    localStorage.getItem('strategy:divs') || '{ "div1": 20, "div2": 45, "div3": 35 }',
  ).div1,
  div2: JSON.parse(
    localStorage.getItem('strategy:divs') || '{ "div1": 20, "div2": 45, "div3": 35 }',
  ).div2,
  div3: JSON.parse(
    localStorage.getItem('strategy:divs') || '{ "div1": 20, "div2": 45, "div3": 35 }',
  ).div3,

  resetPanelSizes: () => {
    set({ div1: 20, div2: 45, div3: 35 });
    localStorage.setItem(
      'strategy:divs',
      JSON.stringify({ div1: 20, div2: 45, div3: 35 }),
    );
  },
  setStrategyPanelSize: (size, div) => {
    const adjustment = size === -10 ? -10 : 10;
    const otherAdjustment = size === -10 ? 5 : -5;

    // Create a map of divs to their corresponding values
    const divMap = {
      div1: get().div1,
      div2: get().div2,
      div3: get().div3,
    };

    // Adjust the size of the specified div
    divMap[div] = divMap[div] + adjustment;

    // Adjust the size of the other divs
    for (const key in divMap) {
      if (key !== div) {
        divMap[key as 'div1' | 'div2' | 'div3'] =
          divMap[key as 'div1' | 'div2' | 'div3'] + otherAdjustment;
      }
    }

    // Update the state with the new div sizes
    set(divMap);

    // Save the new div sizes to local storage
    localStorage.setItem('strategy:divs', JSON.stringify(divMap));
  },
  setStrategyEnd: (strategyEnd) => set({ strategyEnd }),
  setStrategyStart: (strategyStart) => set({ strategyStart }),
  setDeliverableExpectedDate: (deliverableExpectedDate) =>
    set({ deliverableExpectedDate }),
  subIndicators: [],
  activeContributions: [],
  allowHoldByContribution: undefined,
  deliverableBusinessUnits: [],
  allowLinkDeliverable: undefined,
  parentStrategies: undefined,
  selectableLinkedDeliverable: [],
  allowAutoUpdate: undefined,
  defaultStatusId: '',
  dropdownOptions: undefined,

  filteredDeliverableStatus: [],
  filteredDue: [],
  filteredFrequency: [],
  filteredBusinessUnits: [],
  filteredActionAreas: [],
  filteredResponseTypes: [],
  filteredDeliverableDue: [],
  selectedActionIds: [],
  selectedDeliverableIds: [],
  selectedContributionIds: [],

  primaryStrategy: null,
  setPrimaryStrategy: (strategy) => set({ primaryStrategy: strategy }),
  allStrategies: [],
  setAllStrategies: (strategies) => set({ allStrategies: strategies }),
  allActions: [],
  setAllActions: (actions) => set({ allActions: actions }),
  allDeliverables: [],
  setAllDeliverables: (deliverables) => set({ allDeliverables: deliverables }),
  allContributions: [],
  setAllContributions: (contributions) => set({ allContributions: contributions }),

  setSubIndicators: (value) => set({ subIndicators: value }),
  setActiveContributions: (value) => set({ activeContributions: value }),
  setAllowHoldByContribution: (value) => set({ allowHoldByContribution: value }),
  setDeliverableBusinessUnits: (value) => set({ deliverableBusinessUnits: value }),
  setAllowLinkDeliverable: (value) => set({ allowLinkDeliverable: value }),
  setParentStrategies: (value) => set({ parentStrategies: value }),
  setSelectableLinkedDeliverable: (value) => set({ selectableLinkedDeliverable: value }),
  setAllowAutoUpdate: (value) => set({ allowAutoUpdate: value }),
  setDefaultStatusId: (value) => set({ defaultStatusId: value }),
  setDropdownOptions: (value: DropdownOptions | undefined) =>
    set({ dropdownOptions: value }),

  setFilteredDeliverableStatus: (value) => set({ filteredDeliverableStatus: value }),
  setFilteredDue: (value) => set({ filteredDue: value }),
  setFilteredActionAreas: (value) => set({ filteredActionAreas: value }),
  setFilteredFrequency: (value) => set({ filteredFrequency: value }),
  setFilteredBusinessUnits: (value) => set({ filteredBusinessUnits: value }),
  setFilteredResponseTypes: (value) => set({ filteredResponseTypes: value }),
  setFilteredDeliverableDue: (value) => set({ filteredDeliverableDue: value }),
  setSelectedActionIds: (value) => set({ selectedActionIds: value }),
  setSelectedDeliverableIds: (value) => set({ selectedDeliverableIds: value }),
  setSelectedContributionIds: (value) => set({ selectedContributionIds: value }),

  quantities: {
    Actions: 0,
    Contributions: 0,
    Deliverables: 0,
  },

  setQuantities: (value) => set({ quantities: value }),

  activeActions: [],
  activeDeliverableIds: [],
  activeContributionIds: [],

  setActiveActions: (value) => set({ activeActions: value }),
  setActiveDeliverableIds: (value) => set({ activeDeliverableIds: value }),
  setActiveContributionIds: (value) => set({ activeContributionIds: value }),

  clearActiveItems: (updateFilters) => {
    set({
      activeActions: [],
      activeDeliverableIds: [],
      activeContributionIds: [],
    });

    const { allStrategies } = get();
    if (allStrategies.length > 0) {
      const localId = localStorage.getItem('localStrategyId');
      if (localId && allStrategies.map((x) => x.id).includes(localId)) {
        updateFilters('strategyId', localId);
      } else {
        updateFilters('strategyId', allStrategies[0].id);
      }
    }
  },

  getAllowEditStrategy: () => {
    const role = useGlobalSettingsStore.getState().globalSettings?.role;
    return !!role && allowEditStrategyRoles.includes(role);
  },
}));
