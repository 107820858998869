import { Checkbox, DialogContentText, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { useState } from 'react';
import { usePostData } from '@/helpers/hooks';
import { emptyGuid } from '@/helpers/utils';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';
import { useShallow } from 'zustand/react/shallow';
interface Props {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  contributionId: string;
  setReportTypeId?: (id: string) => void;
}

interface ReportType {
  name: string;
  order: number;
  allowOtherOrgs: boolean;
}

export default function NewReportTypeDialog({
  isOpen,
  setOpen,
  contributionId,
  setReportTypeId,
}: Props) {
  const [reportType, setReportType] = useState<ReportType>({
    name: '',
    order: 0,
    allowOtherOrgs: false,
  });
  const queryClient = useQueryClient();
  const addReportTypeMutation = usePostData<string, unknown, ReportType, unknown>({
    url: `/Strategy/ReportType?id=${contributionId}`,
  });

  const globalSettings = useGlobalSettingsStore(
    useShallow((state) => state.globalSettings),
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    addReportTypeMutation.mutate(reportType, {
      onSuccess: async (res) => {
        handleClose();
        if (contributionId === emptyGuid) setReportTypeId?.(res ?? '');
      },
      onError: () => {},
    });
  };
  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>New Report Type</DialogTitle>
        <DialogContent>
          <DialogContentText mb={1}>
            Please insert details of the new Report Type
          </DialogContentText>
          <TextField
            fullWidth
            value={reportType.name}
            onChange={(e) => setReportType({ ...reportType, name: e.target.value })}
            margin="dense"
            label="Name"
            id="Name"
          />
          {globalSettings.role === 'Global Admin' && (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    setReportType({ ...reportType, allowOtherOrgs: e.target.checked })
                  }
                  name={'AllowOtherOrgsReportType'}
                />
              }
              label="Allow other subscriptions to use this Report Type"
              sx={{
                '&.MuiFormControlLabel-labelPlacementEnd': {
                  marginBottom: '0!important',
                },
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
