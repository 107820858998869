import { useEffect } from 'react';

const useAddTakeTourButton = () => {
  useEffect(() => {
    const parentElement = document.querySelector('.breadcrumb-right-actions');
    if (parentElement) {
      const existingButton = parentElement.querySelector('.replay-onboarding-button');

      if (!existingButton) {
        const button = document.createElement('button');
        button.className = 'replay-onboarding-button border-0 mr-3';
        button.innerHTML =
          '<i class="fas fa-redo-alt mr-1" style="color: #ff0044"></i> Replay Onboarding';
        button.onclick = (e) => {
          e.preventDefault();

          const event = new Event('replayOnboarding');
          window.dispatchEvent(event);
        };
        parentElement.prepend(button);
      }
    }
  }, []);
};

export default useAddTakeTourButton;
