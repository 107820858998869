import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import { usePostData } from '@/helpers/hooks';

interface Props {
  handleForgotPWClick: (b: boolean) => void;
}

const ResetPasswordForm: React.FC<Props> = ({ handleForgotPWClick }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [resetEmailAddr, setResetEmailAddr] = useState<string>('');
  const [resetEmailErr, setResetEmailErr] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const resetPasswordMutation = usePostData<unknown, unknown, { email: string }, string>({
    url: `/Login/RequestResetPassword`,
    options: {
      onSuccess: () => {
        setIsSubmitted(true);
      },
      onError: () => {
        enqueueSnackbar('An error happened, please try again', {
          variant: 'error',
        });
      },
    },
  });
  const handleResetEmail = async () => {
    if (!resetEmailAddr) {
      setResetEmailErr('Email address is required');
      return;
    }
    await resetPasswordMutation.mutateAsync({ email: resetEmailAddr });
  };
  return (
    <>
      {!isSubmitted ? (
        <>
          {/*<Typography
            sx={{
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Forgot your password?
          </Typography>*/}
          <Typography>
            Enter the email address for your account so we can send you reset instructions
          </Typography>
          <TextField
            required
            size={'small'}
            fullWidth
            label="Enter email address"
            value={resetEmailAddr}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setResetEmailErr('');
              setResetEmailAddr(event.target.value);
            }}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: (theme) => theme.palette.backgroundBrown.dark,
                borderRadius: '8px',
                '& fieldset': {
                  border: '1px solid white',
                },
              },
              marginTop: 2,
              marginBottom: 2,
            }}
            error={!!resetEmailErr}
            helperText={resetEmailErr}
          ></TextField>
          <Button
            variant={'contained'}
            onClick={handleResetEmail}
            fullWidth
            sx={{ marginBottom: 2 }}
          >
            Send Reset Instructions
          </Button>
          <div className="flex items-center justify-center">
            <Typography>Back to</Typography>
            <Button
              sx={{ textTransform: 'none', padding: 0 }}
              onClick={() => handleForgotPWClick(false)}
            >
              Sign in
            </Button>
          </div>
        </>
      ) : (
        <>
          <Typography
            sx={{
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Forgot password
          </Typography>
          <Typography
            sx={{
              marginBottom: 2,
            }}
          >
            Create a new password for your weavr account by filling out the form below
          </Typography>
          <Divider></Divider>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.backgroundBrown.dark,
              borderRadius: '10px',
              padding: 1,
              marginY: 2,
            }}
          >
            <Typography
              sx={{
                color: 'success.main',
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              Thank you. if an account exists with this email address, you should receive
              an email to reset your password. Otherwise, please contact support.
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};
export default ResetPasswordForm;
