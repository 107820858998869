import React from 'react';
import ReactLoading from 'react-loading';
import ErrorComponent from '@/components/ErrorComponent';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallbackUI?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
  shouldReload: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      shouldReload: false,
    };
  }

  static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const shouldReload = this.shouldReloadOnError(error.message);
    if (shouldReload) {
      this.setState({ errorInfo, shouldReload: true });
      window.location.reload();
      return;
    }
    this.setState({ error, errorInfo, hasError: true });
  }

  shouldReloadOnError = (message: string) =>
    /Loading chunk \d+ failed/.test(message) ||
    /Failed to fetch dynamically imported module/.test(message);

  render() {
    if (this.state.shouldReload) {
      return (
        <div className="grid h-screen place-items-center text-3xl font-bold">
          <div>
            weavr is being updated!
            <div className="flex justify-center">
              <ReactLoading type="bubbles" height={150} width={150} color="#9C5738" />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.hasError) {
      return (
        <ErrorComponent
          fallbackUI={this.props.fallbackUI}
          error={this.state.error!}
          errorInfo={this.state.errorInfo}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
