import LinkOffIcon from '@mui/icons-material/LinkOff';
import SaveIcon from '@mui/icons-material/Save';
import { createFilterOptions, IconButton, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Import Grid v2
import { memo, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';

import HookFormAutoComplete from '@/components/HookFormAutoComplete';
import HookFormDatePicker from '@/components/HookFormDatePicker';
import HookFormInput from '@/components/HookFormInput';
import DeleteIconRed from '@/components/icons/DeleteIconRed';
import AddNewUserProvider from '@/contexts/AddNewUserProvider';
import { emptyGuid } from '@/helpers/utils';
import { AutoCompleteOption } from '@/pages/strategies/ContributionCardContent';
import NewBusinessUnitDialog from '@/pages/strategies/NewBusinessUnitDialog/NewBusinessUnitDialog';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import { DeliverableCardProp } from '@/pages/strategies/types/deliverable.types';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';

type Props = {
  onDeleteClick: () => void;
  handleLastActionClick: () => void;
  isLinked: boolean;
  handleUnlink: () => void;
} & Pick<
  DeliverableCardProp,
  'item' | 'dropdownOptions' | 'setAddingDeliverable' | 'disabled'
>;

const DeliverableCardContent = memo(
  function DeliverableCardContent({
    item,
    dropdownOptions,
    onDeleteClick,
    handleLastActionClick,
    isLinked,
    handleUnlink,
    disabled,
  }: Props) {
    const filter = createFilterOptions<AutoCompleteOption>();
    const [newBusinessUnitDialogOpen, setNewBusinessUnitDialogOpen] = useState(false);
    const newBusinessUnitRef = useRef('');
    const { allowLinkDeliverable, parentStrategies, selectableLinkedDeliverable } =
      useStrategyStore(
        useShallow((state) => ({
          allowLinkDeliverable: state.allowLinkDeliverable,
          parentStrategies: state.parentStrategies,
          selectableLinkedDeliverable: state.selectableLinkedDeliverable,
        })),
      );
    const globalSettings = useGlobalSettingsStore(
      useShallow((state) => state.globalSettings),
    );

    const allowEditStrategy = useStrategyStore(
      useShallow((state) => state.getAllowEditStrategy()),
    );

    const { watch } = useFormContext();
    const isGlobalAdmin = globalSettings.role === 'Global Admin';
    const { control } = useFormContext();
    const selectedBusinessUnits = useWatch({
      control,
      name: 'businessUnitIds',
    });

    const filteredBusinessUnits = useMemo(() => {
      if (!dropdownOptions?.businessUnits) return [];

      return dropdownOptions.businessUnits.filter(
        (bu) =>
          !bu.disabled ||
          (selectedBusinessUnits && selectedBusinessUnits.includes(bu.id)),
      );
    }, [dropdownOptions?.businessUnits, selectedBusinessUnits]);

    return (
      <>
        <hr />
        <Grid
          container
          spacing={1}
          sx={{
            pl: 2,
            pr: 2,
          }}
        >
          {/* Description */}
          <Grid size={12}>
            <HookFormInput
              onMouseDown={handleLastActionClick}
              name={'description'}
              label={'Description'}
              disabled={
                !allowEditStrategy ||
                disabled ||
                (item.hasChildren && item.answerableById !== item.strategyId)
              }
              multiline={true}
            />
          </Grid>
          {!isLinked && (
            <>
              {/* Expected End Date*/}
              <Grid
                size={12}
                sx={{
                  mt: 1,
                }}
              >
                <HookFormDatePicker
                  label={'Expected End Date'}
                  name={'targetEndDate'}
                  isMonthPicker={true}
                  openTo={'month'}
                  disabled={disabled}
                ></HookFormDatePicker>
              </Grid>

              {/* Business unit */}
              <Grid size={9} id="select-business-unit">
                <Tooltip
                  title={
                    item.isNew
                      ? 'Please save the deliverable first to add business units'
                      : ''
                  }
                  placement="top"
                >
                  <div>
                    {' '}
                    {/* Wrapper div needed for Tooltip to work with disabled elements */}
                    <HookFormAutoComplete
                      name={'businessUnitIds'}
                      label={'Business Units'}
                      options={filteredBusinessUnits}
                      disabled={!allowEditStrategy || item.isNew || disabled}
                      displayStatus={item.isNew}
                      disableClearable
                      multiple
                      allowDeleteDisabled
                      onTextFieldMouseDown={handleLastActionClick}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              watch('businessUnitIds')?.length === 0 ? 'red' : undefined,
                          },
                        },
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options as AutoCompleteOption[], params);

                        const { inputValue } = params;
                        const isExisting = options.some(
                          (option: any) =>
                            inputValue.toLowerCase() === option.title.toLowerCase(),
                        );
                        // Suggest the creation of a new value
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            id: '',
                            title: `Add (${inputValue}) as Business Unit`,
                            inputValue: 'AddNew',
                          });
                          newBusinessUnitRef.current = inputValue;
                        }
                        return filtered;
                      }}
                      onChangeInterceptor={(_, newValue) => {
                        const values = newValue as AutoCompleteOption[];
                        if (values.some((x) => x.inputValue === 'AddNew')) {
                          setNewBusinessUnitDialogOpen(true);
                          return;
                        } else return newValue;
                      }}
                    />
                  </div>
                </Tooltip>
              </Grid>
            </>
          )}

          {/* Grouped By*/}
          {allowLinkDeliverable &&
            (!!item.groupedById ||
              item.hasChildren ||
              item.isNew ||
              !!item.linkedDeliverableId) && (
              <>
                <Grid
                  size={6}
                  sx={{
                    mb: 1,
                  }}
                >
                  <HookFormAutoComplete
                    name={'groupedById'}
                    label={'Grouped By Deliverable'}
                    options={
                      selectableLinkedDeliverable?.filter((x) => x.id !== item.id) ?? []
                    }
                    disablePortal
                    onTextFieldMouseDown={handleLastActionClick}
                    disabled={item.hasChildren || !!item.linkedDeliverableId || disabled}
                  />
                </Grid>

                {((!!watch('groupedById') && watch('groupedById') !== emptyGuid) ||
                  !!item.linkedDeliverableId) && (
                  <Grid
                    size={6}
                    sx={{
                      mb: 1,
                    }}
                  >
                    <HookFormAutoComplete
                      name={'answerableById'}
                      label={'Answerable By'}
                      options={[
                        { id: item?.strategyId ?? '', title: 'This strategy' },
                        {
                          id: parentStrategies?.id,
                          title: `${parentStrategies?.name} (Main Strategy)`,
                        },
                      ]}
                      disablePortal
                      onTextFieldMouseDown={handleLastActionClick}
                      disableClearable
                      disabled={disabled}
                    />
                  </Grid>
                )}
              </>
            )}

          {/* Deliverable Indicator*/}
          {isGlobalAdmin &&
            !isLinked &&
            dropdownOptions?.deliverableIndicators !== undefined &&
            dropdownOptions?.deliverableIndicators.length > 0 && (
              <Grid
                size={9}
                sx={{
                  pb: 1,
                }}
              >
                <HookFormAutoComplete
                  name={'deliverableIndicatorId'}
                  label={'Deliverable Indicator'}
                  onTextFieldMouseDown={handleLastActionClick}
                  options={dropdownOptions?.deliverableIndicators}
                  displayStatus={item.isNew}
                  disabled={disabled}
                />
              </Grid>
            )}

          {/* Save / Delete */}
          {allowEditStrategy && !isLinked && (
            <>
              {item.isNew && (
                <Grid size={3} sx={{ position: 'relative' }}>
                  <Tooltip title="Save">
                    <IconButton
                      form="deliverable-form"
                      type="submit"
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        backgroundColor: '#2e7d32',
                        '&:hover': {
                          backgroundColor: '#1b5e20', // dark red on hover
                        },
                      }}
                      disabled={disabled}
                    >
                      <SaveIcon style={{ color: 'white' }}></SaveIcon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {!item.isNew && (
                <Grid size={3} sx={{ position: 'relative' }}>
                  <DeleteIconRed handleActionDelete={onDeleteClick} disabled={disabled} />
                </Grid>
              )}
            </>
          )}

          {/* Unlink */}
          {isLinked && (
            <Grid
              container
              size={12}
              sx={{
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip title="Unlink">
                <IconButton onClick={handleUnlink} disabled={disabled}>
                  <LinkOffIcon color={'error'}></LinkOffIcon>
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        {newBusinessUnitDialogOpen && (
          <AddNewUserProvider>
            <NewBusinessUnitDialog
              deliverableId={item.id}
              businessUnit={{
                name: '',
                type: 0,
                approvalLevels: 0,
                approvers: [],
                deactivateDate: null,
              }}
              initialName={newBusinessUnitRef.current}
              onClose={() => setNewBusinessUnitDialogOpen(false)}
            ></NewBusinessUnitDialog>
          </AddNewUserProvider>
        )}
      </>
    );
  },
  (prev, next) => prev.item === next.item && prev.disabled == next.disabled,
);

export default DeliverableCardContent;
