import * as Icons from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  styled,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import FirstNationIcon from '@/components/svgComponents/FirstNationIcon';
import HomosexualIcon from '@/components/svgComponents/HomosexualIcon';

// List of 40 Material-UI icons with their names
const iconList = [
  { icon: Icons.CorporateFare, name: 'Organisation' },
  { icon: Icons.Diversity1, name: 'Diversity' },
  { icon: Icons.Diversity3, name: 'Equity' },
  { icon: Icons.Diversity2, name: 'Inclusion' },
  { icon: FirstNationIcon, name: 'First Nation' },
  { icon: HomosexualIcon, name: 'Homosexual' },
  { icon: Icons.Female, name: 'Women' },
  { icon: Icons.Accessible, name: 'Disability' },
  { icon: Icons.BusinessCenter, name: 'Employment Plan' },
  { icon: Icons.Handshake, name: 'Procurement Plan' },
  { icon: Icons.AdsClick, name: 'Goal' },
  { icon: Icons.Balance, name: 'Balance' },
  { icon: Icons.Assessment, name: 'Assessment' },
  { icon: Icons.ConnectWithoutContact, name: 'Social' },
  { icon: Icons.EmojiObjects, name: 'Innovation' },
  { icon: Icons.Psychology, name: 'Brainstorm' },
];

// Predefined color options
const colorOptions = [
  // Greens
  '#606c38',
  '#6b705c',
  '#8a9a5b',
  '#9da890',
  '#a5a58d',

  '#2b5a55',
  '#569a8f',
  '#6a8eae',

  '#61481c',
  '#7f5539',
  '#8b4513',
  '#bc6c25',
  '#9c6644',
  '#c37856',
  '#b08968',
  '#cb997e',
  '#dda15e',
  '#ddb892',
  '#e6ccb2',
  '#baa892',
  '#b49c91',

  '#a47e3b',
  '#bf9742',
  '#e6b325',
  '#e9c46a',
  '#ffd74c',
  '#e9a600',

  '#7b2b3b',
];

// Custom styled Tooltip
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  zIndex: 1500,
});

export const getIconComponent = (iconName: string): React.ElementType => {
  const iconItem = iconList.find((item) => item.name === iconName);
  return iconItem?.icon || Icons.Add;
};

export default function IconColorPicker() {
  const { setValue, watch } = useFormContext();

  const selectedIconName = watch('iconName');
  const selectedColor = watch('iconColor');

  const [iconAnchorEl, setIconAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [colorAnchorEl, setColorAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIconAnchorEl(event.currentTarget);
  };

  const handleIconClose = () => {
    setIconAnchorEl(null);
  };

  const handleIconSelect = (name: string) => {
    setValue('iconName', name);
    handleIconClose();
  };

  const handleColorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setColorAnchorEl(event.currentTarget);
  };

  const handleColorClose = () => {
    setColorAnchorEl(null);
  };

  const handleColorSelect = (color: string) => {
    setValue('iconColor', color);
    handleColorClose();
  };

  const iconOpen = Boolean(iconAnchorEl);
  const colorOpen = Boolean(colorAnchorEl);
  const iconId = iconOpen ? 'icon-popover' : undefined;
  const colorId = colorOpen ? 'color-popover' : undefined;

  const SelectedIcon = getIconComponent(selectedIconName);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Button
        variant="contained"
        onClick={handleIconClick}
        aria-describedby={iconId}
        sx={{ minWidth: 40, height: 40, p: 1 }}
      >
        <SelectedIcon style={{ color: selectedColor }} />
      </Button>
      <Popover
        id={iconId}
        open={iconOpen}
        anchorEl={iconAnchorEl}
        onClose={handleIconClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, width: 240, height: 240, overflowY: 'auto' }}>
          <Grid container spacing={1}>
            {iconList.map(({ icon: Icon, name }, index) => (
              <Grid item key={index} xs={3}>
                <StyledTooltip title={name}>
                  <IconButton
                    onClick={() => handleIconSelect(name)}
                    sx={{ width: 40, height: 40 }}
                  >
                    <Icon />
                  </IconButton>
                </StyledTooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
      <Button
        variant="contained"
        onClick={handleColorClick}
        aria-describedby={colorId}
        sx={{
          bgcolor: selectedColor,
          '&:hover': { bgcolor: selectedColor },
          minWidth: 40,
          height: 40,
          p: 1,
        }}
      >
        <Icons.Palette />
      </Button>
      <Popover
        id={colorId}
        open={colorOpen}
        anchorEl={colorAnchorEl}
        onClose={handleColorClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, width: 200 }}>
          <Grid container spacing={1}>
            {colorOptions.map((color, index) => (
              <Grid item key={index} xs={3}>
                <StyledTooltip title={color}>
                  <IconButton
                    onClick={() => handleColorSelect(color)}
                    sx={{
                      bgcolor: color,
                      width: 40,
                      height: 40,
                      '&:hover': { bgcolor: color },
                      border: color === '#FFFFFF' ? '1px solid #000000' : 'none',
                    }}
                  />
                </StyledTooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
}
