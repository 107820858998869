import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ListIcon from '@mui/icons-material/List';
import PieChartIcon from '@mui/icons-material/PieChart';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { SvgIconProps } from '@mui/material';
import React, { memo } from 'react';

import BaseIcon from '@/pages/strategies/Icons/BaseIcon';

const RESPONSE_TYPES = [
  'Text',
  'Status',
  'MultipleTextResponses',
  'Percentage',
  'Currency',
  'MultipleChoice',
  'Number',
  'MultipleNumberResponses',
  'SingleChoice',
] as const;

interface ResponseTypeIconProps extends SvgIconProps {
  responseType: string;
}

const ResponseTypeIcon: React.FC<ResponseTypeIconProps> = memo(({ responseType }) => {
  let IconComponent: React.ElementType | undefined = undefined;
  switch (responseType) {
    case 'Text':
      IconComponent = TextFieldsIcon;
      break;
    case 'Status':
      IconComponent = CheckCircleIcon;
      break;
    case 'MultipleTextResponses':
      IconComponent = FormatListNumberedIcon;
      break;
    case 'Percentage':
      IconComponent = PieChartIcon;
      break;
    case 'Currency':
      IconComponent = AttachMoneyIcon;
      break;
    case 'MultipleChoice':
      IconComponent = ListIcon;
      break;
    case 'Number':
      IconComponent = Filter1Icon;
      break;
    case 'MultipleNumberResponses':
      IconComponent = Filter9PlusIcon;
      break;
    case 'SingleChoice':
      IconComponent = RadioButtonCheckedIcon;
      break;
    default:
      break;
  }
  return (
    <BaseIcon
      IconComponent={IconComponent}
      IconType={RESPONSE_TYPES as unknown as string[]}
      title={responseType}
    />
  );
});

export default ResponseTypeIcon;
