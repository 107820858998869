import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import { CircularProgress } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { format } from 'date-fns';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ZodTypeAny } from 'zod';

import { DatePicker } from '@/components/DatePicker';
import useDisplayFeedback from '@/components/hooks/useDisplayFeedback';
import { isFieldRequired } from '@/helpers/formUtils';

type DatePickerProps = React.ComponentProps<typeof DatePicker>;

type Props = {
  name: string;
  label: string;
  dataTestId?: string;
  schema?: ZodTypeAny;
  returnFormat?: string;
  disabled?: boolean;
} & DatePickerProps;

const HookFormDatePicker = ({
  name,
  label,
  dataTestId,
  schema,
  returnFormat,
  disabled = false,
  ...restProps
}: Props) => {
  const { control, formState } = useFormContext();
  const displayFeedback = useDisplayFeedback({ formState, name });
  const required = schema !== undefined && isFieldRequired(schema, name);

  const DateIcon = () => {
    return displayFeedback === 'submitting' ? (
      <div style={{ height: 20, paddingLeft: 5 }}>
        <CircularProgress size={20} />
      </div>
    ) : displayFeedback === 'success' ? (
      <CheckCircleIcon color="success" />
    ) : (
      <EventIcon />
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
        const displayValue = typeof value === 'string' ? new Date(value) : value;

        const handleChange = (date: Date | null) => {
          if (date && returnFormat) {
            onChange(format(date, returnFormat));
          } else {
            onChange(date);
          }
        };

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={displayValue}
              onChange={handleChange}
              label={label}
              slots={{ openPickerIcon: DateIcon }}
              slotProps={{
                textField: {
                  required: required,
                  size: 'small',
                  error: !!error,
                  helperText: error?.message,
                  inputRef: ref,
                  inputProps: { 'data-testid': dataTestId ?? name },
                  fullWidth: true,
                },
                layout: {
                  sx: {
                    button: {
                      '&:disabled': { color: 'lightgrey' },
                    },
                  },
                },
              }}
              name={name}
              disabled={disabled}
              {...restProps}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default memo(HookFormDatePicker);
