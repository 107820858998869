import React, { useEffect, useRef, useState } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { Mention } from 'react-mentions';
import Picker from '@emoji-mart/react';
import { SentimentSatisfiedAlt, Send } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import {
    defaultMentionStyle,
    MentionsInputStyled,
} from '@/components/css/hookFormMentionStyles';

type MentionItem = {
    id: string;
    display: string;
};

type HookFormMentionTextFieldProps<T extends FieldValues> = {
    name: string;
    mentionItems?: MentionItem[];
    placeholder?: string;
    disabled?: boolean;
    showPicker?: boolean;
    onSubmit?: () => void;
    nonRemovableMentions?: string[];
};

interface EmojiObject {
    native: string;
}

export default function MentionTextField<T extends FieldValues>({
    name,
    mentionItems = [],
    placeholder = 'Mention people using "@"',
    disabled = false,
    showPicker = false,
    onSubmit,
    nonRemovableMentions = [],
}: HookFormMentionTextFieldProps<T>) {
    const { control } = useFormContext();
    const [showEmojis, setShowEmojis] = useState(false);
    const [emojiData, setEmojiData] = useState<object | null>(null);
    const emojiPicker = useRef(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                emojiPicker.current &&
                !(emojiPicker.current as HTMLElement).contains(event.target as Node)
            ) {
                setShowEmojis(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [emojiPicker]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                const handleChange = (event: any, newValue: string, newPlainTextValue: string, mentions: any[]) => {
                    const nonRemovableMentionRegex = new RegExp(`@\\[(.*?)\\]\\((${nonRemovableMentions.join('|')})\\)`, 'g');
                    const currentNonRemovableMentions = [...value.matchAll(nonRemovableMentionRegex)];

                    let updatedValue = newValue;

                    currentNonRemovableMentions.forEach(match => {
                        const [fullMatch, display, id] = match;
                        if (!updatedValue.includes(fullMatch)) {
                            // If the non-removable mention is not in the new value, add it back
                            updatedValue = `${updatedValue} ${fullMatch}`.trim();
                        }
                    });

                    onChange(updatedValue);
                };

                const handleSelectEmoji = async (emoji: EmojiObject) => {
                    if (!emojiData) {
                        const data = await import('@emoji-mart/data');
                        setEmojiData(data.default);
                    }
                    setShowEmojis(false);
                    onChange((prev: string) => `${prev}${emoji.native}`);
                };

                return (
                    <div className="w-full" style={{ wordBreak: 'break-all' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                border: 'solid 1px grey',
                                borderRadius: 1,
                                paddingInline: '0.9rem',
                                zIndex: 1000,
                                minHeight: '35px',
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                <MentionsInputStyled
                                    value={value}
                                    className={'mentionInputClassName'}
                                    onChange={handleChange}
                                    placeholder={placeholder}
                                    disabled={disabled}
                                >
                                    <Mention style={defaultMentionStyle} trigger="@" data={mentionItems} />
                                </MentionsInputStyled>
                            </div>
                            {showPicker && (
                                <span className="position-relative">
                                    <IconButton
                                        type="button"
                                        className="border-0 bg-white"
                                        disabled={disabled}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setShowEmojis((prev) => !prev);
                                        }}
                                    >
                                        <SentimentSatisfiedAlt style={{ fontSize: 26, color: 'yellowgreen' }} />
                                    </IconButton>
                                    {showEmojis && (
                                        <span
                                            ref={emojiPicker}
                                            style={{
                                                position: 'absolute',
                                                bottom: 34,
                                                right: 0,
                                            }}
                                        >
                                            <Picker
                                                theme="light"
                                                data={emojiData}
                                                onEmojiSelect={(emoji: EmojiObject) => handleSelectEmoji(emoji)}
                                            />
                                        </span>
                                    )}
                                </span>
                            )}
                            {onSubmit && (
                                <IconButton onClick={onSubmit} disabled={disabled}>
                                    <Send style={{ fontSize: 20, color: 'blue' }} />
                                </IconButton>
                            )}
                        </Box>
                    </div>
                );
            }}
        />
    );
}