import { ChipProps, SvgIconProps } from '@mui/material';
import { z } from 'zod';

export const StatusZodType = z.enum([
  'On track',
  'Action required',
  'Achieved',
  'In progress',
  'Not Achieved',
  'Ongoing',
  'Not Started',
  'Not started',
  'Partially Met',
]);

export type StatusType = z.infer<typeof StatusZodType>;

export type AnswerStatus =
  | 'Not Due'
  | 'Not Achieved'
  | 'Achieved'
  | 'On track for completion'
  | 'Completed'
  | 'At risk'
  | 'Yes'
  | 'No';

export const STATUS_WEIGHTS: Record<StatusType, number> = {
  'On track': 0.7,
  'Action required': 0.2,
  Achieved: 1,
  'In progress': 0.5,
  'Not Achieved': 0,
  Ongoing: 0.5,
  'Not Started': 0,
  'Not started': 0,
  'Partially Met': 0.6,
};

export type StatusColor = SvgIconProps['color'] | ChipProps['color'] | `#${string}`;
