import {
  Box,
  BoxProps,
  Dialog,
  DialogContent,
  Typography,
  TypographyProps,
} from '@mui/material';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';
import React, { ComponentType, useEffect, useState, forwardRef } from 'react';

import ArtWork1 from '@/public/images/homepage/art-work.png';
import ArtWork2 from '@/public/images/homepage/bg-blog.png';
import NewsletterPopup from '@/pages/loginPage/NewsletterPopup';
import { useTheme } from '@mui/material/styles';

const AcknowledgementPopup: React.FC = () => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);

   useEffect(() => {
     const hasSeenPopup = Cookies.get('acknowledgementPopupClosed');
     if (hasSeenPopup) return;

     const timer = setTimeout(() => {
       setIsVisible(true);
     }, 2000);
     return () => clearTimeout(timer);
   }, []);

  const handleClose = () => {
    setIsVisible(false);
    Cookies.set('acknowledgementPopupClosed', 'true', { expires: 3650 });
  };

  const BoxComponent = React.forwardRef((props: BoxProps, ref) => (
    <Box {...props} ref={ref} />
  ));
  const TypographyComponent = forwardRef<HTMLDivElement, TypographyProps>(
    (props, ref) => <Typography ref={ref} {...props} />,
  );

  const MotionBox = React.useMemo(() => motion.create(BoxComponent), []);

  const MotionTypography = React.useMemo(() => motion.create(TypographyComponent), []);

  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      transitionDuration={1500}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '900px',
          height: '660px',
          borderRadius: '12px',
          m: 0,
        },
      }}
    >
      <DialogContent
        sx={{
          padding: 0,
          display: 'flex',
        }}
      >
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
          sx={{
            width: '40%',
            padding: 5,
            backgroundColor: theme.palette.backgroundBrown.dark,
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '40%',
              left: '-50%',
              width: '150%',
              height: '150%',
              backgroundImage: `url(${ArtWork1})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              opacity: 0.4,
              transform: 'rotate(-100deg)',
              zIndex: 0,
            },
          }}
        >
          <MotionTypography
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            sx={{
              fontWeight: 600,
              fontFamily: 'Poppins',
              fontSize: 24,
              color: theme.palette.primary.dark,
            }}
          >
            ACKNOWLEDGEMENT
          </MotionTypography>
          <MotionTypography
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            sx={{
              fontWeight: 400,
              my: 2,
              fontSize: 14,
            }}
          >
            weavr acknowledges the Traditional Custodians of Country throughout Australia
            and recognises their continuing connection to land, waters and Community. We
            pay our respect to Aboriginal and Torres Strait Islander Culture; and to
            Elders past and present. Sovereignty has never been ceded. It always was and
            always will be, Aboriginal land.
          </MotionTypography>
          <MotionTypography
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.9 }}
            sx={{
              fontWeight: 400,
              my: 2,
              fontSize: 14,
            }}
          >
            Aboriginal and Torres Strait Islander peoples should be aware that this
            website may contain images or names of people who have since passed away
          </MotionTypography>
        </MotionBox>
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 1 }}
          sx={{
            width: '60%',
            padding: 5,
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              left: 100,
              bottom: 200,
              width: '100%',
              backgroundImage: `url(${ArtWork2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              opacity: 0.4,
            },
          }}
        >
          <NewsletterPopup onClose={handleClose} />
        </MotionBox>
      </DialogContent>
    </Dialog>
  );
};

export default AcknowledgementPopup;
