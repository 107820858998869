import { useContext } from 'react';

import AddNewUserContext from '@/contexts/AddNewUserContext';

const useAddNewUserContext = () => {
  const context = useContext(AddNewUserContext);
  if (context === undefined) {
    throw new Error('useAddNewUser must be used within a AddNewUserProvider');
  }
  return context;
};

export default useAddNewUserContext;
