import React, { FC } from 'react';
import { Components } from 'react-markdown';

import { AnchorButton } from '@/pages/contentBuilder/customComponents/AnchorButton';
import { CenteredImage } from '@/pages/contentBuilder/customComponents/CenteredImage';
import { CenteredText } from '@/pages/contentBuilder/customComponents/CenteredText';
import {
  AnchorButtonProps,
  CenteredImageProps,
  CenteredTextProps,
  FittedTableProps,
  SubscribeButtonProps,
  ChipProps,
} from '@/pages/contentBuilder/customComponents/customComponentTypes';
import FittedTable from '@/pages/contentBuilder/customComponents/FittedTable';
import { SubscribeButton } from '@/pages/contentBuilder/customComponents/SubscribeButton';
import { Chip  } from '@/pages/contentBuilder/customComponents/Chip';

// Extend the Components type to include our custom components
interface ExtendedComponents extends Components {
  centertext: FC<CenteredTextProps>;
  centerimage: FC<CenteredImageProps>;
  fittedtable: FC<FittedTableProps>;
  anchorbutton: FC<AnchorButtonProps>;
  subscribebutton: FC<SubscribeButtonProps>;
  chip: FC<ChipProps>;
}

export const customComponents: ExtendedComponents = {
  a: ({ node, ...props }) => (
    <a
      {...props}
      className="text-blue-500 hover:underline"
      target="_blank"
      rel="noopener noreferrer"
      onMouseOver={(e) => {
        if (typeof props.onMouseOver === 'function') {
          props.onMouseOver(e);
        }
      }}
    />
  ),
  centertext: CenteredText,
  centerimage: CenteredImage,
  fittedtable: FittedTable,
  anchorbutton: AnchorButton,
  subscribebutton: SubscribeButton,
  chip: Chip,
  p: ({ node, children, ...props }) => {
    const childrenArray = React.Children.toArray(children);

    if (
      children !== null &&
      children !== undefined &&
      childrenArray.some(
        (x) =>
          React.isValidElement(x) &&
          x.key !== undefined &&
          (x.key?.includes('centeredimage') ||
            x.key?.includes('centeredtext') ||
            x.key?.includes('fittedtable') ||
            x.key?.includes('subscribebutton') ||
            x.key?.includes('anchorbutton') ||
            x.key?.includes('chip'))
      )
    ) {
      return <>{children}</>;
    } else {
      return <p>{children}</p>;
    }
  },
};
