import { createContext } from 'react';

import { RolesResponse } from '@/pages/globalAdmin/roles/Roles';
import { UserInfo } from '@/pages/globalAdmin/users/NewUserDialog';
import { Subscription } from '@/pages/globalAdmin/users/Users';

type AddNewUserProviderProps = {
  subscriptions: Subscription[] | undefined;
  roles: RolesResponse[] | undefined;
  user: UserInfo | undefined | null;
  setUser: (user: UserInfo | null | undefined) => void;
};

const AddNewUserContext = createContext<AddNewUserProviderProps | undefined>(undefined);

export default AddNewUserContext;
