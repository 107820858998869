import { Button } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { useNewsLetterStore } from '@/store/useNewsLetterStore';

import { SubscribeButtonProps } from './customComponentTypes';

export const SubscribeButton: React.FC<SubscribeButtonProps> = ({ children }) => {
  const toggleOpen = useNewsLetterStore(useShallow((state) => state.toggleOpen));
  if (!children) return null;
  return (
    <Button variant="contained" onClick={toggleOpen} style={{ marginBlock: '1em' }}>
      {children}
    </Button>
  );
};
