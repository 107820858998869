import { FormControl, MenuItem, Select } from '@mui/material';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import StatusIcon from '@/components/status/StatusIcon';
import { DropdownOptions } from '@/pages/strategies/types/Strategy.types';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  dropdownOptions: DropdownOptions;
  name: string;
  disabled?: boolean;
}

function FormHookStatusDropdown({ dropdownOptions, name, disabled }: Props) {
  const { control } = useFormContext();

  const allowEditStrategy = useStrategyStore(
    useShallow((state) => state.getAllowEditStrategy()),
  );

  return (
    <FormControl variant="standard" sx={{ minWidth: 'unset' }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const selectedStatus = dropdownOptions?.statuses?.find(
            (x) => x.id === field.value,
          );
          return (
            <Select
              {...field}
              disabled={!allowEditStrategy || disabled}
              disableUnderline={true}
              inputProps={{ IconComponent: () => null }}
              sx={{
                fontSize: '0.8125rem',
                marginLeft: 1,
                width: 120,
                height: 28,
                borderRadius: '30px',
                color: `${selectedStatus?.color}`,
                border: `1px solid ${selectedStatus?.color}`,
                textAlign: 'center',
                '.MuiInputBase-input': {
                  paddingRight: '0px !important',
                },
              }}
              renderValue={(selected) => (
                <>
                  {selectedStatus && selectedStatus.iconName && (
                    <StatusIcon statusName={selectedStatus.iconName} />
                  )}
                  <div style={{ marginLeft: 5, display: 'inline-block', marginTop: 2 }}>
                    {dropdownOptions?.statuses?.find((x) => x.id === selected)?.title}
                  </div>
                </>
              )}
            >
              {dropdownOptions?.statuses?.map((s) => (
                <MenuItem value={s.id} key={s.id}>
                  {s.title}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </FormControl>
  );
}

export default memo(FormHookStatusDropdown);
