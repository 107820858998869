import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ZodTypeAny } from 'zod';

import { isFieldRequired } from '@/helpers/formUtils';

type Props = SelectProps & {
  name: string;
  label: string;
  children: React.ReactNode;
  onChangeInterceptAsync?: (
    event: SelectChangeEvent<unknown>,
  ) => Promise<SelectChangeEvent<unknown> | undefined>;
  labelColor?: string;
  schema?: ZodTypeAny;
};

const HookFormSelect = ({
  name,
  label,
  children,
  onChangeInterceptAsync,
  labelColor = '',
  schema,
  ...restProps
}: Props) => {
  const labelId = `${name}-label`;
  const { control } = useFormContext();
  const required = schema !== undefined && isFieldRequired(schema, name);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth size={'small'} error={!!error}>
          <InputLabel
            id={labelId}
            sx={{
              color: labelColor,
              '&.Mui-focused': { color: labelColor },
            }}
          >
            {required && !restProps.disabled ? (
              <span>
                {label} <span style={{ color: 'red' }}>*</span>
              </span>
            ) : (
              label
            )}
          </InputLabel>
          <Select
            {...field}
            data-testid={name}
            label={
              required && !restProps.disabled ? (
                <span>
                  {label} <span style={{ color: 'red' }}>*</span>
                </span>
              ) : (
                label
              )
            }
            {...restProps}
            onChange={async (event) => {
              // Conditionally trigger customOnChange
              if (onChangeInterceptAsync) {
                field.onChange(await onChangeInterceptAsync(event));
              } else {
                field.onChange(event);
              }
            }}
            sx={{ fontSize: 15 }} // font size of input label
          >
            {children}
          </Select>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
export default memo(
  HookFormSelect,
  (prev, next) =>
    prev.label === next.label &&
    prev.name === next.name &&
    prev.value === next.value &&
    prev.value !== undefined &&
    next.value !== undefined,
);
