import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';

import { DropdownOption } from '@/components/dropdownOption.type';
import HookFormAutoComplete from '@/components/HookFormAutoComplete';
import ReactHookFormDatePicker from '@/components/HookFormDatePicker';
import ReactHookFormInput from '@/components/HookFormInput';
import MUIIconPicker from '@/components/icons/MUIIconPicker';
import { formatDateTimeToMonthString } from '@/helpers/dateUtils';
import { useDeleteData, usePostData, usePutData } from '@/helpers/hooks';
import { compareObjects, emptyGuid } from '@/helpers/utils';
import FormHookStatusDropdown from '@/pages/strategies/FormHookStatusDropdown';
import HeaderIcon from '@/pages/strategies/Icons/HeaderIcon';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import { DropdownOptions } from '@/pages/strategies/types/Strategy.types';
import {
  formSchema,
  Strategy,
  StrategyFormFields,
} from '@/pages/strategies/types/StrategyCard.types';
import { useConfirm } from '@/store/useConfirmDialogStore';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';
import { ResponseWithValue } from '@/types/responseTypes';

type EditStrategyDialogProps = {
  open: boolean;
  handleClose: () => void;
  item: Strategy;
  selectableParentStrategies: DropdownOption[] | undefined;
  setLocalStrategy: ((d: Strategy | null) => void) | undefined;
  dropdownOptions?: DropdownOptions | undefined;
  addedStrategy?: (id: string) => void;
};

const EditStrategyDialog = ({
  open,
  handleClose,
  item,
  selectableParentStrategies,
  setLocalStrategy,
  dropdownOptions,
  addedStrategy,
}: EditStrategyDialogProps) => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { allowLinkDeliverable } = useStrategyStore(
    useShallow((state) => ({
      allowLinkDeliverable: state.allowLinkDeliverable,
    })),
  );
  const deleteStrategyMutation = useDeleteData<unknown, unknown>(`/Strategy/Strategy`);
  const formRef = React.useRef<HTMLFormElement>(null);

  const globalSettings = useGlobalSettingsStore(
    useShallow((state) => state.globalSettings),
  );

  const allowEditStrategy = useStrategyStore(
    useShallow((state) => state.getAllowEditStrategy()),
  );

  const isGlobalAdmin = globalSettings?.role === 'Global Admin';

  const defaultValues = useMemo(
    () => ({
      startDate: item.startDate ? new Date(item.startDate) : new Date(),
      endDate: item.endDate ? new Date(item.endDate) : new Date(),
      name: item.name ?? '',
      statusId: item.statusId,
      parentStrategyId: item.parentStrategyId ?? emptyGuid,
      isNew: item.isNew ?? false,
      iconName: item.iconName,
      iconColor: item.iconColor,
    }),
    [item],
  );

  const methods = useForm<StrategyFormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });
  const { handleSubmit, formState, reset } = methods;

  const addStrategyMutation = usePostData<ResponseWithValue, unknown, Strategy, string>({
    url: `/Strategy/Strategy`,
  });

  const updateStrategyMutation = usePutData<
    Partial<Strategy>,
    unknown,
    Partial<Strategy>,
    unknown
  >(`/Strategy/Strategy?id=${item?.id}`);

  useEffect(() => {
    reset(defaultValues);
  }, [item, reset, defaultValues]);

  const onSubmit = async (data: StrategyFormFields) => {
    if (item.isNew) {
      const payload = {
        id: item.id,
        statusId: item.statusId,
        startDate: formatDateTimeToMonthString(data.startDate),
        endDate: formatDateTimeToMonthString(data.endDate),
        iconColor: data.iconColor ? data.iconColor : undefined,
        iconName: data.iconName ? data.iconName : undefined,
        name: data.name,
      } as Strategy;
      if (data.parentStrategyId !== emptyGuid)
        payload.parentStrategyId = data.parentStrategyId;
      await addStrategyMutation.mutateAsync(payload, {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries({ queryKey: ['strategyData'] });
          addedStrategy?.(res.value as any as string);
        },
      });
    } else {
      const dirtyFields = compareObjects(
        formState.defaultValues as StrategyFormFields,
        data,
      );
      if (!isEmpty(dirtyFields)) {
        const payload = { id: item.id, ...dirtyFields } as Partial<Strategy>;
        if (payload?.startDate)
          payload.startDate = formatDateTimeToMonthString(payload.startDate);
        if (payload?.endDate)
          payload.endDate = formatDateTimeToMonthString(payload.endDate);
        if (!!payload.iconName && !payload.iconColor) payload.iconColor = data.iconColor;
        if (!!payload.iconColor && !payload.iconName) payload.iconName = data.iconName;

        await updateStrategyMutation.mutateAsync(payload, {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: ['strategyData'],
            });
          },
        });
      }
    }
    handleClose();
  };

  const handleStrategyDelete = async () => {
    if (isGlobalAdmin) {
      const confirmed = await confirm({
        title: 'Delete Strategy (Global Admin)',
        message:
          'Do you wish to delete the Strategy? This will also delete all business units if <strong style="color:red">permanently delete</strong> is used and cannot be undone.',
        hasAltOption: true,
        altButtonText: 'Permanently Delete',
        confirmButtonText: 'Yes',
      });
      if (confirmed.confirmed) {
        const hardDelete = confirmed.comment === 'Permanently Delete';
        await deleteStrategyMutation.mutateAsync(
          `${item.id}${hardDelete ? '&hardDelete=true' : ''}`,
          {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: ['strategyData'] });
              setLocalStrategy?.(null);
            },
          },
        );
      }
    } else {
      confirm({
        title: 'Delete Strategy',
        message: 'Do you wish to delete the Strategy?',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result && item) {
          deleteStrategyMutation.mutate(item.id as string, {
            onSuccess: () => {
              setLocalStrategy?.(null);
            },
          });
        }
      });
    }
  };

  const showParentStrategy = allowLinkDeliverable && !item.hasChildren;

  return (
    <FormProvider {...methods}>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle className="flex items-center gap-2">
          <HeaderIcon name={'Strategies'} />
          {item.isNew ? 'New Strategy' : 'Edit Strategy'}
        </DialogTitle>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)} id="strategy-from">
          <DialogContent>
            <Box className="flex items-center">
              <ReactHookFormInput
                name={'name'}
                label={'Name'}
                disabled={!allowEditStrategy}
              />

              {dropdownOptions !== undefined && (
                <FormHookStatusDropdown
                  name={'statusId'}
                  dropdownOptions={dropdownOptions as DropdownOptions}
                />
              )}
            </Box>
            <Box className="mt-4 flex w-full">
              {allowLinkDeliverable && <MUIIconPicker />}
            </Box>
            <hr />
            <Box className="flex w-full gap-2">
              <Box>
                <ReactHookFormDatePicker
                  label={'Start Date'}
                  name={'startDate'}
                  isMonthPicker={true}
                  openTo={'month'}
                  dataTestId={'start date'}
                ></ReactHookFormDatePicker>
              </Box>
              <Box>
                <ReactHookFormDatePicker
                  label={'End Date'}
                  name={'endDate'}
                  isMonthPicker={true}
                  openTo={'month'}
                  dataTestId={'end date'}
                ></ReactHookFormDatePicker>
              </Box>
            </Box>
            {showParentStrategy && (
              <Box className="mt-4">
                <HookFormAutoComplete
                  label={'Linked to'}
                  name={'parentStrategyId'}
                  options={
                    selectableParentStrategies?.filter((x) => x.id !== item.id) || []
                  }
                ></HookFormAutoComplete>
              </Box>
            )}
          </DialogContent>
          <DialogActions className="!mx-4 !mb-4 flex">
            <Button color="error" onClick={handleStrategyDelete} className="!mr-auto">
              Delete
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
};

export default EditStrategyDialog;
