import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  ClickAwayListener,
  IconButton,
  Paper,
  styled,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { isAxiosError } from 'axios';
import { motion } from 'framer-motion';
import { debounce } from 'lodash';
import { SendIcon, ThumbsDown, ThumbsUp } from 'lucide-react';
import { Resizable, ResizableProps } from 're-resizable';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import remarkGfm from 'remark-gfm';
import { v4 as uuidv4 } from 'uuid';
import { useShallow } from 'zustand/react/shallow';

import ContactUsDialog from '@/components/loginPage/ContactUsDialog';
import { getStream } from '@/helpers/axiosHelper';
import { usePostData } from '@/helpers/hooks';
import { Chip } from '@/pages/contentBuilder/customComponents/Chip';
import { customComponents } from '@/pages/contentBuilder/MarkdownCustomComponents';
import StyledMarkdown from '@/pages/contentBuilder/StyledMarkdown';
import BujiBg from '@/public/images/homepage/login-bg.png';
import WeavrLogo from '@/public/images/weavr_logo.png';
import BunjiSVG from '@/public/svgs/bunji.svg?react';
import IconContactSVG from '@/public/svgs/icon_contact.svg?react';
import IconPlus from '@/public/svgs/icon_plus.svg?react';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';

type Message = {
  text: string;
  isUser: boolean;
  storedChatId?: string;
  isLiked?: boolean;
};

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: '#f5f5f5',
  backgroundImage: `linear-gradient(rgba(245, 245, 245, 0.8), rgba(245, 245, 245, 0.8)), url('/images/bunji/bunji-bg.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.up('sm')]: {
    height: '700px',
  },
}));

const MessagesContainer = styled(Box)({
  flexGrow: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  '& > *': {
    width: '100%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
});

const jiggleVariants = {
  initial: { scale: 0.1, opacity: 0 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: { duration: 2 },
  },
  jiggle: {
    rotate: [0, -5, 5, -5, 5, 0],
    transition: {
      delay: 2, // Start after the initial animation
      duration: 0.5,
      times: [0, 0.2, 0.4, 0.6, 0.8, 1],
      ease: 'easeInOut',
    },
  },
};

type BunjiButtonProps = {
  isOpen?: boolean;
} & ButtonBaseProps;

const StyledBunjiButton = styled(ButtonBase)<BunjiButtonProps>(({ theme }) => ({
  padding: '0!important',
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 0 25px rgba(255,255,0,0.5)',
  },
}));

const defaultLinks = `<chip label="Access and Complete your Progress Update" href="/knowledgebase/Access and Complete your Progress Update"></chip>
    <chip label="Set up SSO for your organisation (Admins only)" href="/knowledgebase/Set up SSO for your organisation (Admins only)"></chip>
    <chip label="Login using SSO" href="/knowledgebase/Login using SSO"></chip></div>`;

const Chatbot: React.FC = React.memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [currentAiMessage, setCurrentAiMessage] = useState('');
  const welcomeMessageSimulated = useRef(false);
  const simulationTimeoutRef = useRef<number | null>(null);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [size, setSize] = useState({ width: 516, height: 700 });
  const resizableRef = useRef<Resizable>(null);

  const { role } = useGlobalSettingsStore(useShallow((state) => state.globalSettings));

  const simulateWelcomeMessage = useCallback(() => {
    if (welcomeMessageSimulated.current) {
      return;
    }
    welcomeMessageSimulated.current = true;
    setIsStreaming(true); // Use isStreaming instead of isWelcomeStreaming

    let welcomeMessage = `<p>Wiyabu! Welcome! I'm here to help you with any questions you have about weavr. If you need assistance with anything, just let me know. Stay deadly!</p><p>If you want to see popular questions type <code>/help</code></p>`;

    if (role === 'Global Admin') {
      welcomeMessage += `
        <p>As a Global Admin, you can use these special commands:</p>
        <ul>
          <li><code>/addfeatured|question|answer</code> - Add a featured question</li>
          <li><code>/gettop5</code> - See top 5 most asked questions</li>
          <li><code>/getunanswered5</code> - See top 5 unanswered questions</li>
        </ul>
      `;
    }

    let charIndex = 0;
    let intervalId: number;

    const startSimulation = () => {
      intervalId = window.setInterval(() => {
        if (charIndex < welcomeMessage.length) {
          setCurrentAiMessage((_) => welcomeMessage.slice(0, charIndex + 1));
          charIndex++;
        } else {
          window.clearInterval(intervalId);
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: welcomeMessage + defaultLinks, isUser: false },
          ]);
          setCurrentAiMessage('');
          setIsStreaming(false);
        }
      }, 1);
    };

    // Delay the start of the simulation
    simulationTimeoutRef.current = window.setTimeout(startSimulation, 160);

    return () => {
      if (simulationTimeoutRef.current) {
        clearTimeout(simulationTimeoutRef.current);
      }
      clearInterval(intervalId);
      welcomeMessageSimulated.current = false;
      setIsStreaming(false); // Ensure isStreaming is set to false on cleanup
    };
  }, [role]);

  const startNewSession = useCallback(() => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
    setMessages([]);
    welcomeMessageSimulated.current = false;
    simulateWelcomeMessage();

    localStorage.setItem('chatSessionId', newSessionId);
  }, [simulateWelcomeMessage]);

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    // Check for existing session ID in local storage
    const existingSessionId = localStorage.getItem('chatSessionId');
    if (existingSessionId) {
      setSessionId(existingSessionId);
    } else {
      startNewSession();
    }
  }, [startNewSession]);

  useEffect(() => {
    let cleanup: (() => void) | undefined;

    if (isOpen && messages.length === 0 && !welcomeMessageSimulated.current) {
      cleanup = simulateWelcomeMessage();
    }

    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  }, [isOpen, messages.length, simulateWelcomeMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentAiMessage, scrollToBottom]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent, overrideInput?: string) => {
      e.preventDefault();
      const questionToAsk = overrideInput || input;
      if (!questionToAsk.trim() || isStreaming || !sessionId) return;

      setInput('');
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: questionToAsk, isUser: true },
      ]);

      let fullResponse = '';

      try {
        await getStream<string>(
          '/AiChat/AskQuestion',
          (chunk) => {
            fullResponse += chunk;
            setCurrentAiMessage((prevMessage) => prevMessage + chunk);
          },
          {
            data: { question: questionToAsk, sessionId: sessionId },
          },
        );

        // Extract storedChatId from the response
        const match = fullResponse.match(/\[STORED_CHAT_ID:([^\]]+)\]/);
        let storedChatId: string | undefined;
        if (match) {
          storedChatId = match[1];
          fullResponse = fullResponse.replace(match[0], '');
        }

        // Add the complete AI message to the messages array
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: fullResponse, isUser: false, storedChatId },
        ]);
      } catch (error) {
        console.error('Error fetching response:', error);
        let errorMessage = 'Sorry, an error occurred. Please try again.';

        if (isAxiosError(error)) {
          errorMessage = (error.response?.data as string) || errorMessage;
        }

        setMessages((prevMessages) => [
          ...prevMessages,
          { text: errorMessage, isUser: false },
        ]);
      } finally {
        setCurrentAiMessage('');
        setIsStreaming(false);
      }
    },
    [input, isStreaming, sessionId],
  );

  const { mutateAsync: updateFeedback } = usePostData<
    { isLiked: boolean },
    unknown,
    { storedChatId: string; isLiked: boolean | null; feedbackChanged: boolean }
  >({
    url: '/AiChat/UpdateFeedback',
  });

  const handleFeedback = useCallback(
    async (messageIndex: number, newIsLiked: boolean | null) => {
      const message = messages[messageIndex];
      if (!message.storedChatId) return;

      const feedbackChanged = message.isLiked !== newIsLiked;

      try {
        await updateFeedback(
          {
            storedChatId: message.storedChatId,
            isLiked: newIsLiked,
            feedbackChanged,
          },
          {
            onSuccess: () => {
              setMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                newMessages[messageIndex] = {
                  ...newMessages[messageIndex],
                  isLiked: newIsLiked ?? false,
                };
                return newMessages;
              });
            },
          },
        );
      } catch (error) {
        console.error('Error sending feedback:', error);
      }
    },
    [messages, updateFeedback],
  );

  const handleChipClick = useCallback(
    (question: string) => {
      const syntheticEvent = { preventDefault: () => void 0 } as React.FormEvent;
      handleSubmit(syntheticEvent, question);
    },
    [handleSubmit],
  );

  const renderMessage = useCallback(
    (message: Message, index: number) => {
      const feedbackSection = !message.isUser && message.storedChatId && (
        <Box mt={1} display="flex" justifyContent="flex-end">
          <IconButton
            onClick={() => handleFeedback(index, message.isLiked === true ? null : true)}
            color={message.isLiked === true ? 'success' : 'default'}
            disabled={message.isLiked === true}
            sx={{
              '&.Mui-disabled': {
                color: 'success.main',
              },
            }}
          >
            <ThumbsUp size={16} />
          </IconButton>
          <IconButton
            onClick={() =>
              handleFeedback(index, message.isLiked === false ? null : false)
            }
            disabled={message.isLiked === false}
            color={message.isLiked === false ? 'error' : 'default'}
            sx={{
              '&.Mui-disabled': {
                color: 'error.main',
              },
            }}
          >
            <ThumbsDown size={16} />
          </IconButton>
        </Box>
      );

      const renderContent = (content: React.ReactNode): React.ReactNode => {
        if (typeof content === 'string') {
          return (
            <StyledMarkdown>
              <Markdown
                components={{
                  ...customComponents,
                  p: ({ children }) => (
                    <Typography component="p" paragraph>
                      {React.Children.map(children, (child) => {
                        if (typeof child === 'string') {
                          return child
                            .split(/(\[SUGGESTED_QUESTION\].*?\[\/SUGGESTED_QUESTION\])/)
                            .map((part, i) => {
                              if (
                                part.startsWith('[SUGGESTED_QUESTION]') &&
                                part.endsWith('[/SUGGESTED_QUESTION]')
                              ) {
                                const question = part.replace(
                                  /^\[SUGGESTED_QUESTION\]|\[\/SUGGESTED_QUESTION\]$/g,
                                  '',
                                );
                                return (
                                  <Box
                                    component="span"
                                    display="inline-block"
                                    mb={1}
                                    key={`${index}-${i}-${question}`}
                                  >
                                    <Chip
                                      label={question}
                                      onClick={() => handleChipClick(question)}
                                    />
                                  </Box>
                                );
                              }
                              return (
                                <Typography component="span" key={`${index}-${i}`}>
                                  {part}
                                </Typography>
                              );
                            });
                        }
                        return child;
                      })}
                    </Typography>
                  ),
                  code: ({ children }) => (
                    <Chip
                      label={children as string}
                      onClick={() => handleChipClick(children as string)}
                    />
                  ),
                }}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeSlug, rehypeRaw]}
              >
                {content}
              </Markdown>
            </StyledMarkdown>
          );
        }
        return content;
      };

      return (
        <Paper
          key={index}
          elevation={1}
          sx={{
            p: 2,
            mb: 2,
            maxWidth: '80%',
            borderRadius: 2,
            alignSelf: message.isUser ? 'flex-end' : 'flex-start',
            backgroundColor: message.isUser ? '#e0f2f1' : '#f5f5f5',
          }}
        >
          {renderContent(message.text)}
          {feedbackSection}
        </Paper>
      );
    },
    [handleFeedback, handleChipClick],
  );

  const chatbotContent = useMemo(
    () => (
      <ChatContainer>
        <Box
          className="!bg-brown-dark p-6"
          sx={{
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${BujiBg})`,
              backgroundSize: '200% 200%',
              backgroundPosition: 'right',
              backgroundRepeat: 'no-repeat',
              zIndex: 0,
              opacity: 0.15,
            },
            backgroundColor: 'var(--brown-dark)',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Box
                component="img"
                src={WeavrLogo}
                alt="Bunji"
                className="mb-1 aspect-auto w-10 rounded-full bg-white"
              />
              <Typography variant="h6" className="!text-lg font-bold !text-white">
                👋 Hi there
              </Typography>
              <Typography className="!text-lg !text-white">
                Welcome to weavr support
              </Typography>
            </Box>
            <Box display="flex" gap={2} flexDirection="column" alignItems="center">
              <Box display="flex" gap={0.2}>
                <Tooltip title="Ask a new question">
                  <IconButton
                    className="rounded-none"
                    onClick={() => startNewSession()}
                    aria-label="Ask a new question"
                    sx={{ width: 'fit-content' }}
                    disabled={isStreaming} // Use only isStreaming
                  >
                    <IconPlus />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Contact us">
                  <IconButton
                    className="rounded-none"
                    onClick={() => setIsContactFormOpen(true)}
                    aria-label="Ask a new question"
                    sx={{ width: 'fit-content' }}
                  >
                    <IconContactSVG />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className={`flex-grow overflow-hidden p-4`}
          sx={{
            backgroundImage: `
              linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), 
              url(${BujiBg}),
              url(${BujiBg})
            `,
            backgroundSize: '180%, 190%',
            backgroundPosition: 'left top, -10% 140%',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Box marginTop={3} display="flex" flexDirection="column" height="100%">
            <MessagesContainer>
              {messages.map((message, index) => renderMessage(message, index))}
              {/* Current AI message */}
              {currentAiMessage && (
                <Paper
                  elevation={1}
                  sx={{
                    p: 2,
                    mb: 2,
                    maxWidth: '100%',
                    borderRadius: 2,
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  <StyledMarkdown>
                    <Markdown
                      components={customComponents}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeSlug, rehypeRaw]}
                    >
                      {currentAiMessage}
                    </Markdown>
                  </StyledMarkdown>
                </Paper>
              )}
              <div ref={messagesEndRef} />
            </MessagesContainer>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message..."
                variant="outlined"
                margin="normal"
                autoComplete="off"
                slotProps={{
                  input: {
                    endAdornment: (
                      <IconButton
                        type="submit"
                        color="primary"
                        disabled={!input.trim() || isStreaming}
                      >
                        <SendIcon />
                      </IconButton>
                    ),
                  },
                }}
              />
            </form>
          </Box>
        </Box>
      </ChatContainer>
    ),
    [
      messages,
      currentAiMessage,
      input,
      isStreaming,
      handleSubmit,
      renderMessage,
      startNewSession,
    ],
  );

  const handleResize = useCallback(
    (
      e: MouseEvent | TouchEvent,
      direction: string,
      ref: HTMLElement,
      d: { width: number; height: number },
    ) => {
      const debouncedResize = debounce(() => {
        setSize((prevSize) => ({
          width: prevSize.width + d.width,
          height: prevSize.height + d.height,
        }));
      }, 5);

      debouncedResize();
    },
    [],
  );

  const resizableProps: ResizableProps = useMemo(
    () => ({
      size: size,
      minWidth: 516,
      minHeight: 700,
      maxWidth: '100%',
      maxHeight: '100%',
      enable: {
        top: false,
        right: true,
        bottom: true,
        left: false,
        topRight: true,
        bottomRight: true,
        bottomLeft: false,
        topLeft: false,
      },
      onResize: handleResize,
      style: {
        position: 'fixed',
        bottom: 120,
        left: 32,
        zIndex: 1000,
        transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
        transform: isOpen ? 'scale(1)' : 'scale(0)',
        opacity: isOpen ? 1 : 0,
      },
    }),
    [size, isOpen, handleResize],
  );

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div>
        <motion.div
          className="fixed bottom-9 left-8"
          variants={jiggleVariants}
          initial="initial"
          animate={['animate', 'jiggle']}
        >
          <StyledBunjiButton
            onClick={() => setIsOpen(!isOpen)}
            sx={{
              boxShadow: isOpen
                ? '0 0 25px rgba(255,255,0,0.5)'
                : '0 0 10px rgba(255,255,255,0.5)',
            }}
          >
            <BunjiSVG />
          </StyledBunjiButton>
        </motion.div>

        {!isMobile && (
          <Resizable {...resizableProps} ref={resizableRef}>
            <Paper
              elevation={8}
              className="flex h-full w-full flex-col overflow-hidden rounded-lg"
            >
              {chatbotContent}
            </Paper>
          </Resizable>
        )}
        {isContactFormOpen && (
          <ContactUsDialog
            open={isContactFormOpen}
            setOpen={setIsContactFormOpen}
            defaultSelect="General Enquiry"
          />
        )}
      </div>
    </ClickAwayListener>
  );
});

export default Chatbot;
