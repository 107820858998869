import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import StatusIcon from '@/components/status/StatusIcon';
import { Deliverable } from '@/pages/strategies/types/deliverable.types';
import { DropdownOptions } from '@/pages/strategies/types/Strategy.types';
import { Strategy } from '@/pages/strategies/types/StrategyCard.types';

interface Props {
  item: Deliverable | Strategy;
  dropdownOptions: DropdownOptions;
  onChange: (event: SelectChangeEvent) => void;
  dataTestId: string;
  onMouseDown?: () => void;
  disabled?: boolean;
}

const StatusDropdown = ({
  item,
  dropdownOptions,
  onChange,
  dataTestId,
  onMouseDown,
  disabled,
}: Props) => {
  const status = dropdownOptions?.statuses?.find((x) => x.id === item.statusId);

  return (
    <FormControl variant="standard" sx={{ marginLeft: 'auto', minWidth: 'unset' }}>
      <Select
        disabled={disabled}
        value={item.statusId}
        disableUnderline={true}
        inputProps={{ IconComponent: () => null }}
        sx={{
          fontSize: '0.8125rem',
          marginLeft: 1,
          width: 120,
          height: 24,
          borderRadius: '30px',
          color: `${status?.color}`,
          border: `1px solid ${status?.color}`,
          textAlign: 'center',
          '.MuiInputBase-input': {
            paddingRight: '0px !important',
          },
        }}
        data-testid={dataTestId}
        onChange={onChange}
        onMouseDown={onMouseDown}
        renderValue={(selected) => (
          <>
            {status && status.iconName && <StatusIcon statusName={status.iconName} />}
            <div
              style={{
                marginLeft: 5,
                display: 'inline-block',
                marginTop: 2,
              }}
            >
              {dropdownOptions?.statuses?.find((x) => x.id === selected)?.title}
            </div>
          </>
        )}
      >
        {dropdownOptions &&
          dropdownOptions?.statuses?.map((s) => (
            <MenuItem value={s.id} key={s.id}>
              {s.title}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default StatusDropdown;
