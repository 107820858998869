import { zodResolver } from '@hookform/resolvers/zod';
import HelpIcon from '@mui/icons-material/Help';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    DialogContentText,
    Grid,
    IconButton,
    MenuItem,
    Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import HookFormAutoComplete from '@/components/HookFormAutoComplete';
import HookFormDatePicker from '@/components/HookFormDatePicker';
import HookFormSelect from '@/components/HookFormSelect';

import {
    checkPeriodConflictDates,
    formatDateTimeToMonthString,
    getAvailableToDate,
    getDatesForPeriod,
    isEndDateMatchPeriod,
} from '@/helpers/dateUtils';
import { usePostData } from '@/helpers/hooks';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import {
    ContributionPeriod,
    Periodicity,
} from '@/pages/strategies/types/Contribution.types';
import { useConfirm } from '@/store/useConfirmDialogStore';
import { ResponseRequireConfirmation } from '@/types/responseTypes';
import { useShallow } from 'zustand/react/shallow';
import { emptyGuid } from '../../helpers/utils';

interface Props {
    isOpen: boolean;
    setOpen: (open: boolean) => void;
    contributionPeriod: ContributionPeriod;
    businessUnits?: string[];
    allPeriods: ContributionPeriod[];
}



const PeriodUpdateDialog = ({ isOpen, setOpen, contributionPeriod, businessUnits, allPeriods }: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { strategyEnd, strategyStart, deliverableExpectedDate, allBusinessUnits } =
        useStrategyStore(useShallow((state) => ({
            strategyEnd: state.strategyEnd,
            strategyStart: state.strategyStart,
            deliverableExpectedDate: state.deliverableExpectedDate,
            allBusinessUnits: state.dropdownOptions?.businessUnits,
        })));
    const queryClient = useQueryClient();
    const confirm = useConfirm();

    const [payload, setPayload] = useState<Partial<ContributionPeriod>>({});

    const addPeriodMutation = usePostData<
        ResponseRequireConfirmation,
        unknown,
        Partial<ContributionPeriod>,
        unknown
    >({
        url: `/Period/AddPeriod`,
        options: {
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: ['strategyData'],
                });
                setOpen(false);
            },
        },
    });




    const updatePeriodMutation = usePostData<
        ResponseRequireConfirmation,
        unknown,
        Partial<ContributionPeriod>,
        unknown
    >({
        url: `/Period/UpdatePeriod`,
        options: {
            onSuccess: async (res) => {
                if (res.requireConfirmation) {
                    if (res.message?.includes('are already Approved')) {
                        const msg = res.message
                            .replace('20', '\n20')
                            .replace('By adding', '\n By adding');
                        const confirmed = await confirm({ title: 'Are you sure?', message: msg });
                        if (confirmed) {
                            const confirmedPayload = {
                                userConfirmed: true,
                                confirmApproved: true,
                                ...payload,
                            };
                            await updatePeriodMutation.mutateAsync(confirmedPayload, {
                                onSuccess: async () => {
                                    await queryClient.invalidateQueries({
                                        queryKey: ['strategyData'],
                                    });
                                    setOpen(false);
                                },
                            });
                        }
                    } else {
                        const confirmed = await confirm({
                            title: 'Confirm',
                            message: res.message!,
                        });

                        if (confirmed) {
                            const confirmedPayload = { userConfirmed: true, ...payload };
                            await updatePeriodMutation.mutateAsync(confirmedPayload);
                            setOpen(false);
                        }
                    }
                } else {
                    await queryClient.invalidateQueries({
                        queryKey: ['strategyData'],
                    });
                    setOpen(false);
                }
            },
        },
    });

    const formSchema = z
        .object({
            fromDate: z
                .date()
                .min(
                    dayjs(strategyStart).startOf('month').toDate(),
                    'From date cannot be earlier than Strategy start date',
                )
                .max(
                    dayjs(strategyEnd).endOf('month').toDate(),
                    'From date cannot be after than Strategy end date',
                ),
            toDate: z.date().optional(),
            frequency: z.nativeEnum(Periodicity),
            overrideBUIds: z.array(z.string().uuid()).optional(),
        })
        .superRefine((data, ctx) => {
            if (data.frequency !== Periodicity.Once) {
                if (dayjs(data.toDate).isAfter(strategyEnd, 'month')) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'To date cannot be after than Strategy end date',
                        path: ['toDate'],
                    });
                }

                if (dayjs(data.fromDate).isAfter(data.toDate, 'month')) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'To date cannot be earlier than from date',
                        path: ['toDate'],
                    });
                }
                if (dayjs(data.fromDate).isSame(data.toDate, 'month')) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'To date cannot be the same as from date',
                        path: ['toDate'],
                    });
                }

                if (!isEndDateMatchPeriod(data.fromDate, data.toDate!, data.frequency)) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,

                        message: 'To date mismatch with From date and Frequency.',
                        path: ['toDate'],
                    });
                }
            }
            // Check to date at backend
            // } else {
            //   // For Once periodicity, ensure toDate is the same as fromDate
            //   if (!dayjs(data.toDate).isSame(data.fromDate, 'month')) {
            //     ctx.addIssue({
            //       code: z.ZodIssueCode.custom,
            //       message: 'For Once frequency, To date must be the same as From date',
            //       path: ['toDate'],
            //     });
            //   }
            // }

            return z.NEVER;
        });

    const memoizedDefaultValue = useMemo(() => {
        return {
            frequency: contributionPeriod.periodicity,
            fromDate: contributionPeriod.fromDate
                ? new Date(contributionPeriod.fromDate)
                : new Date(),
            toDate: contributionPeriod.toDate
                ? new Date(contributionPeriod.toDate)
                : new Date(),
            overrideBUIds: contributionPeriod.overrideBUIds || [],
        };
    }, [contributionPeriod]);

    type FormFields = z.infer<typeof formSchema>;
    const methods = useForm<FormFields>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: zodResolver(formSchema),
        defaultValues: memoizedDefaultValue,
    });

    useEffect(() => {
        methods.reset(memoizedDefaultValue);
    }, [memoizedDefaultValue, methods]);

    const handleClose = () => {
        setOpen(false);
    };
    const onSubmit = async (data: FormFields) => {
        const conflicts = checkPeriodConflictDates(contributionPeriod.id, allPeriods, data.fromDate, data.frequency, data.toDate);

        if (conflicts) {
            enqueueSnackbar('The specified time frame conflicts with existing periods.', { variant: 'error' });
            return;
        }

        const payload = {
            contributionId: contributionPeriod.contributionId,
            id: contributionPeriod.id,
            periodicity: data.frequency,
            fromDate: formatDateTimeToMonthString(fromDate),
            toDate: toDate ? formatDateTimeToMonthString(toDate) : '',
            overrideBUIds: data.overrideBUIds,
        };
        setPayload(payload);
        try {
            if (contributionPeriod.id && contributionPeriod.id !== emptyGuid) {
                await updatePeriodMutation.mutateAsync(payload);
            } else {
                await addPeriodMutation.mutateAsync(payload);
            }
        } catch (error) {
            enqueueSnackbar('An error occurred while updating the period.', { variant: 'error' });
        }
    };

    const periodicity = methods.watch('frequency');
    const fromDate = methods.watch('fromDate');
    const toDate = methods.watch('toDate');
    const availablePeriodDates = getAvailableToDate(fromDate, strategyEnd!, periodicity);

    const filteredBusinessUnits = useMemo(() => {
        return allBusinessUnits?.filter(bu =>
            !(businessUnits ?? []).includes(bu.id)
        ) || [];
    }, [allBusinessUnits, businessUnits]);

    console.log(allBusinessUnits);
    console.log(businessUnits);
    return (
        <>
            <Dialog open={isOpen} onClose={handleClose} fullWidth>
                <FormProvider {...methods}>
                    <DialogTitle fontSize={16} sx={{ paddingBottom: 0 }}>
                        {contributionPeriod.isNew ? 'New' : 'Update'} Contribution Period
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please insert details your contribution period
                        </DialogContentText>
                        <DialogContentText mb={2}>
                            <span
                                style={{
                                    color: dayjs(toDate).isAfter(dayjs(deliverableExpectedDate), 'm')
                                        ? '#ff9800'
                                        : '',
                                }}
                            >
                                {'Deliverable is expected to be completed before ' +
                                    dayjs(deliverableExpectedDate).format('MM/YYYY')}
                            </span>
                        </DialogContentText>

                        <form onSubmit={methods.handleSubmit(onSubmit)} id="period-update-form">
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        pb: 2,
                                    }}
                                >
                                    <HookFormSelect label={'Frequency'} name={'frequency'}>
                                        <MenuItem value={Periodicity.Monthly}>Monthly</MenuItem>
                                        <MenuItem value={Periodicity.Quarterly}>Quarterly</MenuItem>
                                        <MenuItem value={Periodicity.TwiceAYear}>Twice a year</MenuItem>
                                        <MenuItem value={Periodicity.Annual}>Annual</MenuItem>
                                        <MenuItem value={Periodicity.Once}>Once</MenuItem>
                                    </HookFormSelect>
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    sx={{
                                        pb: 2,
                                        pr: 1,
                                    }}
                                >
                                    <HookFormDatePicker
                                        label={periodicity === Periodicity.Once ? 'Date' : 'From'}
                                        name={'fromDate'}
                                        isMonthPicker={true}
                                        minDate={strategyStart}
                                    ></HookFormDatePicker>
                                </Grid>
                                {periodicity !== Periodicity.Once && (
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{
                                            pb: 2,
                                        }}
                                    >
                                        <HookFormDatePicker
                                            label={'To'}
                                            name={'toDate'}
                                            isMonthPicker={true}
                                            shouldDisableMonth={(month) => {
                                                return !availablePeriodDates.some((d) =>
                                                    dayjs(d).isSame(month as Date, 'month'),
                                                );
                                            }}
                                            shouldDisableYear={(year) => {
                                                return !availablePeriodDates.some((d) =>
                                                    dayjs(d).isSame(year as Date, 'year'),
                                                );
                                            }}
                                        ></HookFormDatePicker>
                                    </Grid>
                                )}
                                <Grid item xs={12} container>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    flexGrow: 1,
                                                }}
                                            >
                                                <HookFormAutoComplete
                                                    multiple
                                                    name={'overrideBUIds'}
                                                    label={'Override Business Units'}
                                                    options={filteredBusinessUnits}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    ml: 1,
                                                }}
                                            >
                                                <Tooltip title="Selecting any option here will override the Business Unit chosen from the deliverable (optional)">
                                                    <IconButton>
                                                        <HelpIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions sx={{ padding: 3, paddingTop: 0 }}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <LoadingButton
                            form="period-update-form"
                            type="submit"
                            variant="contained"
                            loading={methods.formState.isSubmitting}
                        >
                            Save
                        </LoadingButton>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </>
    );
};
PeriodUpdateDialog.displayName = 'PeriodUpdateDialog';

export default PeriodUpdateDialog;
