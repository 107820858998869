import { zodResolver } from '@hookform/resolvers/zod';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContentText, IconButton, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid2';
import { SelectChangeEvent } from '@mui/material/Select';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';

import { DropdownOption } from '@/components/dropdownOption.type';
import HookFormAutoComplete from '@/components/HookFormAutoComplete';
import HookFormSelect from '@/components/HookFormSelect';
import LoadingCircular from '@/components/LoadingCircular';
import useAddNewUserContext from '@/contexts/useAddNewUserContext';
import { useFetchData, usePostData, usePutData } from '@/helpers/hooks';
import { compareObjects } from '@/helpers/utils';
import NewGroupDialog, { Group } from '@/pages/admin/NewGroupDialog';
import NewUserDialog, { UserInfo } from '@/pages/globalAdmin/users/NewUserDialog';
import { useConfirm } from '@/store/useConfirmDialogStore';

import HookFormDatePicker from '../../../components/HookFormDatePicker';
import HookFormInput from '../../../components/HookFormInput';
import HooksGoogleMaps from '../../../components/HooksGoogleMaps';
import { AutoCompleteOption } from '../ContributionCardContent';

type Props = {
  deliverableId?: string;
  groups?: Group[];
  businessUnit: BusinessUnit;
  onClose: () => void;
  initialName?: string;
};

type Approvers = Record<number, string[]>;

type BusinessUnitApprover = {
  userId: string;
  level: number;
  displayName: string;
  isActive: boolean;
};

export type BusinessUnit = {
  id?: string;
  name: string;
  groupId?: string;
  type: 0 | 1;
  location?: string;
  approvalLevels: number;
  approvers: BusinessUnitApprover[];
  deactivateDate?: string | null;
};

export type UserData = {
  displayName: string;
  id: string;
};

export default function NewBusinessUnitDialog({
  onClose,
  deliverableId,
  groups,
  businessUnit: businessUnitFromProps,
  initialName,
}: Props) {
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const isNew = !businessUnitFromProps.id;
  const [businessUnit, setBusinessUnit] = useState<BusinessUnit>({
    ...businessUnitFromProps,
    deactivateDate: businessUnitFromProps.deactivateDate ?? null,
  });

  const { data: userData } = useFetchData<UserData[]>(['users'], '/user/GetUsers', {
    refetchOnWindowFocus: false,
  });

  const { data: groupDataFromQuery } = useFetchData<Group[]>(
    ['groups'],
    '/Group/GetGroups',
    {
      refetchOnWindowFocus: false,
    },
  );

  const groupData = groups ? groups : groupDataFromQuery;

  const [isAddingGroup, setIsAddingGroup] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setUser } = useAddNewUserContext();

  const handleClose = () => {
    onClose();
  };

  const addBusinessUnitMutation = usePostData<
    BusinessUnit,
    unknown,
    BusinessUnit,
    unknown
  >({
    url: `/BusinessUnit/BusinessUnit${deliverableId ? '?deliverableId=' + deliverableId : ''}`,
    options: {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: ['businessUnits'] }),
          queryClient.invalidateQueries({ queryKey: ['DropdownOptions'] }),
          queryClient.invalidateQueries({ queryKey: ['strategyData'] }),
        ]);
        handleClose();
      },
      onError: () => {
        handleClose();
      },
    },
  });

  const updateBusinessUnitMutation = usePutData<
    Partial<string>,
    unknown,
    Partial<BusinessUnit>,
    unknown
  >(`/BusinessUnit/BusinessUnit`, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['businessUnits'] });
      handleClose();
    },
    onError: () => {
      handleClose();
    },
  });

  const formSchema = z.object({
    name: z.string().min(1, 'Name is required'),
    group: z.string().optional(),
    location: z.string().optional(),
    type: z.string().optional(),
    approvalLevels: z.number().optional(),

    deactivateDate: z.string().nullable().optional(),
    approvers: z.array(
      z.object({
        level: z.number(),
        users: z.array(z.string()).superRefine((users, ctx) => {
          if (users.length === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                ctx.path[1] === 0
                  ? 'At least one Officer is required'
                  : 'At least one Approver is required',
            });
          }
        }),
      }),
    ),
  });

  type FormFields = z.infer<typeof formSchema>;

  const defaultValues = {
    name: initialName ?? '',
    group: '',
    location: '',
    type: '',
    approvalLevels: 0,
    deactivateDate: null,
    approvers: [],
  };

  const methods = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    context: { schema: formSchema },
    defaultValues: defaultValues,
  });

  const { control, handleSubmit, reset, watch } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'approvers',
  });

  const approvalLevels = watch('approvalLevels');

  useEffect(() => {
    const currentLength = fields.length;
    const targetLength = (approvalLevels ?? 0) + 1;

    if (currentLength < targetLength) {
      for (let i = currentLength; i < targetLength; i++) {
        append({ level: i, users: [] });
      }
    } else if (currentLength > targetLength) {
      for (let i = currentLength - 1; i >= targetLength; i--) {
        remove(i);
      }
    }
  }, [approvalLevels, fields.length, append, remove]);

  const mapBusinessUnitApproversToApprovers = useCallback(
    (businessUnitApprovers: BusinessUnitApprover[]): Approvers => {
      return businessUnitApprovers.reduce<Approvers>((acc, approver) => {
        const level = approver.level;
        if (!acc[level]) {
          acc[level] = [];
        }
        acc[level].push(approver.userId);
        return acc;
      }, {});
    },
    [],
  );

  useEffect(() => {
    if (!isNew && businessUnitFromProps) {
      methods.reset({
        name: businessUnitFromProps.name,
        group: businessUnitFromProps.groupId || '',
        location: businessUnitFromProps.location,
        type: businessUnitFromProps.type.toString(),
        approvalLevels: businessUnitFromProps.approvalLevels,
        deactivateDate: businessUnitFromProps.deactivateDate,
        approvers: Object.entries(
          mapBusinessUnitApproversToApprovers(businessUnitFromProps.approvers),
        ).map(([level, users]) => ({
          level: parseInt(level),
          users,
        })),
      });
    }
  }, [businessUnitFromProps, isNew, mapBusinessUnitApproversToApprovers, methods]);

  const onSubmit = async (data: FormFields) => {
    // Ensure all required approvers are present
    const approvers = data.approvers ?? [];
    const requiredLevels = Array.from({ length: data.approvalLevels ?? 0 }, (_, i) => i);

    const missingApprovers = requiredLevels.filter(
      (level) =>
        !approvers.some(
          (approver) => approver.level === level && approver.users.length > 0,
        ),
    );

    if (missingApprovers.length > 0) {
      const messages = missingApprovers.map(
        (level) => `Please select ${getLabel(level)}.`,
      );
      await confirm({
        title: 'Error',
        message: messages.join('\n'),
      });
      return;
    }

    try {
      setIsSubmitting(true);

      const formattedDeactivateDate = data.deactivateDate
        ? new Date(data.deactivateDate).toISOString().split('T')[0]
        : null;

      const formattedBusinessUnit = {
        ...businessUnit,
        name: data.name?.trim() || businessUnit.name,
        groupId: data.group || businessUnit.groupId,
        location: data.location?.trim() || businessUnit.location,
        type: data.type ? (parseInt(data.type, 10) as 0 | 1) : businessUnit.type,
        deactivateDate: formattedDeactivateDate,
        approvers: data.approvers.flatMap((approver) =>
          approver.users.map((userId) => ({
            userId,
            level: approver.level,
            displayName: userData?.find((u) => u.id === userId)?.displayName ?? '',
            isActive: true,
          })),
        ),
      };

      if (isNew) {
        await addBusinessUnitMutation.mutateAsync(formattedBusinessUnit);
      } else {
        const dirtyFields = compareObjects(businessUnitFromProps, formattedBusinessUnit);
        const payload = {
          ...(businessUnit.id && { id: businessUnit.id }),
          ...dirtyFields,
        };
        const result = await updateBusinessUnitMutation.mutateAsync(payload);
        if (result) {
          const confirmResult = await confirm({
            title: 'Business units are pending for approval',
            message: `The following business units are still pending for approval and need to be approved. Do you want to approve all pending business responses for approval?<br/><br/>${result}`,
          });
          if (confirmResult) {
            const newPayload = { ...payload, confirm: true };
            await updateBusinessUnitMutation.mutateAsync(newPayload);
          }
        }
      }
      setIsSubmitting(false);
      reset();
    } catch (e) {
      setIsSubmitting(false);
      console.error(e);
    }
  };

  const dataWithAddNewOption: DropdownOption[] = useMemo(
    () => [
      ...(userData
        ? userData.map((user) => ({
            id: user.id || '',
            title: user.displayName,
          }))
        : []),
      { id: '', title: 'Add New User', inputValue: 'AddNew' },
    ],
    [userData],
  );

  const getLabel = useCallback((level: number) => {
    switch (level) {
      case 0:
        return 'Officer';
      case 1:
        return '1st Approver';
      case 2:
        return '2nd Approver';
      case 3:
        return '3rd Approver';
      case 4:
        return '4th Approver';
      case 5:
        return '5th Approver';
      default:
        return '';
    }
  }, []);

  const handleApprovalLevelChange = async (
    e: SelectChangeEvent<unknown>,
  ): Promise<SelectChangeEvent<unknown> | undefined> => {
    const newValue = parseInt(e.target.value as string, 10);
    const updateApprovers = (approvers: BusinessUnitApprover[], maxLevel: number) =>
      approvers.filter((approver) => approver.level <= maxLevel);

    const updatedApprovers = updateApprovers(businessUnit.approvers, newValue);
    const updateBusinessUnitState = (approvers: BusinessUnitApprover[]) => {
      setBusinessUnit((prevState) => ({
        ...prevState,
        approvalLevels: newValue,
        approvers,
      }));

      methods.setValue('approvalLevels', newValue);
      methods.setValue(
        'approvers',
        approvers.map((approver) => ({
          level: approver.level,
          users: [approver.userId],
        })),
      );
    };

    updateBusinessUnitState(updatedApprovers);

    return e;
  };

  return (
    <div>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle
          fontSize={16}
          sx={{
            paddingBottom: 0,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '1.125rem',
            fontWeight: 600,
            lineHeight: '1.625rem',
            textAlign: 'left',
            color: '#492B18',
            position: 'relative',
          }}
        >
          {isNew ? 'Add New' : 'Update'} Business Unit
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'rgba(0, 0, 0, 0.54)',
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContentText mb={1} sx={{ lineHeight: '1.625rem', paddingTop: '1rem' }}>
            Please insert details of the Business Unit
          </DialogContentText>
        </DialogTitle>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} id={'businessUnit-form'}>
            <DialogContent sx={{ paddingTop: '0.5rem', paddingBottom: '2rem' }}>
              <Grid container direction="row" spacing={2}>
                <Grid size={12}>
                  <HookFormInput name={'name'} label={'Name'} schema={formSchema} />
                </Grid>
                <Grid size={12}>
                  <HooksGoogleMaps
                    name={'location'}
                    label="Location"
                    size={'small'}
                    dataValue={businessUnit.location || ''}
                  />
                </Grid>
                <Grid container size={{ xs: 12 }} spacing={2}>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <HookFormSelect
                      fullWidth
                      onChangeInterceptAsync={async (e) => {
                        const selectedValue = e.target.value as string;
                        if (selectedValue === undefined) {
                          setIsAddingGroup(true);
                        } else {
                          setBusinessUnit({ ...businessUnit, groupId: selectedValue });
                          methods.setValue('group', selectedValue);
                        }
                        return e;
                      }}
                      name={'group'}
                      label={'Group'}
                    >
                      {groupData &&
                        groupData.map((g) => (
                          <MenuItem value={g.id} key={g.id}>
                            {g.name}
                          </MenuItem>
                        ))}
                      <MenuItem sx={{ fontWeight: '800' }} value={undefined}>
                        {'Add New Group'}
                      </MenuItem>
                    </HookFormSelect>
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <HookFormSelect fullWidth label={'Type'} name={'type'}>
                      <MenuItem value="0">Internal</MenuItem>
                      <MenuItem value="1">External</MenuItem>
                    </HookFormSelect>
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <HookFormSelect
                      fullWidth
                      margin="dense"
                      label={'Approval Levels'}
                      name={'approvalLevels'}
                      onChangeInterceptAsync={async (e) => {
                        return handleApprovalLevelChange(e);
                      }}
                    >
                      <MenuItem value={0}>0</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </HookFormSelect>
                  </Grid>
                </Grid>
                {userData ? (
                  fields.map((field, index) => (
                    <Grid size={12} key={field.id}>
                      <HookFormAutoComplete
                        disableCloseOnSelect
                        size={'small'}
                        name={`approvers.${index}.users` as const}
                        label={getLabel(index)}
                        schema={formSchema}
                        options={dataWithAddNewOption}
                        multiple
                        disableClearable={true}
                        getOptionLabel={(option) =>
                          (option as DropdownOption).title || ''
                        }
                        onChangeInterceptor={(_, newValue) => {
                          const values = newValue as AutoCompleteOption[];
                          if (values.some((x) => x.title === 'Add New User')) {
                            setUser(null);
                            return;
                          } else return newValue;
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid size={12}>
                    <LoadingCircular />
                  </Grid>
                )}
                <Grid size={6}>
                  <HookFormDatePicker
                    label={'Deactivate Date'}
                    name={'deactivateDate'}
                    openTo={'month'}
                    returnFormat="yyyy-MM-dd"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{ padding: '1.5rem', justifyContent: 'flex-end', paddingTop: '0' }}
            >
              <Button
                onClick={handleClose}
                sx={{
                  marginRight: '0.5rem',
                  width: '6.600625rem',
                  height: '2.375rem',
                  padding: '0.75rem 0.2175rem',
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                form="businessUnit-form"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                sx={{
                  width: '6.600625rem',
                  height: '2.375rem',
                  padding: '0.75rem 0.2175rem',
                  gap: '0.435rem',
                  border: '0.10875rem solid transparent',
                }}
              >
                {isSubmitting
                  ? isNew
                    ? 'Adding...'
                    : 'Saving...'
                  : isNew
                    ? 'Add'
                    : 'Save'}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <NewUserDialog />

      {isAddingGroup && (
        <NewGroupDialog
          onClose={(id) => {
            if (id) setBusinessUnit({ ...businessUnit, groupId: id });
            setIsAddingGroup(false);
          }}
        />
      )}
    </div>
  );
}
