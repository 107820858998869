import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';
import { MentionsInput } from 'react-mentions';

const paddingTop = '21px';
const paddingBottom = '3px';
// const padding = `${paddingTop} 0 ${paddingBottom} 0`;

const getPlaceholderStyle = (isSuperscript: boolean): CSSProperties => ({
  top: isSuperscript ? 0 : paddingTop,
});

type MentionInputProps = {
  // ... kept only the interesting props for brevity
  className?: string;
  disabled?: boolean;
  error?: boolean;
  inputClassName?: string;
  mentionStyle?: CSSProperties;
  onChange?: (value: string) => void;
  value?: string;
};

const props: MentionInputProps = {
  error: false,
  disabled: false,
  value: '',
  inputClassName: '',
  className: '',
};

export const MentionsInputStyled = styled(MentionsInput)(({ theme }) => ({
  flex: '1 1 auto',
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  overflowWrap: 'break-word',
  flexWrap: 'inherit',
  [`& .mentionInputClassName__control`]: { width: '100%', paddingRight: '2px' },

  [`& .mentionInputClassName__input`]: {
    wordWrap: 'break-word',
    border: 'none',
    color: theme.palette.text.primary,
    overflowWrap: 'break-word',
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      color: theme.palette.text.disabled,
    },
    '&:hover:not(:disabled):not(:focus)': {},
  },

  [`& .mentionInputClassName__highlighter`]: {},

  [`& .mentionInputClassName__suggestions`]: {
    backgroundColor: theme.palette.background.paper,
    marginTop: `calc(${paddingTop} + ${paddingBottom}) !important`,
    boxShadow: `0 0 8px ${theme.palette.action.disabled}`,
    width: 'max-content',
    maxHeight: 300,
    overflow: 'auto',
  },

  [`& .mentionInputClassName__suggestions__item`]: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
    paddingInline: '10px',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  [`& .mentionInputClassName__suggestions__item--focused`]: {
    backgroundColor: theme.palette.action.selected,
  },

  '&:focus-within': {
    [`& .floatingLabelClassName`]: {
      ...getPlaceholderStyle(true),
      color: !props.error && theme.palette.primary.main,
    },
  },
}));

export const defaultMentionStyle: CSSProperties = {
  backgroundColor: '#00A9E0',

  display: 'inline-block',
  opacity: 0.3,
  paddingTop: '0px',
  paddingBottom: '0px',
  paddingInline: '1px',
  marginBottom: '-10px',
  marginLeft: -1,
  borderRadius: 3,
};
