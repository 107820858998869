import './helpers/prototypeOverrides';
import './index.css';
import './wdyr';

import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { LicenseInfo } from '@mui/x-license';
import { DevSupport } from '@react-buddy/ide-toolbox';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import App from '@/App';
import themeOptions from '@/components/mui/themeOptions';
import { ComponentPreviews, useInitial } from '@/dev';
import msalInstance from '@/hooks/useMsal';
import QueryProvider from '@/QueryProvider';

import ErrorBoundary from './components/ErrorBoundry';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://246b2481480f588e0747d534b232b6cf@o4507304131952640.ingest.us.sentry.io/4507304133722112',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://weavr.au'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

LicenseInfo.setLicenseKey(
  '36df99e76983a4d359cad2b0d31e9a6dTz05MjYwNSxFPTE3NTAyMDYzNzUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    white: Palette['primary'];
    green: Palette['primary'];
    roseLight: Palette['primary'];
    brownPrimary: Palette['primary'];
    backgroundBrown: Palette['primary'];
    brownDark: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    green?: PaletteOptions['primary'];
    roseLight?: PaletteOptions['primary'];
    brownPrimary?: PaletteOptions['primary'];
    backgroundBrown?: PaletteOptions['primary'];
    brownDark?: PaletteOptions['primary'];
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    '2xl': true;
    '3xl': true;
    '4xl': true;
    '5xl': true;
  }
}

(window as any).global = window;

const theme = createTheme({ cssVariables: true, ...themeOptions });

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as { account: AccountInfo };
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement as HTMLElement);
  root.render(
    <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HelmetProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <QueryProvider>
              <MsalProvider instance={msalInstance}>
                <ErrorBoundary>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {process.env.NODE_ENV === 'development' ? (
                      <React.StrictMode>
                        <App />
                      </React.StrictMode>
                    ) : (
                      <App />
                    )}
                  </LocalizationProvider>
                </ErrorBoundary>
              </MsalProvider>
            </QueryProvider>
          </SnackbarProvider>
        </HelmetProvider>
      </ThemeProvider>
    </DevSupport>,
  );
}
