import { Collapse } from '@mui/material';
import React, { useState } from 'react';

import LoginForm from '@/components/navigation/LoginForm';
import ResetPasswordForm from '@/components/navigation/ResetPasswordForm';

const EmailLoginDialog: React.FC = () => {
  const [isForgottenPW, setIsForgottenPW] = useState<boolean>(false);
  return (
    <>
      <Collapse in={!isForgottenPW}>
        <LoginForm handleForgotPWClick={setIsForgottenPW} />
      </Collapse>
      <Collapse in={isForgottenPW}>
        <ResetPasswordForm handleForgotPWClick={setIsForgottenPW} />
      </Collapse>
    </>
  );
};

export default EmailLoginDialog;
