import dayjs from 'dayjs';
import { z } from 'zod';

import { isEndDateMatchPeriod } from '@/helpers/dateUtils';
import { Periodicity } from '@/pages/strategies/types/Contribution.types';

export const validateContributionPeriod = (
    data: {
        fromDate: string;
        toDate: string;
        periodicity: Periodicity;
    },
    ctx: z.RefinementCtx,
    strategyEnd: Date,
) => {
    if (data.periodicity === Periodicity.Once) {
        // Only validate fromDate when periodicity is Once
        if (dayjs(data.fromDate).isAfter(strategyEnd, 'month')) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'From date cannot be after than Strategy end date',
                path: ['fromDate'],
            });
        }
    } else {
        if (dayjs(data.toDate).isAfter(strategyEnd, 'month')) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'To date cannot be after than Strategy end date',
                path: ['toDate'],
            });
        }

        if (dayjs(data.fromDate).isAfter(data.toDate, 'month')) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'To date cannot be earlier than from date',
                path: ['toDate'],
            });
        }
        if (!isEndDateMatchPeriod(data.fromDate, data.toDate, data.periodicity)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'To date mismatch with From date and Frequency.',
                path: ['toDate'],
            });
        }
    }
};