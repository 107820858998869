import { useMsal } from '@azure/msal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { loginRequest } from '@/authConfig';
import HookFormInput from '@/components/HookFormInput';
import { usePostData } from '@/helpers/hooks';

const EmailSSOLoginForm = () => {
  const { instance, inProgress } = useMsal();

  const formSchema = z.object({
    email: z.string().email().min(1, 'Please provide your email.'),
  });
  type FormFields = z.infer<typeof formSchema>;

  const methods = useForm<FormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: { email: '' },
  });

  const validateEmailMutate = usePostData<string, any, { email: string }>({
    url: '/login/VerifyEmail',
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data: FormFields) => {
    await validateEmailMutate.mutateAsync({
      email: data.email,
    });
    await instance.loginRedirect(loginRequest(data.email));
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="email-sso-login-form"
        className="space-y-6"
      >
        <HookFormInput
          name={'email'}
          label={'Email address (REQUIRED)'}
          disabled={inProgress !== 'none'}
          sx={{
            '& .MuiInputBase-root': {
              height: '54px',
              backgroundColor: (theme) => theme.palette.backgroundBrown.dark,
              borderRadius: '8px',
              '& fieldset': {
                border: '1px solid white',
              },
            },
          }}
        />

        <div className="flex justify-end">
          <LoadingButton
            className="h-[52px]"
            type={'submit'}
            form="email-sso-login-form"
            variant={'contained'}
            fullWidth
            loading={inProgress !== 'none'}
            sx={{
              fontSize: 15,
              px: 4,
              py: 2,
            }}
          >
            Continue
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default EmailSSOLoginForm;
