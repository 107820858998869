import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, SvgIconProps, Tooltip } from '@mui/material';
import React, { memo } from 'react';

interface Props extends SvgIconProps {
  IconComponent?: React.ElementType;
  title: string | undefined;
  IconType?: string[];
}
const BaseIcon = memo(({ IconComponent, title, IconType, ...props }: Props) => {
  if (title === undefined) {
    return null;
  }

  let IconComponentToRender = IconComponent ?? HelpOutlineIcon;

  if (IconType !== undefined && IconType.length > 0 && !IconType.includes(title)) {
    IconComponentToRender = HelpOutlineIcon;
  }

  return (
    <Tooltip title={title}>
      <Box
        sx={(theme) => ({
          borderRadius: '50%',
          borderColor: theme.palette.brownPrimary.main,
          backgroundColor: theme.palette.brownPrimary.main,
          borderWidth: '1.5px',
          padding: 0.5,
          color: 'white!important',
          display: 'flex',
        })}
      >
        <IconComponentToRender {...props} />
      </Box>
    </Tooltip>
  );
});
export default BaseIcon;
