import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';

import HookFormCheckbox from '@/components/HookFormCheckbox';
import { CopyIcon } from '@/components/icons/CopyIcon';
import OverflowTypography from '@/components/mui/OverflowTypography';
import { usePostData } from '@/helpers/hooks';
import { validateContributionPeriod } from '@/helpers/validations';
import ContributionPeriodEdit from '@/pages/strategies/bulkUpdates/ContributionPeriodEdit';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';

import HookFormAutoComplete from '../../../components/HookFormAutoComplete';
import { Periodicity } from '../types/Contribution.types';
import { Deliverable } from '../types/deliverable.types';
import { useShallow } from 'zustand/react/shallow';

type BulkUpdateDeliverableProps = {
  setOpen: (open: boolean) => void;
  open: boolean;
  deliverables: Deliverable[];
};

export default function BulkUpdateDeliverable({
  setOpen,
  open,
  deliverables,
}: BulkUpdateDeliverableProps) {
  const queryClient = useQueryClient();

  const { allStrategies, allContributions, allActions, strategyStart, strategyEnd } =
    useStrategyStore(
      useShallow((state) => ({
        strategyStart: state.strategyStart,
        strategyEnd: state.strategyEnd,
        allStrategies: state.allStrategies,
        allActions: state.allActions,
        allContributions: state.allContributions,
      })),
    );
  const contributions = useMemo(
    () =>
      allContributions
        .filter((x) => deliverables.map((d) => d.id).includes(x.deliverableId))
        .map((x) => ({
          id: x.id,
          deliverableId: x.deliverableId,
          deliverableOrder: x.deliverableOrder,
          actionOrder: x.actionOrder,
          order: x.order,
          name: x.name,
          responseType: x.responseType,
          autoStatusUpdate: x.autoStatusUpdate,
          contributionPeriods: x.contributionPeriods.map((cp) => ({
            fromDate: cp.fromDate,
            toDate: cp.toDate,
            periodicity: cp.periodicity,
          })),
          responseOptions:
            x.responseOptions.length > 0
              ? x.responseOptions.map((ro) => ro.title)
              : undefined,
        })),
    [allContributions, deliverables],
  );
  const formSchema = z.object({
    strategyId: z.string().uuid().optional(),
    actionId: z.string().uuid().optional(),
    contributions: z
      .array(
        z.object({
          id: z.string(),
          deliverableId: z.string(),
          actionOrder: z.coerce.string(),
          deliverableOrder: z.coerce.string(),
          order: z.coerce.string(),
          name: z.string(),
          responseType: z.string(),
          autoStatusUpdate: z.boolean(),
          contributionPeriods: z.array(
            z
              .object({
                fromDate: z
                  .string()
                  .refine(
                    (fromDate) =>
                      !dayjs(strategyStart).isAfter(fromDate, 'M') &&
                      !dayjs(strategyEnd).isBefore(fromDate, 'M'),
                    'From date must be on or after the Strategy start date and before the Strategy end date',
                  ),
                toDate: z
                  .string()
                  .refine(
                    (toDate) =>
                      dayjs(toDate).isAfter(strategyStart, 'M') &&
                      dayjs(toDate).isSameOrBefore(strategyEnd, 'M'),
                    'To date must be after the Strategy start date and on or before the Strategy end date',
                  ),

                periodicity: z.coerce
                  .number()
                  .refine((val) => Object.values(Periodicity).includes(val), {
                    message: 'Invalid enum value. Expected a valid periodicity value.',
                  }),
              })
              .superRefine((data, ctx) =>
                validateContributionPeriod(data, ctx, strategyEnd!),
              ),
          ),
          responseOptions: z.string().array().optional(),
        }),
      )
      .optional(),
    includeBusinessUnits: z.boolean().optional(),
    includeContributions: z.boolean().optional(),
  });
  type FormFields = z.infer<typeof formSchema>;

  const defaultValues = useMemo(
    () => ({
      strategyId: '',
      actionId: '',
      contributions: contributions as any,
      includeBusinessUnits: false,
      includeContributions: false,
      lastOrderDeliverable: '1',
    }),
    [contributions],
  );
  const methods = useForm<FormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const { handleSubmit, watch, control, reset } = methods;

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);
  const handleClose = () => {
    setOpen(false);
  };

  const strategyId = watch('strategyId');
  const actionId = watch('actionId');
  const showContributions = watch('includeContributions');

  const actionOptions = useMemo(
    () =>
      allActions
        .filter((x) => x.strategyId === strategyId)
        .map((x) => ({
          id: x.id,
          title: x.name,
        })) || [],
    [allActions, strategyId],
  );

  const { fields: contributionFields } = useFieldArray({
    control,
    name: 'contributions',
  });

  type Payload = {
    deliverables: any;
    selectedAction: any;
    selectedStrategy: any;
    lastOrderDeliverable: any;
  };

  const mutation = usePostData<unknown, unknown, Payload>({
    url: '/Bulk/DuplicateDeliverables',
  });

  const onSubmit = async (data: FormFields) => {
    const updatedDeliverables = deliverables.map((deliverable) => ({
      ...deliverable,
      contributions: data.includeContributions
        ? data.contributions?.filter((x) => x.deliverableId === deliverable.id) || []
        : [],
      businessUnitIds: data.includeBusinessUnits ? deliverable.businessUnitIds || [] : [],
    }));

    const filteredStrategies = allStrategies.filter((strategy) =>
      strategy.actions.some((action) => action.id === data.actionId),
    );
    const filteredDeliverables = filteredStrategies[0].deliverables;
    const deliverablesForAction = filteredDeliverables.filter(
      (deliverable) => deliverable.actionId === data.actionId,
    );
    let lastDeliverable;

    if (deliverablesForAction.length !== 0) {
      lastDeliverable = deliverablesForAction.reduce(
        (latest, deliverable) =>
          deliverable.order > latest.order ? deliverable : latest,
        deliverables[0],
      );
    }

    const payload: Payload = {
      deliverables: updatedDeliverables,
      selectedAction: data.actionId,
      selectedStrategy: data.strategyId,
      lastOrderDeliverable: lastDeliverable?.order,
    };
    await mutation.mutateAsync(payload, {
      onSuccess: async () => {
        handleClose();
      },
      onError: (error) => {},
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id={'deliverable-dup-form'}>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={false}
          PaperProps={{
            sx: {
              width: '49.3125rem',
              borderRadius: '0.875rem 0.875rem 0.875rem 0.875rem',
              opacity: 1,
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          <DialogTitle mb={0}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CopyIcon sx={{ width: 44, height: 44 }} />
                <Box
                  sx={{
                    ml: 1,
                  }}
                >
                  <Typography
                    component="h2"
                    sx={{
                      fontWeight: '600',
                      fontSize: '1.1rem',
                      color: '#9C5738',
                    }}
                  >
                    Bulk Duplicate
                  </Typography>
                  <Typography>
                    Select a strategy and action to duplicate deliverables.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent sx={{ gap: '1.25rem' }}>
            <Grid
              container
              sx={{
                mt: 1,
              }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Box
                    sx={{
                      mb: 2,
                    }}
                  >
                    <HookFormAutoComplete
                      name={'strategyId'}
                      label={'Strategy'}
                      options={allStrategies.map((x) => ({
                        id: x.id,
                        title: x.name,
                      }))}
                    />
                  </Box>
                  <Box>
                    <HookFormAutoComplete
                      name={'actionId'}
                      label={'Action'}
                      options={actionOptions}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <HookFormCheckbox
                  name={'includeBusinessUnits'}
                  label={'Include Business Unit'}
                />
                <HookFormCheckbox
                  name={'includeContributions'}
                  label={'Include contributions'}
                />
              </Grid>

              <Collapse in={showContributions}>
                {showContributions && (
                  <Grid item xs={12}>
                    <Divider />
                    <Typography
                      variant="h6"
                      gutterBottom
                      component="h2"
                      sx={{
                        fontSize: '1rem',
                        color: '#9C5738',
                      }}
                    >
                      Contribution
                    </Typography>
                    {contributionFields.map((contribution, contributionIndex) => {
                      return (
                        <Grid container key={contribution.id} item xs={12}>
                          <Grid item xs={12}>
                            <OverflowTypography
                              variant="subtitle1"
                              fontSize="1rem"
                              color="#745947"
                              maxLine={1}
                              mb={1}
                              mt={2}
                              mx={1}
                              showIcon
                            >
                              {contribution.actionOrder}.{contribution.deliverableOrder}.
                              {contribution.order} {contribution.name}
                            </OverflowTypography>
                          </Grid>
                          <ContributionPeriodEdit
                            key={contribution.id}
                            contributionIndex={contributionIndex}
                            control={control}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {showContributions && contributionFields.length === 0 && (
                  <Grid item xs={12}>
                    <DialogContentText>No contributions found.</DialogContentText>
                  </Grid>
                )}
              </Collapse>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end', padding: '1.5rem' }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              form="deliverable-dup-form"
              disabled={!strategyId || !actionId}
              variant={'contained'}
              color="primary"
            >
              Duplicate
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
}
