import dayjs from 'dayjs';
import { z } from 'zod';

import { DropdownOption } from '@/components/dropdownOption.type';
import { Action } from '@/pages/strategies/types/Action.types';
import { Contribution } from '@/pages/strategies/types/Contribution.types';
import { Deliverable } from '@/pages/strategies/types/deliverable.types';
import { DropdownOptions } from '@/pages/strategies/types/Strategy.types';

export type Strategy = {
  id: string;
  name: string;
  startDate?: Date | string;
  endDate?: Date | string;
  progressStatus?: string;
  description?: string;
  actions?: Action[];
  deliverables?: Deliverable[];
  contributions?: Contribution[];
  isNew?: boolean;
  order?: number;
  statusId: string;
  parentStrategyId?: string;
  hasChildren?: boolean;
  iconName?: string;
  iconColor?: string;
};

export type StrategyCardProps = {
  onHeaderClick: (strategyId?: string, isNew?: boolean) => void;
  dropdownOptions?: DropdownOptions | undefined;
  setAddingStrategy?: (d: Strategy | null) => void;
  setLocalStrategy?: (d: Strategy | null) => void;
  selectableParentStrategies?: DropdownOption[];
  disabled: boolean;
};

export const formSchema = z
  .object({
    startDate: z.date(),
    endDate: z.date(),
    name: z.string().min(1, 'Strategy name is required'),
    statusId: z.string(),
    parentStrategyId: z.string().uuid().optional(),
    iconName: z.string().optional(),
    iconColor: z.string().optional(),
  })
  .refine((data) => !dayjs(data.startDate).isAfter(data.endDate, 'month'), {
    message: 'To date cannot be earlier than from date',
    path: ['startDate'],
  });

export type StrategyFormFields = z.infer<typeof formSchema>;
