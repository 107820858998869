import { useRouter } from '@tanstack/react-router';
import React from 'react';

import Footer from '@/components/navigation/Footer';
import Navbar from '@/components/navigation/Navbar';
import { useNewsLetterStore } from '@/store/useNewsLetterStore';
import Chatbot from '@/components/Chatbot';
import { useShallow } from 'zustand/react/shallow';
import NewsletterSidebar from '@/pages/loginPage/NewsletterSidebar';
import UnsubscribeBanner from '@/components/banners/UnsubscribeBanner';

const PageLayout = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const isMarketing =
    router.state.location.pathname === '/' ||
    router.state.location.pathname.startsWith('/blogs');

  const { open, onClose } = useNewsLetterStore(
    useShallow((state) => ({
      open: state.open,
      onClose: state.onClose,
    })),
  );

  return (
    <>
      <div className="relative flex grow flex-col overflow-x-hidden">
        <Navbar />
        <UnsubscribeBanner />
        <main>{children}</main>
        <NewsletterSidebar open={open} onClose={onClose} />
        <Chatbot />
      </div>
      <footer>{!isMarketing && <Footer />}</footer>
    </>
  );
};

export default PageLayout;
