import { useQueryClient } from '@tanstack/react-query';

import { Settings } from '@/contexts/Settings.types';
import { usePutData } from '@/helpers/hooks';

const useGlobalSettingsActions = () => {
  const queryClient = useQueryClient();
  const updateMutate = usePutData<unknown, unknown, Partial<Settings>, unknown>(
    '/user/updateglobalsettings',
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );

  const updateSettings = async (newSettings: Partial<Settings>) => {
    await updateMutate.mutateAsync(newSettings);
    await resetSettings();
  };

  const resetSettings = async () => {
    await queryClient.invalidateQueries();
  };

  return { updateSettings, resetSettings };
};

export default useGlobalSettingsActions;
