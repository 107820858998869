import { motion } from 'framer-motion';

const pageVariants = {
  initial: (direction: Direction) => ({
    opacity: 0,
    x: direction === 'right' ? 100 : -100,
  }),
  in: {
    opacity: 1,
    x: 0,
  },
  out: (direction: Direction) => ({
    opacity: 0,
    x: direction === 'right' ? -100 : 100,
  }),
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5,
};

type Props = {
  children: React.ReactNode;
  direction?: Direction;
};

type Direction = 'left' | 'right';

const MotionWrapper = ({ children, direction }: Props) => {
  return direction ? (
    <motion.div
      custom={direction}
      className="flex h-full flex-col items-stretch"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      {children}
    </motion.div>
  ) : (
    <div className="flex h-full flex-col items-stretch">{children}</div>
  );
};

export default MotionWrapper;
