import { ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Poppins',
    allVariants: {
      color: '#595959',
    },
    body1: {
      fontSize: 'clamp(0.8125rem, 0.78125rem + 0.15625vw, 0.9rem)',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 'clamp(0.9125rem, 0.80125rem + 0.172vw, 1rem)',
      marginTop: '0.625rem',
      marginBottom: '0.625rem',
      lineHeight: 1.6,
    },
    h1: {
      fontSize: 'clamp(1.8rem, 1.35rem + 2.25vw, 3rem)',
      fontWeight: 600,
      marginTop: '1.5625rem',
      marginBottom: '1.5625rem',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: 'clamp(1.35rem, 1.125rem + 1.125vw, 2rem)',
      fontWeight: 600,
      marginTop: '0.9375rem',
      marginBottom: '0.9375rem',
      lineHeight: 1.3,
    },
    h3: {
      fontSize: 'clamp(1.125rem, 0.9rem + 0.9vw, 1.5rem)',
      fontWeight: 500,
      marginTop: '0.9375rem',
      marginBottom: '0.9375rem',
      lineHeight: 1.4,
    },
    h4: {
      fontSize: 'clamp(1rem, 0.875rem + 0.625vw, 1.25rem)',
      fontWeight: 500,
      marginTop: '0.625rem',
      marginBottom: '0.625rem',
      lineHeight: 1.4,
    },
    h5: {
      fontSize: 'clamp(0.9rem, 0.84375rem + 0.28125vw, 1.125rem)',
      fontWeight: 500,
      marginTop: '0.625rem',
      marginBottom: '0.625rem',
      lineHeight: 1.5,
    },
    h6: {
      fontSize: 'clamp(0.8125rem, 0.78125rem + 0.15625vw, 0.9rem)',
      fontWeight: 500,
      marginTop: '0.625rem',
      marginBottom: '0.625rem',
      lineHeight: 1.5,
    },
  },
  palette: {
    roseLight: {
      main: '#FAF8F5',
    },
    primary: {
      main: '#BC703E',
      dark: '#492B18',
    },
    error: {
      main: '#d32f2f',
      dark: '#c62828',
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
    },
    info: {
      main: '#0288d1',
      light: '#62b3d2',
      dark: '#42a5f5',
    },
    brownPrimary: { light: '#D9A77C', main: '#BC703E', dark: '#492B18' },
    backgroundBrown: {
      main: '#FAF8F5',
      light: '#FFFCFA',
      dark: '#FAF2EC',
    },
    brownDark: {
      main: '#9C5738',
      light: '#745947',
    },
    /*secondary: {
                  main: '#42a5f5',
                },*/
    black: {
      main: '#23272b',
    },
    white: {
      main: '#ffffff',
    },
    green: {
      main: '#19897C',
    },
    text: {
      primary: '#4f4c4c',
      secondary: '#1A1A1A',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          padding: '8px 12px',
          lineHeight: 1.7,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              textTransform: 'none',
              backgroundColor: '#A76337',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#9C5738', // Replace with your desired hover color
              },
              padding: '0.5rem 1.5rem',
            }),
          borderRadius: '0.9rem',
          textTransform: 'none',
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '1rem',
          '&:last-child': {
            paddingBottom: '1rem',
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 375,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1440,
      '3xl': 1920,
      '4xl': 2560,
      '5xl': 3840,
    },
  },
};
export default themeOptions;
