function Footer() {
  return (
    <div
      className="bg-rose text-sm text-gray-700"
      style={{
        height: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Copyright @ 2020-{new Date().getFullYear().toString().substring(2)} | Powered by{' '}
      <span className="ml-2 text-brownPrimary"> LOGIT Australia Pty Ltd</span>
    </div>
  );
}
export default Footer;
