import SaveIcon from '@mui/icons-material/Save';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { memo } from 'react';
import { useShallow } from 'zustand/react/shallow';

import HookFormAutoComplete from '@/components/HookFormAutoComplete';
import ReactHookFormInput from '@/components/HookFormInput';
import DeleteIconRed from '@/components/icons/DeleteIconRed';
import { useDeleteData } from '@/helpers/hooks';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import { ActionProps } from '@/pages/strategies/types/Action.types';
import { useConfirm } from '@/store/useConfirmDialogStore';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';

type Props = {
  handleLastActionClick: () => void;
} & Pick<ActionProps, 'dropdownOptions' | 'item' | 'setActiveActions' | 'disabled'>;

const ActionCardContent = memo(
  function ActionCardContent({
    dropdownOptions,
    item,
    setActiveActions,
    handleLastActionClick,
    disabled,
  }: Props) {
    const confirm = useConfirm();

    const deleteActionMutation = useDeleteData<unknown, unknown>(`/Strategy/Action`);

    const globalSettings = useGlobalSettingsStore(
      useShallow((state) => state.globalSettings),
    );
    const isGlobalAdmin = globalSettings.role === 'Global Admin';
    const allowEditStrategy = useStrategyStore(
      useShallow((state) => state.getAllowEditStrategy()),
    );

    const handleActionDelete = () => {
      if (disabled) return;

      confirm({
        title: 'Delete Action',
        message:
          'Are you sure you want to delete this Action? \n' +
          'Please note, the Action will be deleted from any associated Strategies, or Progress Update records.',
      }).then((result) => {
        if (result && item) {
          deleteActionMutation.mutate(item.id as string, {
            onSuccess: () => {
              setActiveActions?.([]);
            },
          });
        }
      });
    };

    return (
      <>
        <hr />
        <Grid
          container
          spacing={1}
          sx={{
            pl: 1,
            pr: 1,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              pb: 1,
            }}
          >
            <ReactHookFormInput
              onMouseDown={handleLastActionClick}
              name={'description'}
              label={'Description'}
              rows={3}
              disabled={!allowEditStrategy || disabled}
              multiline={true}
            />
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              pb: 1,
            }}
          >
            {dropdownOptions?.actionAreas ? (
              <HookFormAutoComplete
                name={'areaId'}
                label={'Action Area'}
                onTextFieldMouseDown={handleLastActionClick}
                options={dropdownOptions?.actionAreas}
                disabled={!allowEditStrategy || disabled}
                displayStatus={item.isNew}
              />
            ) : (
              <></>
            )}
          </Grid>
          {allowEditStrategy && (
            <>
              {item.isNew && (
                <Grid item xs={3} sx={{ position: 'relative' }}>
                  <Tooltip title="Save">
                    <IconButton
                      form="action-form"
                      type="submit"
                      sx={{
                        position: 'absolute',
                        right: 0,
                        backgroundColor: '#2e7d32',
                        '&:hover': {
                          backgroundColor: '#1b5e20', // dark red on hover
                        },
                      }}
                      disabled={disabled}
                    >
                      <SaveIcon style={{ color: 'white' }}></SaveIcon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {!item.isNew && (
                <Grid item xs={3} sx={{ position: 'relative' }}>
                  <DeleteIconRed
                    handleActionDelete={handleActionDelete}
                    disabled={disabled}
                  />
                </Grid>
              )}
            </>
          )}
          {isGlobalAdmin &&
            dropdownOptions?.focusAreas !== undefined &&
            dropdownOptions?.focusAreas.length > 0 && (
              <>
                {globalSettings.allowAddPrimaryFocusAreas && (
                  <Grid
                    item
                    xs={9}
                    sx={{
                      pb: 1,
                    }}
                  >
                    <HookFormAutoComplete
                      name={'primaryFocusId'}
                      label={'Primary Focus Area'}
                      onTextFieldMouseDown={handleLastActionClick}
                      options={dropdownOptions.focusAreas}
                      displayStatus={item.isNew}
                    />
                  </Grid>
                )}

                {globalSettings.allowAddSecondaryFocusAreas && (
                  <Grid
                    item
                    xs={9}
                    sx={{
                      pb: 1,
                    }}
                  >
                    <HookFormAutoComplete
                      name={'secondaryFocusId'}
                      label={'Secondary Focus Area'}
                      onTextFieldMouseDown={handleLastActionClick}
                      options={dropdownOptions.focusAreas}
                      displayStatus={item.isNew}
                    />
                  </Grid>
                )}
              </>
            )}
        </Grid>
      </>
    );
  },
  (prev, next) => prev.item === next.item && prev.disabled == next.disabled,
);

export default ActionCardContent;
