import { useCallback } from 'react';
import { DeliverableWithLastContribution } from '../BulkUpdatePanel';
import { Contribution } from '../types/Contribution.types';
import { Deliverable } from '../types/deliverable.types';

const useDeliverablesWithLastContribution = (
    deliverables: Deliverable[],
    allContributions: Contribution[]
): DeliverableWithLastContribution[] => {
    const getDeliverablesWithLastContribution = useCallback(() => {
        const deliverableMap = new Map<string, DeliverableWithLastContribution>();

        deliverables.forEach((deliverable) => {
            deliverableMap.set(deliverable.id, {
                id: deliverable.id,
                name: deliverable.name,
                order: deliverable.order,
                actionOrder: deliverable.actionOrder,
                lastContributionOrder: '1',
            });
        });

        deliverables.forEach((deliverable) => {
            const filteredContributions = allContributions.filter(
                (contribution) => contribution.deliverableId === deliverable.id
            );

            const lastContribution = filteredContributions.reduce((latest, current) => {
                const currentOrder = parseFloat(current.order);
                const latestOrder = parseFloat(latest.order);

                return currentOrder > latestOrder ? current : latest;
            }, filteredContributions[0]);

            if (lastContribution) {
                const deliverableEntry = deliverableMap.get(deliverable.id);
                if (deliverableEntry) {
                    deliverableEntry.lastContributionOrder = (parseFloat(lastContribution.order) + 1).toString();
                }
            }
        });

        return Array.from(deliverableMap.values());
    }, [deliverables, allContributions]);

    return getDeliverablesWithLastContribution();
};

export default useDeliverablesWithLastContribution;
