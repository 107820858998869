/* eslint-disable jsx-a11y/no-autofocus */
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  styled,
  TextField,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import DOMPurify from 'dompurify';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useConfirmDialogStore } from '@/store/useConfirmDialogStore';

import { DatePicker } from './DatePicker';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '1.5rem',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  marginTop: theme.spacing(2),
  minWidth: '600px',
  [theme.breakpoints.down('md')]: {
    minWidth: '150px',
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(() => ({
  textTransform: 'none',
}));

const MemoizedChip = memo(({ label, color }: { label: string; color: string }) => (
  <Chip label={label} size="small" color={color as any} />
));

const sanitizeContent = (input: string) => {
  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'chip') {
      const color = (node as HTMLElement).getAttribute('color') || 'default';
      Object.assign(node, { color });
    }
  });

  const config = {
    ALLOWED_TAGS: ['br', 'chip', 'strong', 'summary', 'details', 'u', 'span'],
    ALLOWED_ATTR: ['color', 'open', 'className'],
  };

  return DOMPurify.sanitize(input, config);
};

const CustomRenderer: React.FC<{ content: string }> = memo(({ content }) => {
  const sanitizedContent = useMemo(() => sanitizeContent(content), [content]);

  const renderChipsAndText = useCallback((text: string) => {
    const parts = text.split(/(<chip[^>]*>.*?<\/chip>|<span[^>]*>.*?<\/span>)/);
    return parts.map((part, index) => {
      if (part.startsWith('<chip')) {
        const colorMatch = part.match(/color="([^"]*)"/);
        const color = colorMatch ? colorMatch[1] : 'default';
        const chipText = part.replace(/<chip[^>]*>(.*?)<\/chip>/, '$1');
        return <MemoizedChip key={index} label={chipText} color={color} />;
      } else if (part.startsWith('<span')) {
        const classMatch = part.match(/classname="([^"]*)"/);
        const className = classMatch ? classMatch[1] : '';
        const spanText = part.replace(/<span[^>]*>(.*?)<\/span>/, '$1');
        return (
          <span
            key={index}
            className={className}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(spanText) }}
          />
        );
      } else {
        return (
          <span
            key={index}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(part) }}
          />
        );
      }
    });
  }, []);

  const renderContent = useCallback(() => {
    const parts = sanitizedContent.split(/(<details.*?>.*?<\/details>)/s);
    return parts.map((part, index) => {
      if (part.startsWith('<details')) {
        const detailsMatch = part.match(
          /<details(.*?)>(.*?)<summary>(.*?)<\/summary>(.*?)<\/details>/s,
        );
        if (detailsMatch) {
          const [, attrs, , summary, detailsContent] = detailsMatch;
          return (
            <details
              key={`details-${index}`}
              {...(attrs
                ? { open: attrs.includes('open') && attrs.includes('open="true"') }
                : {})}
            >
              <summary>{renderChipsAndText(summary)}</summary>
              {renderChipsAndText(detailsContent)}
            </details>
          );
        }
      }
      return renderChipsAndText(part);
    });
  }, [sanitizedContent, renderChipsAndText]);

  return <>{renderContent()}</>;
});

const ConfirmDialog: React.FC = () => {
  const { isOpen, options, closeDialog } = useConfirmDialogStore();
  const [localValue, setLocalValue] = useState<string | Date | undefined>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const dialogSize: DialogProps['maxWidth'] = useMemo(() => {
    if (options?.size === 'xl') return 'xl';
    if (options?.size === 'lg') return 'lg';
    if (options?.size === 'sm') return 'sm';
    return 'sm'; // default size
  }, [options?.size]);

  const dialogContent = useMemo(
    () => (
      <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }} component="div">
        <CustomRenderer
          content={
            Array.isArray(options?.message)
              ? options?.message.join('\n')
              : (options?.message as string)
          }
        />
      </DialogContentText>
    ),
    [options?.message],
  );

  useEffect(() => {
    if (options) {
      if ('comment' in options && options.comment) {
        setLocalValue(options.comment);
      } else if ('selectedDate' in options && options.selectedDate) {
        setLocalValue(options.selectedDate);
      } else {
        setLocalValue('');
      }
    }
  }, [options]);

  if (!isOpen || !options) return null;

  const handleAltOption = () => {
    if ('hasAltOption' in options && options.hasAltOption) {
      closeDialog({
        confirmed: true,
        comment: options.altButtonText,
        altButtonText: options.altButtonText,
      });
    }
    setLocalValue('');
    setHasError(false);
  };
  const handleConfirm = () => {
    if (
      'dropdownList' in options &&
      options.dropdownList?.length &&
      (localValue === undefined || localValue === '')
    ) {
      setHasError(true);
      return;
    }

    const minDate = new Date(1900, 0, 1);
    const maxDate = new Date(2050, 11, 31);

    if ('dropdownList' in options && options.dropdownList?.length) {
      closeDialog({
        confirmed: true,
        selectedOption: options.dropdownMultiple
          ? (localValue as string).split(',')
          : (localValue as string),
        comment: localValue as string,
      });
    } else if ('commentType' in options && options.showComment) {
      if (options.commentType === 'text' || options.commentType === 'multiLine') {
        closeDialog({
          confirmed: true,
          comment: typeof localValue === 'string' ? localValue : '',
        });
      } else if (options.commentType === 'date') {
        if (
          localValue instanceof Date &&
          !isNaN(localValue.getTime()) &&
          localValue >= minDate &&
          localValue <= maxDate
        ) {
          closeDialog({
            confirmed: true,
            selectedDate: localValue,
          });
        } else {
          setHasError(true);
          return;
        }
      }
    } else if ('hasAltOption' in options && options.hasAltOption) {
      closeDialog({
        confirmed: true,
        comment: typeof localValue === 'string' ? localValue : 'Confirmed',
        altButtonText: options.altButtonText,
      });
    } else {
      closeDialog(true);
    }

    setLocalValue('');
    setHasError(false);
  };

  const handleCancel = () => {
    closeDialog(false);
    setLocalValue('');
    setHasError(false);
  };

  const handleDateChange = (value: unknown) => {
    if (value instanceof Date) {
      setLocalValue(value);
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={dialogSize}
    >
      <StyledDialogTitle id="confirm-dialog-title">{options.title}</StyledDialogTitle>
      <StyledDialogContent>
        {dialogContent}
        {'commentType' in options &&
        (options.commentType === 'text' || options.commentType === 'multiLine') &&
        options.showComment ? (
          <TextField
            label={options.commentLabel ?? 'Comment'}
            multiline={options.commentType === 'multiLine'}
            minRows="3"
            margin="normal"
            className="w-full"
            value={localValue}
            onChange={(event) => setLocalValue(event.target.value)}
          />
        ) : 'commentType' in options &&
          options.commentType === 'date' &&
          options.showComment ? (
          <div className="mt-4">
            <DatePicker
              label="Date"
              value={localValue instanceof Date ? localValue : null}
              onChange={handleDateChange}
              isMonthPicker={'isMonthPicker' in options ? options.isMonthPicker : false}
            />
          </div>
        ) : 'dropdownList' in options && options.dropdownList?.length ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginTop: '12px',
            }}
          >
            <FormControl fullWidth className="mt-3" error={hasError}>
              <InputLabel id="confirm-input">{options.dropdownLabel}</InputLabel>
              <Select
                multiple={options.dropdownMultiple}
                labelId="confirm-input"
                value={
                  localValue instanceof Date
                    ? undefined
                    : options.dropdownMultiple
                      ? (localValue as string).split(',').filter((x) => x !== '')
                      : localValue
                }
                label={options.dropdownLabel}
                sx={{ fontSize: '0.8125rem' }}
                onChange={(event) => {
                  if (options.dropdownMultiple)
                    setLocalValue((event.target.value as unknown as string[]).join(','));
                  else setLocalValue(event.target.value as string);
                  setHasError(false);
                }}
              >
                {options.dropdownList.map((s) => {
                  if (typeof s === 'string')
                    return (
                      <MenuItem value={s} key={s}>
                        {s}
                      </MenuItem>
                    );
                  else {
                    return (
                      <MenuItem value={s.id} key={s.id}>
                        {s.title}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
              {hasError && <FormHelperText>Please select an item</FormHelperText>}
            </FormControl>
          </div>
        ) : null}
      </StyledDialogContent>
      <StyledDialogActions>
        {!options.isModal && (
          <StyledButton onClick={handleCancel} color="inherit" variant="contained">
            {options.cancelButtonText ? options.cancelButtonText : 'Cancel'}
          </StyledButton>
        )}
        {'hasAltOption' in options && options.hasAltOption && (
          <StyledButton
            onClick={handleAltOption}
            color={options.altButtonColor || 'error'}
            variant="contained"
          >
            {options.altButtonText || 'No'}
          </StyledButton>
        )}
        <StyledButton
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          autoFocus
        >
          {options.confirmButtonText ? options.confirmButtonText : 'Ok'}
        </StyledButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
