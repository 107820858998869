import React from 'react';
import { Helmet } from 'react-helmet-async';

import MotionWrapper from '@/components/MotionWrapper';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';

// Default meta as fallback
const defaultMeta = {
  title: 'Weavr',
  description: 'Weavr Platform',
};

export const createRouteContent = (
  component: React.ComponentType<any>,
  meta?: { title?: string; description?: string },
  props?: any,
) => {
  const RouteContent: React.FC = () => {
    const publicPathNames = ['/', '/terms', '/privacy_policy', '/security', '/careers'];
    const isAuthenticated = useGlobalSettingsStore.getState().isAuthenticated;
    const path = window.location.pathname;
    return (
      <>
        <Helmet>
          <title>{meta?.title || defaultMeta.title}</title>
          <meta
            name="description"
            content={meta?.description || defaultMeta.description}
          />
        </Helmet>
        {publicPathNames.includes(path) ||
        path.startsWith('/casestudies') ||
        path.startsWith('/blogs') ||
        path.startsWith('/careers') ||
        isAuthenticated ? (
          <MotionWrapper direction="right">
            {React.createElement(component, props)}
          </MotionWrapper>
        ) : null}
      </>
    );
  };

  return RouteContent;
};
