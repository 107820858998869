import { AddCircle } from '@mui/icons-material';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { memo, useCallback, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { DropdownOption } from '@/components/dropdownOption.type';
import BasicMenu from '@/components/mui/ButtonMenu';
import ViewModeIcon from '@/components/svgComponents/ViewModeIcon';
import { formatDateTimeToMonthString, formatDuration } from '@/helpers/dateUtils';
import { usePutData } from '@/helpers/hooks';
import { emptyGuid } from '@/helpers/utils';
import useUpdateFilters from '@/hooks/useUpdateFilters';
import StatusDropdown from '@/pages/strategies/StatusDropdown';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import StrategyEditDialog from '@/pages/strategies/strategyTopArea/StrategyEditDialog';
import { StrategyHierarchyDrawer } from '@/pages/strategies/strategyTopArea/StrategyHierarchyDrawer';
import { DropdownOptions } from '@/pages/strategies/types/Strategy.types';
import { Strategy, StrategyCardProps } from '@/pages/strategies/types/StrategyCard.types';
import { strategyItemFilters } from '@/types/itemFilter';

const StrategyCard = memo(
  ({
    onHeaderClick,
    dropdownOptions,
    setLocalStrategy,
    selectableParentStrategies,
    disabled,
  }: StrategyCardProps) => {
    const routeApi = getRouteApi('/strategies');
    const { strategyId } = routeApi.useSearch();
    const updateFilters = useUpdateFilters<strategyItemFilters>(routeApi);

    const [open, setOpen] = useState(false);
    const [editingItem, setEditingItem] = useState<Strategy | null>(null);

    const { defaultStatusId, item, allStrategies, allowLinkDeliverable } =
      useStrategyStore(
        useShallow((state) => ({
          defaultStatusId: state.defaultStatusId,
          item: state.primaryStrategy,
          allStrategies: state.allStrategies,
          allowLinkDeliverable: state.allowLinkDeliverable,
        })),
      );

    const [drawerOpen, setDrawerOpen] = useState(false);

    const queryClient = useQueryClient();

    const options: DropdownOption[] = useMemo(() => {
      if (!allStrategies) return [];

      // First get root strategies (those without parents)
      const rootStrategies = allStrategies.filter((s) => !s.parentStrategyId);

      // Create hierarchical options
      const createOptions = (
        strategies: typeof allStrategies,
        level = 0,
      ): DropdownOption[] => {
        return strategies
          .map((strategy) => ({
            id: strategy.id,
            title: (level > 0 ? `L${level}. ` : '') + strategy.name,
            inset: !!strategy.parentStrategyId,
          }))
          .reduce((acc: DropdownOption[], curr) => {
            acc.push(curr);

            const children = allStrategies.filter((s) => s.parentStrategyId === curr.id);
            if (children.length > 0) {
              acc.push(...createOptions(children, level + 1));
            }

            return acc;
          }, []);
      };

      return createOptions(rootStrategies);
    }, [allStrategies]);

    const updateStrategyMutation = usePutData<
      Partial<Strategy>,
      unknown,
      Partial<Strategy>,
      unknown
    >(`/Strategy/Strategy?id=${item?.id}`);

    const dropDownStatusChanged = async (e: SelectChangeEvent) => {
      if (disabled) return;

      await updateStrategyMutation.mutateAsync(
        { id: item?.id, statusId: e.target.value },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: ['strategyData'],
            });
          },
        },
      );
    };

    const handleStrategyAdd = useCallback(() => {
      if (disabled) return;

      const s: Strategy = {
        name: '',
        isNew: true,
        id: emptyGuid,
        statusId: defaultStatusId,
      };
      setEditingItem(s);
      setOpen((prev) => !prev);
    }, [defaultStatusId]);

    const handleStrategyEdit = useCallback(() => {
      if (disabled) return;

      setEditingItem(item);
      setOpen((prev) => !prev);
    }, [item]);

    const duration =
      item?.startDate !== undefined && item?.endDate !== undefined
        ? formatDuration(item?.startDate, item?.endDate)
        : undefined;

    return (
      <>
        <Box>
          <div className="mt-3 flex flex-wrap items-center gap-3 px-4 py-3">
            <Typography
              variant="h5"
              component="h1"
              sx={{
                fontWeight: '600',
              }}
            >
              Strategies
            </Typography>
            <Box
              sx={{
                marginLeft: allowLinkDeliverable ? 'auto' : 'unset',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {allowLinkDeliverable && (
                <>
                  <Button
                    variant={'outlined'}
                    onClick={() => {
                      if (!disabled) setDrawerOpen(true);
                    }}
                  >
                    <ViewModeIcon />
                    <Typography>View Hierarchy</Typography>
                  </Button>
                  <StrategyHierarchyDrawer
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                  />
                </>
              )}
              <IconButton
                onClick={handleStrategyAdd}
                color={'primary'}
                data-testid="add-strategy"
                disabled={disabled}
              >
                <AddCircle fontSize={'large'} />
              </IconButton>
            </Box>
          </div>
          {item && (
            <Box className="mb-2 ml-2 flex items-center rounded-md border-[1px] border-brown-dark !bg-[#FAF2EC66] p-4">
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                  }}
                >
                  <Typography style={{ color: 'black' }} variant={'body2'}>
                    {item.name}
                  </Typography>
                  <Box className="flex gap-1">
                    <Typography style={{ color: 'grey' }}>Start date:</Typography>
                    <Typography style={{ color: 'black' }}>
                      {item.startDate
                        ? formatDateTimeToMonthString(item.startDate, 'string')
                        : 'No date'}
                    </Typography>
                  </Box>
                  <Box className="flex gap-1">
                    <Typography style={{ color: 'grey' }}>End date:</Typography>
                    <Typography style={{ color: 'black' }}>
                      {item.endDate
                        ? formatDateTimeToMonthString(item.endDate, 'string')
                        : 'No date'}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexGrow: 1,
                      gap: 2,
                    }}
                  >
                    {duration !== undefined && duration?.length > 0 && (
                      <Box className="flex flex-wrap gap-1">
                        <Typography style={{ color: 'grey' }}>Duration:</Typography>
                        <Typography style={{ color: 'black' }}>{duration}</Typography>
                      </Box>
                    )}
                  </Box>
                  {/*{item.hasChildren && (
                    <Chip
                      label={'Main Strategy'}
                      sx={{ marginLeft: 1 }}
                      color={'info'}
                      variant={'outlined'}
                      size={'small'}
                    ></Chip>
                  )}*/}
                  {dropdownOptions && item && (
                    <StatusDropdown
                      onChange={dropDownStatusChanged}
                      dataTestId={'statusDropdown'}
                      item={item}
                      dropdownOptions={dropdownOptions as DropdownOptions}
                      disabled={disabled}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  ml: 'auto',
                }}
              >
                <IconButton
                  onClick={handleStrategyEdit}
                  color={'primary'}
                  sx={{ padding: '6px' }}
                  disabled={disabled}
                >
                  <EditIcon />
                </IconButton>
                {allStrategies.length > 0 && (
                  <BasicMenu
                    buttonLabel={<ArrowDropDownCircleIcon />}
                    menuItems={options}
                    menuAction={(id) => {
                      if (id !== item.id) onHeaderClick?.(id);
                    }}
                    showSelected={true}
                    initialSelectedItems={strategyId ? [strategyId] : []}
                    disabled={disabled}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
        {/*<div style={{ display: 'flex' }}>
          {strategiesWithChild.length > 0 &&
            strategiesWithChild.map((item) => (
              <Box
                key={item?.id}
                className="mb-2 ml-2 flex max-w-[200px] cursor-pointer items-center rounded-md border-[1px] border-dashed border-brown-dark !bg-[#FAF2EC66] p-4"
                onClick={() => onHeaderClick?.(item?.id)}
              >
                <Box sx={{ marginLeft: 0 }}>
                  <Typography
                    style={{ color: 'black' }}
                    variant={'body2'}
                    sx={{
                      mt: 0,
                    }}
                  >
                    {item.name} ({dayjs(item.startDate).format("MMM'YY")} to{' '}
                    {dayjs(item.endDate).format("MMM'YY")}){' '}
                  </Typography>
                  {item.hasChildren && (
                    <Chip
                      label={'Main Strategy'}
                      sx={{ marginLeft: 1, marginBottom: 1 }}
                      color={'info'}
                      variant={'outlined'}
                      size={'small'}
                    ></Chip>
                  )}
                  {dropdownOptions && item && (
                    <StatusDropdown
                      onChange={dropDownStatusChanged}
                      dataTestId={'statusDropdown'}
                      item={item}
                      dropdownOptions={dropdownOptions as DropdownOptions}
                      disabled
                    />
                  )}
                </Box>
              </Box>
            ))}
        </div>*/}
        {editingItem != null && (
          <StrategyEditDialog
            open={open}
            handleClose={() => setOpen(false)}
            item={editingItem}
            selectableParentStrategies={selectableParentStrategies}
            setLocalStrategy={setLocalStrategy}
            dropdownOptions={dropdownOptions}
            addedStrategy={(v) => updateFilters('strategyId', v)}
          />
        )}
      </>
    );
  },
);
export default StrategyCard;
