import { CheckCircle, Info } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';

import { DropdownOption } from '@/components/dropdownOption.type';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
    },
  },
};

interface Props {
  items: string[] | DropdownOption[];
  onItemsSelected: (selectedItems: string[]) => void;
  selectedItems: string[];
  label: string;
  className?: string;
  hideSelectAll?: boolean;
  parentClass?: string;
  width?: string | number | undefined;
  disabled?: boolean;
}

const MultiCheckbox: React.FC<Props> = ({
  items,
  onItemsSelected,
  selectedItems,
  label,
  className,
  hideSelectAll,
  parentClass,
  width,
  disabled = false,
}) => {
  const topItemRef = React.useRef<HTMLLIElement>(null);
  const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
    const {
      target: { value },
    } = event;

    if (!hideSelectAll && value[value.length - 1] === 'Select All') {
      onItemsSelected(
        selectedItems.length === items.length
          ? []
          : items.map((i) => {
              if (typeof i === 'string') {
                return i;
              } else {
                return i.id;
              }
            }),
      );
      if (topItemRef.current) {
        setTimeout(() => {
          topItemRef.current!.scrollIntoView();
        }, 0);
      }
      return;
    }
    const newSelectedItems = typeof value === 'string' ? value.split(',') : value;

    onItemsSelected(newSelectedItems);
  };

  const stringToComponentMap: { [key: string]: ReactNode } = {
    Info: <Info fontSize="small" color="info" />,
    Check: <CheckCircle fontSize="small" color="success" />,
  };
  const parseTitle = (title: string, color?: string): ReactNode => {
    const titleComponents: ReactNode[] = [];
    let match;
    let lastIndex = 0;

    // Create a regex pattern that looks for substrings like <Info />, <Warning />, etc.
    const regex = /<(\w+) \/>/g;

    while ((match = regex.exec(title)) !== null) {
      // Push text before the match
      if (match.index > lastIndex) {
        titleComponents.push(title.slice(lastIndex, match.index));
      }

      // Push the matched component
      const componentName = match[1];
      if (stringToComponentMap[componentName]) {
        titleComponents.push(stringToComponentMap[componentName]);
      }

      lastIndex = regex.lastIndex;
    }

    // Push any remaining text
    if (lastIndex < title.length) {
      titleComponents.push(title.slice(lastIndex));
    }

    // Apply color to the entire title if titleComponents length is 1
    if (titleComponents.length === 1) {
      return <span style={{ color }}>{title}</span>;
    }

    return titleComponents.map((comp, index) => {
      if (React.isValidElement(comp)) {
        return React.cloneElement(comp as ReactElement, { key: index });
      }
      return (
        <span key={index} style={{ color }}>
          {comp}
        </span>
      );
    });
  };

  return (
    <div
      className={`${parentClass} d-relative !min-w-fit ${disabled ? 'opacity-50' : ''}`}
    >
      <FormControl sx={{ m: 1, width: width ?? 300 }} className={className}>
        <InputLabel size="small" id="multiple-checkbox-label">
          {label}
        </InputLabel>
        <Select
          size="small"
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          autoWidth
          value={selectedItems ?? []}
          onChange={handleChange}
          input={<OutlinedInput size="small" label={label} />}
          renderValue={(selected) => {
            if (typeof items[0] === 'string') return selected.join(', ');
            else {
              const itemArr = items as DropdownOption[];
              return selected
                .map((s) => itemArr.find((i) => i.id === s)?.title)
                .join(', ');
            }
          }}
          MenuProps={MenuProps}
          disabled={disabled}
        >
          {items.length > 1 && !hideSelectAll && (
            <MenuItem key="select-all" value="Select All" ref={topItemRef}>
              <Checkbox
                checked={selectedItems.length === items.length}
                indeterminate={
                  selectedItems.length > 0 && selectedItems.length < items.length
                }
              />
              <ListItemText primary="Select All" />
            </MenuItem>
          )}
          {items
            .filter((item) => item !== null)
            .map((item) => {
              const itemId = typeof item === 'string' ? item : item.id;
              const title = typeof item === 'string' ? item : item.title;
              const color = typeof item === 'string' ? undefined : item.color;

              const titleComponent = parseTitle(title, color);

              return (
                <MenuItem key={itemId} value={itemId}>
                  <Checkbox checked={selectedItems.indexOf(itemId) > -1} />
                  <ListItemText>{titleComponent}</ListItemText>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultiCheckbox;
