import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, useTheme } from '@mui/material';
import React from 'react';

import { NewsletterForm } from './NewsletterForm';

interface NewsletterProps {
  onClose: () => void;
}

const NewsletterPopup: React.FC<NewsletterProps> = ({ onClose }) => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: -theme.spacing(1),
            color: theme.palette.grey[500],
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
            '&:hover': {
              transform: 'rotate(90deg)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <NewsletterForm onClose={onClose} variant="inline" />
      </Box>
    </>
  );
};

export default NewsletterPopup;