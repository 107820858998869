import { useMemo } from 'react';
import { Page, MenuItem } from '@/components/navigation/navPages';
import { Role } from '@/contexts/Settings.types';

export function useVisiblePages(pages: Page[], userRole: Role | undefined) {
  return useMemo(() => {
    return pages.filter((page) => {
      // If the page is a string, it's visible to all roles
      if (typeof page === 'string') {
        return true;
      }

      // If the page is an object, check if it has a roles property
      const section = Object.values(page)[0];

      // filter the report child based on role
      if (section.itemSets) {
        section.itemSets = section.itemSets
          .map((itemSet) => ({
            ...itemSet,
            items: itemSet.items.filter((item): item is Exclude<MenuItem, string> => {
              if (typeof item === 'object') {
                if (item.roles) {
                  return userRole !== undefined && item.roles.includes(userRole);
                }
              }
              return true;
            }),
          }))
          .filter((itemSet) => itemSet.items.length > 0);
      }

      // If the roles property is undefined, include the page
      if (section.roles === undefined) {
        return true;
      }

      // If the roles property is not undefined, check if the user's role is in the roles array
      return userRole !== undefined && section.roles.includes(userRole);
    });
  }, [pages, userRole]);
}
