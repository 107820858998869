import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { ComponentPropsWithoutRef } from 'react';

import { ChessIcon } from '@/components/icons/ChessIcon';

type IconType =
  | typeof RocketLaunchIcon
  | typeof CrisisAlertIcon
  | typeof AssignmentTurnedInIcon;

type HeaderIconProps = {
  size?: number;
  name: 'Actions' | 'Deliverables' | 'Contributions' | 'Strategies';
} & ComponentPropsWithoutRef<IconType>;

const HeaderIcon = ({ name, size, ...restProps }: HeaderIconProps) => {
  switch (name) {
    case 'Actions':
      return (
        <RocketLaunchIcon {...restProps} sx={{ pl: 1, pr: 1, fontSize: size ?? 40 }} />
      );
    case 'Deliverables':
      return (
        <CrisisAlertIcon {...restProps} sx={{ pl: 1, pr: 1, fontSize: size ?? 40 }} />
      );
    case 'Contributions':
      return (
        <AssignmentTurnedInIcon
          {...restProps}
          sx={{ pl: 1, pr: 1, fontSize: size ?? 40 }}
        />
      );
    case 'Strategies':
      return <ChessIcon {...restProps} sx={{ pl: 1, pr: 1, fontSize: size ?? 40 }} />;
    default:
      <></>;
  }
  return name;
};

export default HeaderIcon;
