import { Grid, MenuItem } from '@mui/material';
import { useFieldArray, useWatch } from 'react-hook-form';

import HookFormDatePicker from '@/components/HookFormDatePicker';
import HookFormSelect from '@/components/HookFormSelect';

interface Props {
  contributionIndex: number;
  control: any;
}

const ContributionPeriodsEdit = ({ contributionIndex, control }: Props) => {
  const { fields: periodFields } = useFieldArray({
    control,
    name: `contributions.${contributionIndex}.contributionPeriods`,
  });

  return (
    <Grid
      container
      spacing={2}
      item
      xs={12}
      sx={{
        mx: '0.25rem',
      }}
    >
      {periodFields.map((period, periodIndex) => {
        const frequencyValue = useWatch({
          control,
          name: `contributions.${contributionIndex}.contributionPeriods.${periodIndex}.periodicity`,
        });
        
        return (
          <Grid item xs={12} key={period.id}>
            <Grid container spacing={2}>
              <Grid item xs={5} sx={{ paddingLeft: '0 !important' }}>
                <HookFormDatePicker
                  isMonthPicker={true}
                  name={`contributions.${contributionIndex}.contributionPeriods.${periodIndex}.fromDate`}
                  label="From Date"
                  returnFormat="yyyy-MM-dd"
                />
              </Grid>
              {frequencyValue !== 0 && (
                <Grid item xs={5}>
                  <HookFormDatePicker
                    isMonthPicker={true}
                    name={`contributions.${contributionIndex}.contributionPeriods.${periodIndex}.toDate`}
                    label="To Date"
                    returnFormat="yyyy-MM-dd"
                  />
                </Grid>
              )}
              <Grid item xs={2}>
                <HookFormSelect
                  label={'Frequency'}
                  name={`contributions.${contributionIndex}.contributionPeriods.${periodIndex}.periodicity`}
                >
                  <MenuItem value={2}>Monthly</MenuItem>
                  <MenuItem value={4}>Quarterly</MenuItem>
                  <MenuItem value={8}>Twice a year</MenuItem>
                  <MenuItem value={16}>Annual</MenuItem>
                  <MenuItem value={0}>Once</MenuItem>
                </HookFormSelect>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ContributionPeriodsEdit;
