import { styled } from '@mui/material/styles';

const HeaderDiv = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: -15px;
  padding-inline: 15px;
  container-type: inline-size;
  @container (max-width: 300px) {
    flex-direction: column;
  }
`;
export default HeaderDiv;
