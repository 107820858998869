import React from 'react';

import { AnchorButtonProps } from './customComponentTypes';

export const AnchorButton: React.FC<AnchorButtonProps> = ({
  children,
  href,
  backgroundcolor = '#9c5738',
  textcolor = 'white',
  opennewtab,
}) => (
  <a
    href={href}
    className="mb-3"
    style={{
      padding: '8px 16px',
      backgroundColor: backgroundcolor,
      color: textcolor,
      borderRadius: '15px',
      textDecoration: 'none',
      border: 'none',
      display: 'inline-block',
    }}
    target={opennewtab ? '_blank' : '_self'}
    rel="noreferrer"
  >
    {children}
  </a>
);
