import {
  AccountInfo,
  InteractionRequiredAuthError,
  SilentRequest,
} from '@azure/msal-browser';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponseHeaders,
  InternalAxiosRequestConfig,
} from 'axios';

import msalInstance from '@/hooks/useMsal';

interface ResponseData {
  detail?: string;
}

export interface ResponseRequireConfirmation {
  type?: string;
  requireConfirmation?: boolean;
  message?: string;
}

interface RetryableAxiosRequestConfig extends InternalAxiosRequestConfig {
  retry?: number;
  method?: string;
}

const MAX_RETRY_ATTEMPTS = 3;

async function acquireToken(account: AccountInfo): Promise<string> {
  const silentRequest: SilentRequest = {
    scopes: ['api://3ac46749-417f-4cdb-a79b-a30f5fc1e63b/access_as_user'],
    account,
  };

  try {
    await msalInstance.initialize();
    const response = await msalInstance.acquireTokenSilent(silentRequest);
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      const response = await msalInstance.acquireTokenPopup(silentRequest);
      return response.accessToken;
    } else {
      throw error;
    }
  }
}

const createAxiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.VITE_APP_BASE_URL,
    withCredentials: true,
    timeout: 60000,
  });

  instance.interceptors.request.use(
    async (config) => {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        const accessToken = await acquireToken(accounts[0]);
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      config.headers['Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

      return config;
    },
    (error) => Promise.reject(error),
  );

  let isRefreshing = false;
  let failedQueue: Array<{
    resolve: (value?: unknown) => void;
    reject: (reason?: any) => void;
  }> = [];

  const processQueue = (error: AxiosError | null, token: string | null = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  instance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as RetryableAxiosRequestConfig;
      if (!originalRequest) {
        return Promise.reject(error);
      }

      if (originalRequest.method?.toUpperCase() !== 'GET') {
        return Promise.reject(error);
      }

      if (error.response?.status === 400 && error.response?.data === 'reload_page') {
        window.location.reload();
        return Promise.reject(error);
      }

      if (error.response?.status !== 401) {
        return Promise.reject(error);
      }

      if (error.response?.headers['token-expired'] !== 'true') {
        if (
          error.response?.headers['user-not-exist'] === 'true' ||
          error.response?.headers['user-not-active'] === 'true'
        ) {
          msalInstance.logoutRedirect();
          return Promise.reject(error);
        }
      }

      if (!originalRequest.retry) {
        originalRequest.retry = 0;
      }

      if (originalRequest.retry >= MAX_RETRY_ATTEMPTS) {
        return Promise.reject(error);
      }

      originalRequest.retry += 1;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => instance(originalRequest))
          .catch((err) => Promise.reject(err));
      }

      isRefreshing = true;

      try {
        await instance.post(`/login/refresh`);
        processQueue(null);
        return instance(originalRequest);
      } catch (refreshError: unknown) {
        const responseData = (refreshError as AxiosError)?.response?.data as ResponseData;
        if (
          (refreshError as AxiosError)?.response?.status === 400 &&
          responseData?.detail === 'Invalid refresh token'
        ) {
          msalInstance.logoutRedirect();
        }
        processQueue(refreshError as AxiosError);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    },
  );

  return instance;
};

const axiosInstance = createAxiosInstance();

function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError !== undefined;
}

type RequestConfig = AxiosRequestConfig & { retry?: number };

export const get = async <T>(
  url: string,
  config?: RequestConfig,
): Promise<{ data: T; status: number }> => {
  try {
    const response = await axiosInstance.get<T>(url, { ...config, method: 'GET' });
    return { data: response.data, status: response.status };
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      if (error.code === 'ECONNABORTED') {
        console.log('A timeout happened on url ' + url);
      } else if (error.code === 'ERR_NETWORK') {
        // Consider handling network errors here
      }
    }
    throw error;
  }
};

export const post = async <T>(
  url: string,
  data?: unknown,
  config?: RequestConfig,
): Promise<{ data: T; status: number; headers: AxiosResponseHeaders }> => {
  try {
    const response = await axiosInstance.post<T>(url, data, config);
    return {
      data: response.data,
      status: response.status,
      headers: response.headers as AxiosResponseHeaders,
    };
  } catch (error: unknown) {
    if (isAxiosError(error) && error.response === undefined) {
      // Consider handling network errors here
    }
    throw error;
  }
};

export const put = async <T>(
  url: string,
  data?: unknown,
  config?: RequestConfig,
): Promise<{ data: T; status: number }> => {
  try {
    const response = await axiosInstance.put<T>(url, data, config);
    return { data: response.data, status: response.status };
  } catch (error: unknown) {
    if (isAxiosError(error) && error.response === undefined) {
      // Consider handling network errors here
    }
    throw error;
  }
};

export const deleteRequest = async <T>(
  url: string,
  data?: any,
  config?: RequestConfig,
): Promise<{ data: T; status: number }> => {
  try {
    const response = await axiosInstance.request<T>({
      url,
      method: 'DELETE',
      data,
      ...config,
    });
    if (response.status < 200 || response.status >= 300) {
      throw new Error(`Failed to delete data: ${response.statusText}`);
    }
    return { data: response.data, status: response.status };
  } catch (error: unknown) {
    if (isAxiosError(error) && error.response === undefined) {
      // Consider handling network errors here
    }
    throw error;
  }
};
interface CustomXMLHttpRequest extends XMLHttpRequest {
  seenBytes?: number;
}

export const getStream = async <T>(
  url: string,
  onChunk: (chunk: string) => void,
  config?: RequestConfig,
): Promise<void> => {
  try {
    const response = await axiosInstance.post(url, config?.data, {
      ...config,
      responseType: 'text',
      onDownloadProgress: (progressEvent) => {
        const xhr = progressEvent.event.srcElement;
        const newData = xhr.responseText.slice(xhr.seenBytes || 0);
        xhr.seenBytes = xhr.responseText.length;

        if (newData) {
          // Remove empty arrays and trim whitespace
          const cleanedData = newData.replace(/\[\s*\]/g, '');
          if (cleanedData) {
            console.log('Received chunk:', cleanedData); // Debug log
            onChunk(cleanedData);
          }
        }
      },
    });

    // if (response.data && typeof response.data === 'string') {
    //   const remainingData = response.data.slice(response.data.lastIndexOf('\n') + 1);
    //   if (remainingData) {
    //     console.log('Received final chunk:', remainingData); // Debug log
    //     onChunk(remainingData);
    //   }
    // }
  } catch (error) {
    console.error('Error:', error);

    throw error;
  }
};
export default axiosInstance;
