import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Card,
  CardContent,
  CircularProgress,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { Link } from '@tanstack/react-router';
import React from 'react';

import { useFetchData } from '@/helpers/hooks';

interface BusinessUnitProgressInfo {
  businessUnitName: string;
  progresses: ProgressInfo[];
}

interface ProgressInfo {
  name: string;
  completed: boolean;
  id: string;
  dueDate: Date;
  progressOrder: string;
  period: string;
  bupStatus: 'Approved' | 'InProgress' | 'ActionRequired' | 'ApprovalRequested';
}

interface GroupedProgress extends ProgressInfo {
  businessUnitName: string;
}

const InfoPopover = ({
  contributionId,
  anchorEl,
  disabled,
  handleClose,
}: {
  contributionId: string;
  anchorEl: HTMLButtonElement;
  handleClose: () => void;
  disabled: boolean;
}) => {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { isFetching, data } = useFetchData<BusinessUnitProgressInfo[]>(
    ['contributionInfo'],
    `/Contribution/ContributionInfo?id=${contributionId}`,
  );

  return (
    <>
      <Popover
        id={id}
        open={true}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Card sx={{ overflow: 'scroll' }}>
          <CardContent sx={{ maxWidth: 800 }}>
            {isFetching ? (
              <CircularProgress></CircularProgress>
            ) : (
              <>
                <Button
                  variant={'text'}
                  sx={{ marginLeft: '-8px', color: 'inherit', fontSize: 12 }}
                  onClick={handleClose}
                >
                  <ArrowBackIcon />
                  Back
                </Button>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  Related progress updates
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  All Progress updates related to this question are listed below
                </Typography>
                {data && data.length > 0 ? (
                  data.map((d) => (
                    <Stack
                      key={d.businessUnitName}
                      direction={'row'}
                      sx={{
                        padding: '6px',
                        borderRadius: '8px',
                        marginTop: '12px',
                        backgroundColor: '#9C57381A',
                        width: 'max-content',
                        minWidth: 400,
                      }}
                    >
                      <Typography
                        variant="h1"
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          margin: 0,
                          width: '200px',
                          alignSelf: 'center',

                          color: (theme) => {
                            return theme.palette.primary.main;
                          },
                        }}
                      >
                        {d.businessUnitName}
                      </Typography>
                      {d.progresses.map((p) => {
                        const colorCode = (() => {
                          console.log(p);
                          switch (true) {
                            case p.bupStatus === 'Approved' && !p.completed:
                              return {
                                inner: '#DC2626',
                                border: '#DC2626',
                                text: '#FFFFFF',
                                arrow: '#FFFFFF',
                                status: 'Approved-Not-Answered',
                              };
                            case p.bupStatus === 'Approved' && p.completed:
                              return {
                                inner: '#219653',
                                border: '#219653',
                                text: '#FFFFFF',
                                arrow: '#FFFFFF',
                                status: 'Approved-Answered',
                              };
                            case p.completed:
                              return {
                                inner: '#2196531A',
                                border: '#219653',
                                text: 'inherit',
                                arrow: '#A76337',
                                status: 'Answered',
                              };
                            default:
                              return {
                                inner: '#E9A6001A',
                                border: '#E9A600',
                                text: 'inherit',
                                arrow: '#A76337',
                                status: 'Not Answered',
                              };
                          }
                        })();
                        return (
                          <div
                            style={{
                              flexShrink: 0,
                              marginLeft: 'auto',
                            }}
                            key={p.name}
                          >
                            <Link
                              title={colorCode.status}
                              className="div-hover"
                              to={
                                disabled
                                  ? ''
                                  : `/collaborate/edit?progress=${p.id}&q=${p.progressOrder}`
                              }
                              disabled={disabled}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button
                                sx={{
                                  marginRight: 1,
                                  border: `3px solid ${colorCode.border}`,
                                  padding: '5px',
                                  borderRadius: '18px',
                                  whiteSpace: 'noWrap',
                                  backgroundColor: colorCode.inner,
                                  '&:hover': {
                                    border: `3px solid ${colorCode.border}`,
                                    filter: 'brightness(90%)',
                                  },
                                  '&:active': {
                                    border: `3px solid ${colorCode.border}`,
                                    filter: 'brightness(90%)',
                                  },
                                }}
                                variant={'outlined'}
                              >
                                <Typography
                                  sx={{
                                    pr: 1,
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: colorCode.text,
                                  }}
                                >
                                  {dayjs(p.period).format('MMM-YYYY')}
                                </Typography>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.75"
                                    y="0.75"
                                    width="22.5"
                                    height="22.5"
                                    rx="11.25"
                                    stroke={colorCode.arrow}
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    d="M8.22476 15.7749C8.28283 15.8331 8.35182 15.8794 8.42779 15.9109C8.50376 15.9425 8.58521 15.9587 8.66747 15.9587C8.74973 15.9587 8.83118 15.9425 8.90715 15.9109C8.98312 15.8794 9.05212 15.8331 9.11018 15.7749L14.7086 10.1764V14.0827C14.7086 14.2484 14.7745 14.4074 14.8917 14.5246C15.0089 14.6418 15.1679 14.7077 15.3336 14.7077C15.4994 14.7077 15.6583 14.6418 15.7756 14.5246C15.8928 14.4074 15.9586 14.2484 15.9586 14.0827V8.66602C15.9586 8.50026 15.8928 8.34128 15.7756 8.22407C15.6583 8.10686 15.4994 8.04102 15.3336 8.04102H9.91695C9.75119 8.04102 9.59222 8.10686 9.47501 8.22407C9.3578 8.34128 9.29195 8.50026 9.29195 8.66602C9.29195 8.83178 9.3578 8.99075 9.47501 9.10796C9.59222 9.22517 9.75119 9.29102 9.91695 9.29102H13.8232L8.22476 14.8905C8.16665 14.9485 8.12056 15.0175 8.0891 15.0933C8.05765 15.1692 8.04146 15.2505 8.04146 15.3327C8.04146 15.4148 8.05765 15.4961 8.0891 15.572C8.12056 15.6479 8.16665 15.7168 8.22476 15.7749Z"
                                    fill={colorCode.arrow}
                                  />
                                </svg>
                              </Button>
                            </Link>
                          </div>
                        );
                      })}
                    </Stack>
                  ))
                ) : (
                  <Typography
                    variant={'h5'}
                    sx={{
                      mt: 1,
                      fontSize: '1rem',
                    }}
                  >
                    No record found
                  </Typography>
                )}
                <div className="mt-3">
                  <Typography
                    sx={{
                      display: 'inline',
                    }}
                  >
                    <FiberManualRecordIcon
                      sx={{ color: '#FC9A3B', fontSize: 14, paddingBottom: '2px' }}
                    ></FiberManualRecordIcon>
                    Incomplete
                  </Typography>
                  <Typography
                    sx={{
                      display: 'inline',
                      ml: 2,
                    }}
                  >
                    <FiberManualRecordIcon
                      sx={{ color: '#219653', fontSize: 14, paddingBottom: '2px' }}
                    ></FiberManualRecordIcon>
                    Completed
                  </Typography>
                </div>
              </>
            )}
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

export default InfoPopover;
