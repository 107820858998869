import { styled } from '@mui/material/styles';

const StyledMarkdown = styled('div')(({ theme }) => ({
  fontSize: '1rem',

  '& h1': {
    fontSize: 'clamp(1.8em, 2vw, 3em)',
    marginBottom: '0.1em',
    fontWeight: 'bold',
  },
  '& h2': {
    fontSize: 'clamp(1.4em, 1.8vw, 1.8em)',
    marginBottom: '0.6em',
    fontWeight: '400',
  },
  '& h3': {
    fontSize: 'clamp(1.3em, 1.6vw, 1.6em)',
    marginBottom: '1em',
  },
  '& h4': {
    fontSize: 'clamp(1.2em, 1.4vw, 1.4em)',
    marginBottom: '1em',
  },
  '& h5': {
    fontSize: 'clamp(1.1em, 1.2vw, 1.2em)',
    marginBottom: '1em',
  },
  '& h6': {
    fontSize: 'clamp(1em, 1vw, 1em)',
    marginBottom: '1em',
  },
  '& p': {
    marginBottom: '1em',
    lineHeight: '1.6',
  },
  '& a': {
    color: 'blue',
    textDecoration: 'underline',
  },
  '& ul': {
    marginBottom: '1em',
  },
  '& ul > li': {
    listStyleType: 'disc',
    marginLeft: '2em',
  },
  '& li + li': {
    marginTop: '0.8em',
  },
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '0.5em',
  },
  '& ol': {
    counterReset: 'item',
    listStyleType: 'none',
    marginBottom: '1em',
    paddingLeft: '0',
  },
  '& li': {
    lineHeight: '2',
  },
  '& ol > li': {
    counterIncrement: 'item',
    display: 'flex', // Use flexbox
    alignItems: 'flex-start', // Align items to the top
    marginBottom: '0.5em', // Add some space between list items

    '&::before': {
      content: 'counter(item) "."',
      fontWeight: 'bold',
      minWidth: '1.5em', // Provide some fixed width for the numbers
      marginRight: '0.5em', // Add some space between the number and text
    },
  },
  // Updated table styles
  '& .table-container': {
    width: '100%',
    marginBottom: '2rem',
    overflowX: 'auto',
  },
  '& .responsive-table': {
    width: '100%',
    maxWidth: '600px',
    borderCollapse: 'collapse',
    color: '#000',
    fontSize: '1rem',
    margin: '0 auto',
  },
  '& .responsive-table td': {
    padding: '12px',
    border: '1px solid #ddd',
  },
  '& .responsive-table td:first-of-type': {
    backgroundColor: '#fae2d5',
  },

  // Updated checklist styles
  '& .checklist-container': {
    backgroundColor: '#f2f2f2',
    padding: '2rem',
    marginBottom: '2rem',
    lineHeight: '1.6',
    color: 'black',
    fontWeight: 500,
    fontSize: '1.1rem',
    borderRadius: '15px',
    width: '100%',
    maxWidth: '600px',
    marginInline: 'auto',
  },
  '& .checklist-item': {
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  '& .checklist-item:last-child': {
    marginBottom: 0,
  },
  '& .checklist-item::before': {
    content: '"✅"',
    marginRight: '10px',
    fontSize: '1.2em',
  },

  // Responsive styles
  [theme.breakpoints.down('lg')]: {
    '& .responsive-table, & .checklist-container': {
      maxWidth: '600px',
    },
    '& .responsive-table td': {
      padding: '10px',
    },
    '& .checklist-container': {
      padding: '1.75rem',
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .responsive-table, & .checklist-container': {
      maxWidth: '600px',
      fontSize: '0.95rem',
    },
    '& .responsive-table td': {
      padding: '8px',
    },
    '& .checklist-container': {
      padding: '1.5rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .responsive-table, & .checklist-container': {
      maxWidth: '100%',
    },
    '& .responsive-table td': {
      padding: '6px',
    },
    '& .checklist-container': {
      padding: '1.25rem',
      borderRadius: '10px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    '& .responsive-table td': {
      padding: '4px',
    },
    '& .checklist-container': {
      padding: '1rem',
      borderRadius: '8px',
    },
    '& .checklist-item': {
      marginBottom: '10px',
    },
  },
}));

export default StyledMarkdown;
