import { DropdownOption } from '@/components/dropdownOption.type';
import { AnswerStatus, StatusType } from '@/components/status/statusTypes';
import { Role } from '@/contexts/Settings.types';
import { Action } from '@/pages/strategies/types/Action.types';
import { Contribution } from '@/pages/strategies/types/Contribution.types';
import { Deliverable } from '@/pages/strategies/types/deliverable.types';

type StatusResponseItem = {
  count: number;
  area: string;
  status: string;
};

type StatusResponse = {
  statuses: StatusResponseItem[];
};

export type StrategyResponse = {
  strategies: StrategyFromResponse[];
  allowEdit: boolean;
  allowLinkDeliverable: boolean;
  allowHoldByContribution: boolean;
  allowAutoUpdate: boolean;
};

export type StrategyFromResponse = {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  progressStatus?: string;
  description?: string;
  actions: Action[];
  deliverables: Deliverable[];
  contributions: Contribution[];
  order: number;
  parentStrategyId?: string;
  statusId: string;
  hasChildren?: boolean;
  level?: number;
};

export const allowEditStrategyRoles: Role[] = [
  'Global Admin',
  'Administrator',
  'Collaborator',
  'Designer',
];

export type StatusDropdownOption = {
  color: string;
  iconName: StatusType | AnswerStatus;
} & DropdownOption;

export type DropdownOptions = {
  actionAreas: DropdownOption[];
  focusAreas: DropdownOption[];
  deliverableIndicators: DropdownOption[];
  businessUnits: DropdownOption[];
  reportTypes: DropdownOption[];
  statuses: StatusDropdownOption[];
};

export type DesignItem = {
  type: 'Strategy' | 'Action' | 'Deliverable' | 'Contribution';
  id: string;
};

export type SubIndicator = {
  id: string;
  title: string;
};
