import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

import {
  AnswerStatus,
  STATUS_WEIGHTS,
  StatusColor,
  StatusType,
} from '@/components/status/statusTypes';

type AdditionalStatusTypes = 'Action Required' | 'Approved' | 'Approval Requested';

export function calculateProgressPercentage(statuses: StatusType[]): number {
  if (statuses.length === 0) {
    return 0;
  }
  const totalWeight = statuses.reduce((sum, status) => {
    return sum + STATUS_WEIGHTS[status];
  }, 0);
  const averageWeight = totalWeight / statuses.length;
  return Math.round(averageWeight * 100);
}

const statusMap = new Map<string, StatusColor>([
  ['(in progress)', '#ffbb28'],
  ['(completed)', 'default'],
  ['on track for completion', 'secondary'],
  ['on track', 'secondary'],
  ['achieved', 'success'],
  ['completed', 'success'],
  ['yes', 'success'],
  ['action required', 'warning'],
  ['at risk', 'warning'],
  ['partially met', 'default'],
  ['in progress', 'info'],
  ['ongoing', 'secondary'],
  ['not achieved', 'error'],
  ['no', 'error'],
  ['not started', 'default'],
  ['not due', 'default'],
]);

// New custom hook
export function useStatusColor() {
  const theme = useTheme();

  return useMemo(() => {
    function getColor(
      status: StatusType | AnswerStatus | AdditionalStatusTypes,
      isForChip = false,
      getHex = false,
    ): StatusColor | string {
      if (!status) return 'inherit';

      const lowercaseStatus = status.toLowerCase().trim();

      // First try exact match
      let color = statusMap.get(lowercaseStatus);

      // If no exact match, try partial match
      if (!color) {
        for (const [key, value] of statusMap) {
          if (lowercaseStatus.includes(key)) {
            color = value;
            break;
          }
        }
      }

      if (!color || (color === 'default' && !isForChip)) return 'inherit';

      // If it's already a hex color, return it directly
      if (color.startsWith('#')) return color;

      if (getHex) {
        switch (color) {
          case 'secondary':
            return theme.palette.secondary.main;
          case 'success':
            return theme.palette.success.main;
          case 'warning':
            return theme.palette.warning.main;
          case 'info':
            return theme.palette.info.main;
          case 'error':
            return theme.palette.error.main;
          case 'default':
            return theme.palette.grey[400];
          default:
            return 'inherit';
        }
      }

      return color;
    }

    return {
      getColor,
      calculateProgressPercentage,
    };
  }, [theme]);
}
