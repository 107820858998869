import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  alpha,
  Box,
  Chip,
  CircularProgress,
  Divider,
  ListItem,
  ListItemText,
  styled,
  TextField,
} from '@mui/material';
import Autocomplete, { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import React, { memo, useCallback, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ZodTypeAny } from 'zod';

import { DropdownOption } from '@/components/dropdownOption.type';
import useDisplayFeedback from '@/components/hooks/useDisplayFeedback';
import { isFieldRequired } from '@/helpers/formUtils';
import { emptyGuid } from '@/helpers/utils';

type AutocompleteProps = React.ComponentProps<typeof Autocomplete>;

type Props = {
  name: string;
  label: string;
  options: DropdownOption[];
  displayStatus?: boolean;
  onChangeInterceptor?: (
    event: React.SyntheticEvent<Element, Event>,
    value: unknown,
  ) => unknown | undefined;
  onTextFieldMouseDown?: () => void;
  schema?: ZodTypeAny;
  allowDeleteDisabled?: boolean;
} & Omit<AutocompleteProps, 'renderInput'>;

const StyledChip = styled(Chip)<{ isdisabled: string }>(() => ({
  variants: [
    {
      props: {
        isDisabled: 'true',
      },

      style: {
        opacity: 0.6,
      },
    },
  ],
}));

const HookFormAutoComplete = ({
  name,
  label,
  options,
  displayStatus = true,
  onChangeInterceptor,
  onTextFieldMouseDown,
  schema,
  allowDeleteDisabled = false,
  ...restProps
}: Props) => {
  const { control, formState } = useFormContext();
  const displayFeedback = useDisplayFeedback({ formState, name });
  const [isDropboxOpen, setIsDropboxOpen] = useState<boolean>(false);
  const addStatusIconToEndAdornment = (endAdornment: any) => {
    const children = React.Children.toArray(endAdornment?.props.children);
    if (displayStatus) {
      if (displayFeedback === 'submitting')
        children.push(<CircularProgress size={20} key={'loading'} />);
      if (displayFeedback === 'success')
        children.push(<CheckCircleIcon color="success" key={'success'} />);
    }
    return React.cloneElement(endAdornment, {}, children);
  };
  const textFieldRef = useRef<HTMLElement>();

  const required = schema !== undefined && isFieldRequired(schema, name);

  const renderOption = React.useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: unknown,
      state: AutocompleteRenderOptionState,
    ) => {
      const typedOption = option as DropdownOption & { inputValue?: string };
      const isAddNewOption =
        'inputValue' in typedOption && typedOption.inputValue === 'AddNew';

      return (
        <React.Fragment key={typedOption.id}>
          {isAddNewOption && <Divider sx={{ width: '100%', marginBottom: '4px' }} />}
          <ListItem
            {...('key' in props ? (({ key, ...rest }) => rest)(props) : props)}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: '#faf0e6',
              },
              color: isAddNewOption ? '#A76337' : 'inherit',
            }}
          >
            <ListItemText
              primary={typedOption.title}
              primaryTypographyProps={{
                style: {
                  color: isAddNewOption ? '#A76337' : 'inherit',
                },
              }}
            />
            {state.selected && (
              <Box sx={{ color: '#A76337' }}>
                <CheckIcon />
              </Box>
            )}
          </ListItem>
        </React.Fragment>
      );
    },
    [],
  );

  const handleKeyPress = useCallback(
    (
      event: React.KeyboardEvent,
      inputValue: string | undefined,
      onChange: (value: unknown) => void,
      value: any[],
    ) => {
      if (event.key === 'Enter' && inputValue) {
        event.preventDefault();

        const existingOption = options.find((q) => q.title === inputValue);
        if (existingOption) {
          if (restProps.multiple) {
            const newValue = [...(value as string[]), existingOption.id];
            onChange(newValue);
          } else {
            onChange(existingOption.id);
          }

          return;
        }

        const addNewOption = {
          id: 'new',
          title: inputValue,
          inputValue: 'AddNew',
        };
        if (onChangeInterceptor) {
          const interceptedValue = onChangeInterceptor(event as any, [addNewOption]);
          onChange(interceptedValue);
        } else {
          onChange([addNewOption]);
        }
      }
    },
    [onChangeInterceptor],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
        return (
          <Autocomplete
            ref={ref}
            size={'small'}
            options={options}
            getOptionLabel={(option) => (option as DropdownOption).title || ''}
            getOptionDisabled={(option) => (option as DropdownOption).disabled || false}
            disableCloseOnSelect={restProps.multiple}
            disabled={restProps.disabled}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const dropdownOption = option as DropdownOption;
                const { key: _key, ...props } = getTagProps({ index });
                return (
                  <StyledChip
                    key={dropdownOption.id}
                    label={dropdownOption.title}
                    size={'small'}
                    {...props}
                    isdisabled={dropdownOption.disabled ? 'true' : 'false'}
                    onDelete={
                      allowDeleteDisabled || !dropdownOption.disabled
                        ? props.onDelete
                        : undefined
                    }
                    sx={{
                      backgroundColor: alpha('#A76337', 0.1),
                      color: '#3F2E03',
                      '& .MuiChip-deleteIcon': {
                        color: '#A76337',
                        width: '1.2rem',
                        height: '1.2rem',
                        padding: '0.050625rem 0 0 0',
                      },
                    }}
                  />
                );
              })
            }
            renderOption={renderOption}
            renderInput={(params) => {
              let otherParams = params;

              if (Object.prototype.hasOwnProperty.call(params, 'key')) {
                const { _key, ...rest } = params as any;
                otherParams = rest;
              }
              return (
                <TextField
                  onMouseDown={onTextFieldMouseDown}
                  {...otherParams}
                  label={
                    required && !restProps.disabled ? (
                      <span>
                        {label} <span style={{ color: 'red' }}>*</span>
                      </span>
                    ) : (
                      label
                    )
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: addStatusIconToEndAdornment(
                      params.InputProps.endAdornment,
                    ),
                    style: { fontSize: 15 },
                  }}
                  helperText={error?.message}
                  inputRef={textFieldRef}
                  error={!!error}
                />
              );
            }}
            onOpen={() => setIsDropboxOpen(true)}
            onClose={() => setIsDropboxOpen(false)}
            onChange={(e, v) => {
              let newValue = v;
              if (onChangeInterceptor) newValue = onChangeInterceptor(e, v);
              if (Array.isArray(newValue)) {
                if (textFieldRef.current && !isDropboxOpen) {
                  textFieldRef.current.focus();
                }
                onChange((v as DropdownOption[]).map((x) => x.id));
                if (textFieldRef.current && !isDropboxOpen) {
                  textFieldRef.current.blur();
                }
              } else if (newValue !== undefined)
                onChange((newValue as DropdownOption)?.id || emptyGuid);
            }}
            value={
              typeof value === 'string'
                ? (options.find((x) => x.id === value) ?? null)
                : options.filter((x) => (value as string[])?.includes(x.id))
            }
            {...restProps}
            onKeyDown={(event) => {
              const inputValue = event.currentTarget.querySelector('input')?.value;
              handleKeyPress(event, inputValue, onChange, value);
            }}
          />
        );
      }}
    ></Controller>
  );
};

export default memo(HookFormAutoComplete);
