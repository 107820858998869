import CloseIcon from '@mui/icons-material/Close';
import { Box, Collapse, Dialog, Tab, Tabs, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { useNavigate, useSearch } from '@tanstack/react-router';
import React, { useCallback, useState } from 'react';

import { EmailIcon } from '@/components/icons/EmailIcon';
import { EmailIconColored } from '@/components/icons/EmailIconColored';
import { SsoIcon } from '@/components/icons/SsoIcon';
import EmailLoginDialog from '@/components/navigation/EmailLoginDialog';
import EmailResetForm from '@/components/navigation/EmailResetForm';
import EmailSSOLoginForm from '@/components/navigation/EmailSSOLoginForm';
import Logo2 from '@/public/images/homepage/brown-circle.png';
import LoginBg from '@/public/images/homepage/login-bg.png';
import LoginImage1 from '@/public/images/homepage/login-m1.png';
import LoginImage2 from '@/public/images/homepage/login-m2.png';
import WeavrTextLogo from '@/public/images/text-logo1.svg';
import WeavrLogo from '@/public/images/weavr_logo.png';

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel({ children, value, index }: TabPanelProps) {
  const isActive = value === index;

  return (
    <div
      role="tabpanel"
      hidden={!isActive}
      id={`login-tabpanel-${index}`}
      aria-labelledby={`login-tab-${index}`}
    >
      {isActive && <Box>{children}</Box>}
    </div>
  );
}

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  resetUserId: string | undefined;
  resetToken: string | undefined;
};

const LoginDialog = ({ open, setOpen, resetUserId, resetToken }: Props) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const isReset = !!resetUserId && !!resetToken;

  const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  }, []);

  const handleResetSuccess = useCallback(() => {
    navigate({ to: '/' });
  }, [navigate]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '24px',
        },
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '826px',
          },
        },
      }}
    >
      <Paper className="flex h-[491px]">
        <Paper sx={{ flex: '1 0 476px', padding: 3.5 }}>
          <div className="space-y-8">
            <div className="flex items-center">
              <img src={WeavrLogo} alt="Weavr Logo" className="h-[35px] pr-1" />
              <img src={WeavrTextLogo} alt="Weavr Logo" className="h-[14px] pr-1" />
            </div>
            <Collapse in={!isReset} sx={{ marginTop: '0 !important' }}>
              <div className="space-y-4">
                <div className="space-y-1">
                  <Typography
                    variant={'h2'}
                    sx={{
                      fontWeight: 600,
                      fontSize: 21,
                      color: '#492B18',
                    }}
                  >
                    Sign in
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 13,
                      width: 400,
                    }}
                  >
                    Preferably opt for the first option should you encounter any problems
                    feel free to reach out to us
                  </Typography>
                </div>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label="login options"
                  TabIndicatorProps={{ sx: { height: '4px' } }}
                  sx={{
                    height: '50px',
                    '& .MuiTab-root': {
                      minHeight: '20px',
                      textTransform: 'none',
                      color: '#0000006b',
                      '&.Mui-selected': {
                        color: '#000000',
                      },
                    },
                  }}
                >
                  <Tab
                    label="Single Sign-On"
                    icon={<SsoIcon />}
                    iconPosition="start"
                    sx={{ fontSize: 15 }}
                    aria-controls="login-tabpanel-0"
                  />
                  <Tab
                    label="Email & Password"
                    icon={activeTab === 1 ? <EmailIconColored /> : <EmailIcon />}
                    iconPosition="start"
                    sx={{ fontSize: 15 }}
                    aria-controls="login-tabpanel-1"
                  />
                </Tabs>
                <div>
                  <TabPanel value={activeTab} index={0}>
                    <EmailSSOLoginForm />
                  </TabPanel>
                  <TabPanel value={activeTab} index={1}>
                    <EmailLoginDialog />
                  </TabPanel>
                </div>
              </div>
            </Collapse>
            <Collapse in={isReset}>
              <EmailResetForm
                resetToken={resetToken!}
                resetUserId={resetUserId!}
                onResetSuccess={handleResetSuccess}
              />
            </Collapse>
          </div>
        </Paper>
        <Paper
          sx={{
            position: 'relative',
            backgroundColor: '#9C5738',
            overflow: 'hidden',
            flex: '1 0 350px',
          }}
        >
          <IconButton
            sx={{ float: 'right', zIndex: 99, mr: 1, mt: 1 }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
          <img
            style={{
              position: 'absolute',
              opacity: 0.1,
              transform: 'matrix(1.5, -3, -3, -1.5, 100, 200)',
            }}
            src={LoginBg}
            alt="Background"
          />
          <div
            style={{
              position: 'absolute',
              width: 350,
              top: -30,
              left: 50,
            }}
          >
            <img
              loading="lazy"
              title="demo image"
              alt="login backgound img 1"
              src={LoginImage2}
              className="media h-full w-full object-cover object-center"
            />
          </div>
          <div style={{ position: 'absolute', width: 350, top: 50, left: -40 }}>
            <img
              loading="lazy"
              title="demo image"
              alt="login backgound img 2"
              src={LoginImage1}
              className="media h-full w-full object-cover object-center"
            />
          </div>
          <div style={{ position: 'absolute', top: 310, textAlign: 'center' }}>
            <div className="flex flex-col items-center">
              <span className="my-2 flex items-center">
                <img
                  loading="lazy"
                  title="software"
                  alt="software logo"
                  src={Logo2}
                  className="media h-5 w-5 object-cover object-center"
                />
                <Typography
                  sx={{
                    color: '#fff',
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                >
                  Software
                </Typography>
              </span>
              <Typography
                sx={{
                  color: '#fff',
                  fontWeight: 700,
                  fontSize: 18,
                  marginTop: 1,
                }}
              >
                Welcome to weavr software
              </Typography>
              <Typography
                sx={{
                  color: '#fff',
                  fontWeight: 400,
                  fontSize: 12,
                  marginX: 4,
                  marginTop: 1,
                }}
              >
                Explore the depth and diversity of Aboriginal Cultures - a vibrant
                tapestry of ancient wisdom, art, and resilience
              </Typography>
            </div>
          </div>
        </Paper>
      </Paper>
    </Dialog>
  );
};

export default LoginDialog;
