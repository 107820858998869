import { useFetchData } from '@/helpers/hooks';
import { RolesResponse } from '@/pages/globalAdmin/roles/Roles';
import { Subscription } from '@/pages/globalAdmin/users/Users';

const useSubscriptionsAndRoles = () => {
  const { data: subscriptions } = useFetchData<Subscription[]>(
    ['Subscriptions'],
    '/Subscription/GetSubscriptions',
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: roles } = useFetchData<RolesResponse[]>(['roles'], '/User/GetRoles');

  return { subscriptions, roles };
};

export default useSubscriptionsAndRoles;
