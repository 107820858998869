import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Grid2 as Grid,
  styled,
} from '@mui/material';
import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { sort } from 'fast-sort';
import { enqueueSnackbar } from 'notistack';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';
import { useShallow } from 'zustand/react/shallow';

import { DropdownOption } from '@/components/dropdownOption.type';
import useAddTakeTourButton from '@/components/hooks/useAddTakeTourButton';
import LoadingCircular from '@/components/LoadingCircular';
import { useStatusColor } from '@/components/status/statusFunctions';
import { StatusType } from '@/components/status/statusTypes';
import { useFetchData } from '@/helpers/hooks';
import { emptyGuid, naturalSort } from '@/helpers/utils';
import ActionCard from '@/pages/strategies/ActionCard';
import ContributionCard from '@/pages/strategies/ContributionCard';
import DeliverableCard from '@/pages/strategies/DeliverableCard';
import LinkDeliverableDialog from '@/pages/strategies/LinkDeliverableDialog';
import StrategyPanelHeader from '@/pages/strategies/StrategyPanelHeader';
import { StrategySearchPanel } from '@/pages/strategies/StrategySearchPanel';
import StrategyTopArea from '@/pages/strategies/strategyTopArea/StrategyTopArea';
import {
  Contribution,
  ContributionPeriod,
  Periodicity,
} from '@/pages/strategies/types/Contribution.types';
import { Deliverable } from '@/pages/strategies/types/deliverable.types';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';
import { strategyItemFilters } from '@/types/itemFilter';

import { useStrategyStore } from './store/useStrategyStore';
import { Action } from './types/Action.types';
import {
  DropdownOptions,
  StatusDropdownOption,
  StrategyFromResponse,
  StrategyResponse,
  SubIndicator,
} from './types/Strategy.types';

const StyledDiv = styled('div')(() => ({
  maxHeight: '1000px',
  overflowY: 'scroll',
  padding: 15,
  margin: '-10px -18px -10px -18px',
  zIndex: 0,
  position: 'relative',
}));

const Strategies: React.FC = () => {
  useAddTakeTourButton();
  const counter = useRef(0);

  const routeApi = getRouteApi('/strategies');
  const navigate = useNavigate({ from: routeApi.id });
  const updateFilters = useCallback(
    (name: keyof strategyItemFilters, value: unknown) => {
      navigate({ search: (prev: any) => ({ ...prev, [name]: value }) });
    },
    [navigate],
  );
  const {
    strategyId,
    actionId: primaryActionId,
    deliverableId: primaryDeliverableId,
    contributionId: primaryContributionId,
    deliverableDue: filterDeliverableDue,
    status: filteredDeliverableStatuses,
    businessUnit: filteredBusinessUnits,
    actionArea: filteredActionAreas,
    frequency: filteredFrequency,
    responseType: filteredResponseTypes,
  } = routeApi.useSearch();
  counter.current = counter.current + 1;

  const globalSettings = useGlobalSettingsStore(
    useShallow((state) => state.globalSettings),
  );

  const isGlobalAdmin = globalSettings.role === 'Global Admin';
  const isEditStrategyPageEnabled = useMemo(() => {
    const validRolesArray = globalSettings.strategyEditRoles.split(',');
    return !!validRolesArray.find((q) => q === globalSettings.role);
  }, [globalSettings]);
  const organisationId = globalSettings.organisationId;

  const [isLinkDeliverableOpen, setIsLinkDeliverableOpen] = useState<boolean>(false);
  /*Get Dropdown options*/
  const { data: dropdownOpts } = useFetchData<DropdownOptions>(
    ['DropdownOptions'],
    '/Strategy/DropdownOptions',
    {
      refetchOnWindowFocus: true,
    },
  );

  const {
    isLoading,
    isFetching,
    data: strategyResponseData,
  } = useFetchData<StrategyResponse>(['strategyData'], `/strategy/GetAll`, {
    refetchOnWindowFocus: false,
  });
  const { data: subindicators } = useFetchData<SubIndicator[]>(
    ['SubIndicators'],
    'AppLookup/SubIndicators',
    {
      enabled: isGlobalAdmin,
    },
  );

  const {
    div1,
    div2,
    div3,
    setParentStrategies,
    allowLinkDeliverable,
    setAllowLinkDeliverable,
    setSelectableLinkedDeliverable,
    setStrategyEnd,
    setStrategyStart,
    setDeliverableExpectedDate,
    setDeliverableBusinessUnits,
    setSubIndicators,
    setAllowAutoUpdate,
    setActiveContributions,
    setAllowHoldByContribution,
    defaultStatusId,
    setDefaultStatusId,
    primaryStrategy,
    setPrimaryStrategy,
    allStrategies,
    setAllStrategies,
    allActions,
    allDeliverables,
    setAllDeliverables,
    setAllActions,
    allContributions,
    setAllContributions,
    quantities,
    setQuantities,
    filteredDeliverableDue,
    dropdownOptions,
    setDropdownOptions,
    selectedActionIds,
    selectedDeliverableIds,
    selectedContributionIds,
    setSelectedActionIds,
    setSelectedDeliverableIds,
    setSelectedContributionIds,
    setFilteredDeliverableDue,
    activeActions,
    activeDeliverableIds,
    activeContributionIds,
    setActiveActions,
    setActiveDeliverableIds,
    setActiveContributionIds,
    clearActiveItems,
  } = useStrategyStore(
    useShallow((state) => ({
      div1: state.div1,
      div2: state.div2,
      div3: state.div3,
      setParentStrategies: state.setParentStrategies,
      allowLinkDeliverable: state.allowLinkDeliverable,
      setAllowLinkDeliverable: state.setAllowLinkDeliverable,
      setSelectableLinkedDeliverable: state.setSelectableLinkedDeliverable,
      setStrategyEnd: state.setStrategyEnd,
      setStrategyStart: state.setStrategyStart,
      setDeliverableExpectedDate: state.setDeliverableExpectedDate,
      setDeliverableBusinessUnits: state.setDeliverableBusinessUnits,
      setSubIndicators: state.setSubIndicators,
      setAllowAutoUpdate: state.setAllowAutoUpdate,
      setActiveContributions: state.setActiveContributions,
      setAllowHoldByContribution: state.setAllowHoldByContribution,
      setDefaultStatusId: state.setDefaultStatusId,
      defaultStatusId: state.defaultStatusId,
      primaryStrategy: state.primaryStrategy,
      setPrimaryStrategy: state.setPrimaryStrategy,
      allStrategies: state.allStrategies,
      setAllStrategies: state.setAllStrategies,
      allActions: state.allActions,
      setAllActions: state.setAllActions,
      allDeliverables: state.allDeliverables,
      setAllDeliverables: state.setAllDeliverables,
      allContributions: state.allContributions,
      setAllContributions: state.setAllContributions,
      quantities: state.quantities,
      setQuantities: state.setQuantities,
      filteredDeliverableDue: state.filteredDeliverableDue,
      dropdownOptions: state.dropdownOptions,
      setDropdownOptions: state.setDropdownOptions,
      selectedActionIds: state.selectedActionIds,
      selectedDeliverableIds: state.selectedDeliverableIds,
      selectedContributionIds: state.selectedContributionIds,
      setSelectedActionIds: state.setSelectedActionIds,
      setSelectedDeliverableIds: state.setSelectedDeliverableIds,
      setSelectedContributionIds: state.setSelectedContributionIds,
      setFilteredDeliverableDue: state.setFilteredDeliverableDue,
      activeActions: state.activeActions,
      activeDeliverableIds: state.activeDeliverableIds,
      activeContributionIds: state.activeContributionIds,
      setActiveActions: state.setActiveActions,
      setActiveDeliverableIds: state.setActiveDeliverableIds,
      setActiveContributionIds: state.setActiveContributionIds,
      clearActiveItems: state.clearActiveItems,
    })),
  );

  const [foundActions, setFoundActions] = useState<Action[]>([]);
  const [foundDeliverables, setFoundDeliverables] = useState<Deliverable[]>([]);
  const [foundContributions, setFoundContributions] = useState<Contribution[]>([]);

  const [actionSelectAll, setActionSelectAll] = useState<boolean>(false);
  const [deliverableSelectAll, setDeliverableSelectAll] = useState<boolean>(false);
  const [contributionSelectAll, setContributionSelectAll] = useState<boolean>(false);

  /*Handle Add button click*/
  const [addingAction, setAddingAction] = useState<Action | null>(null);
  const [addingDeliverable, setAddingDeliverable] = useState<Deliverable | null>(null);
  const [addingContribution, setAddingContribution] = useState<Contribution | null>(null);

  const [deliverablesStatusList, setDeliverableStatusList] = useState<
    StatusDropdownOption[]
  >([]);

  useEffect(() => {
    const strategies = strategyResponseData?.strategies || [];
    const buildOrderedStrategies = (
      parentId: string | null,
      level = 0,
    ): StrategyFromResponse[] => {
      const children = strategies
        .filter((x) => {
          if (!parentId) {
            // Root
            return !x.parentStrategyId;
          }
          return parentId === x.parentStrategyId;
        })
        .sort((a, b) => a.order - b.order);

      let result: StrategyFromResponse[] = [];

      children.forEach((strategy) => {
        const nestedChildren = buildOrderedStrategies(strategy.id, level + 1);

        result.push({
          ...strategy,
          hasChildren: nestedChildren.length > 0,
          level,
        });
        if (nestedChildren.length > 0) {
          result = result.concat(nestedChildren);
        }
      });
      return result;
    };

    const orderedStrategies = buildOrderedStrategies(null);
    setAllStrategies(orderedStrategies);
  }, [setAllStrategies, strategyResponseData]);

  // AllActions, AllDeliverables, AllContributions are used for url mapping, use all***InStrategy below for items filtered by strategy
  useEffect(() => {
    setAllDeliverables(allStrategies.map((s) => s.deliverables).flat());
    setAllActions(allStrategies.map((s) => s.actions).flat());
    setAllContributions(allStrategies.map((s) => s.contributions).flat());
  }, [allStrategies, setAllActions, setAllContributions, setAllDeliverables]);

  useEffect(() => {
    const s = allStrategies.find((x) => x.id === strategyId);
    if (s) setPrimaryStrategy(s);
  }, [allStrategies, setPrimaryStrategy, strategyId]);

  const allActionsInStrategy: Action[] = useMemo(() => {
    return primaryStrategy?.actions?.length ? primaryStrategy?.actions.flat() : [];
  }, [primaryStrategy]);

  const allDeliverablesInStrategy: Deliverable[] = useMemo(() => {
    return primaryStrategy?.deliverables?.length
      ? primaryStrategy?.deliverables.flat()
      : [];
  }, [primaryStrategy]);

  const allContributionsInStrategy: Contribution[] = useMemo(
    () =>
      primaryStrategy?.contributions?.length ? primaryStrategy?.contributions.flat() : [],
    [primaryStrategy],
  );

  const responseTypes = useMemo(() => {
    if (strategyResponseData === undefined || !primaryStrategy?.contributions) return [];

    const responseTypesWithCount = primaryStrategy.contributions.reduce(
      (acc: Record<string, number>, curr) => {
        const responseType = curr.responseType;
        acc[responseType] = (acc[responseType] || 0) + 1;
        return acc;
      },
      {},
    );

    const responseTypeItems = Object.entries(responseTypesWithCount).map(
      ([key, value]) => `${key} (${value})`,
    );

    return responseTypeItems.map(
      (type): DropdownOption => ({
        id: type,
        title: type.replace(/([A-Z])/g, (match, p1, offset) =>
          offset > 0 ? ` ${p1}` : p1,
        ),
      }),
    );
  }, [strategyResponseData, primaryStrategy]);

  /* Checkbox */
  const selectedContributions = useMemo(
    () =>
      allContributionsInStrategy.filter((c) => selectedContributionIds.includes(c.id)),
    [allContributionsInStrategy, selectedContributionIds],
  );

  const handleActionCheckboxClick = (id: string) => {
    if (!isEditStrategyPageEnabled) return;

    if (selectedContributionIds.length > 0) setSelectedContributionIds([]);
    if (selectedDeliverableIds.length > 0) setSelectedDeliverableIds([]);
    if (selectedActionIds.includes(id)) {
      setSelectedActionIds(selectedActionIds.filter((x) => x !== id));
    } else {
      setSelectedActionIds([id, ...selectedActionIds]);
    }
  };

  const handleDeliverableCheckboxClick = (id: string) => {
    if (!isEditStrategyPageEnabled) return;

    if (selectedContributionIds.length > 0) setSelectedContributionIds([]);
    if (selectedActionIds.length > 0) setSelectedActionIds([]);
    if (selectedDeliverableIds.includes(id)) {
      setSelectedDeliverableIds(selectedDeliverableIds.filter((x) => x !== id));
    } else {
      setSelectedDeliverableIds([id, ...selectedDeliverableIds]);
    }
  };

  const handleContributionCheckboxClick = (id: string) => {
    if (!isEditStrategyPageEnabled) return;

    if (selectedDeliverableIds.length > 0) setSelectedDeliverableIds([]);
    if (selectedActionIds.length > 0) setSelectedActionIds([]);
    if (selectedContributionIds.includes(id)) {
      setSelectedContributionIds(selectedContributionIds.filter((x) => x !== id));
    } else {
      setSelectedContributionIds([id, ...selectedContributionIds]);
    }
  };

  const filteredDeliverableByBusinessUnits = useMemo(() => {
    return allDeliverablesInStrategy.filter((d) =>
      filteredBusinessUnits?.some((fbu) => d?.businessUnitIds?.includes(fbu)),
    );
  }, [filteredBusinessUnits, allDeliverablesInStrategy]);

  const filteredContributionByFrequency = useMemo(() => {
    return allContributionsInStrategy.filter((c) =>
      filteredFrequency?.some((f) =>
        c.contributionPeriods.some((cp) => cp.periodicity.toString() === f),
      ),
    );
  }, [allContributionsInStrategy, filteredFrequency]);

  const filteredContributionByResponseType = useMemo(() => {
    const newFilteredResponseTypes =
      filteredResponseTypes?.map((x) => x.split(' ')[0]) || [];
    return allContributionsInStrategy.filter((c) =>
      newFilteredResponseTypes.includes(c.responseType),
    );
  }, [allContributionsInStrategy, filteredResponseTypes]);

  const filteredDeliverableByDue = useMemo(() => {
    return allDeliverablesInStrategy.filter((d) =>
      filteredDeliverableDue.some((f) => d.targetEndDate === f),
    );
  }, [allDeliverablesInStrategy, filteredDeliverableDue]);

  const filteredDeliverableByStatus = useMemo(() => {
    return allDeliverablesInStrategy.filter((d) =>
      filteredDeliverableStatuses?.includes(d.statusId),
    );
  }, [allDeliverablesInStrategy, filteredDeliverableStatuses]);

  const filteredActions = useMemo(() => {
    let actions = foundActions.length > 0 ? foundActions : allActionsInStrategy;
    if (filteredBusinessUnits?.length) {
      actions = actions.filter((a) =>
        filteredDeliverableByBusinessUnits?.some((d) => d.actionId === a.id),
      );
    }
    if (filteredFrequency?.length) {
      actions = actions.filter((a) =>
        filteredContributionByFrequency.some((c) => c.actionId === a.id),
      );
    }

    if (filteredActionAreas?.length) {
      actions = actions.filter((a) => filteredActionAreas.some((c) => c === a.areaId));
    }
    if (filteredDeliverableStatuses?.length) {
      actions = actions.filter((a) =>
        filteredDeliverableByStatus.some((d) => d.actionId == a.id),
      );
    }

    if (filteredResponseTypes?.length) {
      actions = actions.filter((a) =>
        filteredContributionByResponseType.some((c) => c.actionId === a.id),
      );
    }

    if (filteredDeliverableDue?.length) {
      actions = actions.filter((a) =>
        filteredDeliverableByDue.some((d) => d.actionId === a.id),
      );
    }

    return actions;
  }, [
    allActionsInStrategy,
    filteredActionAreas,
    filteredBusinessUnits?.length,
    filteredContributionByFrequency,
    filteredContributionByResponseType,
    filteredDeliverableByBusinessUnits,
    filteredDeliverableByDue,
    filteredDeliverableByStatus,
    filteredDeliverableDue?.length,
    filteredDeliverableStatuses?.length,
    filteredFrequency?.length,
    filteredResponseTypes?.length,
    foundActions,
  ]);

  const { getColor } = useStatusColor();

  useEffect(() => {
    setAllowLinkDeliverable(strategyResponseData?.allowLinkDeliverable ?? false);
  }, [strategyResponseData, setAllowLinkDeliverable]);

  useEffect(() => {
    const dropdownOptionsCopy = structuredClone(dropdownOpts);
    dropdownOptionsCopy?.statuses.forEach((s) => {
      s.color = getColor(s.title as StatusType, true, true) || '';
      s.iconName = s.title as StatusType;
    });
    setDropdownOptions(dropdownOptionsCopy);
    setDefaultStatusId(
      dropdownOptionsCopy?.statuses?.find((x) => x.title === 'Not started')?.id || '',
    );
  }, [dropdownOpts, getColor, setDefaultStatusId, setDropdownOptions]);

  const orderedActions = useMemo(() => {
    return naturalSort(filteredActions).asc([
      (a) => a.order,
      (a) => {
        const num = parseInt(a.order);
        return isNaN(num) ? Infinity : num;
      },
    ]);
  }, [filteredActions]);

  const allGroupedDeliverable = useMemo(() => {
    if (!allowLinkDeliverable) return [];
    const d = allDeliverables.filter((x) => !!x.groupedById);
    return sort(d).asc([(x) => x.actionOrder, (x) => x.groupedById]);
  }, [allDeliverables, allowLinkDeliverable]);

  const allLinkedDeliverables = useMemo(() => {
    if (!allowLinkDeliverable) return [];
    return allDeliverables.filter((x) => !!x.linkedDeliverableId);
  }, [allDeliverables, allowLinkDeliverable]);

  useEffect(() => {
    allLinkedDeliverables.forEach((x) => {
      const parent = allDeliverables.find((d) => d.id === x.linkedDeliverableId);
      if (parent) parent.replicateDeliverableId = x.id;
    });
  }, [allDeliverables, allLinkedDeliverables]);

  const filteredDeliverables: Deliverable[] = useMemo(() => {
    let deliverables =
      foundDeliverables.length > 0 ? foundDeliverables : allDeliverablesInStrategy;
    deliverables = deliverables.filter(
      (d) => filteredActions.length && filteredActions.some((a) => a.id === d.actionId),
    );
    if (filteredBusinessUnits?.length) {
      deliverables = filteredDeliverableByBusinessUnits;
    }
    if (filteredFrequency?.length) {
      deliverables = deliverables.filter((d) =>
        filteredContributionByFrequency.some((c) => c.deliverableId === d.id),
      );
    }
    if (activeActions.length) {
      deliverables = deliverables.filter((d) => activeActions.includes(d.actionId));
    }
    if (primaryStrategy) {
      deliverables = deliverables.filter((d) => d.strategyId === primaryStrategy.id);
    }

    if (deliverables.length && dropdownOptions?.statuses !== undefined) {
      const statuses = dropdownOptions?.statuses;
      setDeliverableStatusList(
        statuses?.filter((s) => deliverables.some((d) => d.statusId === s.id)),
      );
    }

    if (filteredDeliverableStatuses?.length) {
      deliverables = filteredDeliverableByStatus;
    }

    if (filteredDeliverableByDue?.length) {
      deliverables = filteredDeliverableByDue;
    }

    if (filteredResponseTypes?.length) {
      deliverables = deliverables.filter((d) =>
        filteredContributionByResponseType.some((c) => c.deliverableId === d.id),
      );
    }
    return deliverables;
  }, [
    foundDeliverables,
    allDeliverablesInStrategy,
    filteredBusinessUnits?.length,
    filteredFrequency?.length,
    activeActions,
    primaryStrategy,
    dropdownOptions?.statuses,
    filteredDeliverableStatuses?.length,
    filteredDeliverableByDue,
    filteredResponseTypes?.length,
    filteredActions,
    filteredDeliverableByBusinessUnits,
    filteredContributionByFrequency,
    filteredDeliverableByStatus,
    filteredContributionByResponseType,
  ]);

  const orderedDeliverables = useMemo(() => {
    const children = filteredDeliverables.filter((x) => !!x.groupedById);
    if (children.length === 0) {
      const parent = naturalSort(filteredDeliverables).asc((d) => [
        d.actionOrder,
        d.order,
      ]);
      const orderedDeliverable: Deliverable[] = [];
      parent.forEach((x) => {
        if (x.replicateDeliverableId) {
          orderedDeliverable.push(x);
          const linkedGroupedItems = allGroupedDeliverable.filter(
            (d) => d.groupedById === x.replicateDeliverableId,
          );
          orderedDeliverable.push(...linkedGroupedItems);
        } else orderedDeliverable.push(x);
      });
      return orderedDeliverable;
    } else {
      let orderedDeliverable: Deliverable[] = [];
      const parent = naturalSort(filteredDeliverables.filter((x) => !x.groupedById)).asc(
        (d) => [d.actionOrder, d.order],
      );
      parent.forEach((x) => {
        const childDeliverable = children.filter((c) => c.groupedById == x.id);
        if (childDeliverable.length > 0) {
          orderedDeliverable.push({ hasChildren: true, ...x });
          orderedDeliverable = orderedDeliverable.concat(
            childDeliverable.sort((a, b) => {
              return a.order.localeCompare(b.order);
            }),
          );
        } else {
          orderedDeliverable.push(x);
        }
      });
      return orderedDeliverable;
    }
  }, [allGroupedDeliverable, filteredDeliverables]);

  const orderedContributionsInStrategy: Contribution[] = useMemo(() => {
    return allContributionsInStrategy.map((contribution) => {
      const deliverable = allDeliverablesInStrategy.find(
        (d) => d.id === contribution.deliverableId,
      );
      return {
        ...contribution,
        businessUnitIds: deliverable?.businessUnitIds || [],
      };
    });
  }, [allContributionsInStrategy, allDeliverablesInStrategy]);

  const filteredContributions = useMemo(() => {
    let contributions =
      foundContributions.length > 0 ? foundContributions : allContributionsInStrategy;

    // Apply business unit filtering first
    if (filteredBusinessUnits && filteredBusinessUnits.length > 0) {
      contributions = contributions.filter((c) => {
        // Check if any of the contribution's periods have override business units
        const hasOverrideBU = c.contributionPeriods.some(
          (cp) => cp.overrideBUIds && cp.overrideBUIds.length > 0,
        );

        if (hasOverrideBU) {
          // If overrides exist, only check the override business units
          return c.contributionPeriods.some(
            (cp) =>
              cp.overrideBUIds &&
              cp.overrideBUIds.some((buid) => filteredBusinessUnits?.includes(buid)),
          );
        } else {
          // If no overrides, check the associated deliverable's business units
          const associatedDeliverable = allDeliverablesInStrategy.find(
            (d) => d.id === c.deliverableId,
          );
          return (
            associatedDeliverable &&
            associatedDeliverable.businessUnitIds.some((buid) =>
              filteredBusinessUnits?.includes(buid),
            )
          );
        }
      });
    }
    // Then apply other filters
    contributions = contributions.filter(
      (c) =>
        !!filteredActions &&
        filteredActions.some((a) => a.id === c.actionId) &&
        filteredDeliverables.some((d) => d.id === c.deliverableId),
    );

    if (filteredFrequency?.length) {
      contributions = filteredContributionByFrequency.filter((c) =>
        contributions.some((fc) => fc.id === c.id),
      );
    } else if (activeDeliverableIds.length) {
      contributions = contributions.filter((d) =>
        activeDeliverableIds.includes(d.deliverableId),
      );
    } else if (activeActions.length) {
      contributions = contributions.filter((d) =>
        activeActions.includes(d.actionId as string),
      );
    } else if (primaryStrategy) {
      contributions = contributions.filter((d) => d.strategyId === primaryStrategy?.id);
    }

    if (filteredResponseTypes?.length) {
      const responseTypes = filteredResponseTypes.map((x: any) => x.split(' ')[0]);
      contributions = contributions.filter((c) => responseTypes.includes(c.responseType));
    }

    return contributions;
  }, [
    foundContributions,
    allContributionsInStrategy,
    filteredBusinessUnits,
    filteredActions,
    filteredDeliverables,
    filteredFrequency,
    filteredContributionByFrequency,
    activeDeliverableIds,
    activeActions,
    primaryStrategy,
    allDeliverablesInStrategy,
    filteredResponseTypes,
  ]);
  const orderedContributions = useMemo(() => {
    return naturalSort(filteredContributions).asc((c) => [
      c.actionOrder,
      c.deliverableOrder,
      c.order,
    ]);
  }, [filteredContributions]);

  useEffect(() => {
    setDeliverableSelectAll(
      filteredDeliverables
        .filter((x) => !x.linkedDeliverableId)
        .every((d) => selectedDeliverableIds.includes(d.id)),
    );
  }, [filteredDeliverables, selectedDeliverableIds]);

  useEffect(() => {
    setContributionSelectAll(
      filteredContributions.every((c) => selectedContributionIds.includes(c.id)),
    );
  }, [filteredContributions, selectedContributionIds]);

  useEffect(() => {
    setQuantities({
      Actions: filteredActions.length,
      Deliverables: filteredDeliverables.length,
      Contributions: filteredContributions.length,
    });
  }, [filteredActions, filteredDeliverables, filteredContributions, setQuantities]);

  const handleActionSelectAll = () => {
    if (!isEditStrategyPageEnabled) return;

    if (selectedActionIds.length > 0) setSelectedActionIds([]);
    if (!actionSelectAll) {
      const idSet = new Set(selectedActionIds);
      filteredActions.forEach((x) => idSet.add(x.id));
      setSelectedActionIds([...idSet]);
    } else setSelectedActionIds([]);
    setActionSelectAll(!actionSelectAll);
  };

  const handleDeliverableSelectAll = () => {
    if (!isEditStrategyPageEnabled) return;

    if (selectedContributionIds.length > 0) setSelectedContributionIds([]);
    if (!deliverableSelectAll) {
      const idSet = new Set(selectedDeliverableIds);
      filteredDeliverables
        .filter((x) => !x.linkedDeliverableId)
        .forEach((x) => idSet.add(x.id));
      setSelectedDeliverableIds([...idSet]);
    } else setSelectedDeliverableIds([]);
    setDeliverableSelectAll(!deliverableSelectAll);
  };

  const handleContributionSelectAll = () => {
    if (selectedDeliverableIds.length > 0) setSelectedDeliverableIds([]);
    if (!contributionSelectAll) {
      const idSet = new Set(selectedContributionIds);
      filteredContributions.forEach((x) => idSet.add(x.id));
      setSelectedContributionIds([...idSet]);
    } else setSelectedContributionIds([]);
    setContributionSelectAll(!contributionSelectAll);
  };

  const hasRunInitialEffect = useRef(false);

  useEffect(() => {
    hasRunInitialEffect.current = false;
  }, [organisationId]);

  useEffect(() => {
    if (strategyId && !allStrategies.some((x) => x.id === strategyId)) {
      clearActiveItems(updateFilters);
    }
    if (!strategyId && allStrategies.length > 0)
      updateFilters('strategyId', allStrategies[0].id);
    if (
      hasRunInitialEffect.current ||
      (allActionsInStrategy.length === 0 &&
        allDeliverablesInStrategy.length === 0 &&
        allContributionsInStrategy.length === 0)
    )
      return;
    if (primaryActionId) {
      const action = allActionsInStrategy.find((x) => x.id === primaryActionId);
      if (action) {
        setActiveActions([primaryActionId]);
        updateFilters('strategyId', action.strategyId);
      } else {
        updateFilters('actionId', undefined);
      }
    }
    if (primaryDeliverableId) {
      if (allDeliverablesInStrategy.find((x) => x.id === primaryDeliverableId)) {
        setActiveDeliverableIds([primaryDeliverableId]);
        const deliverable = allDeliverablesInStrategy.find(
          (x) => x.id === primaryDeliverableId,
        );
        if (deliverable) {
          setActiveActions([deliverable.actionId]);
          updateFilters('strategyId', deliverable.strategyId);
          updateFilters('actionId', deliverable.actionId);
        }
      } else {
        updateFilters('deliverableId', undefined);
      }
    }
    if (primaryContributionId) {
      if (allContributionsInStrategy.find((x) => x.id === primaryContributionId)) {
        setActiveContributionIds([primaryContributionId]);
        const contribution = allContributionsInStrategy.find(
          (x) => x.id === primaryContributionId,
        );
        if (contribution) {
          setActiveActions([contribution.actionId as string]);
          updateFilters('strategyId', contribution.strategyId);
          setActiveDeliverableIds([contribution.deliverableId]);
          updateFilters('actionId', contribution.actionId);
          updateFilters('deliverableId', contribution.deliverableId);
        }
      } else {
        updateFilters('contributionId', undefined);
      }
    }

    if (filterDeliverableDue?.length === 1 && filterDeliverableDue[0] === '3') {
      setFilteredDeliverableDue(['next-three-months']);
    }
    hasRunInitialEffect.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allStrategies,
    allContributionsInStrategy,
    allDeliverablesInStrategy,
    allActionsInStrategy,
  ]); //Only load strategies during the initial rendering.

  const clearParams = useCallback(() => {
    updateFilters('strategyId', undefined);
    updateFilters('actionId', undefined);
    updateFilters('deliverableId', undefined);
    updateFilters('contributionId', undefined);
  }, [updateFilters]);

  const handleStrategyHeaderClick = useCallback(
    (strategyId?: string, isNew?: boolean) => {
      clearParams();
      setActiveActions([]);
      setActiveDeliverableIds([]);
      setActiveContributionIds([]);
      if (isNew) {
        const strategy = allStrategies[allStrategies.length - 1];

        if (strategy !== undefined) {
          setPrimaryStrategy(strategy);
          updateFilters('strategyId', strategy.id);
        }
      } else if (strategyId) {
        updateFilters('strategyId', strategyId);
        localStorage.setItem('localStrategyId', strategyId);
      }
    },
    [allStrategies, clearParams, setPrimaryStrategy, updateFilters],
  );

  const handleActionHeaderClick = useCallback(
    (x: Action) => {
      if (activeActions.includes(x.id)) {
        setActiveActions(activeActions.filter((a) => a !== x.id));
        updateFilters('actionId', undefined);
        updateFilters('deliverableId', undefined);
        updateFilters('contributionId', undefined);
      } else {
        updateFilters('actionId', x.id);
        setActiveActions([...activeActions, x.id]);
      }

      setActiveDeliverableIds(
        activeDeliverableIds.filter((d) =>
          filteredDeliverables.some((fd) => fd.id === d),
        ),
      );

      setActiveContributionIds(
        activeContributionIds.filter((c) =>
          filteredContributions.some((fc) => fc.id === c),
        ),
      );
    },
    [
      activeActions,
      activeContributionIds,
      activeDeliverableIds,
      filteredContributions,
      filteredDeliverables,
      updateFilters,
    ],
  );

  const handleDeliverableHeaderClick = useCallback(
    (x: Deliverable) => {
      if (activeDeliverableIds.includes(x.id)) {
        updateFilters('deliverableId', undefined);
        setActiveDeliverableIds(activeDeliverableIds.filter((d) => d !== x.id));
        updateFilters('contributionId', undefined);
      } else {
        updateFilters('deliverableId', x.id);
        updateFilters('actionId', x.actionId);
        setActiveDeliverableIds([...activeDeliverableIds, x.id]);
        setActiveActions([...new Set(activeActions).add(x.actionId)]);
      }

      setActiveContributionIds(
        activeContributionIds.filter((c) =>
          filteredContributions.some((fc) => fc.id === c),
        ),
      );
    },
    [
      activeActions,
      activeContributionIds,
      activeDeliverableIds,
      filteredContributions,
      updateFilters,
    ],
  );

  const handleContributionHeaderClick = useCallback(
    (x: Contribution) => {
      if (activeContributionIds.includes(x.id)) {
        updateFilters('contributionId', undefined);
        setActiveContributionIds(activeContributionIds.filter((d) => d !== x.id));
      } else {
        updateFilters('contributionId', x.id);
        updateFilters('deliverableId', x.deliverableId);
        updateFilters('actionId', x.actionId);
        setActiveContributionIds([...activeContributionIds, x.id]);
        setActiveActions([...new Set(activeActions).add(x.actionId as string)]);
        setActiveDeliverableIds([...new Set(activeDeliverableIds).add(x.deliverableId)]);
      }
    },
    [activeActions, activeContributionIds, activeDeliverableIds, updateFilters],
  );

  const primaryDeliverable: Deliverable | null = useMemo(
    () => filteredDeliverables.find((d) => d.id === primaryDeliverableId) ?? null,
    [filteredDeliverables, primaryDeliverableId],
  );

  const existedActionOrder = useMemo(() => {
    return orderedActions
      .filter((c) => c.strategyId === primaryStrategy?.id)
      .map((x) => x.order);
  }, [orderedActions, primaryStrategy]);

  const getNextOrder = useCallback((orders: string[]): string => {
    const orderNumbers = orders
      .map((order) => parseInt(order, 10))
      .filter((order) => !isNaN(order));

    const maxOrder = Math.max(...orderNumbers);

    if (orderNumbers.length === 0 || maxOrder === -Infinity) return '1';

    const nextOrderNumber = maxOrder + 1;
    return nextOrderNumber.toString();
  }, []);

  const handleActionAdd = useCallback(() => {
    if (!isEditStrategyPageEnabled) return;

    updateFilters('actionId', undefined);
    const maxOrder = getNextOrder(existedActionOrder);

    if (orderedActions?.length === 0 || (!!orderedActions && orderedActions[0]?.id)) {
      const a: Action = {
        order: maxOrder,
        name: '',
        isNew: true,
        id: emptyGuid,
      };
      setAddingAction(a);
    }
  }, [orderedActions, primaryStrategy?.id]);

  useEffect(() => {
    setStrategyEnd(new Date(primaryStrategy?.endDate ?? ''));
    setStrategyStart(new Date(primaryStrategy?.startDate ?? ''));
    setDeliverableExpectedDate(
      new Date(
        allDeliverables.find((d) => d.id === primaryDeliverableId)?.targetEndDate ?? '',
      ),
    );
    setDeliverableBusinessUnits(
      dropdownOptions?.businessUnits.filter((x) =>
        primaryDeliverable?.businessUnitIds.includes(x.id),
      ),
    );
    setSubIndicators(subindicators);
    setActiveContributions(filteredContributions);
    setAllowHoldByContribution(strategyResponseData?.allowHoldByContribution ?? false);
    setAllowAutoUpdate(strategyResponseData?.allowAutoUpdate ?? false);
  }, [
    primaryStrategy,
    allDeliverables,
    primaryDeliverableId,
    dropdownOptions,
    strategyResponseData,
    subindicators,
    filteredContributions,
  ]);

  const existedDeliverableOrder = useMemo(() => {
    return orderedDeliverables
      .filter((c) => c.actionId === primaryActionId)
      .map((x) => x.order);
  }, [orderedDeliverables, primaryActionId]);

  const handleDeliverableAdd = useCallback(() => {
    if (
      orderedDeliverables.length === 0 ||
      (!!orderedDeliverables && orderedDeliverables[0]?.id)
    ) {
      const a = allActionsInStrategy.find((x) => x.id === primaryActionId);
      const d: Deliverable = {
        name: '',
        isNew: true,
        id: emptyGuid,
        actionId: primaryActionId as string,
        statusId: defaultStatusId,
        actionOrder: a?.order,
        order: getNextOrder(existedDeliverableOrder),
        businessUnitIds: [],
        strategyId: a?.strategyId,
      };
      setAddingDeliverable(d);
    }
  }, [
    allActionsInStrategy,
    defaultStatusId,
    getNextOrder,
    orderedDeliverables,
    primaryActionId,
    existedDeliverableOrder,
  ]);

  const existedContributionOrder = useMemo(() => {
    return orderedContributions
      .filter((c) => c.deliverableId === primaryDeliverableId)
      .map((x) => x.order);
  }, [orderedContributions, primaryDeliverableId]);
  const handleContributionAdd = () => {
    if (
      orderedContributions.length === 0 ||
      (!!orderedContributions && orderedContributions[0]?.id)
    ) {
      const cp: ContributionPeriod = {
        id: emptyGuid,
        periodicity: Periodicity.Monthly,
      };
      const maxOrder = getNextOrder(existedContributionOrder);
      const c: Contribution = {
        name: '',
        isNew: true,
        id: emptyGuid,
        deliverableId: primaryDeliverableId as string,
        contributionPeriods: [cp],
        reportTypeIds: [],
        responseOptions: [],
        responseType: 'Number',
        actionOrder: primaryDeliverable?.actionOrder,
        deliverableOrder: primaryDeliverable?.order,
        order: maxOrder,
        isActive: true,
      };
      setAddingContribution(c);
    }
  };

  /*Joyride*/
  const [run, setRunJoyride] = useState(false);

  useEffect(() => {
    if (isLoading) return;

    const timer = setTimeout(() => {
      if (!localStorage.getItem('strategyTourTaken')) {
        setRunJoyride(true);
      }

      const replayHandler = () => {
        localStorage.removeItem('strategyTourTaken');
        setRunJoyride(true);
      };

      window.addEventListener('replayOnboarding', replayHandler);

      return () => {
        window.removeEventListener('replayOnboarding', replayHandler);
      };
    }, 1000); // Wait for 1 second

    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }, [isLoading]);

  const joyrideSteps = [
    {
      target: '#strategy-card',
      content:
        'Strategy details are shown here, if there are multiple strategies, click the arrow to reveal more details',
    },
    {
      target: '#status-statistic',
      content: 'Deliverable statistics based on the selected Strategy are shown here',
    },
    {
      target: '#btn-action-add',
      content: 'When a strategy is selected, you can add a new action to the strategy',
    },
    {
      target: '#select-business-unit',
      content:
        'Add business units by selecting from the dropdown box, or search by typing the name in the text box',
    },
    // { TODO fix these ids to proper element target
    //   target: '#select-response-types',
    //   content:
    //     'When the response type is SingleChoice, Checkboxes, MultipleNumberResponses, you can add new response options by typing it in the text box',
    // },
  ];

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status, index, type } = data;
      if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
        setRunJoyride(false);
        localStorage.setItem('strategyTourTaken', 'true');
      } else if (index === 2 && type === 'tooltip') {
        setActiveDeliverableIds([orderedDeliverables[0]?.id]);
      } else if (index === 3 && type === 'tooltip') {
        setActiveContributionIds([orderedContributions[0]?.id]);
      }
    },
    [orderedDeliverables, orderedContributions],
  );

  /*Expand All / Collapse All*/
  const [isActionsExpandAll, setIsActionsExpandAll] = useState(false);
  const [isDeliverableExpandAll, setIsDeliverableExpandAll] = useState(false);
  const [isContributionExpandAll, setIsContributionExpandAll] = useState(false);

  const handleActionsExpandAll = useCallback(() => {
    if (activeActions.length === 0) {
      setActiveActions(allActionsInStrategy.map((x) => x.id));
    } else {
      setActiveActions([]);
    }
    setIsActionsExpandAll(!isActionsExpandAll);
  }, [activeActions.length, isActionsExpandAll, allActionsInStrategy]);

  const handleDeliverablesExpandAll = useCallback(() => {
    if (activeDeliverableIds.length === 0) {
      setActiveDeliverableIds(filteredDeliverables.map((x) => x.id));
    } else {
      setActiveDeliverableIds([]);
    }
    setIsDeliverableExpandAll(!isDeliverableExpandAll);
  }, [activeDeliverableIds, filteredDeliverables, isDeliverableExpandAll]);

  const handleContributionsExpandAll = useCallback(() => {
    if (activeContributionIds.length === 0) {
      setActiveContributionIds(filteredContributions.map((x) => x.id));
    } else {
      setActiveContributionIds([]);
    }
    setIsContributionExpandAll(!isContributionExpandAll);
  }, [activeContributionIds.length, filteredContributions, isContributionExpandAll]);

  /*
   * Synchronized scroll
   */
  const actionListRef: RefObject<HTMLDivElement> = useRef(null);
  const deliverableListRef: RefObject<HTMLDivElement> = useRef(null);
  const contributionListRef: RefObject<HTMLDivElement> = useRef(null);
  const actionListRefs = useRef<Map<Action, HTMLDivElement> | null>(null);
  const deliverableListRefs = useRef<Map<Deliverable, HTMLDivElement> | null>(null);
  const contributionListRefs = useRef<Map<Contribution, HTMLDivElement> | null>(null);

  function getMap(type: 'action' | 'deliverable' | 'contribution') {
    if (type === 'action') {
      if (!actionListRefs.current) {
        actionListRefs.current = new Map<Action, HTMLDivElement>();
      }
      return actionListRefs.current;
    }
    if (type === 'deliverable') {
      if (!deliverableListRefs.current) {
        deliverableListRefs.current = new Map<Deliverable, HTMLDivElement>();
      }
      return deliverableListRefs.current;
    }
    if (type === 'contribution') {
      if (!contributionListRefs.current) {
        contributionListRefs.current = new Map<Contribution, HTMLDivElement>();
      }
      return contributionListRefs.current;
    }
  }

  const handleActionScroll = useCallback(() => {
    if (actionListRef.current) {
      const listTop = actionListRef.current.scrollTop;
      let actionOrder = '';
      let minOffset = Infinity;
      // Find the item with the smallest offset from the top
      actionListRefs.current?.forEach((ref, action) => {
        if (ref) {
          const offset = ref.offsetTop - listTop;
          if (offset >= 0 && offset < minOffset) {
            minOffset = offset;
            actionOrder = action.order;
          }
        }
      });
      if (actionOrder !== '') {
        if (deliverableListRefs.current) {
          for (const [key, value] of deliverableListRefs.current) {
            if (key.actionOrder === actionOrder) {
              if (deliverableListRef.current)
                deliverableListRef.current.scrollTop = value.offsetTop;
              break;
            }
          }
        }
        if (contributionListRefs.current) {
          for (const [key, value] of contributionListRefs.current) {
            if (key.actionOrder === actionOrder) {
              if (contributionListRef.current)
                contributionListRef.current.scrollTop = value.offsetTop;
              break;
            }
          }
        }
      }
    }
  }, []);
  const handleDeliverableScroll = useCallback(() => {
    if (deliverableListRef.current) {
      let actionOrder = '';
      const listTop = deliverableListRef.current.scrollTop;
      let minOffset = Infinity;
      deliverableListRefs.current?.forEach((ref, d) => {
        if (ref) {
          const offset = ref.offsetTop - listTop;
          if (offset >= 0 && offset < minOffset) {
            minOffset = offset;
            actionOrder = d.actionOrder ?? '';
          }
        }
      });
      if (actionOrder !== '') {
        if (actionListRefs.current) {
          for (const [key, value] of actionListRefs.current) {
            if (key.order === actionOrder) {
              if (actionListRef.current)
                actionListRef.current.scrollTop = value.offsetTop;
              break;
            }
          }
        }
        if (contributionListRefs.current) {
          for (const [key, value] of contributionListRefs.current) {
            if (key.actionOrder === actionOrder) {
              if (contributionListRef.current)
                contributionListRef.current.scrollTop = value.offsetTop;
              break;
            }
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    const actionList = actionListRef.current;
    if (actionList !== null) {
      actionList.addEventListener('scroll', handleActionScroll);
      return () => {
        actionList.removeEventListener('scroll', handleActionScroll);
      };
    }
  }, [handleActionScroll]);
  useEffect(() => {
    const deliverableList = deliverableListRef.current;
    if (deliverableList !== null) {
      deliverableList.addEventListener('scroll', handleDeliverableScroll);
      return () => {
        deliverableList.removeEventListener('scroll', handleDeliverableScroll);
      };
    }
  }, [handleDeliverableScroll]);

  const handleSearch = useCallback(
    (searchItem: string) => {
      setActiveActions([]);
      setActiveDeliverableIds([]);
      setActiveContributionIds([]);

      setFoundActions([]);
      setFoundDeliverables([]);
      setFoundContributions([]);
      if (searchItem.length) {
        const foundAction = allActionsInStrategy.filter((x) =>
          x.name?.toLowerCase().trim().includes(searchItem.toLowerCase().trim()),
        );
        const foundDeliverable = allDeliverablesInStrategy.filter((x) =>
          x.name?.toLowerCase().trim().includes(searchItem.toLowerCase().trim()),
        );
        const foundContribution = allContributionsInStrategy.filter((x) =>
          x.name?.toLowerCase().trim().includes(searchItem.toLowerCase().trim()),
        );
        setFoundContributions(foundContribution);
        const contributionParentDeliverables = allDeliverables.filter(
          (d) =>
            foundContribution.some((c) => d.id === c.deliverableId) &&
            !foundDeliverable.some((fd) => fd.id === d.id),
        );
        setFoundDeliverables([
          ...new Set([...foundDeliverable, ...contributionParentDeliverables]),
        ]);
        const contributionParentActions = allActionsInStrategy.filter(
          (a) =>
            foundContribution.some((c) => a.id === c.actionId) &&
            !foundAction.some((fa) => fa.id === a.id),
        );
        const deliverableParentActions = allActionsInStrategy.filter(
          (a) =>
            foundDeliverable.some((d) => a.id === d.actionId) &&
            !foundAction.some((fa) => fa.id === a.id),
        );
        setFoundActions([
          ...new Set([
            ...foundAction,
            ...contributionParentActions,
            ...deliverableParentActions,
          ]),
        ]);
      }
    },
    [
      allActionsInStrategy,
      allContributionsInStrategy,
      allDeliverables,
      allDeliverablesInStrategy,
    ],
  );

  // Link Deliverable
  const showLinkedDeliverable = useCallback(
    (id: string) => {
      const deliverable = allDeliverables.find((x) => x.id === id);
      const strategy = allStrategies.find((x) => x.id === deliverable?.strategyId);
      const action = allActions.find((x) => x.id === deliverable?.actionId);
      updateFilters('strategyId', strategy?.id);
      setActiveActions([action?.id || '']);
      updateFilters('actionId', action?.id);
      setActiveDeliverableIds([id]);
      updateFilters('deliverableId', id);
      enqueueSnackbar('Deliverable switched', { variant: 'info' });
    },
    [
      allActions,
      allDeliverables,
      allStrategies,
      setActiveActions,
      setActiveDeliverableIds,
      updateFilters,
    ],
  );

  const deliverablesWithChildren = useMemo(() => {
    const result: { id: string; childrenIds: DropdownOption[] }[] = [];

    allDeliverablesInStrategy.forEach((deliverable) => {
      const childrenIds = allDeliverables
        .filter((d) => d.linkedDeliverableId === deliverable.id)
        .map((d) => ({ id: d.id, title: d.name }) as DropdownOption);

      if (childrenIds.length > 0) {
        result.push({
          id: deliverable.id,
          childrenIds: childrenIds,
        });
      }
    });

    return result;
  }, [allDeliverablesInStrategy, allDeliverables]);

  useEffect(() => {
    if (!allowLinkDeliverable) return;
    if (!primaryStrategy || !primaryStrategy.parentStrategyId) return;
    const parentStrategy = allStrategies.find(
      (x) => x.id === primaryStrategy?.parentStrategyId,
    );
    setParentStrategies(parentStrategy);
  }, [allStrategies, allowLinkDeliverable, primaryStrategy, setParentStrategies]);

  const selectableParentStrategies: DropdownOption[] = useMemo(() => {
    if (!allowLinkDeliverable) return [];
    return (
      allStrategies
        //.filter((x) => !x.parentStrategyId)
        .map((x) => ({ id: x.id, title: x.name }))
    );
  }, [allStrategies, allowLinkDeliverable]);
  useEffect(() => {
    if (!allowLinkDeliverable) {
      setSelectableLinkedDeliverable([]);
    } else {
      const selectableLinkedDeliverable = allDeliverablesInStrategy
        .filter((x) => !!x.linkedDeliverableId)
        .map((x) => ({ id: x.id, title: `${x.actionOrder}.${x.order} ${x.name}` }));

      setSelectableLinkedDeliverable(selectableLinkedDeliverable);
    }
  }, [allDeliverablesInStrategy, allowLinkDeliverable, setSelectableLinkedDeliverable]);

  const hasStatusResponseType = useCallback(
    (id: string) => {
      return allContributions
        .filter((c) => c.deliverableId === id)
        .some((x) => x.responseType === 'Status');
    },
    [allContributions],
  );

  const contributionCount = useMemo(
    () => (id: string) => {
      return allContributions.filter((c) => c.deliverableId === id).length;
    },
    [allContributions],
  );

  const deliverableCount = useMemo(
    () => (id: string) => {
      return allDeliverables.filter((d) => d.actionId === id).length;
    },
    [allDeliverables],
  );

  /*Loading Circle*/
  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center" data-testid="loader">
        <LoadingCircular />
      </div>
    );
  }

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 999 })}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Joyride
        steps={joyrideSteps}
        continuous
        hideCloseButton
        run={run}
        callback={handleJoyrideCallback}
        showProgress={true}
        showSkipButton={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        disableScrolling
      />
      <div className="min-h-screen" data-testid="strategy-content">
        <div className="flex flex-grow flex-col">
          <Grid
            container
            spacing={2}
            columns={15}
            direction="row"
            sx={{
              p: 3,
              alignItems: 'stretch',
            }}
          >
            {/*Strategies Tab*/}
            <Grid className="w-[100%]">
              <StrategyTopArea
                handleStrategyHeaderClick={handleStrategyHeaderClick}
                dropdownOptions={dropdownOptions}
                selectableParentStrategies={selectableParentStrategies}
                isReadOnly={!isEditStrategyPageEnabled}
              />
            </Grid>

            {/*Search panel*/}
            <Grid
              size={{ xs: 15 }}
              sx={{
                order: { xs: 3 },
              }}
            >
              <StrategySearchPanel
                handleSearch={handleSearch}
                deliverablesStatusList={deliverablesStatusList}
                allActionsInStrategy={allActionsInStrategy}
                allDeliverables={allDeliverables}
                responseTypeItems={responseTypes}
                selectedContributions={selectedContributions}
              ></StrategySearchPanel>
            </Grid>
          </Grid>

          <div className="flex min-w-full gap-4 px-7">
            {/*Actions Tab*/}
            <div
              style={{ flexBasis: `${Math.max(div1, 0)}%` }}
              className="w-full flex-shrink flex-grow transition-all duration-300 ease-in-out"
            >
              <Card
                elevation={4}
                sx={{
                  borderTopLeftRadius: '15px 15px',
                  borderTopRightRadius: '15px 15px',
                }}
              >
                <CardContent className="!pt-0">
                  <StrategyPanelHeader
                    handleSelectAll={handleActionSelectAll}
                    parentItemId={primaryStrategy?.id || ''}
                    handleAddClick={handleActionAdd}
                    isExpandAll={activeActions.length > 0}
                    handleExpandAll={handleActionsExpandAll}
                    backgroundColor={'#9C5738'}
                    name={'Actions'}
                    count={quantities['Actions']}
                    disabled={!isEditStrategyPageEnabled}
                  />
                  <StyledDiv ref={actionListRef}>
                    {addingAction && isEditStrategyPageEnabled && (
                      <ActionCard
                        isActive={true}
                        item={addingAction}
                        onHeaderClick={(item) =>
                          item.id === emptyGuid
                            ? setAddingAction(null)
                            : handleActionHeaderClick(item)
                        }
                        dropdownOptions={dropdownOptions}
                        setAddingAction={setAddingAction}
                        existedActionOrder={existedActionOrder}
                        disabled={!isEditStrategyPageEnabled}
                      />
                    )}
                    {orderedActions?.length ? (
                      orderedActions.map((x: Action) => (
                        <ActionCard
                          isActive={activeActions.includes(x.id)}
                          key={x.id}
                          item={x}
                          onHeaderClick={(item) => handleActionHeaderClick(item)}
                          dropdownOptions={dropdownOptions}
                          setActiveActions={setActiveActions}
                          isSelected={selectedActionIds.includes(x.id)}
                          handleCheckboxClick={handleActionCheckboxClick}
                          deliverableCount={deliverableCount(x.id)}
                          ref={(node) => {
                            const map = getMap('action') as Map<Action, HTMLDivElement>;
                            if (node) {
                              map.set(x, node);
                            } else {
                              map.delete(x);
                            }
                          }}
                          existedActionOrder={existedActionOrder}
                          disabled={!isEditStrategyPageEnabled}
                        />
                      ))
                    ) : (
                      <>No Action found</>
                    )}
                  </StyledDiv>
                </CardContent>
              </Card>
            </div>
            {/*Deliverables Tab*/}
            <div
              style={{ flexBasis: `${Math.max(div2, 0)}%` }}
              className="w-full flex-shrink flex-grow transition-all duration-300 ease-in-out"
            >
              <Card
                elevation={4}
                sx={{
                  borderTopLeftRadius: '15px 15px',
                  borderTopRightRadius: '15px 15px',
                }}
              >
                <CardContent className="!pt-0">
                  <StrategyPanelHeader
                    selectedItemIds={selectedDeliverableIds}
                    handleSelectAll={handleDeliverableSelectAll}
                    parentItemId={primaryActionId}
                    handleAddClick={handleDeliverableAdd}
                    isExpandAll={activeDeliverableIds.length > 0}
                    handleExpandAll={handleDeliverablesExpandAll}
                    backgroundColor={'#E9A600'}
                    selectAll={deliverableSelectAll}
                    name={'Deliverables'}
                    handleAltOptionClicked={() => {
                      setIsLinkDeliverableOpen(true);
                    }}
                    allowLinkDeliverable={
                      allowLinkDeliverable && !!primaryStrategy?.parentStrategyId
                    }
                    count={quantities['Deliverables']}
                    disabled={!isEditStrategyPageEnabled}
                  />
                  <StyledDiv ref={deliverableListRef}>
                    {addingDeliverable && (
                      <DeliverableCard
                        isActive={true}
                        key={addingDeliverable.id}
                        onHeaderClick={(item) =>
                          item.id === emptyGuid
                            ? setAddingDeliverable(null)
                            : handleDeliverableHeaderClick(item)
                        }
                        dropdownOptions={dropdownOptions}
                        item={addingDeliverable}
                        setAddingDeliverable={setAddingDeliverable}
                        existedDeliverableOrder={existedDeliverableOrder}
                        disabled={!isEditStrategyPageEnabled}
                      ></DeliverableCard>
                    )}
                    {orderedDeliverables.length ? (
                      orderedDeliverables.map((x) => {
                        return (
                          <DeliverableCard
                            key={x.id}
                            isActive={activeDeliverableIds.includes(x.id)}
                            item={x}
                            onHeaderClick={(item) => handleDeliverableHeaderClick(item)}
                            hasStatusResponseType={hasStatusResponseType(x.id)}
                            dropdownOptions={dropdownOptions}
                            setActiveDeliverables={setActiveDeliverableIds}
                            isSelected={selectedDeliverableIds.includes(x.id)}
                            handleCheckboxClick={handleDeliverableCheckboxClick}
                            showLinkedDeliverable={showLinkedDeliverable}
                            contributionCount={contributionCount(x.id)}
                            existedDeliverableOrder={existedDeliverableOrder}
                            deliverableWithChildren={deliverablesWithChildren.find(
                              (d) => d.id === x.id,
                            )}
                            ref={(node) => {
                              const map = getMap('deliverable') as Map<
                                Deliverable,
                                HTMLDivElement
                              >;
                              if (node) {
                                map.set(x, node);
                              } else {
                                map.delete(x);
                              }
                            }}
                            disabled={!isEditStrategyPageEnabled}
                          />
                        );
                      })
                    ) : (
                      <>No Deliverable Found</>
                    )}
                  </StyledDiv>
                </CardContent>
              </Card>
            </div>
            {/*Contributions Tab*/}
            <div
              style={{ flexBasis: `${Math.max(div3, 0)}%` }}
              className="w-full flex-shrink flex-grow transition-all duration-300 ease-in-out"
            >
              <Card
                elevation={4}
                sx={{
                  borderTopLeftRadius: '15px 15px',
                  borderTopRightRadius: '15px 15px',
                }}
              >
                <CardContent className="!pt-0">
                  <StrategyPanelHeader
                    selectedItemIds={selectedContributionIds}
                    handleSelectAll={handleContributionSelectAll}
                    parentItemId={primaryDeliverableId}
                    handleAddClick={handleContributionAdd}
                    isExpandAll={activeContributionIds.length > 0}
                    handleExpandAll={handleContributionsExpandAll}
                    backgroundColor={'#492B18'}
                    selectAll={contributionSelectAll}
                    name={'Contributions'}
                    title={'Question Setup'}
                    allowAddContribution={
                      primaryDeliverable?.answerableById === undefined ||
                      primaryDeliverable?.answerableById === primaryStrategy?.id
                    }
                    count={quantities['Contributions']}
                    disabled={!isEditStrategyPageEnabled}
                  />
                  <StyledDiv ref={contributionListRef}>
                    {addingContribution && (
                      <ContributionCard
                        isActive={true}
                        primaryDeliverableId={primaryDeliverableId}
                        item={addingContribution}
                        setAddingContribution={setAddingContribution}
                        dropdownOptions={dropdownOptions}
                        handleHeaderClick={(item) =>
                          item.id === emptyGuid
                            ? setAddingContribution(null)
                            : handleContributionHeaderClick(item)
                        }
                        existedContributionOrder={existedContributionOrder}
                        businessUnits={
                          orderedContributionsInStrategy.find(
                            (c) => c.id === addingContribution?.id,
                          )?.businessUnitIds || []
                        }
                        disabled={!isEditStrategyPageEnabled}
                      ></ContributionCard>
                    )}
                    {orderedContributions.length ? (
                      orderedContributions.map((x) => {
                        return (
                          <ContributionCard
                            key={x.id}
                            isActive={activeContributionIds.includes(x.id)}
                            item={x}
                            dropdownOptions={dropdownOptions}
                            setAddingContribution={setAddingContribution}
                            handleHeaderClick={handleContributionHeaderClick}
                            setActiveContributions={setActiveContributionIds}
                            isSelected={selectedContributionIds.includes(x.id)}
                            handleCheckboxClick={handleContributionCheckboxClick}
                            existedContributionOrder={existedContributionOrder}
                            ref={(node) => {
                              const map = getMap('contribution') as Map<
                                Contribution,
                                HTMLDivElement
                              >;
                              if (node) {
                                map.set(x, node);
                              } else {
                                map.delete(x);
                              }
                            }}
                            businessUnits={
                              orderedContributionsInStrategy.find((c) => c.id === x.id)
                                ?.businessUnitIds || []
                            }
                            disabled={!isEditStrategyPageEnabled}
                          />
                        );
                      })
                    ) : (
                      <>No Contribution Found</>
                    )}
                  </StyledDiv>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        {isLinkDeliverableOpen && (
          <LinkDeliverableDialog
            isOpen={isLinkDeliverableOpen}
            setOpen={setIsLinkDeliverableOpen}
            strategies={allStrategies
              .filter((x) => x.id === primaryStrategy?.parentStrategyId)
              .map((x) => ({ id: x.id, title: x.name }))}
            deliverables={allDeliverables.filter(
              (x) =>
                x.strategyId === primaryStrategy?.parentStrategyId &&
                !x.replicateDeliverableId,
            )}
            order={getNextOrder(existedDeliverableOrder)}
            currentStrategyId={primaryStrategy?.id}
          />
        )}
      </div>
    </>
  );
};

export default Strategies;
