import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers-pro';
import React from 'react';

type MuiDatePickerProps = React.ComponentProps<typeof MuiDatePicker>;

interface Props extends MuiDatePickerProps {
  isMonthPicker?: boolean;
  size?: 'small';
}

export const DatePicker = ({ isMonthPicker, size, ...restProps }: Props) => (
  <MuiDatePicker
    format={isMonthPicker ? 'MM/yyyy' : 'dd/MM/yyyy'}
    views={isMonthPicker ? ['year', 'month'] : ['day', 'month', 'year']}
    slotProps={{
      textField: {
        size: size && size === 'small' ? 'small' : 'medium',
        InputLabelProps: {
          shrink: true,
        },
      },
    }}
    {...restProps}
    sx={{
      '& .MuiInputBase-root': {
        fontSize: 15,
      },
    }}
  ></MuiDatePicker>
);
