import Box from '@mui/material/Box';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ChessIcon = (props: SvgIconProps) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        borderRadius: '50%',
        backgroundColor: 'primary.main',
        p: 0,
        color: 'white',
      }}
    >
      <SvgIcon {...props}>
        <svg viewBox="0 0 100 100" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#ffffff"
            className="fill-000000"
            d="M63 36c0 1.1-.9 2-2 2H39c-1.1 0-2-.9-2-2s.9-2 2-2h22c1.1 0 2 .9 2 2zM34 84h32c1.1 0 2-.9 2-2s-.9-2-2-2H34c-1.1 0-2 .9-2 2s.9 2 2 2zm35 1H31c-2.2 0-4 1.8-4 4s1.8 4 4 4h38c2.2 0 4-1.8 4-4s-1.8-4-4-4zM40.973 39c-.277 29.941-2.637 33.513-3.583 40h25.22c-.946-6.487-3.306-10.059-3.583-40H40.973zm-6.008-16 3.89 10h22.291l3.89-10H34.965zm30.459-1 2.44-6.275-3.729-1.45-1.361 3.501c-1.851-.886-5.641-1.543-10.218-1.724A3.97 3.97 0 0 0 54 13a4 4 0 0 0-8 0c0 1.231.568 2.318 1.443 3.052-4.577.181-8.367.838-10.218 1.724l-1.361-3.501-3.729 1.45L34.576 22h30.848z"
          ></path>
        </svg>
      </SvgIcon>
    </Box>
  );
};
