import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';

import NotificationSidebar from '@/components/NotificationSidebar';
import { useFetchData, usePostData } from '@/helpers/hooks';

export type NotificationItem = {
  id: string;
  title: string;
  notificationType:
    | 'ProgressComment'
    | 'GoodNewsComment'
    | 'UserRegistration'
    | 'ProgressUpdateCreated'
    | 'ProgressUpdateSubmissionDue'
    | 'ProgressUpdateActionRequired'
    | 'ProgressUpdatePendingReview'
    | 'NewFeature';
  message: string;
  url: string;
  isRead: boolean;
  createdDate: Date;
  createdBy: string;
};
type Notification = {
  items: NotificationItem[];
  unSeen: number;
};

const Notification = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState<number>(0); // Initialize count to 0

  const { status, data: notification } = useFetchData<Notification>(
    ['notifications'],
    '/notification/GetUserNotifications',
  );

  const setSeen = usePostData<unknown, unknown, unknown, string>({
    url: `/notification/SetSeen`,
  });

  useEffect(() => {
    if (notification) {
      const unreadCount = notification.unSeen;
      setCount(unreadCount);
    }
  }, [notification]);

  const handleClickAway = (open: boolean) => {
    if (open) {
      setOpen(false);
    }
  };

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
    setCount(0);
    setSeen.mutate({});
  };

  return (
    <div>
      <IconButton
        title="notification"
        className="h-12 w-12 p-2"
        sx={{
          borderRadius: '0.4rem!important',
          backgroundColor: 'black!important',
        }}
        onClick={handleOpen}
      >
        <Badge
          badgeContent={status === 'pending' ? 0 : count}
          color="error"
          className={`${status === 'success' && count > 0 ? 'animate-jiggle hover:animate-none' : ''}`}
        >
          <NotificationsIcon className="text-white" style={{ fontSize: '1.6rem' }} />
        </Badge>
      </IconButton>
      {notification && (
        <ClickAwayListener onClickAway={() => handleClickAway(open)}>
          <div>
            <NotificationSidebar open={open} notifications={notification.items} />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Notification;
