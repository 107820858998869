import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';

import HierarchyChart, { HierarchyItem } from '@/components/HierarchyChart';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import { Strategy } from '@/pages/strategies/types/StrategyCard.types';
import ArtWork from '@/public/images/homepage/art-work.png';

type Props = {
  drawerOpen: boolean;
  setDrawerOpen: (b: boolean) => void;
};

const buildHierarchy = (strategies: Strategy[]): HierarchyItem[] => {
  const hierarchyMap: Record<string, HierarchyItem> = {};
  const roots: HierarchyItem[] = [];

  strategies.forEach((strategy) => {
    hierarchyMap[strategy.id] = {
      id: strategy.id,
      name: strategy.name,
      iconName: strategy.iconName,
      backgroundColor: strategy.iconColor,
      subordinates: [],
    };
  });
  strategies.forEach((strategy) => {
    if (strategy.parentStrategyId) {
      hierarchyMap[strategy.parentStrategyId]?.subordinates?.push(
        hierarchyMap[strategy.id],
      );
    } else {
      roots.push(hierarchyMap[strategy.id]);
    }
  });

  return roots;
};

export const StrategyHierarchyDrawer = ({ drawerOpen, setDrawerOpen }: Props) => {
  const allStrategies = useStrategyStore(useShallow((state) => state.allStrategies));
  const chartData = useMemo(() => buildHierarchy(allStrategies), [allStrategies]);
  return (
    <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Box
        sx={{
          padding: 2,
          width: '400px',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: (theme) => theme.palette.backgroundBrown.main,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 100,
            left: -800,
            right: 0,
            bottom: 0,
            height: '100%',
            backgroundImage: `url(${ArtWork})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.06,
            transform: 'rotate(-10deg)',
          },
        }}
      >
        <div className={'inline-flex w-[100%] items-center justify-between'}>
          <Typography variant={'h3'} mb={2}>
            Strategy Hierarchy
          </Typography>
          <IconButton onClick={() => setDrawerOpen(false)}>
            <ArrowBackRoundedIcon></ArrowBackRoundedIcon>
          </IconButton>
        </div>
        <div className={'mt-5 flex flex-col items-center justify-between gap-4'}>
          {chartData.map((d) => (
            <HierarchyChart
              data={d}
              key={d.id}
              setDrawerOpen={setDrawerOpen}
            ></HierarchyChart>
          ))}
        </div>
      </Box>
    </Drawer>
  );
};
