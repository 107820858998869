import { zodResolver } from '@hookform/resolvers/zod';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  CardContent,
  Checkbox,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { isEmpty } from 'lodash-es';
import { forwardRef, memo, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useShallow } from 'zustand/react/shallow';

import ReactHookFormInput from '@/components/HookFormInput';
import { usePostData, usePutData } from '@/helpers/hooks';
import { compareObjects, emptyGuid } from '@/helpers/utils';
import useUpdateFilters from '@/hooks/useUpdateFilters';
import ActionCardContent from '@/pages/strategies/ActionCardContent';
import HeaderDiv from '@/pages/strategies/HeaderDiv';
import ActionAreaIcon from '@/pages/strategies/Icons/ActionAreaIcon';
import { useStrategyStore } from '@/pages/strategies/store/useStrategyStore';
import CardCountChip from '@/pages/strategies/wrapperCard/CardCountChip';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';
import { strategyItemFilters } from '@/types/itemFilter';
import { ResponseWithValue } from '@/types/responseTypes';

import { Action, ActionProps } from './types/Action.types';
import StyledCard from './wrapperCard/StyledCard';

const ActionCard = memo(
  forwardRef<HTMLDivElement, ActionProps>(function ActionCard(
    {
      handleCheckboxClick,
      isSelected,
      isActive,
      item,
      onHeaderClick,
      dropdownOptions,
      setAddingAction,
      setActiveActions,
      deliverableCount,
      existedActionOrder,
      disabled,
    },
    ref, // Forwarded ref
  ) {
    const routeApi = getRouteApi('/strategies');

    const updateFilters = useUpdateFilters<strategyItemFilters>(routeApi);
    const { actionId: primaryActionId } = routeApi.useSearch();

    const globalSettings = useGlobalSettingsStore(
      useShallow((state) => state.globalSettings),
    );
    const allowEditStrategy = useStrategyStore(
      useShallow((state) => state.getAllowEditStrategy()),
    );
    const formSchema = z.object({
      order: z.coerce
        .string()
        .min(1, 'Action order is required')
        .refine(
          (value) => !existedActionOrder.filter((x) => x !== item.order).includes(value),
          'Order exists',
        ),
      name: z.string().min(1, 'Action Name is required'),
      description: z.string(),
      areaId: z.string().min(1, 'Action Area is required'),
      primaryFocusId: z.string().optional(),
      secondaryFocusId: z.string().optional(),
    });

    type ActionFormFields = z.infer<typeof formSchema>;
    const defaultValues = useMemo(
      () => ({
        order: item.order,
        name: item.name ?? '',
        description: item.description ?? '',
        areaId: item.areaId ?? '',
        primaryFocusId: item.primaryFocusId ?? '',
        secondaryFocusId: item.secondaryFocusId ?? '',
      }),
      [item],
    );

    const methods = useForm<ActionFormFields>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: zodResolver(formSchema),
      defaultValues: defaultValues,
    });
    const { handleSubmit, formState } = methods;

    const { primaryStrategy } = useStrategyStore(
      useShallow((state) => ({
        primaryStrategy: state.primaryStrategy,
      })),
    );

    const queryClient = useQueryClient();

    const addActionMutation = usePostData<ResponseWithValue, unknown, Action, string>({
      url: `/Strategy/Action?strategyId=${primaryStrategy?.id}`,
    });
    const updateActionMutation = usePutData<
      Partial<Action>,
      unknown,
      Partial<Action>,
      unknown
    >(`/Strategy/Action`);

    const onBlur = async (data: ActionFormFields) => {
      if (disabled) return;

      if (!item.isNew) {
        const dirtyFields = compareObjects(
          formState.defaultValues as ActionFormFields,
          data,
        );
        if (!isEmpty(dirtyFields)) {
          const payload = { id: item.id, ...dirtyFields } as Partial<Action>;
          await updateActionMutation.mutateAsync(payload, {
            onSuccess: async () => {
              await queryClient.invalidateQueries({
                queryKey: ['strategyData'],
              });
            },
          });
        }
      }
    };
    const onSubmit = async (data: ActionFormFields) => {
      if (disabled) return;

      if (item.isNew) {
        const payload = {
          id: emptyGuid,
          order: data.order,
          name: data.name ?? '',
          description: data.description ?? '',
          areaId: data.areaId,
          primaryFocusId: data.primaryFocusId,
          secondaryFocusId: data.secondaryFocusId,
        } as Action;

        await addActionMutation.mutateAsync(payload, {
          onSuccess: (res) => {
            onHeaderClick({ ...item, id: res.value as any as string });
            setAddingAction?.(null);
          },
        });
      }
    };

    useEffect(() => {
      if (formState.isSubmitSuccessful) {
        setTimeout(() => {
          methods.reset({ ...defaultValues }, { keepValues: true });
        }, 2000);
      }
    }, [defaultValues, formState, methods]);

    const handleLastActionClick = () => {
      if (isActive && !item.isNew) updateFilters('actionId', item.id);
    };

    const actionAreaTitle = useMemo(() => {
      return dropdownOptions?.actionAreas.find((x) => x.id === item.areaId)?.title;
    }, [dropdownOptions, item.areaId]);

    // const focusAreaTitle = useMemo(() => {
    //   return dropdownOptions?.focusAreas.find((x) => x.id === item.focusId)?.title;
    // }, [dropdownOptions, item.focusId]);

    return (
      <FormProvider {...methods}>
        <form
          onBlur={() => {
            if (!item.isNew) {
              handleSubmit(onBlur)();
            }
          }}
          onSubmit={handleSubmit(onSubmit)}
          id="action-form"
        >
          <StyledCard
            isActive={isActive}
            className={primaryActionId === item.id || item.isNew ? 'gradient-border' : ''}
            ref={ref}
          >
            <CardContent sx={{ padding: '0!important', containerType: 'inline-size' }}>
              <HeaderDiv>
                <Checkbox
                  className="flex-shrink-0"
                  onMouseDown={handleLastActionClick}
                  checked={isSelected}
                  onChange={() => handleCheckboxClick?.(item.id)}
                  disabled={!allowEditStrategy || disabled}
                ></Checkbox>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexGrow: 1,
                    width: '100%',
                  }}
                >
                  {isActive || (item.isNew ?? false) ? (
                    <div style={{ width: '100%' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          flexGrow: 1,
                          containerType: 'inline-size',
                          '@container (max-width: 300px)': {
                            flexDirection: 'column',
                          },
                        }}
                      >
                        <ReactHookFormInput
                          onMouseDown={handleLastActionClick}
                          name={'order'}
                          label={'Order'}
                          style={{ width: '60px', marginRight: '3px' }}
                          disabled={!allowEditStrategy || disabled}
                        />
                        <ReactHookFormInput
                          onMouseDown={handleLastActionClick}
                          name={'name'}
                          label={'Name'}
                          disabled={!allowEditStrategy || disabled}
                          multiline={true}
                          maxRows={3}
                          minRows={1}
                        />
                      </Box>
                    </div>
                  ) : (
                    <Typography style={{ color: 'black' }} onClick={() => onHeaderClick}>
                      {item.order}. {item.name}
                    </Typography>
                  )}
                  <div className="ml-auto flex shrink-0 items-center gap-2">
                    {!isActive && actionAreaTitle !== undefined && (
                      <ActionAreaIcon actionAreaType={actionAreaTitle} />
                    )}

                    {/*{!isActive && focusAreaTitle !== undefined && (*/}
                    {/*  <FocusAreaIcon focusAreaType={focusAreaTitle} />*/}
                    {/*)}*/}

                    {!isActive && deliverableCount !== undefined && (
                      <CardCountChip title="Deliverables" count={deliverableCount} />
                    )}

                    <IconButton
                      onClick={() => onHeaderClick(item)}
                      onMouseDown={handleLastActionClick}
                    >
                      {isActive || (item.isNew ?? false) ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </div>
                </Box>
              </HeaderDiv>
              <Collapse in={isActive || (item.isNew ?? false)} mountOnEnter unmountOnExit>
                <ActionCardContent
                  dropdownOptions={dropdownOptions}
                  item={item}
                  setActiveActions={setActiveActions}
                  handleLastActionClick={handleLastActionClick}
                  disabled={disabled}
                />
              </Collapse>
            </CardContent>
          </StyledCard>
        </form>
      </FormProvider>
    );
  }),
  (prev, next) =>
    prev.item === next.item &&
    prev.isActive == next.isActive &&
    prev.disabled == next.disabled,
);
export default ActionCard;
