import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useShallow } from 'zustand/react/shallow';

import ConfettiComponent from '@/components/ConfettiComponent';
import HookFormInput from '@/components/HookFormInput';
import HookFormSelect from '@/components/HookFormSelect';
import DeleteOutlineIcon from '@/components/icons/DeleteOutlineIcon';
import { ImageIcon } from '@/components/icons/ImageIcon';
import { PhotoCameraIcon } from '@/components/icons/PhotoCameraIcon';
import {
  captureUserScreenshot,
  isValidUploadedImageType,
  showImageSizeWithLetter,
} from '@/helpers/fileUtils';
import { usePostData } from '@/helpers/hooks';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';

import BookDemoFormInput from './BookDemoFormInput';

const PHONE_REGEX = /^(?:\+61|0)([2-9])(?:[-.\s]?\d{3,4}){2,3}$/;

const cancelButtonSx = {
  marginRight: 2,
  color: '#202020',
  width: '122px',
  height: '54px',
  borderRadius: '15px',
  borderColor: '#20202040',
  fontWeight: 500,
  fontSize: 16,
  textTransform: 'none',
};

const submitButtonSx = {
  borderRadius: '15px',
  fontWeight: 500,
  fontSize: 16,
  boxShadow: '0px 0px 12px 0px #00000040 inset',
  border: '1.873px solid #BE7140',
  '&:hover': {
    border: '2px solid #B16B3D',
    color: '#ffffff',
  },
  '&:disabled': {
    backgroundColor: '#B16B3D1A',
    border: '4px solid #A763371A',
  },
};

const selectSx = {
  fontSize: 15,
  backgroundColor: '#FAF2EC',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#FAF2EC',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#A0AAB4',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#A0AAB4',
  },
};

type Props = {
  isModal?: boolean;
  handleOpen?: (isOpen: boolean) => void;
  defaultSelect?: string;
};

function ContactUsForm({ isModal = false, handleOpen, defaultSelect }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userEmail, organisationName } = useGlobalSettingsStore(
    useShallow((state) => state.globalSettings),
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [image, setImage] = useState<File | undefined>(undefined);

  const isLoggedIn = Boolean(userEmail);

  const formSchema = z.object({
    fullName: isLoggedIn
      ? z.string().optional()
      : z.string().min(1, 'Full name is required'),
    email: z.string().email().min(1, 'Email is required'),
    companyName: isLoggedIn
      ? z.string().optional()
      : z.string().min(1, 'Company name is required'),
    phone: z
      .string()
      .optional()
      .refine(
        (value) => !value || PHONE_REGEX.test(value),
        'Please provide a valid contact number',
      ),
    subject: z.string().min(1, 'Subject is required'),
    message: z.string().min(1, 'Message is required'),
  });

  type ContactUsFormFields = z.infer<typeof formSchema>;

  const methods = useForm<ContactUsFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: '',
      email: userEmail || '',
      companyName: organisationName || '',
      phone: '',
      subject: defaultSelect || (isLoggedIn ? 'General Enquiry' : 'weavr Software'),
      message: '',
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const contactUsMutation = usePostData<any, any, any>({
    url: `/Public/ContactUs`,
    options: {
      onSuccess: () => {
        setIsSubmitted(true);
        methods.reset();
        handleOpen?.(false);
      },
      onError: () => {
        enqueueSnackbar(
          'An error occurred when submitting the form. Please try again later.',
          { variant: 'error' },
        );
      },
    },
  });

  const onSubmit = async (data: ContactUsFormFields) => {
    const formData = new FormData();
    formData.append('email', data.email);
    formData.append('subject', data.subject);
    formData.append('message', data.message);
    formData.append('fullName', data.fullName ?? '');
    formData.append('companyName', data.companyName ?? '');
    formData.append('phone', data.phone ?? '');
    if (image) formData.append('image', image);
    await contactUsMutation.mutateAsync(formData);
  };

  const uploadOrDropFileHandler = (event: any) => {
    event.preventDefault();

    const isChangeEvent: boolean = event.type !== 'change' && event?.target?.value;
    if (event.type !== 'change' && event.type !== 'drop') {
      return;
    }

    // Validations *******************************************************
    const files: FileList | null =
      event.type === 'change' ? event?.target?.files : event?.dataTransfer?.files;
    if (files?.length === 0 || !files?.length) return;
    const file = files[0];
    if (!file || !isValidUploadedImageType(file)) {
      enqueueSnackbar('Invalid file format', { variant: 'error' });
      if (isChangeEvent) {
        event.target.value = '';
      }
      return;
    }
    // Validations *******************************************************

    setImage(file);
    if (isChangeEvent) {
      event.target.value = '';
    }
  };

  const clickGetScreenShotHandler = async () => {
    const capturedImage = await captureUserScreenshot();
    if (capturedImage !== null) setImage(capturedImage);
  };

  // useEffect(() => {
  //   trigger();
  // }, [isLoggedIn, trigger]);

  if (isSubmitted) return <SubmissionSuccess />;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id={isModal ? 'contactus-modal-form' : 'contactus-form'}
      >
        {!isLoggedIn && (
          <>
            <BookDemoFormInput name="fullName" label="Full Name" />
            <BookDemoFormInput name="companyName" label="Company Name" />
            <BookDemoFormInput name="phone" label="Phone Number (optional)" />
          </>
        )}
        <BookDemoFormInput name="email" label="Email" disabled={isLoggedIn} />
        <HookFormSelect
          name="subject"
          label="Subject"
          className="mb-4"
          size="medium"
          labelColor="#A0AAB4"
          sx={selectSx}
        >
          {getSubjectOptions(isLoggedIn).map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </HookFormSelect>
        <HookFormInput name="message" label="Message" multiline rows={3} />
        {isModal ? (
          <div className="w-[389px]">
            <h4 className="my-2 text-base font-semibold">Media Upload</h4>
            <div className="rounded-[10px] border border-dashed border-[#00000040]">
              <label
                htmlFor="imageUploader"
                className="flex h-16 items-center gap-2 p-4"
                onDragOver={(event) => event.preventDefault()}
                onDrop={(event) => uploadOrDropFileHandler(event)}
              >
                <input
                  id="imageUploader"
                  type="file"
                  accept=".jpeg, .png, .jpg, .JPG, .webp,, image/jpeg, image/png, image/webp"
                  hidden
                  multiple={false}
                  disabled={false}
                  onChange={(event) => uploadOrDropFileHandler(event)}
                />
                {!image ? (
                  <>
                    <span className="flex-1">
                      Take a screen shot or drag and drop here
                    </span>
                    <button
                      type="button"
                      onClick={clickGetScreenShotHandler}
                      className="flex flex-1 items-center justify-center gap-1 rounded-md border border-[#CECECE] bg-[#FAF2EC] px-3 py-2 duration-150 hover:bg-[#e4ddd7]"
                    >
                      <PhotoCameraIcon />
                      <span className="text-xs">Take a screen shot</span>
                    </button>
                  </>
                ) : (
                  <>
                    <div className="flex flex-1 items-center gap-2 text-xs">
                      <ImageIcon />
                      <div className="flex flex-col">
                        <span className="flex items-center font-semibold">
                          <p className="w-full max-w-48 truncate">{image.name}</p>
                        </span>
                        <span>{showImageSizeWithLetter(image)}</span>
                      </div>
                    </div>
                    <DeleteOutlineIcon
                      className="cursor-pointer"
                      onClick={(event) => {
                        event.preventDefault();
                        setImage(undefined);
                      }}
                    />
                  </>
                )}
              </label>
              {image ? (
                <div className="text-center">
                  <img
                    src={URL.createObjectURL(image)}
                    alt="uploaded image"
                    className="max-w-96 rounded-md"
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        <Divider sx={{ my: 2 }} />
        {isModal ? (
          <ModalButtons handleOpen={handleOpen} />
        ) : (
          <SubmitButton isValid={isValid} />
        )}
      </form>
    </FormProvider>
  );
}

function SubmissionSuccess() {
  return (
    <div className="flex w-full items-center justify-center p-6">
      <ConfettiComponent show={true} />
      <div className="submission-success flex w-full flex-col items-center justify-center text-center transition-all duration-500 ease-in-out">
        <h2 className="mb-4 text-2xl font-semibold">
          Thank you! Your message has been received.
        </h2>
        <p className="text-lg">We will contact you shortly.</p>
      </div>
    </div>
  );
}

function ModalButtons({ handleOpen }: { handleOpen?: (isOpen: boolean) => void }) {
  return (
    <div className="flex place-content-end">
      <Button
        form="contactus-form"
        sx={cancelButtonSx}
        variant="outlined"
        onClick={() => handleOpen?.(false)}
      >
        Cancel
      </Button>
      <Button
        form="contactus-modal-form"
        type="submit"
        variant="contained"
        sx={submitButtonSx}
      >
        Let&apos;s yarn!
      </Button>
    </div>
  );
}

function SubmitButton({ isValid }: { isValid: boolean }) {
  return (
    <Button
      variant="contained"
      disabled={!isValid}
      sx={submitButtonSx}
      fullWidth
      form="contactus-form"
      type="submit"
    >
      Submit enquiry
    </Button>
  );
}

function getSubjectOptions(isLoggedIn: boolean) {
  return isLoggedIn
    ? [
        { value: 'General Enquiry', label: 'General Enquiry' },
        { value: 'Feature Request', label: 'Feature Request' },
        { value: 'Bug Report', label: 'Bug Report' },
        { value: 'Account Support', label: 'Account Support' },
      ]
    : [
        { value: 'General Enquiry', label: 'General Enquiry' },
        { value: 'weavr Software', label: 'weavr Software' },
        { value: 'weavr Services', label: 'weavr Services' },
      ];
}

export default ContactUsForm;
