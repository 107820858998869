import { create } from 'zustand';

import { ConfirmOptions, ConfirmResult, DialogSize } from '@/types/confirmDialogTypes';
import { useShallow } from 'zustand/react/shallow';
interface ConfirmDialogState {
  isOpen: boolean;
  options: ConfirmOptions | null;
  resolve: ((value: ConfirmResult<any>) => void) | null;
  openDialog: <T extends ConfirmOptions>(options: T) => Promise<ConfirmResult<T>>;
  closeDialog: (result: ConfirmResult<any>) => void;
  size?: DialogSize;
}

export const useConfirmDialogStore = create<ConfirmDialogState>((set, get) => ({
  isOpen: false,
  options: null,
  resolve: null,
  openDialog: <T extends ConfirmOptions>(options: T) => {
    return new Promise<ConfirmResult<T>>((resolve) => {
      set({ isOpen: true, options, resolve: resolve as any });
    });
  },
  closeDialog: (result: ConfirmResult<any>) => {
    const { resolve } = get();
    if (resolve) {
      resolve(result);
    }
    set({ isOpen: false, options: null, resolve: null });
  },
}));

export function useConfirm() {
  const openDialog = useConfirmDialogStore(useShallow((state) => state.openDialog));

  return function confirm<T extends ConfirmOptions>(
    options: T,
  ): Promise<ConfirmResult<T>> {
    return openDialog(options) as Promise<ConfirmResult<T>>;
  };
}
